import { Injectable } from '@angular/core';
import { SearchWorkersFilters } from '../interfaces/search-filters';

@Injectable({
  providedIn: 'root',
})
export class SearchCandidatesFilterService {

  private filters: SearchWorkersFilters;

  constructor() {}

  setFilterList(filters: SearchWorkersFilters) {
    this.filters = filters;
  }

  getFilterList(): SearchWorkersFilters {
    return this.filters;
  }

}
