import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from 'src/app/core/auth/interfaces/role.model';

@Component({
  selector: 'app-register-choices-selection',
  templateUrl: './register-choices.component.html',
  styleUrls: ['./register-choices.component.scss'],
})
export class RegisterChoicesComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void { }

  redirectToCandidateRegistration() {
    const userType = UserType.WORKER;
    this.router.navigate(['auth/register/worker'], {
      queryParams: { userType },
    });
  }
  redirectToCompanyRegistration() {
    const userType = UserType.COMPANY;
    this.router.navigate(['auth/register/company'], {
      queryParams: { userType },
    });
  }
}
