<div (click)="messageListItemClick(proposalUuid)" class="link">
  <div class="row px-3 pt-3">
    <div class="col-10 title-card fw-bold fs-6">
      {{ title }}
    </div>
    <div class="col-2 d-inline-flex" *ngIf="proporalNew">
      <div class="circle"></div>
    </div>
  </div>
  <div class="row px-3 py-2 pb-0 text-color-dark">
    <div class="fs-6">{{ text1 }}</div>
  </div>
  <div class="row px-3 py-2">
    <div class="text-color-dark">
      <tui-svg class="font-12 text-color-dark" src="tuiIconUser"></tui-svg>
      {{ text2 }}
    </div>
  </div>
</div>
