<div class="contact-section pb-5 ngm" id="faqForm">
  <div class="card-light ngm">
    <h2 class="ngm">
      {{
        (isFAQ ? "HOME.CONTACT.FAQ_TITLE" : "HOME.CONTACT.TITLE") | translate
      }}
    </h2>
    <p *ngIf="!isFAQ" class="ngm">{{ "HOME.CONTACT.SUBTITLE" | translate }}</p>
    <p
      *ngIf="isFAQ"
      class="ngm"
      [innerHTML]="'HOME.CONTACT.FAQ_SUBTITLE' | translate"
    ></p>
    <form
      #contactForm
      ngNoForm
      name="contactForm"
      [action]="contactSalesForceUrl"
      method="POST"
      class="ngm"
    >
      <input type="hidden" name="orgid" [value]="contactOrgId" />
      <input type="hidden" name="retURL" [value]="returnUrl" />
      <input type="hidden" name="subject" [value]="subject" />
      <div class="contact-form ngm" [formGroup]="askForm">
        <div class="form-responsive ngm">
          <!-- NAME -->
          <div class="form-group ngm">
            <app-input-label
              [field]="askForm.get('name')"
              [name]="askForm.get('name').label"
              [filter]="false"
            ></app-input-label>
            <tui-input
              [formControl]="askForm.get('name') | castTextInput"
              [tuiTextfieldLabelOutside]="true"
            >
              {{ askForm.get("name").placeholder | translate }}
              <input tuiTextfield name="name" />
            </tui-input>
            <app-validation-input-message
              [statusMessage]="askForm.get('name').statusMessage"
            ></app-validation-input-message>
          </div>
          <!-- EMAIL -->
          <div class="form-group ngm">
            <app-input-label
              [field]="askForm.get('email')"
              [name]="askForm.get('email').label"
              [filter]="false"
            ></app-input-label>
            <tui-input
              [formControl]="askForm.get('email') | castTextInput"
              [tuiTextfieldLabelOutside]="true"
            >
              {{ askForm.get("email").placeholder | translate }}
              <input tuiTextfield name="email" />
            </tui-input>
            <app-validation-input-message
              [statusMessage]="askForm.get('email').statusMessage"
            ></app-validation-input-message>
          </div>
          <!-- PHONE -->
          <div class="form-group ngm">
            <app-input-label
              [field]="askForm.get('phone')"
              [name]="askForm.get('phone').label"
              [filter]="false"
            ></app-input-label>
            <tui-input
              [formControl]="askForm.get('phone') | castTextInput"
              [tuiTextfieldLabelOutside]="true"
            >
              {{ askForm.get("phone").placeholder | translate }}
              <input tuiTextfield name="phone" />
            </tui-input>
            <app-validation-input-message
              [statusMessage]="askForm.get('phone').statusMessage"
            ></app-validation-input-message>
          </div>
          <!-- SUBJECT -->
          <div class="form-group ngm">
            <app-input-label
              [field]="askForm.get('subjectSelect')"
              [name]="askForm.get('subjectSelect').label"
              [filter]="false"
            ></app-input-label>
            <app-select-input
              [control]="askForm.get('subjectSelect') | castSelectInput"
            >
            </app-select-input>
            <app-validation-input-message
              [statusMessage]="askForm.get('subjectSelect').statusMessage"
            ></app-validation-input-message>
          </div>
        </div>
        <!-- MESSAGE -->
        <div class="form-responsive single-col ngm">
          <div class="form-group ngm">
            <app-input-label
              [field]="askForm.get('description')"
              [name]="askForm.get('description').label"
              [filter]="false"
            ></app-input-label>
            <tui-text-area
              class="text-area"
              [formControl]="askForm.get('description')"
              [expandable]="true"
            >
              <textarea
                name="description"
                [placeholder]="
                  askForm.get('description').placeholder | translate
                "
                tuiTextfield
              ></textarea>
            </tui-text-area>
            <app-validation-input-message
              [statusMessage]="askForm.get('description').statusMessage"
            ></app-validation-input-message>
          </div>
        </div>
        <!-- PRIVACY -->
        <form
          [formGroup]="privacyForm"
          class="terms-form ngm"
          *ngIf="privacyForm"
        >
          <tui-checkbox-labeled formControlName="privacyFlag" class="mb-2">
            {{ "HOME.CONTACT.FORM.PRIVACY.TEXT" | translate }}
            <a (click)="viewPrivacy()" class="privacy-link">
              {{ "HOME.CONTACT.FORM.PRIVACY.LINK" | translate }}
            </a>
            <b>*</b>
          </tui-checkbox-labeled>
          <small
            *ngIf="
              privacyForm.get('privacyFlag').touched &&
              privacyForm.get('privacyFlag').errors?.required
            "
            role="alert"
            aria-label="Validation error"
            class="color-status-danger"
            ><strong> {{ "VALIDATIONS.REQUIRED" | translate }} </strong></small
          >
        </form>
      </div>
    </form>
    <button
      class="btn btn-green mt-3 ngm"
      [disabled]="!askForm.valid || !privacyForm.valid"
      (click)="send()"
    >
      {{ "HOME.CONTACT.FORM.BUTTON" | translate }}
    </button>
  </div>
  <form [action]="contactSalesForceUrl" method="POST">
    <input type="hidden" name="orgid" [value]="contactOrgId" />
    <input type="hidden" name="retURL" value="https://www.inplace.it/who" />
    <input type="hidden" name="subject" [value]="subject" />

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail" value="tfranchi@deloitte.it">    -->
    <!--  ----------------------------------------------------------------------  -->
    <!-- <input id="name" maxlength="80" name="name" size="20" type="hidden" />
    <input id="email" maxlength="80" name="email" size="20" type="hidden" />
    <input id="phone" maxlength="40" name="phone" size="20" type="hidden" />
    <input id="subject" maxlength="80" name="subject" size="20" type="hidden"/>
    <textarea type="hidden" name="description"></textarea> -->
  </form>
</div>
