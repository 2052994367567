<div
  class="user-actions-section my-5"
  [ngClass]="userType == UserTypeEnum.WORKER ? 'worker' : 'company'"
>
  <h4>
    {{
      (userType == UserTypeEnum.WORKER
        ? "PRIVATE.WORKER.USER_DETAIL.ACTIONS_TITLE"
        : "PRIVATE.COMPANY.USER.USER_PROFILE.BOX.ACTIONS_TITLE"
      ) | translate
    }}
  </h4>
  <div class="user-action-cards">
    <div
      class="user-action-card cursor-pointer"
      *ngFor="let card of cards"
      (click)="navigate(card.link, card.urlFragment)"
    >
      <div class="card-number card-light mb-3">
        <tui-svg src="tuiIconArrowRightLarge"></tui-svg>
        <h1>{{ card.number }}</h1>
      </div>
      <div class="card-link">
        {{ card.title | translate | uppercase }}
      </div>
    </div>
  </div>
</div>
