export enum CloseOverlayNextStep {
  NONE = 'NONE',
  GENERAL_LOGIN_OVERLAY_OPEN = 'GENERAL_LOGIN_OVERLAY_OPEN',
  CANDIDATE_LOGIN_OVERLAY_OPEN = 'CANDIDATE_LOGIN_OVERLAY_OPEN',
  COMPANY_LOGIN_OVERLAY_OPEN = 'COMPANY_LOGIN_OVERLAY_OPEN',
  REDIRECT_TO_CANDIDATE_REGISTRATION = 'REDIRECT_TO_CANDIDATE_REGISTRATION',
  REDIERCT_TO_COMPANY_REGISTRATION = 'REDIERCT_TO_COMPANY_REGISTRATION',
  REDIRECT_TO_GENERAL_REGISTRATION = 'REDIRECT_TO_GENERAL_REGISTRATION',
  REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN',
  REDIRECT_TO_CANDIDATE_PROFILE = 'REDIRECT_TO_CANDIDATE_PROFILE',
  REDIRECT_TO_CANDIDATE_INCOMPLETE_REGISTRATION = 'REDIRECT_TO_CANDIDATE_INCOMPLETE_REGISTRATION'
}
