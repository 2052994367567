<div class="register-choices my-5">
  <div
    class="register-choice worker cursor-pointer"
    (click)="redirectToCandidateRegistration()"
  >
    <tui-svg src="tuiIconArrowRight"></tui-svg>
    <h3>{{ "REGISTER.REGISTER_AS_CANDIDATE" | translate }}</h3>
  </div>
  <div
    class="register-choice company cursor-pointer"
    (click)="redirectToCompanyRegistration()"
  >
    <tui-svg src="tuiIconArrowRight"></tui-svg>
    <h3>{{ "REGISTER.REGISTER_AS_COMPANY" | translate }}</h3>
  </div>
</div>
