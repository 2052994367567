import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig, backendApi } from 'src/config';
import { catchError, map, timeout } from 'rxjs/operators';

export interface PathParams {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  onError$ = new Subject();

  constructor(private _http: HttpClient) {}

  request<T>(
    apiName: string,
    body?: any,
    queryParams?: any,
    pathParams?: PathParams
  ): Observable<T> {
    var apiConfig = this._getApiConfig(apiName);
    return this._request(apiConfig, body, queryParams, pathParams).pipe(
      timeout(apiConfig.timeout),
      catchError((err) => {
        return this._handleError(err);
      }),
      map((data) => {
        return this._handleSuccess(data);
      })
    );
  }

  getUrlByApiName(apiName: string, pathParams?: PathParams): string {
    var urlWithParams = this._bindPathParams(
      this._getApiConfig(apiName).url,
      pathParams
    );
    return urlWithParams;
  }

  private _request(
    apiConfig: ApiConfig,
    body: any,
    queryParams: any,
    pathParams: PathParams
  ): Observable<object> {
    return this._http.request(
      apiConfig.method,
      this._bindPathParams(apiConfig.url, pathParams),
      {
        headers: apiConfig.headers,
        observe: 'body',
        body: body || {},
        params: queryParams || null,
        responseType: 'json',
      }
    );
  }

  private _getApiConfig(apiName: string): ApiConfig {
    var apiConfig = backendApi.find((el) => el.name === apiName);
    if (!apiConfig) {
      throw new Error('No api found');
    }
    if (!apiConfig.timeout) apiConfig.timeout = 60000;
    var headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=UTF-8'
    );
    if (apiConfig.headers) {
      for (var key in apiConfig.headers) {
        if (apiConfig.headers[key]) {
          headers = headers.set(key, apiConfig.headers[key].toString());
        }
      }
    }
    apiConfig.headers = headers;
    return apiConfig;
  }

  private _bindPathParams(url: string, params: PathParams): string {
    if (params === void 0) {
      params = {};
    }
    var urlInternal = url;
    for (var key in params) {
      if (params[key]) {
        urlInternal = urlInternal.replace(
          new RegExp('{' + key + '}', 'g'),
          params[key]
        );
      }
    }
    return urlInternal;
  }

  private _handleSuccess(response: any) {
    return response;
  }

  private _handleError(error: any) {
    console.error(error);
    this.onError$.next(error);
    return throwError(error);
  }
}
