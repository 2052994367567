import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'documentState' })
export class DocumentStatePipe implements PipeTransform {
  constructor(private i18n: TranslateService) {}
  transform(documentPhase: string): string {
    if (documentPhase === null) {
      return this.i18n.instant(
        `PRIVATE.COMPANY.DOCUMENTS.TAB.CONTRACTS.BUTTON.STATUS.NULL`
      );
    } else {
      return this.i18n.instant(
        `PRIVATE.COMPANY.DOCUMENTS.TAB.CONTRACTS.BUTTON.STATUS.${documentPhase}`
      );
    }
  }
}
