<div class="download-app-section py-5" #container *ngIf="false">
  <div class="img" #image>
    <img src="./assets/images/download_app_phone.svg" />
  </div>
  <div class="content">
    <div class="logo">
      <img src="./assets/icons/inPlace_logo_square.svg" />
    </div>
    <h3>{{ "HOME.DOWNLOAD_APP" | translate }}</h3>
    <div class="d-flex align-items-center justify-content-between my-5">
      <div class="cursor-pointer" (click)="clickGoogleStore()">
        <img width="200" src="./assets/images/img_store_google.svg" />
      </div>
      <div class="cursor-pointer" (click)="clickAppleStore()">
        <img width="160" src="./assets/images/img_store_apple.svg" />
      </div>
    </div>
  </div>
</div>
