import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';

@Pipe({ name: 'castFileInput' })
export class CastFileInputPipe implements PipeTransform {
  transform<S>(value: S): FileInput {
      return value as unknown as FileInput;
  }
}

export interface FileInputConstructor<T>
  extends Omit<BaseInputConstructor<T>, 'pattern'> {
  buttonPlaceholder: string;
  showUploadBtn?: boolean;
  labelUploadBtn?: string;
  upload?: (file: File) => void;
  maxSize?: number;
  accept?: string;
}

export class FileInput<T = string> extends BaseInput<T> {
  inputType = 'file';
  buttonPlaceholder: string;
  showUploadBtn?: boolean;
  labelUploadBtn = 'FILE_INPUT_UPLOAD';
  upload?: (file: File) => void;
  maxSize?: number = 104857600;
  accept?: string;

  constructor(options: FileInputConstructor<T>) {
    super(options);
    this.buttonPlaceholder = options.buttonPlaceholder;
    this.showUploadBtn = options.showUploadBtn;
    this.upload = options.upload;

    if (options.labelUploadBtn) {
      this.labelUploadBtn = options.labelUploadBtn;
    }
    if (options.maxSize) {
      this.maxSize = options.maxSize;
    }

    if (options.accept) {
      this.accept = options.accept;
    }
  }
}
