import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY_ARRAY,
  TuiBooleanHandler,
  TuiDay,
  TuiTime,
  TuiValidationError,
} from '@taiga-ui/cdk';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { CCNLLevelRecord } from '../pages/private/pages/company/pages/documents/pages/offert/pages/offert-detail/pages/offert-simulation/interfaces/ccnlInfo';
import { MacroDocumentType } from '../pages/private/pages/worker/pages/user/models/macro-documents-type';
import { DocumentStatus } from '../pages/private/pages/worker/pages/user/pages/user-worker-profile/models/documents-status';
import { SelectOption } from '../shared/form';
import { DecimalComaPipe } from '../shared/pipes/decimal-coma.pipe';
import { Sede } from './auth/interfaces/company.model';
import { RoleType } from './auth/interfaces/role.model';

export enum BadgePage {
  SEARCH = 'SEARCH',
  PROFILE = 'PROFILE',
}
export enum BadgeName {
  COMPLETA_PROFILO = 'COMPLETA_PROFILO',
  RICHIEDI_OFFERTA_COMMERCIALE = 'RICHIEDI_OFFERTA_COMMERCIALE',
  ATTIVA_FIRMA_DIGITALE = 'ATTIVA_FIRMA_DIGITALE',
}
export enum BadgeType {
  BADGE = 'BADGE',
  BUTTON = 'BUTTON',
}
export interface BadgeListItem {
  page: BadgePage;
  badges: Badge[];
}
export interface Badge {
  name: BadgeName;
  type: BadgeType;
}

@Injectable()
export class UtilityService {
  constructor(
    private apiClient: ApiClient,
    private decimalCommaPipe: DecimalComaPipe,
    private i18n: TranslateService
  ) {}

  getStates(): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.countries')).map((e) => ({
      cod: e[0] as string,
      des: e[1] as string,
    }));
  }

  getNationalities(): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.nationalities')).map(
      (e) => ({ cod: e[0] as string, des: e[1] as string })
    );
  }

  getRegions(): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.regions')).map((e) => ({
      cod: e[0] as string,
      des: e[1] as string,
    }));
  }

  getProvinces(regionCode: string): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.province2region'))
      .filter((e) => e[1] == regionCode)
      .map((e) => e[0])
      .map((code) => ({
        cod: code,
        des: this.i18n.instant('MAPPINGS.provinces')[code],
      }));
  }

  getCities(provinceCode: string): SelectOption[] {
    const mapped = Object.entries(this.i18n.instant('MAPPINGS.mun2province'))
      .filter((e) => e[1] == provinceCode)
      .map((e) => e[0])
      .map((code) => ({
        cod: code,
        des: this.i18n.instant('MAPPINGS.municipalities')[code],
        cap: this.i18n.instant('MAPPINGS.mun2zipcode')[code],
      }));
    return mapped;
  }

  getCitiesList() {
    return Object.entries(this.i18n.instant('MAPPINGS.municipalities')).map(
      (e) => ({
        cod: e[0] as string,
        des: (e[1] as string)
          .toLowerCase()
          .replace(/\b\w/g, (s) => s.toUpperCase()),
      })
    );
  }

  getLingue(): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.languages')).map((e) => ({
      cod: e[0] as string,
      des: e[1] as string,
    }));
  }

  getCityByCod(cod: string) {
    return Object.entries(this.i18n.instant('MAPPINGS.municipalities')).find(
      (e) => e[0] == cod && e[1]
    );
  }

  getLingueFiltered(languageCodToFilterOut: string[]): SelectOption[] {
    if (languageCodToFilterOut.length > 0) {
      var languages = [];
      this.getLingue().forEach((language) => {
        if (!languageCodToFilterOut.includes(language.cod)) {
          languages.push(language);
        }
      });
      return languages;
    } else {
      return this.getLingue();
    }
  }

  sortLanguages(languages: string[]) {
    languages.unshift('ITALIANO', 'INGLESE', 'FRANCESE', 'SPAGNOLO', 'TEDESCO');
    return [...new Set(languages)];
  }

  sortCities(cities: { cod: string; des: string }[]) {
    return cities.sort((a, b) =>
      a.des.toLowerCase().localeCompare(b.des.toLowerCase())
    );
  }

  getLanguageLevels(): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.languagelevels')).map(
      (e) => ({ cod: e[0] as string, des: e[1] as string })
    );
  }

  getEducationLevels(): SelectOption[] {
    return Object.entries(this.i18n.instant('MAPPINGS.educationlevels')).map(
      (e) => ({ cod: e[0] as string, des: e[1] as string })
    );
  }

  getDocumentsType(
    role: RoleType,
    range: MacroDocumentType
  ): Observable<SelectOption[]> {
    return this.apiClient
      .request<{
        documentType: {
          [x: string]: {
            documentExt: string[];
            types: {
              [x: string]: string;
            };
          }[];
        };
      }>('getDocumentsType', null, { range, role })
      .pipe(
        map((resp) => {
          return Object.keys(resp.documentType[range][0].types).map((doc) => {
            return {
              cod: doc,
              des: resp.documentType[range][0].types[doc],
            };
          });
        })
      );
  }

  getCcnlLevelList(
    ccnlCod: string,
    taskCod: string
  ): Observable<SelectOption[]> {
    return this.apiClient
      .request<CCNLLevelRecord[]>('getCcnlLevels', null, {
        ccnlCod,
        taskCod,
      })
      .pipe(
        map((res) =>
          res.map((level) => ({
            cod: level.cod,
            des: `${level.des}${
              level.rml
                ? ' - ' +
                  this.decimalCommaPipe.transform(level.rml) +
                  this.i18n.instant('UTILS.VALUTA')
                : ''
            }`,
          }))
        )
      );
  }

  getTaskList(ccnlCod: string): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getTaskWorker', null, {
      ccnlCod,
    });
  }

  getTaskListNoSelect(ccnlCod: string): Observable<SelectOption[]> {
    return this.apiClient.request('getTaskWorker', null, {
      ccnlCod,
    });
  }

  getTaskListAll(): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getTaskWorker');
  }

  getJobList(): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getJobs');
  }

  getCcnlList(): Observable<SelectOption[]> {
    return this.apiClient.request('getCcnlList'); // returns CcnlInfo object
  }

  getCcnlListAzienda(): Observable<SelectOption[]> {
    return this.apiClient.request('getCcnlClient');
  }

  getAttivitaATECO(): Observable<SelectOption[]> {
    return this.apiClient.request('getAtecoList').pipe(
      map((options: SelectOption[]) =>
        options.map((option) => ({
          cod: option.cod,
          des: option.cod + ' ' + option.des,
        }))
      )
    );
  }

  getSettoriList(): Observable<SelectOption[]> {
    return of(
      Object.entries(this.i18n.instant('MAPPINGS.sectors')).map((e) => ({
        cod: e[0] as string,
        des: e[1] as string,
      }))
    );
  }

  getListGroundForAppeal(): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getListGroundForAppeal');
  }

  getCausGroundForAppeal(): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getCausGroundForAppeal');
  }

  getListOfWorkingPlaces(): Observable<SelectOption[]> {
    return this.apiClient
      .request<Sede[]>('getListOfWorkingPlaces')
      .pipe(
        map((resp) => resp.map((elm) => ({ cod: elm.uuid, des: elm.nome })))
      );
  }

  getTuiDateFromDate(date: string | number): TuiDay | string {
    if (date) {
      if (typeof date == 'number') {
        date = new Date(date).toISOString();
      }
      if (date.includes('T')) {
        date = date.split('T')[0];
      }
      const tuiDate = new TuiDay(
        +date.split('-')[0],
        +date.split('-')[1] - 1,
        +date.split('-')[2]
      );
      return tuiDate;
    }
    return date as string;
  }

  /*
   * Get TuiDate and TuiTime fron local date time
   */
  getTuiDateTimeFromDate(date: string | number): [TuiDay, TuiTime] {
    if (date) {
      if (typeof date == 'number') {
        date = new Date(date).toLocaleString();
      }
      if (date.includes(', ')) {
        const time = date.split(', ')[1];
        date = date.split(', ')[0];

        const tuiDate = new TuiDay(
          +date.split('/')[2],
          +date.split('/')[1] - 1,
          +date.split('/')[0]
        );

        const tuiTime = new TuiTime(+time.split(':')[0], +time.split(':')[1]);
        return [tuiDate, tuiTime];
      }
    }
    return;
  }

  getDateTimeFromTuiDate(dateTime:TuiDay): Date {
    return new Date(dateTime.year, dateTime.month, dateTime.day)
  }

  /**
   * Get  datetime string YYYY-MM-DD HH:mm from TuiDate and TuiTime
   */
  getDateTimeFromTuiDateTime(dateTime: [TuiDay, TuiTime]): string {
    if (dateTime && dateTime.length > 0 && dateTime[0] && dateTime[1]) {
      return `${dateTime[0].year}-${dateTime[0].month + 1 < 10 ? '0' : ''}${
        dateTime[0].month + 1
      }-${dateTime[0].day < 10 ? '0' : ''}${dateTime[0].day} ${
        dateTime[1].hours < 10 ? '0' : ''
      }${dateTime[1].hours}:${dateTime[1].minutes < 10 ? '0' : ''}${
        dateTime[1].minutes
      }`;
    }

    return;
  }

  getLocalDateTimeFromTuiDay(tuiDay: TuiDay): string {
    if (!tuiDay) {
      return '';
    }
    const pad2 = (n) => ('' + n).padStart(2, '0');

    const y = tuiDay.year;
    const m = pad2(tuiDay.month + 1);
    const d = pad2(tuiDay.day);
    return `${y}-${m}-${d}T00:00:00`;
  }

  getSimpleDateStringFromTuiDay(tuiDay: TuiDay): string {
    if (!tuiDay) {
      return '';
    }
    const pad2 = (n) => ('' + n).padStart(2, '0');

    const y = tuiDay.year;
    const m = pad2(tuiDay.month + 1);
    const d = pad2(tuiDay.day);
    return `${y}-${m}-${d}`;
  }

  /**
   * Get  datetime string YYYY-MM-DDTHH:mm:ss.sss from YYYY-MM-DD HH:mm
   */
  getLocalDateTimeFromDateTime(dateTime: string): string {
    if (dateTime && dateTime.includes(' ')) {
      const fixedDateTime =
        dateTime.split(' ')[0] + 'T' + dateTime.split(' ')[1];
      return fixedDateTime;
    }

    return;
  }

  /*
   * Get TuiDate fron local date time
   */
  getTuiDateFromLocalDate(date: string): TuiDay {
    if (date) {
      if (date.includes(', ')) {
        date = date.split(', ')[0];

        const tuiDate = new TuiDay(
          +date.split('/')[2],
          +date.split('/')[1] - 1,
          +date.split('/')[0]
        );

        return tuiDate;
      }
    }
    return;
  }

  getEditableDocStatus(status: string): boolean {
    if (
      status === DocumentStatus.DOC_UPLOAD_FAILED ||
      status === DocumentStatus.DOC_UPLOAD_SUCCESS_FINAL ||
      status === DocumentStatus.CERTIFIED ||
      status === DocumentStatus.CERT_FAILED ||
      status === DocumentStatus.TO_PROCESS ||
      status === DocumentStatus.DOC_REVOKED ||
      !status
    ) {
      return true;
    }
    return false;
  }

  getDownloadableDocStatus(status: string): boolean {
    if (
      status === DocumentStatus.DOC_UPLOAD_SUCCESS_FINAL ||
      status === DocumentStatus.CERTIFIED
    ) {
      return true;
    }
    return false;
  }

  createControlValidator(handler: TuiBooleanHandler<string>): ValidatorFn {
    return ({ value }: AbstractControl) => {
      const invalidTags = value ? value.filter(handler) : EMPTY_ARRAY;

      return invalidTags.length > 0
        ? {
            tags: new TuiValidationError('Presenti tag non validi (8 cifre)'),
          }
        : null;
    };
  }

  getGCardReasons(): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getGCardReason');
  }

  getGCards(): Observable<SelectOption[]> {
    return this.apiClient.request<SelectOption[]>('getGCard');
  }

  createConversationWithCandidate(candidateId: number, taskCode: string) {
    return this.apiClient.request<number>('createConversation', {
      candidateId: candidateId,
      taskCode: taskCode,
    });
  }

  getConversationsList() {
    return this.apiClient.request('getConversationsList');
  }

  getConversationMessages(conversationId: number) {
    return this.apiClient.request('getConversationMessages', null, null, {
      conversationId,
    });
  }

  updateJob(conversationId: number, taskCode: string) {
    return this.apiClient.request('updateJob', { taskCode: taskCode }, null, {
      conversationId,
    });
  }

  sendMessage(conversationId: number, message: string) {
    return this.apiClient.request('sendMessage', { text: message }, null, {
      conversationId,
    });
  }

  suspendConversationConversation(conversationId: number) {
    return this.apiClient.request('suspendConversation', null, null, {
      conversationId,
    });
  }

  reactivateConversation(conversationId: number) {
    return this.apiClient.request('reactivateConversation', null, null, {
      conversationId,
    });
  }

  getLastMessages(conversationId: number, messageId: number) {
    return this.apiClient.request('getLastMessages', null, null, {
      conversationId,
      messageId,
    });
  }

  getConversationListDelegate(conversationId: number) {
    return this.apiClient.request('getConversationListDelegate', null, null, {
      conversationId,
    });
  }

  updateConversationNotificationFrequency(frequency: string) {
    return this.apiClient.request(
      'updateConversationNotificationFrequency',
      null,
      { frequency: frequency },
      null
    );
  }
}
