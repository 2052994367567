import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { OfferResponse } from '../../pages/private/pages/company/pages/documents/pages/offert/interfaces';
import { RequestAvailabilityRequest } from '../../pages/private/pages/company/pages/messages/interfaces/contract-summary.model';
import { SearchDetail } from '../../pages/private/pages/company/pages/search-workers/interfaces/search-detail';
import {
  UrlResponse,
  VideoUrlResponse,
} from '../../pages/private/pages/worker/pages/documents/model/documents.model';
import {
  SaveFavoriteResponse,
  SearchList,
} from '../interfaces/company-search-list';
import {
  FilterOrderBy,
  SearchWorkersFiltersResponse,
} from '../interfaces/search-filters';
import { SearchWorkersResponse } from '../interfaces/search-workers.model';
import {
  EducationLevel,
  LanguageLevel,
} from './../../pages/private/pages/worker/pages/user/interfaces/worker-curriculum';

export interface SearchPairResponse {
  keyword: string;
  address: string;
}

interface ApiParams {
  keyword?: string;
  additionalKeyword?: string;
  municipality?: string;
  distanceInKm?: number;
  sector?: string;
  status?: string;
  employed?: boolean;
  previousExperiencesInInplace?: boolean;
  educationLevel?: EducationLevel;
  language?: string;
  languageLevel?: LanguageLevel;
  orderBy?: FilterOrderBy;
}

@Injectable()
export class SearchCandidateService {
  constructor(private apiClient: ApiClient, private httpClient: HttpClient) {}

  acceptedOffers(): Observable<OfferResponse[]> {
    return this.apiClient.request('acceptedOffers');
  }

  getFilters(
    isPublic: boolean = false
  ): Observable<SearchWorkersFiltersResponse> {
    if (isPublic) return this.apiClient.request('getPublicCompanyFilters');
    else return this.apiClient.request('getCompanyFilters');
  }

  search(
    params,
    pageNumber,
    isPublic: boolean = false
  ): Observable<SearchList> {
    const apiParams = {
      orderBy: FilterOrderBy.COMPATIBILITA,
      page: pageNumber,
    };
    if (params.what) {
      apiParams['keyword'] = params.what;
    }
    if (params.where) {
      apiParams['address'] = params.where;
    }
    if (params.educationLevel) {
      apiParams['educationLevel'] = params.educationLevel.cod;
    }
    if (params.previousExperience) {
      apiParams['hired'] = params.previousExperience.cod;
    }
    if (params.language) {
      apiParams['languageId1'] = params.language.cod;
    }
    if (params.languageLevel) {
      apiParams['languageLevel1'] = params.languageLevel.cod;
    }
    if (params.sector) {
      apiParams['sectorId'] = params.sector.cod;
    }
    if (params.status) {
      apiParams['status'] = params.status.cod;
    }
    if (params.distance) {
      apiParams['distanceInKm'] = params.distance;
    }
    if (params.orderBy) {
      apiParams.orderBy = params.orderBy;
    }
    if (isPublic)
      return this.apiClient.request('getPublicCompanySearch', null, apiParams);
    else return this.apiClient.request('getCompanySearch', null, apiParams);
  }

  searchWorkers(
    params,
    isPublic: boolean = false
  ): Observable<SearchWorkersResponse> {
    const apiParams: ApiParams = {
      orderBy: FilterOrderBy.COMPATIBILITA,
    };
    if (params.what) {
      apiParams.keyword = params.what;
    }
    if (params.where) {
      apiParams.municipality = params.where.cod;
      apiParams.distanceInKm = params?.distance || 10;
    }
    if (params.extraKeyword) {
      apiParams.additionalKeyword = params.extraKeyword;
    }
    if (params.sector) {
      apiParams.sector = params.sector.cod;
    }
    if (params.status) {
      apiParams.employed = params.status.cod;
    }
    if (params.previousExperiencesInInplace) {
      apiParams.previousExperiencesInInplace =
        params.previousExperiencesInInplace.cod;
    }
    if (params.educationLevel) {
      apiParams.educationLevel = params.educationLevel.cod;
    }
    if (params.language && params.languageLevel) {
      apiParams.language = params.language.cod;
      apiParams.languageLevel = params.languageLevel.cod;
    }
    if (params.distance && params.where) {
      apiParams.distanceInKm = params?.distance;
    }
    if (params.orderBy) {
      apiParams.orderBy = params.orderBy;
    }
    if (isPublic) {
      return this.apiClient.request('getPublicCompanySearch', null, apiParams);
    } else {
      return this.apiClient.request('getCompanySearch', null, apiParams);
    }
  }

  getWorkerByFiscalCode(fiscalCode: string): Observable<SearchWorkersResponse> {
    return this.apiClient.request('getWorkerByFiscalCode', null, {
      fiscalCode,
    });
  }

  detail(params): Observable<SearchDetail> {
    return this.apiClient.request('getSearchWorkerDatail', null, params);
  }

  saveFavoriteCandidate(id: number): Observable<SaveFavoriteResponse> {
    return this.apiClient.request('saveFavorite', null, { id });
  }

  addAvailabilityRequest(params: RequestAvailabilityRequest): Observable<any> {
    return this.apiClient.request('addAvailabilityRequest', params);
  }

  getVideoCVCandidate(workerId: number): Observable<VideoUrlResponse> {
    return this.apiClient.request('getVideoCVCandidate', null, null, {
      workerId,
    });
  }

  getCandidatePhoto(workerId) {
    return this.apiClient.request('getCandidatePhoto', null, null, {
      workerId,
    });
  }

  getCVCandidate(workerId: number): Observable<UrlResponse> {
    return this.apiClient.request('getCVCandidate', null, null, {
      workerId,
    });
  }

  getCandidateDossier(workerId: number, name: string) {
    // return this.apiClient.request('getCandidateDossier', null, null, { workerId, });
    return this.httpClient
      .get(
        this.apiClient.getUrlByApiName('getCandidateDossier', {
          workerId: workerId,
        }),
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .pipe(
        map((response) => {
          const blob = new Blob([response.body], { type: response.body.type });
          saveAs(blob, 'dossier_' + name + '.pdf');
        })
      );
  }

  getActiveOffert() {
    return this.apiClient.request('getActiveOffert');
  }

  requestInterview(candidateArcaId: number): Observable<any> {
    return this.apiClient.request('requestInterview', null, null, {
      candidateArcaId,
    });
  }

  requestVideoInterview(candidateArcaId: number): Observable<any> {
    return this.apiClient.request('requestVideoInterview', null, null, {
      candidateArcaId,
    });
  }
}
