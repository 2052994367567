import { Component, Input, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { ICON_TYPE } from 'src/app/shared/notifications/enum/icon-type';
import { Notification } from 'src/app/shared/notifications/interfaces/notification';

@Component({
  selector: 'app-notification-carousel-card',
  templateUrl: './notification-carousel-card.component.html',
  styleUrls: ['./notification-carousel-card.component.scss'],
})
export class NotificationCarouselCardComponent implements OnInit {
  @Input() notification: Notification;
  @Input() userType: UserType;

  iconTypeMap = ICON_TYPE;

  constructor() { }

  ngOnInit(): void { }

  getPath(iconType: string): string {
    if (iconType in this.iconTypeMap) {
      return `/private/${this.userType}${this.iconTypeMap[iconType].path}`;
    }
    return `/private/${this.userType}/notifications`;
  }

  getDate(): string {
    return new Date(this.notification.sendDate).toLocaleString('it', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  }
}
