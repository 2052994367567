import { Pipe, PipeTransform } from '@angular/core';
import { fullTimeWorkMap } from 'src/app/pages/private/pages/company/pages/documents/pages/offert/pages/offert-detail/pages/offert-simulation/interfaces/time-sheet-map';

@Pipe({ name: 'getFullTime' })
export class FullTimePipe implements PipeTransform {
  transform(typeTime: number): string | number {
    if ((typeTime || typeTime === 0) && fullTimeWorkMap.get(typeTime)) {
      return fullTimeWorkMap.get(typeTime).des;
    }
    return typeTime;
  }
}
