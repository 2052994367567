import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import {
  NbAuthJWTToken,
  NbAuthSimpleInterceptor,
} from '@nebular/auth';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const AUTH_INTERCEPTOR_HEADER = new InjectionToken<string>(
  'AUTH_INTERCEPTOR_HEADER'
);
export const NB_AUTH_TOKEN_INTERCEPTOR_FILTER = new InjectionToken<string>(
  'NB_AUTH_TOKEN_INTERCEPTOR_FILTER'
);
@Injectable()
export class AuthInterceptor extends NbAuthSimpleInterceptor {
  constructor(
    injector: Injector,
    @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter: any,
    @Inject(AUTH_INTERCEPTOR_HEADER)
    protected headerName: string,
  ) {
    super(injector);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      return this.authService.isAuthenticatedOrRefresh().pipe(
        switchMap((authenticated) => {
          if (authenticated) {
            if (req.url.includes('directline')) {
              req = req.clone({
                setHeaders: {
                  [this.headerName]:
                    'Bearer ' + environment.chatbot.tokenChatUser,
                },
              });
              return next.handle(req);
            }
            return this.authService.getToken().pipe(
              switchMap((token: NbAuthJWTToken) => {
                const tokenValue = `Bearer ${token.getValue()}`;
                req = req.clone({
                  setHeaders: {
                    [this.headerName]: tokenValue,
                  },
                });

                return next.handle(req);
              })
            );
          } else {
            if (req.url.includes('directline')) {
              req = req.clone({
                setHeaders: {
                  [this.headerName]:
                    'Bearer ' + environment.chatbot.tokenChatUser,
                },
              });
            }
            // Request is sent to server without authentication so that the client code
            // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
            return next.handle(req);
          }
        })
      );
    } else if (req.url.includes('refresh-token')) {
      req = req.clone({
        body: {
          refreshToken: localStorage.getItem('refresh-token'),
        },
      });
      return next.handle(req);
    }

    return next.handle(req);
  }
}
