import { NgModule } from "@angular/core";
import {
  CastBaseInputPipe,
  CastTextInputPipe,
  CastAutocompleteInputPipe,
  CastCheckboxInputPipe,
  CastDateInputPipe,
  CastDividerPipe,
  CastFileInputPipe,
  CastFilterButtonsInputPipe,
  CastNopInputPipe,
  CastRadiosInputPipe,
  CastSelectInputPipe,
  CastTextareaInputPipe,
  CastFormControlPipe
} from "./models";
import { CastAutocompleteWithAddButtonInputPipe } from "./models/inputs/autocompleteWithAddButton-input";
import { CastDateInputTimePipe } from "./models/inputs/date-input-time";
import { CastSliderInputPipe } from "./models/inputs/slider-input";
import { CastTagInputPipe } from "./models/inputs/tag-input";
import { CastTimeInputPipe } from "./models/inputs/time-input";
import { CastToggleInputPipe } from "./models/inputs/toggle-input";
import { CastBaseInputArrayPipe } from "./components";
import {
  CastCompanyUserPipe,
  CastWorkerCompanyUserPipe,
  CastWorkerUserPipe
} from "src/app/core/auth/services/user.service";
import { CastDataSourcePipe, CastServerPageableDataSourcePipe, CastSimpleDataSourcePipe } from "../table/components/table/table.component";

const CAST_INPUT_PIPES = [
  CastFormControlPipe,
  CastBaseInputPipe,
  CastBaseInputArrayPipe,
  CastTextInputPipe,
  CastAutocompleteInputPipe,
  CastAutocompleteWithAddButtonInputPipe,
  CastCheckboxInputPipe,
  CastDateInputTimePipe,
  CastDateInputPipe,
  CastDividerPipe,
  CastFileInputPipe,
  CastFilterButtonsInputPipe,
  CastNopInputPipe,
  CastRadiosInputPipe,
  CastSelectInputPipe,
  CastSliderInputPipe,
  CastTagInputPipe,
  CastTextareaInputPipe,
  CastTimeInputPipe,
  CastToggleInputPipe,
]

const CAST_USER_PIPES = [
  CastWorkerUserPipe,
  CastCompanyUserPipe,
  CastWorkerCompanyUserPipe,
]

const CAST_DATA_SOURCE_PIPES = [
  CastDataSourcePipe,
  CastSimpleDataSourcePipe,
  CastServerPageableDataSourcePipe,
]

@NgModule({
  declarations: [
    ...CAST_INPUT_PIPES,
    ...CAST_USER_PIPES,
    ...CAST_DATA_SOURCE_PIPES,
  ],
  exports: [
    ...CAST_INPUT_PIPES,
    ...CAST_USER_PIPES,
    ...CAST_DATA_SOURCE_PIPES,
  ],
  providers: [
    ...CAST_INPUT_PIPES,
    ...CAST_USER_PIPES,
    ...CAST_DATA_SOURCE_PIPES,
  ]
})
export class CastInputsPipesModule {}
