<tr>
  <th *ngFor="let column of columns" scope="col" [style.width.%]="column.width">
    <!-- TODO: richiamare un metodo sul template non è performante spostare la logica in una direttiva o su un pipe -->
    <app-checkbox-input
      *ngIf="column.checkboxable && checkboxHeader"
      name="headerCheckbox-{{ tableId }}"
      [checked]="checkboxChecked()"
      [indeterminate]="checkboxIndeterminate()"
      (change)="$event.stopPropagation(); checkboxToggle.emit($event)"
    ></app-checkbox-input>

    <!-- TODO: richiamare un metodo sul template non è performante spostare la logica in una direttiva o su un pipe -->
    <span
      *ngIf="column.sortable && column.name"
      (click)="sort($event, column)"
      class="sort cursor-pointer"
      [ngClass]="currentDirection(column.prop)"
    >
      {{ column.name }}
    </span>
    <span *ngIf="!column.sortable">{{ column.name }}</span>
  </th>
</tr>
