import { Component, Input } from '@angular/core';
import { TextareaInput } from '../../../models';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-texarea-input',
  template: `
    <tui-text-area [tuiTextfieldLabelOutside]="true" [formControl]="control">
      {{ control.placeholder | translate }}
    </tui-text-area>
    <!-- <textarea
      id="{{ 'input' + name }}"
      class="sgb-input form-control shape-rectangle nb-transition input-full-width"
      [appColorStatus]="control.colorStatus"
      [rows]="control.rows"
      [placeholder]="control.placeholder | translate"
      [formControl]="control"
    ></textarea> -->
  `,
})
export class TextareaInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: TextareaInput;
}
