import { Injectable } from '@angular/core';

export interface PathParams {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class PixelPartnersService {
  // Pixel Refine all pages
  loadRefinePixel() {
    const i = document.getElementById('refine');
    if (i) document.body.removeChild(i);
    (function (n) {
      function r() {
        var n = t.createElement('iframe');
        return ((n.height = '0'),
        (n.width = '0'),
        (n.style.display = 'none'),
        (n.style.visibility = 'hidden'),
        (n.id = 'refine'),
        n) as any;
      }
      function u(n) {
        t.body.appendChild(n);
      }
      var t: any = document,
        i = 'https://vf.r3f.technology/vf/sync/tags/';

      if (typeof fetch == 'function')
        fetch(i + 'js/' + n)
          .then(function (n) {
            return n.json();
          })
          .then(function (n) {
            var t = r();
            t.srcdoc = n.Body;
            u(t);
          });
      else {
        var t = r();
        (t as any).src = i + n;
        u(t);
      }
    })(2003);
  }

  // Pixel LinkAppeal all pages
  loadLinkAppealPixel(timestamp: number) {
    var t = document;
    const i = t.getElementById('linkappeal');
    if (i) t.body.removeChild(i);

    function r() {
      var n = t.createElement('iframe');
      n.height = '1';
      n.width = '1';
      n.style.border = '0';
      n.style.display = 'none';
      n.style.visibility = 'hidden';
      n.id = 'linkappeal';
      n.src = `https://glass.linkappeal.it/vodka?id_campagna=INPLACE-MAGGIO-2024&id_step=2&code=${timestamp}&ind=1&mod=25`;
      return n;
    }
    var e = r();
    function u(e: HTMLIFrameElement) {
      t.body.appendChild(e);
    }
    u(e);
  }

  loadAdForm() {
    var t = document;
    const i = document.getElementById('localadftrac');
    if (i) document.body.removeChild(i);
    var n = t.createElement('div');
    n.id = 'localadftrac'
    let x: string = `
      <script type=\"text/javascript\">
          window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
          window._adftrack.push({
              HttpHost: 'track.adform.net',
              pm: 3286406,
              divider: encodeURIComponent('|'),
              pagename: encodeURIComponent('TYP_inplace')
          });
          (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();

      </script>
      <noscript>
          <p style='margin:0;padding:0;border:0;'>
              <img src='https://track.adform.net/Serving/TrackPoint/?pm=3286406&ADFPageName=TYP_inplace&ADFdivider=|' width='1' height='1' alt=''/>
          </p>
      </noscript>
      <!-- Adform Tracking Code END --> `

    n.innerHTML = x;
    t.body.appendChild(n);
  }
}


