import {Sede} from "./company.model";

export interface ClientDelegationResponse {
  delegateId: number;
  nome: string;
  cognome: string;
  codiceFiscale: string;
  email: string;
  type: DelegateType;
  roles: DelegationRole[];
  sedi: Sede[];
}

export interface FindDelegateRequest {
  email: string;
  roles: DelegationRole[];
  sedeUuids: String[];
}

export interface CreateDelegateRequest {
  firstName: string;
  lastName: string;
  email: string;
  fiscalCode: string;
  type: DelegateType;
  roles: DelegationRole[];
  sedeUuids: String[];
}

export interface RemoveClientDelegations {
  delegateId: number;
  roles: DelegationRole[];
  sedeUuids: String[];
}

export interface DelegateResponse {
  id: number;
  nome: string;
  cognome: string;
  codiceFiscale: string;
  type: DelegateType;
  delegations: DelegationResponse[];
}

export interface DelegationResponse {
  clientId: number;
  ragioneSociale: string;
  roles: DelegationRole[];
  sedi: Sede[];
}

export interface ActivateDelegateRequest {
  password: string;
  activationToken: string;
}


export enum DelegateType {
  CONSULENTE_LAVORO = "CONSULENTE_LAVORO",
  DIPENDENTE = "DIPENDENTE",
}

export enum DelegationRole {
  ROLE_DELEGATE_PROFILE = "ROLE_DELEGATE_PROFILE",
  ROLE_DELEGATE_RISK_BOARD = "ROLE_DELEGATE_RISK_BOARD",
  ROLE_DELEGATE_INVOICES = "ROLE_DELEGATE_INVOICES",
  ROLE_DELEGATE_PROPOSALS_CONTRACTS = "ROLE_DELEGATE_PROPOSALS_CONTRACTS",
  ROLE_DELEGATE_TIMESHEET = "ROLE_DELEGATE_TIMESHEET",
  ROLE_DELEGATE_COMMERCIAL_OFFERS = "ROLE_DELEGATE_COMMERCIAL_OFFERS",
  ROLE_DELEGATE_JOB_OFFERS_INTERVIEWS = "ROLE_DELEGATE_JOB_OFFERS_INTERVIEWS",
  ROLE_DELEGATE_CONVERSATIONS = "ROLE_DELEGATE_CONVERSATIONS"
}
