import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-operation-completed',
  templateUrl: './operation-completed.component.html',
  styleUrls: ['./operation-completed.component.scss'],
})
export class OperationCompletedComponent implements OnInit {
  @Input() upperTitle: string;
  @Input() upperSubtitle: string;
  @Input() upperBodyContent: TemplateRef<any>;
  @Input() lowerTitle: string;
  @Input() lowerBodyContent: TemplateRef<any>;
  @Input() buttons: TemplateRef<any>[];

  constructor() {}

  ngOnInit(): void {}
}
