<tui-combo-box
  *tuiLet="control.options | async as items"
  [tuiTextfieldLabelOutside]="true"
  [readOnly]="control.readonly"
  [valueContent]="items ? stringify(items, control) : loading"
  [stringify]="stringifyValue(items, control)"
  [formControl]="control"
  [identityMatcher]="identityMatcher"
>
  {{ control.placeholder | translate }}
  <ng-template tuiDataList>
    <cdk-virtual-scroll-viewport
      *tuiLet="items | tuiFilterByInput : matcher(control) as items"
      tuiScrollable
      class="virtual-scroll"
      [itemSize]="68"
      [style.height.px]="items.length * 68"
    >
      <tui-data-list
        #list
        *ngIf="items; else loading"
        [emptyContent]="'Nessun risultato'"
      >
        <button
          *cdkVirtualFor="let item of items"
          [ngClass]="control.optionsSeparator ? 'separator-border' : ''"
          tuiOption
          [value]="getValue(item, control)"
        >
          {{ item.des | translate }}
        </button>
      </tui-data-list>
    </cdk-virtual-scroll-viewport>
  </ng-template>

  <ng-template #loading>
    <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
  </ng-template>
</tui-combo-box>
