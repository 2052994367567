import { Observable } from 'rxjs';
import { BaseInput, BaseInputConstructor } from './base-input';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'castFilterButtonsInput' })
export class CastFilterButtonsInputPipe implements PipeTransform {
  transform<S>(value: S): FilterButtonsInput {
      return value as unknown as FilterButtonsInput;
  }
}

export interface FilterButtonsInputCostructor<T>
  extends Omit<BaseInputConstructor<T>, 'placeholder' | 'pattern'> {
  options: Observable<T[]>;
  disabledItemsHandler?: (value: T[]) => void;
}

export class FilterButtonsInput<T = string> extends BaseInput<T> {
  inputType = 'filter-buttons';
  options: Observable<T[]>;
  disabledItemsHandler?: (value: T[]) => boolean;

  constructor(options: FilterButtonsInputCostructor<T>) {
    super(options);
    this.options = options.options;
    // if (options.disabledItemsHandler) {
    //   this.disabledItemsHandler = options.disabledItemsHandler;
    // }
  }
}
