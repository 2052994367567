import { Component, Input } from '@angular/core';
import { AutocompleteInput } from '../../../models/inputs/autocomplete-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-autocomplete-input',
  template: ``,
})
export class AutocompleteInputComponent extends BaseInputComponent {
  @Input() control: AutocompleteInput;
}
