import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TuiContextWithImplicit,
  TuiIdentityMatcher,
  tuiPure,
  TuiStringHandler,
} from '@taiga-ui/cdk';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectInput, SelectOption } from '../../../models/inputs/select-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-select-input',
  template: `
    <ng-container *ngIf="control.multiple; else select">
      <tui-multi-select
        *tuiLet="control.options | async as items"
        [tuiTextfieldLabelOutside]="true"
        [readOnly]="control.readonly"
        [stringify]="stringifyLabel(items, control)"
        [formControl]="control"
        [identityMatcher]="identityMatcher"
        [tuiTextfieldCleaner]="true"
        [editable]="control.editable || false"
      >
        {{ control.placeholder | translate }}
        <ng-template tuiDataList>
          <tui-data-list *ngIf="items; else loading" tuiMultiSelectGroup>
            <button
              *ngFor="let item of items | tuiFilterByInput : matcher(control)"
              tuiOption
              [value]="getValue(item, control)"
            >
              {{ item.des | translate }}
            </button>
          </tui-data-list>
        </ng-template>
        <ng-template #loading>
          <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
        </ng-template>
      </tui-multi-select>
    </ng-container>
    <ng-template #select>
      <ng-container *ngIf="!control.virtualScroll; else selectVirtualScroll">
        <tui-combo-box
          *tuiLet="control.options | async as items"
          [tuiTextfieldLabelOutside]="true"
          [readOnly]="control.readonly"
          [valueContent]="items ? stringify(items, control) : loading"
          [stringify]="stringifyValue(items, control)"
          [formControl]="control"
          [identityMatcher]="identityMatcher"
        >
          {{ control.placeholder | translate }}
          <ng-template tuiDataList>
            <tui-data-list *ngIf="items; else loading">
              <button
                *ngFor="let item of items | tuiFilterByInput : matcher(control)"
                [ngClass]="control.optionsSeparator ? 'separator-border' : ''"
                tuiOption
                [value]="getValue(item, control)"
              >
                {{ item.des | translate }}
              </button>
            </tui-data-list>
          </ng-template>
          <ng-template #loading>
            <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
          </ng-template>
        </tui-combo-box>
      </ng-container>
    </ng-template>
    <ng-template #selectVirtualScroll>
      <tui-combo-box
        *tuiLet="control.options | async as items"
        [tuiTextfieldLabelOutside]="true"
        [readOnly]="control.readonly"
        [valueContent]="items ? stringify(items, control) : loading"
        [stringify]="stringifyValue(items, control)"
        [formControl]="control"
        [identityMatcher]="identityMatcher"
      >
        {{ control.placeholder | translate }}
        <ng-template tuiDataList>
          <cdk-virtual-scroll-viewport
            *tuiLet="items | tuiFilterByInput : matcher(control) as items"
            tuiScrollable
            class="virtual-scroll"
            [itemSize]="64"
            [style.height.px]="items.length * 64"
          >
            <tui-data-list *ngIf="items; else loading">
              <button
                *cdkVirtualFor="let item of items"
                [ngClass]="control.optionsSeparator ? 'separator-border' : ''"
                tuiOption
                [value]="getValue(item, control)"
              >
                {{ item.des | translate }}
              </button>
            </tui-data-list>
          </cdk-virtual-scroll-viewport>
        </ng-template>
        <ng-template #loading>
          <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
        </ng-template>
      </tui-combo-box>
    </ng-template>
  `,
  styleUrls: ['./select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent extends BaseInputComponent {
  @Input('control') set _control(c: SelectInput) {
    this.control = c;
    this.identityMatcher = c.identityMatcher as TuiIdentityMatcher<any>;
  }
  @Input() name: string;
  initialItems: Observable<SelectOption<string, string>[]>;
  control: SelectInput;
  identityMatcher: TuiIdentityMatcher<any>;

  @tuiPure
  stringify(
    items: ReadonlyArray<SelectOption>,
    control: SelectInput
  ): TuiStringHandler<TuiContextWithImplicit<string | SelectOption>> {
    const map = new Map(
      items.map(({ cod, des }) => [cod, des] as [string, string])
    );
    if (control.bindValue) {
      return ({ $implicit }: TuiContextWithImplicit<string>) =>
        map.get($implicit) || '';
    }
    if (control.bindLabel) {
      return ({ $implicit }: TuiContextWithImplicit<SelectOption>) =>
        $implicit.des || '';
    }
    return ({ $implicit }: TuiContextWithImplicit<SelectOption>) => {
      return map.get($implicit.cod) || '';
    };
  }

  @tuiPure
  stringifyValue(
    items: ReadonlyArray<SelectOption>,
    control: SelectInput
  ): TuiStringHandler<string> {
    if (items) {
      const map = new Map(
        items.map(({ cod, des }) => [cod, des] as [string, string])
      );
      if (control.bindValue) {
        return ($implicit: string) => map.get($implicit) || '';
      }

      return ($implicit: any) => $implicit.des || '';
    }
    return () => '';
  }

  @tuiPure
  stringifyLabel(items: ReadonlyArray<SelectOption>, control: SelectInput) {
    if (items) {
      const map = new Map(
        items.map(({ cod, des }) => [cod, des] as [string, string])
      );

      if (control.bindValue) {
        return ($implicit) => {
          return map.get($implicit) || '';
        };
      }
    }

    return ($implicit) => $implicit.des || '';
  }

  @tuiPure
  getValue(
    item: Readonly<SelectOption>,
    control: SelectInput
  ): string | SelectOption {
    if (control.bindValue) {
      return item.cod;
    }
    return item;
  }

  getByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value === searchValue) return key;
    }
  }

  async onSearchChange($event) {
    if (!this.initialItems) {
      this.initialItems = this.control.options;
    }
    this.control.options = this.initialItems.pipe(
      map((options) => {
        if ($event && !this.control.value) {
          return options.filter((option) =>
            option.des.toUpperCase().includes($event.toUpperCase())
          );
        } else {
          return options;
        }
      })
    );
  }

  readonly matcher = (control: SelectInput) => {
    if (!control.value) {
      if (control.bindValue) {
        return ($implicit: { cod: string; des: string }, search: string) => {
          return $implicit.des.toLowerCase().includes(search.toLowerCase());
        };
      }

      return ($implicit: { cod: string; des: string }, search: string) =>
        $implicit.des.toLowerCase().includes(search.toLowerCase());
    } else {
      return ($implicit: { cod: string; des: string }, search: string) => true;
    }
  };
}
