import {Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {TuiDialogService} from '@taiga-ui/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {RoleType} from 'src/app/core/auth/interfaces/role.model';
import {UserService} from 'src/app/core/auth/services/user.service';
import {UtilityService} from 'src/app/core/utility.service';
import {
  CheckboxInput,
  DateInput,
  FileInput,
  Form,
  NopInput,
  RadiosInput,
  SelectInput,
  TextInput,
  ValidationStatus,
} from 'src/app/shared/form';
import {NonEUWorkerDoc, WorkerDoc} from '../../../pages/private/pages/worker/pages/user/interfaces/worker-doc.model';
import {DocumentInformation, WorkerDocumentsInfo} from '../../../pages/private/pages/worker/pages/user/interfaces/worker-documents.model';
import {DocumentType} from '../../../pages/private/pages/worker/pages/user/models/documents-type';
import {MacroDocumentType} from '../../../pages/private/pages/worker/pages/user/models/macro-documents-type';
import {
  DocContrattualeStatoInserimentoType,
  DocumentStatus,
} from '../../../pages/private/pages/worker/pages/user/pages/user-worker-profile/models/documents-status';
import {
  UserWorkerProfileService
} from '../../../pages/private/pages/worker/pages/user/services/user-worker-profile.service';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';

declare var MediaRecorder: any;

@UntilDestroy()
@Component({
  selector: 'app-documents-section',
  templateUrl: './documents-section.component.html',
  styleUrls: ['./documents-section.component.scss'],
})
export class DocumentsSectionComponent implements OnInit {
  @Input() macroDocType: MacroDocumentType;
  @Input() docType: DocumentType;
  @Input() disabled: boolean;
  @Output() onRefresh?: EventEmitter<any> = new EventEmitter();
  @ViewChild('modalAddDocument') modalAddDocument: TemplateRef<any>;
  @ViewChild('showVideoTemplate') showVideoTemplate: TemplateRef<any>;
  @ViewChild('modalRecord') modalRecord: TemplateRef<any>;
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('video') videoElementRef: ElementRef;
  @ViewChild('deleteUserDialog') deleteUserDialog: TemplateRef<any>;
  @ViewChild('webcam') webcam: ElementRef;
  @ViewChild('modalAddPhoto') modalAddPhoto: TemplateRef<any>;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('photo') photo: ElementRef;

  videoElement: HTMLVideoElement;
  recordVideoElement: HTMLVideoElement;
  mediaRecorder: any;
  recordedBlobs: Blob[];
  isRecording: boolean = false;
  downloadUrl: string;
  stream: MediaStream;
  videoRecord: boolean = false;
  errorRecord: boolean = false;
  errorMsg = '';
  myTimeout: any;

  formDocument: Form;
  formRenewDocument: Form;
  dialogTitle: string;
  showFormRenewDocument = false; 
  photoUrl: string = '';
  uuidEditPhoto: string = '';
  photoTaken: boolean = false;
  errorPhoto: boolean = false;
  photoToSave: HTMLImageElement;
  isBack: boolean;
  takePhotoDisabled: boolean = true;
  docDetail: any 

  hasCV: boolean = false
  hasVideoCV: boolean = false
  workerDocument: DocumentInformation
  lastExpirationDate = null;

  get MacroDocumentType() {
    return MacroDocumentType;
  }
  get DocumentType() {
    return DocumentType;
  }
  get documentStatus() {
    return DocumentStatus;
  }
  get DocContrattualeStatoInserimentoType() {
    return DocContrattualeStatoInserimentoType;
  }

  constructor(
    private loading: NgxSpinnerService,
    private utilityService: UtilityService,
    private dialogService: TuiDialogService,
    private i18n: TranslateService,
    public userService: UserService,
    private service: UserWorkerProfileService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.refresh()
  }

  refresh() {
    switch (this.macroDocType) {
      case MacroDocumentType.DOCUMENTI_A_FINI_FISCALI:
      case MacroDocumentType.DOCUMENTI_IDENTITA:
        this.service.getWorkerPersonalDocuments().pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((e) => {
          this.workerDocument = e.documents[this.macroDocType]
        });
        return

      
      case MacroDocumentType.VIDEOCV:
        this.service
        .getWorkerHasVideoCurriculum().pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((value) => {
          this.hasVideoCV = value;       
        });
        return

      case MacroDocumentType.DOC_CV:
        this.service
        .getWorkerHasCurriculum()
        .pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((value) => {
          this.hasCV = value;       
        });
        return

      case MacroDocumentType.ATTESTATI:
        return

      case MacroDocumentType.PERMESSO_DI_SOGGIORNO:
        this.service
        .getWorkerNonUeDocument()
        .pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((value) => { 
          this.workerDocument = value
        });
        return
    }
  }

  onEditPhoto(isBackPhoto: boolean = false) {
    this.takePhotoDisabled = true;
    this.isBack = isBackPhoto;
    var dialogTitle = 'UTILS.TAKE_PHOTO';
    this.uuidEditPhoto = '';
    this.dialogService
      .open(this.modalAddPhoto, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(dialogTitle),
      })
      .subscribe({
        complete: () => {
          this.stream.getTracks().forEach((track) => {
            track.stop();
          });
        },
      });
    const constraints = {
      video: true,
      audio: false,
    };
    this.photoTaken = false;
    this.errorPhoto = false;
    this.errorMsg = '';
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.videoElement = this.webcam.nativeElement;
        this.stream = stream;
        this.videoElement.srcObject = this.stream;
        this.videoElement.play();
        this.takePhotoDisabled = false;
      })
      .catch((err) => {
        this.errorPhoto = true;
        if (
          err.name === 'NotFoundError' ||
          err.name === 'DevicesNotFoundError'
        ) {
          // required track is missing
          console.log('Required track is missing');
          this.errorMsg = this.i18n.instant(
            'ERRORS.TAKE_PHOTO.DEVICE_NOT_FOUND.MESSAGE'
          );
        } else if (
          err.name === 'NotReadableError' ||
          err.name === 'TrackStartError'
        ) {
          // webcam or mic are already in use
          console.log('Webcam or mic are already in use');
          this.errorMsg = this.i18n.instant(
            'ERRORS.TAKE_PHOTO.ALREADY_IN_USE.MESSAGE'
          );
        } else if (
          err.name === 'OverconstrainedError' ||
          err.name === 'ConstraintNotSatisfiedError'
        ) {
          // constraints can not be satisfied by avb. devices
          console.log('Constraints can not be satisfied by available devices');
          this.errorMsg = this.i18n.instant(
            'ERRORS.TAKE_PHOTO.CONSTRAINTS.MESSAGE'
          );
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError'
        ) {
          // permission denied in browser
          console.log('Permission Denied.');
          this.errorMsg = this.i18n.instant(
            'ERRORS.TAKE_PHOTO.PERMISSION.MESSAGE'
          );
        } else if (err.name === 'TypeError' || err.name === 'TypeError') {
          // empty constraints object
          console.log('Both audio and video are FALSE');
          this.errorMsg = this.i18n.instant(
            'ERRORS.TAKE_PHOTO.CHANNELS_NOT_FOUND.MESSAGE'
          );
        } else {
          // other errors
          console.log('Sorry! Another error occurred.');
          this.errorMsg = this.i18n.instant(
            'ERRORS.TAKE_PHOTO.GENERIC.MESSAGE'
          );
        }
      });
  }

  takePhoto() {
    this.loading.show();
    this.photoTaken = false;
    const height =
      this.webcam.nativeElement.videoHeight /
      (this.webcam.nativeElement.videoWidth / 320);
    const context = this.canvas.nativeElement.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    const data = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', data);
    this.canvas.nativeElement.width = 320;
    this.canvas.nativeElement.height = height;
    context.drawImage(this.webcam.nativeElement, 0, 0, 320, height);
    const dataToSave = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', dataToSave);
    this.photoToSave = dataToSave;
    this.photoTaken = true;
    this.loading.hide();
  }

  savePhoto(type, observer) {
    const name = this.randomString(
      20,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    const photoImg = this.dataURLtoFile(this.photoToSave, name + '.png');
    if (this.isBack) this.formDocument.get('fileRetro').setValue(photoImg);
    else {
      this.formDocument.get('file').setValue(photoImg);
    }
    observer.complete();
  }

  downloadDocument(macroType: MacroDocumentType, type: DocumentType) {

    const document = this.workerDocument
    
      this.loading.show();
      let service
      if (macroType === MacroDocumentType.DOC_CV) {
        this.service
          .downloadCv()
          .pipe(
            finalize(() => this.loading.hide()),
            untilDestroyed(this)
          )
          .subscribe();
      } else if (macroType === MacroDocumentType.PERMESSO_DI_SOGGIORNO) {
        Promise.all([
          this.service.downloadNonUeDocument('PERMESSO').toPromise(),
          this.workerDocument.renewal ? this.service.downloadNonUeDocument('RINNOVO').toPromise() :  null
        ]).then(() => this.loading.hide());
      } else {
        if (document) {
          const uuid = document.uuid;
          this.service
            .downloadDocument(uuid)
            .pipe(
              finalize(() => this.loading.hide()),
              untilDestroyed(this)
            )
            .subscribe();
        }
      }
  }

  onShowDocsModal(macroDocType: MacroDocumentType, docType: DocumentType) {
    this.dialogTitle = this._getDialogTitle(macroDocType, docType);
    // review: docType sempre undefined tranne che per macroDocType = VIDEO_CV
    if (this.workerDocument && this.checkDoc(macroDocType, docType)) {
      this.loading.show();

      const document = this.workerDocument /*this.getFieldDocumentPipe.transform(
        this.workerDocuments,
        macroDocType,
        docType
      );*/
      let servizio: Observable<any> = this.service.getWorkerDocumentDetail(document.type)
      if(macroDocType == MacroDocumentType.PERMESSO_DI_SOGGIORNO)
        servizio = this.service.getWorkerNonUeDocumentDetail()
      console.log(docType)
      servizio
        .pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((docDetail: WorkerDoc) => {
          this._initDocFormDialog(macroDocType, docType, document, docDetail);
          this.openAddDialog();
        });
        
    } else {
      this._initDocFormDialog(macroDocType, docType);
      this.openAddDialog();
    }
  }

  onShowCVVideo() {
    this.loading.show();
    if (this.hasVideoCV) {
      this.service
        .getVideoCV()
        .pipe(
          finalize(() => this.loading.hide()),
          untilDestroyed(this)
        )
        .subscribe((resp) => {
          this.dialogService
            .open(this.showVideoTemplate, {
              dismissible: true,
              size: 'l',
              label: this.i18n.instant(
                'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE_CV_VIDEO'
              ),
              data: resp,
            })
            .subscribe();
        });
    } else {
      this.loading.hide();
      this.dialogService
        .open(this.showVideoTemplate, {
          dismissible: true,
          size: 'l',
        })
        .subscribe();
    }
  }

  onShowRecordCVVideoModal() {
    this.dialogTitle =
      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE_CV_VIDEO';
    this.dialogService
      .open(this.modalRecord, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(this.dialogTitle),
      })
      .subscribe({
        complete: () => {
          this.stream.getTracks().forEach((track) => {
            track.stop();
          });
        },
      });
    const constraints = {
      audio: true,
      video: true,
    };
    this.videoRecord = false;
    this.errorRecord = false;
    this.errorMsg = '';
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.videoElement = this.videoElementRef.nativeElement;
        this.recordVideoElement = this.recordVideoElementRef.nativeElement;
        this.stream = stream;
        this.videoElement.srcObject = this.stream;
      })
      .catch((err) => {
        this.errorRecord = true;
        if (
          err.name === 'NotFoundError' ||
          err.name === 'DevicesNotFoundError'
        ) {
          // required track is missing
          console.log('Required track is missing');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.DEVICE_NOT_FOUND.MESSAGE'
          );
        } else if (
          err.name === 'NotReadableError' ||
          err.name === 'TrackStartError'
        ) {
          // webcam or mic are already in use
          console.log('Webcam or mic are already in use');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.ALREADY_IN_USE.MESSAGE'
          );
        } else if (
          err.name === 'OverconstrainedError' ||
          err.name === 'ConstraintNotSatisfiedError'
        ) {
          // constraints can not be satisfied by avb. devices
          console.log('Constraints can not be satisfied by available devices');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.CONSTRAINTS.MESSAGE'
          );
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError'
        ) {
          // permission denied in browser
          console.log('Permission Denied.');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.PERMISSION.MESSAGE'
          );
        } else if (err.name === 'TypeError' || err.name === 'TypeError') {
          // empty constraints object
          console.log('Both audio and video are FALSE');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.CHANNELS_NOT_FOUND.MESSAGE'
          );
        } else {
          // other errors
          console.log('Sorry! Another error occurred.');
          this.errorMsg = this.i18n.instant('ERRORS.RECORD_CV.GENERIC.MESSAGE');
        }
      });
  }

  onShowAttestatiModal(docType: { key: DocumentType; value: string }) {
    this.dialogTitle = docType.value;
    this.loading.show();
    const document = this.workerDocument /*this.getFieldDocumentPipe.transform(
      this.workerDocument,
      MacroDocumentType.ATTESTATI,
      docType.key
    );*/
    this.service
      .getWorkerDocumentDetail(document.type)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe((docDetail: WorkerDoc) => {
        this._initFormAttestati(docType.key, docDetail);
        this.openAddDialog();
      });
  }

  openAddDialog() {
    this.dialogService
      .open(this.modalAddDocument, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(this.dialogTitle),
      })
      .subscribe();
  }

  checkDoc(macroDocType: MacroDocumentType, docType: DocumentType) {
    let personalDocs = this.workerDocument;//.personalDocument?.documents;
    switch (macroDocType) {
      case MacroDocumentType.DOCUMENTI_A_FINI_FISCALI:
      case MacroDocumentType.DOCUMENTI_IDENTITA:
      //case MacroDocumentType.DOC_CV:
      //case MacroDocumentType.VIDEOCV:
      case MacroDocumentType.ATTESTATI:
      case MacroDocumentType.PERMESSO_DI_SOGGIORNO:
        return true
    }
    return false;
  }

  addDocument(observer) {
    this.loading.show();
    let request = this.formDocument.value
    let docService = "addWorkerDocument"

    if(this.formDocument.value.tipoDocumento == "PERMESSO_DI_SOGGIORNO"){
      docService = "addNonEUWorkerDocument"
      console.log(this.formDocument.get("front").value)

      request = {
        residencePermitFront: this.formDocument.value.front as File,
        residencePermitBack: this.formDocument.value.back as File,
        residencePermit: 
        { 
          number: this.formDocument.value.number,
          issuingBody: this.formDocument.value.issuingBody,
          issuingDate: this.formDocument.value.issuingDate,
          expirationDate: this.formDocument.value.expirationDate,
          issuingReason: this.formDocument.value.issuingReason,
        }
      }
      if(this.showFormRenewDocument && this.formRenewDocument.value.number && this.formRenewDocument.value.number){
        request.renewalReceiptFront = this.formRenewDocument.value.front as File;
        request.renewalReceiptBack = this.formRenewDocument.value.back as File;
        request.renewalReceipt = {
          number: this.formRenewDocument.value.number,
          issuingDate: this.formRenewDocument.value.issuingDate
        }

      }
    }


    this.service[docService](request).pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this.toastr.success(
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOC_UPLOAD_TOAST_TEXT'
          ),
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOC_UPLOAD_TOAST_TITLE'
          )
        );
        this.refresh();
      });
  }

  uploadVideoCV(observer) {
    this.loading.show();
    var params = {
      videoCv: this.formDocument.value.file as File,
    };
    this.service
      .uploadVideoCV(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this.toastr.success(
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TOAST_TEXT'
          ),
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TOAST_TITLE'
          )
        );
        this.refresh();
      });
  }

  uploadCV(observer) {
    this.loading.show();
    var params = {
      cv: this.formDocument.value.file as File,
    };
    this.service
      .uploadCV(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this.toastr.success(
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TOAST_TEXT'
          ),
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TOAST_TITLE'
          )
        );
        this.refresh();
      });
  }

  startRecording() {
    this.recordedBlobs = [];
    for (const mimeType of [
      'video/mp4',
      'video/webm',
      'video/webm; codecs="vp8"',
      'video/webm; codecs="vp8, vorbis"',
      'video/webm; codecs="vp8, opus"',
      'video/webm; codecs="vp9"',
      'video/webm; codecs="vp9, vorbis"',
      'video/webm; codecs="vp9, opus"',
      'video/webm; codecs="av1"',
      'video/webm; codecs="av1, opus"',
    ]) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        try {
          this.mediaRecorder = new MediaRecorder(this.stream, { mimeType });
          this.mediaRecorder.start(); // collect 100ms of data
          this.isRecording = !this.isRecording;
          this.onDataAvailableEvent();
          this.onStopRecordingEvent();
          this.myTimeout = setTimeout(() => {
            if (!this.videoRecord) {
              this.stopRecording();
            }
          }, 30000); // TODO impostare secondi
          return;
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  stopRecording(): void {
    this.mediaRecorder.stop();
    this.isRecording = !this.isRecording;
    this.videoRecord = true;
  }

  playRecording() {
    if (!this.recordedBlobs || !this.recordedBlobs.length) {
      console.log('cannot play.');
      return;
    }
    this.recordVideoElement.play();
  }

  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  onStopRecordingEvent() {
    try {
      clearTimeout(this.myTimeout);
      this.mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, { type: 'video/mp4' });
        this.downloadUrl = window.URL.createObjectURL(videoBuffer);
        this.recordVideoElement.src = this.downloadUrl;
      };
    } catch (error) {
      console.log(error);
    }
  }

  resetRecord() {
    this.videoRecord = false;
  }

  saveRecording(observer) {
    this.loading.show();
    const name = this.randomString(
      20,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    const params = {
      videoCv: new File([this.recordedBlobs[0]], name, {
        type: 'video/x-matroska',
      }),
    };
    this.stream.getTracks().forEach((track) => {
      track.stop();
    });
    this.service
      .uploadVideoCV(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this.toastr.success(
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TOAST_TEXT'
          ),
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TOAST_TITLE'
          )
        );
        this.refresh();
      });
  }

  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  private _getDialogTitle(
    macroDocType: MacroDocumentType,
    docType: DocumentType
  ): string {
    switch (macroDocType) {
      case MacroDocumentType.DOCUMENTI_IDENTITA:
        return 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.TITLE';
      case MacroDocumentType.DOCUMENTI_A_FINI_FISCALI:
        return 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_FISCALI.FORM.TITLE';
      case MacroDocumentType.DOC_CV:
        return 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE';
      case MacroDocumentType.VIDEOCV:
        return 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE_CV_VIDEO';
      case MacroDocumentType.PERMESSO_DI_SOGGIORNO:
        return 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.TITLE_PERMESSO_DI_SOGGIORNO';
          
    }
  }

  private _initDocFormDialog(
    macroDocType: MacroDocumentType,
    docType: DocumentType,
    document?: any,
    docDetail?: any
  ) {
    switch (macroDocType) {
      case MacroDocumentType.DOCUMENTI_IDENTITA:
        this._initFormDocumentoRiconoscimento(
          document?.type,
          docDetail
        );
        break;
      case MacroDocumentType.DOCUMENTI_A_FINI_FISCALI:
        this._initFormCodiceFiscale(docDetail);
        break;
      case MacroDocumentType.DOC_CV:
        this._initFormCVImage(DocumentType.CV, docDetail);
        break;
      case MacroDocumentType.VIDEOCV:
        this._initFormCVImage(DocumentType.VIDEOCV);
        break;
      case MacroDocumentType.PERMESSO_DI_SOGGIORNO:
          this._initFormDocumentiExtracomunitariPermesso(docDetail);
          break;
      
    }
  }

  private _initFormDocumentoRiconoscimento(
    docType: string,
    document?: WorkerDoc
  ) {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        tipoDocumento: new SelectInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.TIPO.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.TIPO.PLACEHOLDER',
          size: '12|12|12|6|6',
          options: this.utilityService.getDocumentsType(
            RoleType.WORKER,
            MacroDocumentType.DOCUMENTI_IDENTITA
          ),
          value: docType,
        }),
        numeroDocumento: new TextInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.NUMERO.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.NUMERO.PLACEHOLDER',
          type: 'text',
          size: '12|12|12|6|6',
          value: document?.numeroDocumento,
        }),
        enteEmissione: new TextInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.EMESSO_DA.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.EMESSO_DA.PLACEHOLDER',
          type: 'text',
          size: '12|12|12|6|6',
          value: document?.enteEmissioneDes,
        }),
        statoEmissione: new SelectInput({
          required: true,
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.STATO.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.STATO.PLACEHOLDER',
          options: of(this.utilityService.getStates()),
          valueChange: (value: string) => {
            if (value) {
              console.log(value)
              if(value == 'ITALIA'){
                this.italianPersonalDocFields(this.formDocument, document)
              }else{
                this.foreignPersonalDocFields(this.formDocument, document)
              }
            }
          },
          size: '12|12|12|6|6',
          value: document?.statoEmissione?.cod,
        }),
        dataEmissione: new DateInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.DATA_EMISSIONE.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.DATA_EMISSIONE.PLACEHOLDER',
          value: this.utilityService.getTuiDateFromDate(
            document?.dataEmissione
          ),
        }),
        dataScadenza: new DateInput({
          size: '12|12|12|6|6',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.DATA_SCADENZA.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.DATA_SCADENZA.PLACEHOLDER',
          min: this.utilityService.getTuiDateFromLocalDate(
            new Date().toLocaleString('it', { timeZoneName: 'short' })
          ),
          value: this.utilityService.getTuiDateFromDate(document?.dataScadenza),
        }),
        fileRetroActive: new CheckboxInput({
          value: false,
          label:
            'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
          size: '12|12|12|12|12',
          formGroupClass: ['mt-4', 'mb-1'],
          valueChange: (value) => {
            if (value) {
              this.formDocument.removeControl('file');
              this.formDocument.addControlAfter(
                'file',
                new FileInput({
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  buttonPlaceholder:
                    'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                  size: '12|12|12|6|6',
                  label: 'UTILS.FRONTE',
                  placeholder: '',
                  maxSize: 5 * 1000000,
                }),
                'fileRetroActive'
              );
              this.formDocument.addControlAfter(
                'fileRetro',
                new FileInput({
                  buttonPlaceholder: 'ciao',
                  label: 'UTILS.RETRO',
                  size: '12|12|12|6|6',
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  placeholder:
                    'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
                  maxSize: 5 * 1000000,
                }),
                'file'
              );
            } else {
              if (this.formDocument) {
                this.formDocument.removeControl('file');
                this.formDocument.removeControl('fileRetro');
                this.formDocument.addControlAfter(
                  'file',
                  new FileInput({
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    buttonPlaceholder:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    size: '12|12|12|6|6',
                    label:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    placeholder: '',
                    maxSize: 5 * 1000000,
                  }),
                  'fileRetroActive'
                );
              }
            }
          },
        }),
        file: new FileInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          buttonPlaceholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
          placeholder: '',
          accept: 'image/png, image/jpeg, image/jpg, application/pdf',
          maxSize: 5 * 1000000,
        }),
        range: new NopInput({ value: MacroDocumentType.DOCUMENTI_IDENTITA }),
        uuidDocumentRegistry: new NopInput({
          value: document?.documentRegistryUuid,
        }),
      },
    });

    if(!document?.statoEmissione || document?.statoEmissione?.cod == 'ITALIA'){
      this.italianPersonalDocFields(this.formDocument, document)
    }else{
      this.foreignPersonalDocFields(this.formDocument, document)
    }
  }

  

  foreignPersonalDocFields(form: Form, document: any){
    form.removeControl("regioneEmissione")
    form.removeControl('provinciaEmissione')
    form.removeControl("comuneEmissione")

    form.addControlAfter(
      'comuneEsteroEmissione',
      new TextInput({
        type: 'text',
        size: '12|12|12|6|6',
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        label:
          'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.FOREIGN_CITY.LABEL',
        placeholder:
          'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.FOREIGN_CITY.PLACEHOLDER',
        value: document?.comuneEsteroEmissione,
      }),
      'statoEmissione'
    );

  }

  italianPersonalDocFields(form: Form, document: any){
    form.removeControl("comuneEsteroEmissione")

    form.addControlAfter("regioneEmissione", 
    new SelectInput({
      required: true,
      label:
        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.REGIONE.LABEL',
      placeholder:
        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.REGIONE.PLACEHOLDER',
      options: of(this.utilityService.getRegions()),
      //readonly: document?.regioneEmissione ? false : true,
      valueChange: (value: string) => {
        if (value) {
          (
            this.formDocument.get('provinciaEmissione') as SelectInput
          ).setOptions(of(this.utilityService.getProvinces(value)));
          this.formDocument.get('provinciaEmissione').readonly = false;
        } else {
          this.formDocument.get('provinciaEmissione').readonly = true;
        }
      },
      size: '12|12|12|6|6',
      value: document?.regioneEmissione?.cod,
    }),
    "statoEmissione");

    form.addControlAfter("provinciaEmissione", 
      new SelectInput({
        required: true,
        label:
          'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.PROVINCIA.LABEL',
        placeholder:
          'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.PROVINCIA.PLACEHOLDER',
        options: document?.regioneEmissione
          ? of(
              this.utilityService.getProvinces(
                document?.regioneEmissione?.cod
              )
            )
          : of([]),
        readonly: document?.provinciaEmissione ? false : true,
        valueChange: (value: string) => {
          if (value) {
            (
              this.formDocument.get('comuneEmissione') as SelectInput
            ).setOptions(of(this.utilityService.getCities(value)));
            this.formDocument.get('comuneEmissione').readonly = false;
          } else {
            this.formDocument.get('comuneEmissione').readonly = true;
          }
        },
        size: '12|12|12|6|6',
        value: document?.provinciaEmissione?.cod,
      }),
    "regioneEmissione");

    form.addControlAfter("comuneEmissione", 
      new SelectInput({
        required: true,
        label:
          'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.COMUNE.LABEL',
        placeholder:
          'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.COMUNE.PLACEHOLDER',
        readonly: document?.comuneEmissione ? false : true,
        options: document?.provinciaEmissione
          ? of(
              this.utilityService.getCities(document?.provinciaEmissione?.cod)
            )
          : of([]),
        
        size: '12|12|12|6|6',
        value: document?.comuneEmissione?.cod,
      }),
      "provinciaEmissione");
  }

  private _initFormCodiceFiscale(document?: WorkerDoc) {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        fileRetroActive: new CheckboxInput({
          value: false,
          label:
            'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
          size: '12|12|12|12|12',
          formGroupClass: ['mt-4', 'mb-1'],
          valueChange: (value) => {
            if (value) {
              this.formDocument.removeControl('file');
              this.formDocument.addControlAfter(
                'file',
                new FileInput({
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  buttonPlaceholder:
                    'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                  size: '12|12|12|6|6',
                  label: 'UTILS.FRONTE',
                  placeholder: '',
                  maxSize: 5 * 1000000,
                }),
                'fileRetroActive'
              );
              this.formDocument.addControlAfter(
                'fileRetro',
                new FileInput({
                  buttonPlaceholder: 'ciao',
                  label: 'UTILS.RETRO',
                  size: '12|12|12|6|6',
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  placeholder:
                    'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
                  maxSize: 5 * 1000000,
                }),
                'file'
              );
            } else {
              if (this.formDocument) {
                this.formDocument.removeControl('file');
                this.formDocument.removeControl('fileRetro');
                this.formDocument.addControlAfter(
                  'file',
                  new FileInput({
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    buttonPlaceholder:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    size: '12|12|12|6|6',
                    label:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    placeholder: '',
                    maxSize: 5 * 1000000,
                  }),
                  'fileRetroActive'
                );
              }
            }
          },
        }),
        file: new FileInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          buttonPlaceholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
          placeholder: '',
          maxSize: 5 * 1000000,
        }),
        tipoDocumento: new NopInput({
          value: DocumentType.CODICE_FISCALE,
        }),
        range: new NopInput({
          value: MacroDocumentType.DOCUMENTI_A_FINI_FISCALI,
        }),
        uuidDocumentRegistry: new NopInput({
          value: document?.documentRegistryUuid,
        }),
      },
    });
  }

  private _cleanElapsedDocForm(){
    if(this.formRenewDocument){
      this.formRenewDocument.removeControl('number');
      this.formRenewDocument.removeControl('issuingDate');
      this.formRenewDocument.removeControl('fileRetroActive');
      this.formRenewDocument.removeControl('front');
      this.formRenewDocument.removeControl('back');
    }
  }

  private _setRequestedDocForm(document, showRichiesta, showForm){
    if(showRichiesta && showForm){
      this.formRenewDocument = new Form({
        header: { show: false },
        controls: {
          checkRequestAfter: new RadiosInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            size: '12|12|12|6|6',
            label: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.RICHIESTA_FUTURA.LABEL',
            options: of([
              { cod: 'SI', des: this.i18n.instant('UTILS.YES') },
              { cod: 'NO', des: this.i18n.instant('UTILS.NO') },
            ]),
            value: document?.renewalReceipt && document?.renewalReceipt.pandoraId ? 'SI' : 'NO',
            valueChange: (value) => {
              this._cleanElapsedDocForm()
              if(value == "SI"){
                this._showElapsedDocForm(document)
              }else{
                delete(document.renewalReceipt)
                this._cleanElapsedDocForm()
              }
            }
          }),
          number: new TextInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            label:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.LABEL',
            placeholder:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.PLACEHOLDER',
            type: 'text',
            size: '12|12|12|6|6',
            value: document?.renewalReceipt?.number,
          }),
          issuingDate: new DateInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            size: '12|12|12|6|6',
            label:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.LABEL',
            placeholder:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.PLACEHOLDER',
            value: this.utilityService.getTuiDateFromDate(
              document?.renewalReceipt?.issuingDate
            ),
          }),
          fileRetroActive: new CheckboxInput({
            value: false,
            label:
              'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
            size: '12|12|12|12|12',
            formGroupClass: ['mt-4', 'mb-1'],
            valueChange: (value) => {
              if (value) {
                this.formDocument.removeControl('front');
                this.formDocument.addControlAfter(
                  'front',
                  new FileInput({
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    buttonPlaceholder:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    size: '12|12|12|6|6',
                    label: 'UTILS.FRONTE',
                    placeholder: '',
                    maxSize: 5 * 1000000,
                  }),
                  'fileRetroActive'
                );
                this.formDocument.addControlAfter(
                  'back',
                  new FileInput({
                    buttonPlaceholder: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    label: 'UTILS.RETRO',
                    size: '12|12|12|6|6',
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    placeholder:
                      'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
                    maxSize: 5 * 1000000,
                  }),
                  'front'
                );
              } else {
                if (this.formDocument) {
                  this.formDocument.removeControl('front');
                  this.formDocument.removeControl('back');
                  this.formDocument.addControlAfter(
                    'front',
                    new FileInput({
                      required: true,
                      validationStatus: [ValidationStatus.ERROR.REQUIRED],
                      buttonPlaceholder:
                        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                      size: '12|12|12|6|6',
                      label:
                        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                      placeholder: '',
                      maxSize: 5 * 1000000,
                    }),
                    'fileRetroActive'
                  );
                }
              }
            },
          }),
          front: new FileInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            buttonPlaceholder:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
            size: '12|12|12|6|6',
            label:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
            placeholder: '',
            maxSize: 5 * 1000000,
          }),
        }
      })
    }else if(showRichiesta && !showForm){
      this.formRenewDocument = new Form({
        header: { show: false },
        controls: {
          checkRequestAfter: new RadiosInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            size: '12|12|12|6|6',
            label: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.RICHIESTA_FUTURA.LABEL',
            options: of([
              { cod: 'SI', des: this.i18n.instant('UTILS.YES') },
              { cod: 'NO', des: this.i18n.instant('UTILS.NO') },
            ]),
            value: document?.renewalReceipt && document?.renewalReceipt.pandoraId ? 'SI' : 'NO',
            valueChange: (value) => {
              if(value == "SI"){
                this._showElapsedDocForm(document)
              }else{
                delete(document.renewalReceipt)
                this._cleanElapsedDocForm()
              }
            }
          }),
        }
      })
    }else if(!showRichiesta && showForm){
      this.formRenewDocument = new Form({
        header: { show: false },
        controls: {
          number: new TextInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            label:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.LABEL',
            placeholder:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.PLACEHOLDER',
            type: 'text',
            size: '12|12|12|6|6',
            value: document?.renewalReceipt?.number,
          }),
          issuingDate: new DateInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            size: '12|12|12|6|6',
            label:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.LABEL',
            placeholder:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.PLACEHOLDER',
            value: this.utilityService.getTuiDateFromDate(
              document?.renewalReceipt?.issuingDate
            ),
          }),
          fileRetroActive: new CheckboxInput({
            value: false,
            label:
              'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
            size: '12|12|12|12|12',
            formGroupClass: ['mt-4', 'mb-1'],
            valueChange: (value) => {
              if (value) {
                this.formDocument.removeControl('front');
                this.formDocument.addControlAfter(
                  'front',
                  new FileInput({
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    buttonPlaceholder:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    size: '12|12|12|6|6',
                    label: 'UTILS.FRONTE',
                    placeholder: '',
                    maxSize: 5 * 1000000,
                  }),
                  'fileRetroActive'
                );
                this.formDocument.addControlAfter(
                  'back',
                  new FileInput({
                    buttonPlaceholder: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    label: 'UTILS.RETRO',
                    size: '12|12|12|6|6',
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    placeholder:
                      'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
                    maxSize: 5 * 1000000,
                  }),
                  'front'
                );
              } else {
                if (this.formDocument) {
                  this.formDocument.removeControl('front');
                  this.formDocument.removeControl('back');
                  this.formDocument.addControlAfter(
                    'front',
                    new FileInput({
                      required: true,
                      validationStatus: [ValidationStatus.ERROR.REQUIRED],
                      buttonPlaceholder:
                        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                      size: '12|12|12|6|6',
                      label:
                        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                      placeholder: '',
                      maxSize: 5 * 1000000,
                    }),
                    'fileRetroActive'
                  );
                }
              }
            },
          }),
          front: new FileInput({
            required: true,
            validationStatus: [ValidationStatus.ERROR.REQUIRED],
            buttonPlaceholder:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
            size: '12|12|12|6|6',
            label:
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
            placeholder: '',
            maxSize: 5 * 1000000,
          }),
        }
      })

    }

    
  }
  
  private _initFormDocumentiExtracomunitariPermesso(document?: any /*onEUWorkerDoc*/) {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        number: new TextInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.PLACEHOLDER',
          type: 'text',
          size: '12|12|12|6|6',
          value: document?.residencePermit?.number,
        }),
        issuingBody: new SelectInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.EMESSO_DA.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.EMESSO_DA.PLACEHOLDER',
          size: '12|12|12|6|6',
          options: this.utilityService.getGCards(),
          value: document?.residencePermit?.issuingBody,
        }),
        issuingDate: new DateInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.PLACEHOLDER',
          value: this.utilityService.getTuiDateFromDate(
            document?.residencePermit?.issuingDate
          ),
        }),
        expirationDate: new DateInput({
          size: '12|12|12|6|6',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_SCADENZA.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_SCADENZA.PLACEHOLDER',
          value: this.utilityService.getTuiDateFromDate(document?.residencePermit?.expirationDate),
          valueChange: (value: TuiDay) => {
            if (value) {
              this.lastExpirationDate = value;
              if(this.utilityService.getDateTimeFromTuiDate(value) >= new Date()){
                this._setRequestedDocForm(document, true, !!document?.renewalReceipt)
              }else{
                this._setRequestedDocForm(document, false, true)
              }
              
              this.showFormRenewDocument = true
            }
          }
        }),
        issuingReason: new SelectInput({
          required: true,
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.MOTIVO.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.MOTIVO.PLACEHOLDER',
          size: '12|12|12|6|6',
          options: this.utilityService.getGCardReasons(),
          value: document?.residencePermit?.issuingReason,
        }),
        fileRetroActive: new CheckboxInput({
          value: false,
          label:
            'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
          size: '12|12|12|12|12',
          formGroupClass: ['mt-4', 'mb-1'],
          valueChange: (value) => {
            if (value) {
              this.formDocument.removeControl('front');
              this.formDocument.addControlAfter(
                'front',
                new FileInput({
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  buttonPlaceholder:
                    'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                  size: '12|12|12|6|6',
                  label: 'UTILS.FRONTE',
                  placeholder: '',
                  accept: 'image/png, image/jpeg, image/jpg, application/pdf',
                  maxSize: 5 * 1000000,
                }),
                'fileRetroActive'
              );
              this.formDocument.addControlAfter(
                'back',
                new FileInput({
                  buttonPlaceholder: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                  label: 'UTILS.RETRO',
                  size: '12|12|12|6|6',
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  placeholder:
                    'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
                    accept: 'image/png, image/jpeg, image/jpg, application/pdf',
                    maxSize: 5 * 1000000,
                }),
                'front'
              );
            } else {
              if (this.formDocument) {
                this.formDocument.removeControl('front');
                this.formDocument.removeControl('back');
                this.formDocument.addControlAfter(
                  'front',
                  new FileInput({
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    buttonPlaceholder:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    size: '12|12|12|6|6',
                    label:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    placeholder: '',
                    accept: 'image/png, image/jpeg, image/jpg, application/pdf',
                    maxSize: 5 * 1000000,
                  }),
                  'fileRetroActive'
                );
              }
            }
          },
        }),
        front: new FileInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          buttonPlaceholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
          placeholder: '',
          accept: 'image/png, image/jpeg, image/jpg, application/pdf',
          maxSize: 5 * 1000000,
        }),
        tipoDocumento: new NopInput({
          value: MacroDocumentType.PERMESSO_DI_SOGGIORNO,
        })
      },
    });

    if(new Date(document?.residencePermit?.expirationDate) >= new Date()){
      this._setRequestedDocForm(document, true, !!document?.renewalReceipt)
    }else{
      this._setRequestedDocForm(document, false, true)
    }
    
    this.showFormRenewDocument = true
  }

  private _showElapsedDocForm(document?){
    console.log("_setElapsedDocForm")

    this.formRenewDocument.addControlAfter("number", 
      new TextInput({
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        label:
          'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.LABEL',
        placeholder:
          'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.NUMERO.PLACEHOLDER',
        type: 'text',
        size: '12|12|12|6|6',
        value: document?.renewalReceipt?.number,
      }),
      'checkRequestAfter'
    )
    
    this.formRenewDocument.addControlAfter("issuingDate", 
    new DateInput({
      required: true,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      size: '12|12|12|6|6',
      label:
        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.LABEL',
      placeholder:
        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.DATA_EMISSIONE.PLACEHOLDER',
      value: this.utilityService.getTuiDateFromDate(
        document?.renewalReceipt?.issuingDate
      ),
    }),"number"
    )

    this.formRenewDocument.addControlAfter("fileRetroActive", new CheckboxInput({
      value: false,
      label:
        'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
      size: '12|12|12|12|12',
      formGroupClass: ['mt-4', 'mb-1'],
      valueChange: (value) => {
        if (value) {
          this.formRenewDocument.removeControl('front');
          this.formRenewDocument.addControlAfter(
            'front',
            new FileInput({
              required: true,
              validationStatus: [ValidationStatus.ERROR.REQUIRED],
              buttonPlaceholder:
                'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
              size: '12|12|12|6|6',
              label: 'UTILS.FRONTE',
              placeholder: '',
              accept: 'image/png, image/jpeg, image/jpg, application/pdf',
              maxSize: 5 * 1000000,
            }),
            'fileRetroActive'
          );
          this.formRenewDocument.addControlAfter(
            'back',
            new FileInput({
              buttonPlaceholder: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
              label: 'UTILS.RETRO',
              size: '12|12|12|6|6',
              required: true,
              validationStatus: [ValidationStatus.ERROR.REQUIRED],
              placeholder:
                'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
              maxSize: 5 * 1000000,
            }),
            'front'
          );
        } else {
          if (this.formRenewDocument) {
            this.formRenewDocument.removeControl('front');
            this.formRenewDocument.removeControl('back');
            this.formRenewDocument.addControlAfter(
              'front',
              new FileInput({
                required: true,
                validationStatus: [ValidationStatus.ERROR.REQUIRED],
                buttonPlaceholder:
                  'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                size: '12|12|12|6|6',
                label:
                  'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                placeholder: '',
                accept: 'image/png, image/jpeg, image/jpg, application/pdf',
                maxSize: 5 * 1000000,
              }),
              'fileRetroActive'
            );
          }
        }
      },
    }),
    "issuingDate")

    this.formRenewDocument.addControlAfter("front", new FileInput({
      required: true,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      buttonPlaceholder:
        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
      size: '12|12|12|6|6',
      label:
        'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.FILE.LABEL',
      placeholder: '',
      accept: 'image/png, image/jpeg, image/jpg, application/pdf',
      maxSize: 5 * 1000000,
    }),
    "fileRetroActive")
  }

  private _initFormCVImage(docType: DocumentType, document?: WorkerDoc) {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        file: new FileInput({
          size: '12|12|12|12|12',
          buttonPlaceholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.FILE.LABEL',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.FILE.LABEL',
          placeholder: '',
          required: true,
          accept:
            docType === DocumentType.VIDEOCV
              ? 'video/mp4, video/quicktime'
              : '',
          maxSize:
            docType === DocumentType.VIDEOCV ? 50 * 1000000 : 5 * 1000000,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          valueChange: (file: File) => {
            this.formDocument.get('nome').setValue(file.name);
          },
        }),
        tipoDocumento: new NopInput({ value: docType }),/*
        alreadyExists: new NopInput({
          value:
          this.workerDocuments.DOC_CV != null,
            //this.workerDocuments.personalDocument.documents?.DOC_CV != null,
        }),*/
      },
    });
  }

  private _initFormAttestati(docType: DocumentType, document?: WorkerDoc) {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        numeroDocumento: new TextInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.NUMERO.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.NUMERO.PLACEHOLDER',
          type: 'text',
          size: '12|12|12|6|6',
          value: document?.numeroDocumento,
        }),
        enteEmissione: new TextInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.EMESSO_DA.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.EMESSO_DA.PLACEHOLDER',
          type: 'text',
          size: '12|12|12|6|6',
          value: document?.enteEmissioneDes,
        }),
        dataEmissione: new DateInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.DATA_EMISSIONE.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.DATA_EMISSIONE.PLACEHOLDER',
          value: this.utilityService.getTuiDateFromDate(
            document?.dataEmissione
          ),
        }),
        dataScadenza: new DateInput({
          size: '12|12|12|6|6',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.DATA_SCADENZA.LABEL',
          placeholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.DATA_SCADENZA.PLACEHOLDER',
          value: this.utilityService.getTuiDateFromDate(document?.dataScadenza),
        }),
        fileRetroActive: new CheckboxInput({
          value: false,
          label:
            'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.DOCUMENTO_RICONOSCIMENTO.FORM.FILE_RETRO_ACTIVE.PLACEHOLDER',
          size: '12|12|12|12|12',
          formGroupClass: ['mt-4', 'mb-1'],
          valueChange: (value) => {
            if (value) {
              this.formDocument.removeControl('file');
              this.formDocument.addControlAfter(
                'file',
                new FileInput({
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  buttonPlaceholder:
                    'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                  size: '12|12|12|6|6',
                  label: 'UTILS.FRONTE',
                  placeholder: '',
                  maxSize: 5 * 1000000,
                }),
                'fileRetroActive'
              );
              this.formDocument.addControlAfter(
                'fileRetro',
                new FileInput({
                  buttonPlaceholder: 'ciao',
                  label: 'UTILS.RETRO',
                  size: '12|12|12|6|6',
                  required: true,
                  validationStatus: [ValidationStatus.ERROR.REQUIRED],
                  placeholder:
                    'PRIVATE.COMPANY.USER.COMPLETE_PROFILE.FORM.SEDE_LEGALE.FORM.SEDE.PLACEHOLDER',
                  maxSize: 5 * 1000000,
                }),
                'file'
              );
            } else {
              if (this.formDocument) {
                this.formDocument.removeControl('file');
                this.formDocument.removeControl('fileRetro');
                this.formDocument.addControlAfter(
                  'file',
                  new FileInput({
                    required: true,
                    validationStatus: [ValidationStatus.ERROR.REQUIRED],
                    buttonPlaceholder:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    size: '12|12|12|6|6',
                    label:
                      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.FILE.LABEL',
                    placeholder: '',
                    maxSize: 5 * 1000000,
                  }),
                  'fileRetroActive'
                );
              }
            }
          },
        }),
        file: new FileInput({
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          buttonPlaceholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.FILE.LABEL',
          size: '12|12|12|6|6',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.ATTESTATI.FORM.FILE.LABEL',
          placeholder: '',
          maxSize: 5 * 1000000,
        }),
        tipoDocumento: new NopInput({
          value: docType,
        }),
      },
    });
  }
}
