import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { UserService } from 'src/app/core/auth/services/user.service';
import {CheckboxInput, DateInput, Form, SelectInput, TextInput, ValidationStatus} from 'src/app/shared/form';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TuiDialogService } from '@taiga-ui/core';
import { DateInputTime } from '../../../form/models/inputs/date-input-time';
import { UtilityService } from 'src/app/core/utility.service';
import { dateTimeValidator } from '../../../validators/valid-date-time.directive';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';
import { InterviewsService } from 'src/app/shared/services/interviews.service';
import { InterviewRequest } from 'src/app/shared/interfaces/interview.model';
import { catchError, map } from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import { LoadingService } from 'src/app/theme/layouts/private/loading.services';
import { ToastrService } from 'ngx-toastr';
import { Sede } from "../../../../core/auth/interfaces/company.model";
import { environment } from '../../../../../environments/environment';

@UntilDestroy()
@Component({
selector: 'app-request-interview-button',
  templateUrl: './request-interview-button.component.html',
  styleUrls: ['./request-interview-button.component.scss'],
})
export class RequestInterviewButtonComponent implements OnInit {
  @ViewChild('modalRequestInverview') modalRequestInverview: TemplateRef<any>;
  @Input() candidateId: number;
  @Input() type: 'VIDEO' | 'IN_PERSON';
  @Input() jobLocations: Sede[]
  @Input() iconButton: boolean;
  @Input() onClose: () => void;
  interviewForm: Form;

  constructor(
    private dialogService: TuiDialogService,
    public userService: UserService,
    private utilityService: UtilityService,
    private i18n: TranslateService,
    private loading: LoadingService,
    private toastrService: ToastrService,
    private interviewService: InterviewsService,
  ) {}

  ngOnInit(): void {
    if(this.type == 'VIDEO'){
      if(environment.newVideoInterviewAppointment)
        this._initVideoInterviewForm();
      else
        this._initInterviewForm();
    }else{
      this._initInterviewForm();
    }
  }

  private _initInterviewForm() {
    this.interviewForm = new Form({
      header: { show: false },
      controls: {
        at: new DateInputTime({
          label: 'FIELDS.INTERVIEW_DATE.LABEL',
          placeholder: 'FIELDS.INTERVIEW_DATE.PLACEHOLDER',
          size: '12|12|6|6|6',
          required: true,
          min: this.utilityService.getTuiDateTimeFromDate(
            new Date(new Date().getTime()).toLocaleString('it', { timeZoneName: 'short' })
          ),
          value: [this.utilityService.getTuiDateTimeFromDate(
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              .toLocaleString('it', { timeZoneName: 'short' })
          )[0], new TuiTime(12, 0, 0)],
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.ERROR.CUSTOM(
              (control) => control.getError('invalidDateTime'),
              {
                text: this.i18n.instant('VALIDATIONS.DATETIME_INVALID'),
              }
            ),
          ],
          validatorOrOpts: [dateTimeValidator()],
          
        }),
        taskCode: new SelectInput({
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          required: true,
          size: '12|12|6|6|6',
          label: 'PRIVATE.COMMON.INTERVIEWS.TABLE.TASK',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.TABLE.TASK',
          options: this.utilityService.getTaskListAll(),
        }),
        locations: new SelectInput<string>({
          required: this.type == 'IN_PERSON',
          readonly: false,
          hidden: this.type == 'VIDEO',
          size: '12|12|12|4|4',
          label: 'PRIVATE.COMMON.INTERVIEWS.LOCATION.LABEL',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.LOCATION.PLACEHOLDER',
          options: of(
            (this.jobLocations || []).map((sede: Sede) => ({
              cod: sede.uuid,
              des: sede.nome,
            }))
          ),
        }),
        useOtherValue: new CheckboxInput({
          required: false,
          hidden: this.type == 'VIDEO',
          label: 'PRIVATE.COMMON.INTERVIEWS.LOCATION.OTHER',
          value: false,
          valueChange: (value) => {
            if (this.interviewForm) {
              if (value) {
                this.interviewForm.get('locations').required = false;
                this.interviewForm.get('locations').readonly = true;
                this.interviewForm.get('locations').setValue(null);
                this.interviewForm.get('otherLocation').required = true;
                this.interviewForm.get('otherLocation').readonly = false;
              } else {
                this.interviewForm.get('otherLocation').required = false;
                this.interviewForm.get('otherLocation').readonly = true;
                this.interviewForm.get('otherLocation').setValue(null);
                this.interviewForm.get('locations').required = true;
                this.interviewForm.get('locations').readonly = false;
              }
            }
          }
        }),
        otherLocation: new TextInput({
          required: false,
          hidden: this.type == 'VIDEO',
          readonly: true,
          type: "text"
        })
      },
    });
  }

  private _initVideoInterviewForm() {
    this.interviewForm = new Form({
      header: { show: false },
      controls: {
        atDate: new DateInput({
          label: 'FIELDS.INTERVIEW_DATE.LABEL',
          placeholder: 'FIELDS.INTERVIEW_DATE.PLACEHOLDER',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          size: '12|12|6|6|6',
          min: this.utilityService.getTuiDateFromLocalDate(
            new Date().toLocaleString('it', { timeZoneName: 'short' })
          ),
          value: this.utilityService.getTuiDateTimeFromDate(
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              .toLocaleString('it', { timeZoneName: 'short' })
          )[0],

          valueChange: (value) => {
            if(this.interviewForm)
              if(!value){
                this.interviewForm.removeControl("atTime")
              }else{
                this.showAtTime(this.interviewForm, value)
              }
          },
        }),
        taskCode: new SelectInput({
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          required: true,
          size: '12|12|6|6|6',
          label: 'PRIVATE.COMMON.INTERVIEWS.TABLE.TASK',
          placeholder: 'PRIVATE.COMMON.INTERVIEWS.TABLE.TASK',
          options: this.utilityService.getTaskListAll(),
        })
      },
    });

    if(this.interviewForm && this.interviewForm.value["atDate"]){
      this.showAtTime(this.interviewForm, this.interviewForm.value["atDate"])
    }

  }

  onShowModalRequestInverview() {
    this.interviewForm = null;
    this.ngOnInit();
    this.dialogService
      .open(this.modalRequestInverview, {
        dismissible: false,
        size: 'l',
        label: this.type == 'VIDEO' ? this.i18n.instant('SEARCH.BUTTONS.INTERVIEW_VIDEO') : this.i18n.instant('SEARCH.BUTTONS.INTERVIEW_PERSONAL'),
      })
      .subscribe();
  }

  onSendInterviewRequest() {
    this.loading.show();
    let dateTime
    let localDateTime
    let dto: InterviewRequest

    if(this.type == "VIDEO" && environment.newVideoInterviewAppointment){
      let time: TuiTime = new TuiTime(Number(this.interviewForm.value.atTime.split(":")[0]), Number(this.interviewForm.value.atTime.split(":")[1]))
      dateTime = this.utilityService.getDateTimeFromTuiDateTime([this.interviewForm.value.atDate, time]);
      localDateTime = new Date(dateTime)
      
      dto = {
        at: localDateTime.toISOString(),
        taskCode: this.interviewForm.value.taskCode,
        candidateArcaId: this.candidateId,
        type: this.type,
        location: null,
      }
    }else{
      dateTime = this.utilityService.getDateTimeFromTuiDateTime(this.interviewForm.value.at);
      localDateTime = this.utilityService.getLocalDateTimeFromDateTime(dateTime);
        
      dto = {
        at: (new Date(localDateTime)).toISOString(),
        taskCode: this.interviewForm.value.taskCode,
        candidateArcaId: this.candidateId,
        type: this.type,
        location: this.type == 'IN_PERSON' ? this.getLocation() : null,
      }
    }
    this.interviewService
        .requestInterview(dto)
        .pipe(
          catchError((err) => {
            this.loading.hide();
            return throwError(err);
          })
        )
        .subscribe(() => {
          this.loading.hide();
          this.toastrService.success(
            this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.REQUEST_SENT.TITLE'),
            this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.REQUEST_SENT.MESSAGE')
          );
          if(this.onClose)
            this.onClose();
        });
        
  }

  private getLocation() :string {
    if (this.interviewForm.get('locations').value) {
      const location = this.jobLocations.find(s => s.uuid == this.interviewForm.get('locations').value);
      const city = (location.comune ? this.utilityService.getCityByCod(location.comune)[1] : location.comuneEstero) as string;
      const district = location.provincia ? location.provincia : location.provinciaEstera;
      return location.indirizzo + ", " + city + " (" + district + ") ";
    } else {
      return this.interviewForm.get('otherLocation').value;
    }
  }

  private showAtTime(interviewForm: Form, value: TuiDay){
    let dates = this.interviewService.requestInterviewTimes(value).pipe(map((slots) => {
      let ret = []
      slots.disponibilita.forEach((element) => {
        ret.push({cod: element, des: element})
      });
      return ret
    }))

    interviewForm.removeControl("atTime")
    interviewForm.addControlAfter(
      "atTime", new SelectInput({
        required: true,
        label:
          'FIELDS.INTERVIEW_TIME.LABEL',
        placeholder:
          'FIELDS.INTERVIEW_TIME.LABEL',
        options: dates,
        size: '12|12|12|6|6',
      }), "atDate"
    )
  }
}
