import { TuiTime } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BaseInput, BaseInputConstructor } from './base-input';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'castTimeInput' })
export class CastTimeInputPipe implements PipeTransform {
  transform<S>(value: S): TimeInput {
      return value as unknown as TimeInput;
  }
}

export interface TimeInputConstructor<T = string>
  extends BaseInputConstructor<T> {
  timePeriods?: [];
}

export class TimeInput<T = string> extends BaseInput<T> {
  inputType = 'time';
  timePeriods: readonly TuiTime[];

  constructor(options: TimeInputConstructor<T>) {
    super(options);
    this.timePeriods = options.timePeriods || tuiCreateTimePeriods();
  }
}
