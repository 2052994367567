import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, iif, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RoleType, UserType } from 'src/app/core/auth/interfaces/role.model';
import { UtilityService } from 'src/app/core/utility.service';
import { AnnouncementsService } from 'src/app/pages/private/pages/company/pages/my-announcements/pages/announcements/services/announcements.service';
import { Form, SelectInput, TextInput } from 'src/app/shared/form';
import { TextboxInputComponent } from 'src/app/shared/form/components/inputs/textbox-input/textbox-input.component';
import { SliderInput } from 'src/app/shared/form/models/inputs/slider-input';
import { SearchAnnouncementsFiltersResponse } from 'src/app/shared/interfaces/search-filters';
import { SharedInRootService } from 'src/app/shared/services/root.service';
import { SearchAnnouncementsService } from 'src/app/shared/services/search-announcements.service';
import { SearchCandidatesFilterService } from 'src/app/shared/services/search-candidates-filter.service';
import { UserService } from './../../../../core/auth/services/user.service';
import { SearchAnnouncementsFilterService } from './../../../services/search-announcements-filter.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-homepage-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() type!: UserType;
  @Input() isPublic: boolean = true;
  @ViewChildren(TextboxInputComponent)
  textboxInput: QueryList<TextboxInputComponent>;

  filterForm: Form;
  filter: SearchAnnouncementsFiltersResponse;
  usingGeolocation: boolean = false;
  UserType = UserType;
  cities = this.utilityService.getCitiesList();
  approvedOffers: Observable<boolean>;

  constructor(
    private router: Router,
    private sharedService: SharedInRootService,
    private searchCandidatesFilterService: SearchCandidatesFilterService,
    private searchAnnouncementsFilterService: SearchAnnouncementsFilterService,
    private utilityService: UtilityService,
    private searchService: SearchAnnouncementsService,
    private announcementService: AnnouncementsService,
    private userService: UserService
  ) {
    this.searchService.getFilters(this.isPublic).subscribe((filters) => {
      this.filter = filters;
      this._setForm();
      //this._asignInitValues();
    });
  }

  ngOnInit(): void {
    this.approvedOffers = this.userService.user$.pipe(
      switchMap((user: any) =>
        iif(
          () => user && user?.userRole == RoleType.COMPANY,
          this.announcementService.acceptedOffers(),
          of([])
        )
      ),
      map((offers) => !!offers.length)
    );
  }

  _asignInitValues() {
    this.filterForm.get('distance').setValue(null);
    this.filterForm.get('distance').disable();
    this.filterForm.get('myLocation').setValue(false);
  }

  _setForm() {
    this.filterForm = new Form({
      header: {
        show: false,
      },
      controls: {
        what: new TextInput({
          type: 'text',
          size: '12|12|12|6|6',
          label: 'HOME.SEARCH.WHAT',
          placeholder: 'HOME.SEARCH.WHAT_PLACEHOLDER',
          pattern: /.*\S.*/,
        }),
        where: new SelectInput<string, string>({
          label: 'SEARCH.FORM.WHERE_MUNICIPALITY.LABEL',
          placeholder: 'SEARCH.FORM.WHERE_MUNICIPALITY.PLACEHOLDER',
          options: of(this.cities),
          virtualScroll: true,
          bindValue: false,
          valueChange: (value: any) => {
            if (value && value?.cod || (typeof (value) == "string" && value)) {
              this.filterForm.get('distance').enable();
              this.filterForm
                .get('distance')
                .setValue(25, { emitEvent: false });
              this.filterForm
                .get('where')
                .setValue(value, { emitEvent: false });
            } else {
              this.filterForm.get('distance').disable();
            }
          },
        }),
        distance: new SliderInput({
          label: 'HOME.SEARCH.DISTANCE',
          size: '12|12|12|6|6',
          min: this.filter.distanceMin,
          max: this.filter.distanceMax,
          segments: 1,
          value: 25,
          readonly: true,
          showCurrentValue: true,
          currentValueLabelEnd: 'UTILS.MISURA_DISTANZA',
        }),
      },
    });
  }

  search() {
    this.filterForm.markAllAsTouched();
    if (this.type == UserType.COMPANY) {
      if (
        !(
          this.filterForm.get('what').value ||
          (this.filterForm.get('where').value &&
            this.filterForm.get('distance').value)
        )
      )
        return;
    }
    if (this.isPublic) {
      var url =
        this.type == UserType.WORKER
          ? '/candidato/cerca-annunci'
          : '/azienda/cerca-candidati';
    } else {
      var url =
        this.type == UserType.WORKER
          ? 'private/candidato/cerca-annunci/search'
          : 'private/azienda/cerca-candidati/search';
    }
    var params: Params = {};
    if (this.filterForm.get('what').value?.length > 0) {
      params['what'] = this.filterForm.get('what').value;
    }
    if (this.filterForm.get('where').value?.length > 0) {
      params['where'] = this.filterForm.get('where').value;
    }
    if (this.filterForm.get('distance').value) {
      params['distance'] = this.filterForm.get('distance').value;
    }
    if (this.type == UserType.COMPANY) {
      this.searchCandidatesFilterService.setFilterList({
        ...this.filterForm.value,
      });
    } else if (this.type == UserType.WORKER) {
      this.searchAnnouncementsFilterService.setFilterList({
        ...this.filterForm.value,
      });
    }

    this.router.navigate([url]);
  }

  onUseMyLocation(useLocation: boolean) {
    if (useLocation) {
      navigator.geolocation.getCurrentPosition(
        this._handleLocationSuccess.bind(this),
        this._handleLocationError.bind(this)
      );
    } else {
      this.usingGeolocation = false;
    }
  }

  private _handleLocationSuccess(result: GeolocationPosition) {
    this.sharedService
      .getLocationByCoordinates(result.coords.latitude, result.coords.longitude)
      .subscribe(
        (location) => {
          this.usingGeolocation = true;
          this.filterForm.get('where').setValue(location.city);
        },
        (err) => {
          this._handleLocationError.bind(this)();
        }
      );
  }

  private _handleLocationError(result: GeolocationPositionError) {
    this.filterForm.get('myLocation').setValue(false);
  }
}
