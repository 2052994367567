import * as flat from 'flat';
import { BackendConfig, backendConfig, loggerConfig, LoggerConfig } from 'src/config';

export interface FlatAppConfig {
  'backend.baseUrl': string;
  'logger.level': number;
}

const merge = <T>(dest: T, source: T): T => {
  const target = {} as T;
  // Merge the object into the target object
  const merger = (obj: any) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // If we're doing a deep merge
          // and the property is an object
          target[prop] = merge(target[prop], obj[prop]);
        } else {
          // Otherwise, do a regular merge
          target[prop] = obj[prop];
        }
      }
    }
  };
  merger(dest);
  merger(source);
  return target;
};

class InternalAppConfig {
  backend: Partial<BackendConfig> = backendConfig;
  logger: LoggerConfig = loggerConfig;

  extend(flatAppConfig: FlatAppConfig): InternalAppConfig {
    const target = merge(
      this,
      flat.unflatten<FlatAppConfig, InternalAppConfig>(flatAppConfig)
    );

    return Object.assign(this, target);
  }
}

export const AppConfig = new InternalAppConfig();
