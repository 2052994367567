import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface HeaderItemModel {
  title: string;
  isAlwaysVisible?: boolean;
  navigationUrl: string;
  fragment?: string;
  cssClass?: string;
  disabled?: boolean;
}

export interface PageHeaderItems {
  left: HeaderItemModel[];
  right: HeaderItemModel[];
  sub: HeaderItemModel[];
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private headerHeight: number;
  private containerID: string;
  private headerSubmenuMutationObserver: MutationObserver =
    new MutationObserver(this._handleHeaderSubmenuMutation.bind(this));
  private submenuHeight: number = 0;
  private headerSubmenuVisible: boolean = false;
  private hasInitiatedSubmenuObserver: boolean = false;
  private containerOffsetChange$: Subject<number> = new Subject();
  private suggestedSub$ = new BehaviorSubject<boolean>(false);
  suggested$ = this.suggestedSub$.asObservable();

  constructor() {
    window.addEventListener(
      'resize',
      this._adjustPageContentPadding.bind(this)
    );
  }

  init(pageContentElementID: string, isFlutter?: boolean) {
    this.headerHeight = 0;
    this.containerID = pageContentElementID;
    this._adjustPageContentPadding(isFlutter);
  }

  containerOffsetChangeObservable(): Observable<number> {
    return this.containerOffsetChange$.asObservable();
  }

  triggerContainerOffsetChangeObservable() {
    const container = document.getElementById(this.containerID);
    if (!container) return;
    var paddingString: string = container.style.paddingTop;
    var padding = Number(paddingString.replace('px', ''));
    this.containerOffsetChange$.next(padding);
  }

  getDefaultLinks(): PageHeaderItems {
    return {
      left: [],
      right: [],
      sub: [],
    };
  }

  getAuthHeaderLinks(): PageHeaderItems {
    return {
      left: [
        {
          title: 'HOME.NAV_LABELS.WHO',
          navigationUrl: '/who',
        },
        // {
        //   title: 'HOME.NAV_LABELS.BLOG',
        //   navigationUrl: '/home', // TBD - Blog page
        //   disabled: true,
        // },
        {
          title: 'HOME.NAV_LABELS.FAQ',
          navigationUrl: '/support',
        },
      ],
      right: [
        {
          title: 'HOME.NAV_LABELS.WORK',
          navigationUrl: '/candidato', // public homepage
        },
        {
          title: 'HOME.NAV_LABELS.CANDIDATES',
          navigationUrl: '/azienda', // public homepage
        },
      ],
      sub: [],
    };
  }

  getPublicHeaderLinks(): PageHeaderItems {
    return {
      left: [
        {
          title: 'HOME.NAV_LABELS.WHO',
          navigationUrl: '/who',
        },
        {
          title: 'HOME.NAV_LABELS.FAQ',
          navigationUrl: '/support',
        },
      ],
      right: [
        {
          title: 'HOME.NAV_LABELS.WORK',
          navigationUrl: '/candidato', // public homepage
        },
        {
          title: 'HOME.NAV_LABELS.CANDIDATES',
          navigationUrl: '/azienda', // public homepage
        },
      ],
      sub: [],
    };
  }

  getPublicHomeHeaderLinks(): PageHeaderItems {
    return {
      left: [
        {
          title: 'HOME.NAV_LABELS.WHO',
          navigationUrl: '/who',
        },
        {
          title: 'HOME.NAV_LABELS.FAQ',
          navigationUrl: '/support',
        },
        {
          title: 'HOME.NAV_LABELS.PRIVACY',
          navigationUrl: '/privacy', // link privacy
        },
      ],
      right: [
        {
          title: 'HOME.NAV_LABELS.WORK',
          navigationUrl: '/candidato', // public homepage
        },
        {
          title: 'HOME.NAV_LABELS.CANDIDATES',
          navigationUrl: '/azienda', // public homepage
        },
      ],
      sub: [],
    };
  }

  setSuggested(value: boolean) {
    this.suggestedSub$.next(value);
  }

  getPrivateWorkerHeaderLinks(): PageHeaderItems {
    let headerLinks = {
      left: [
        {
          title: 'HOME.NAV_LABELS.WHO',
          navigationUrl: '/private/candidato/who',
        },
        // {
        //   title: 'HOME.NAV_LABELS.BLOG',
        //   navigationUrl: '/home', // TBD - Blog page
        //   disabled: true,
        // },
        {
          title: 'HOME.NAV_LABELS.FAQ',
          navigationUrl: '/private/candidato/support/faq/candidato',
        },
      ],
      right: [
        {
          title: 'HOME.NAV_LABELS.WORK',
          navigationUrl: '/private/candidato/home', //private homepage
          cssClass: 'btn btn-green-outline',
        },
      ],
      sub: [
        {
          title: 'MENU.WORKER.ITEM.OVERVIEW',
          navigationUrl: '/private/candidato/utente/user-profile',
        },
        // {
        //   title: 'MENU.WORKER.ITEM.CALENDAR', // new
        //   navigationUrl: '/calendar',
        //   disabled: true,
        // },
        {
          title: 'MENU.WORKER.ITEM.MY_DOCUMENTS',
          navigationUrl: '/private/candidato/documenti',
        },
        {
          title: 'MENU.WORKER.ITEM.MY_JOB_POSITION',
          navigationUrl: '/private/candidato/annunci/preferiti',
        },
        {
          title: 'MENU.WORKER.ITEM.MY_CONVERSATIONS',
          navigationUrl: '/private/candidato/conversations',
        },
        {
          title: 'MENU.WORKER.ITEM.MY_INTERVIEWS',
          navigationUrl: '/private/candidato/interviews',
        },
      ],
    };
    return headerLinks;
  }

  getPrivateWorkerSubHeaderLinksSuggested() {
    const subs = [...this.getPrivateWorkerHeaderLinks().sub];
    subs.push({
      title: 'MENU.WORKER.ITEM.SUGGESTED_ANNOUNCEMENTS',
      navigationUrl: '/private/candidato/annunci/suggeriti',
    });
    return subs;
  }

  getPrivateWorkerTabs(): Observable<HeaderItemModel[]> {
    return this.suggested$.pipe(
      switchMap((suggested) => {
        if (suggested) {
          return of(this.getPrivateWorkerSubHeaderLinksSuggested());
        }
        return of(this.getPrivateWorkerHeaderLinks().sub);
      })
    );
  }

  getPrivateCompanyHeaderLinks(): PageHeaderItems {
    let headerLinks = {
      left: [
        {
          title: 'HOME.NAV_LABELS.WHO',
          navigationUrl: '/private/azienda/who',
        },
        // {
        //   title: 'HOME.NAV_LABELS.BLOG',
        //   navigationUrl: '/home', // TBD - Blog page
        //   disabled: true,
        // },
        {
          title: 'HOME.NAV_LABELS.FAQ',
          navigationUrl: '/private/azienda/support/faq/azienda',
        },
      ],
      right: [
        {
          title: 'HOME.NAV_LABELS.FIND_WORKER',
          navigationUrl: '/private/azienda/home', //private homepage
          cssClass: 'btn btn-green-outline',
        },
        {
          title: 'HOME.NAV_LABELS.CREATE_ANNOUNCEMENT',
          navigationUrl: '/private/azienda/annunci/gestione-annunci/create',
          cssClass: 'btn btn-green-outline',
        },
      ],
      sub: [
        {
          title: 'MENU.COMPANY.ITEM.OVERVIEW',
          navigationUrl: '/private/azienda/utente/user-profile',
        },
        // {
        //   title: 'MENU.COMPANY.ITEM.CALENDAR', // new
        //   navigationUrl: '/calendar',
        //   disabled: true,
        // },
        {
          title: 'MENU.COMPANY.ITEM.MY_DOCUMENTS',
          navigationUrl: '/private/azienda/documenti',
        },
        {
          title: 'MENU.COMPANY.ITEM.MY_ANNOUNCEMENTS',
          navigationUrl: '/private/azienda/annunci/gestione-annunci',
        },
        {
          title: 'MENU.COMPANY.ITEM.MY_CANDIDATES',
          navigationUrl: '/private/azienda/annunci/candidati-salvati',
        },
        {
          title: 'MENU.COMPANY.ITEM.MY_CONVERSATIONS',
          navigationUrl: '/private/azienda/conversations',
        },
        {
          title: 'MENU.COMPANY.ITEM.MY_INTERVIEWS',
          navigationUrl: '/private/azienda/interviews',
        },
      ],
    };
    return headerLinks;
  }

  getPrivateCompanySubHeaderLinksSuggested() {
    const subs = [...this.getPrivateCompanyHeaderLinks().sub];
    subs.push({
      title: 'MENU.COMPANY.ITEM.SUGGESTED_CANDIDATES',
      navigationUrl: '/private/azienda/annunci/candidati-suggeriti',
    });
    return subs;
  }

  getPrivateCompanyTabs(): Observable<HeaderItemModel[]> {
    return this.suggested$.pipe(
      switchMap((suggested) => {
        if (suggested) {
          return of(this.getPrivateCompanySubHeaderLinksSuggested());
        }
        return of(this.getPrivateCompanyHeaderLinks().sub);
      })
    );
  }

  _adjustPageContentPadding(isFlutter?: boolean) {
    const header: HTMLElement = document.getElementById('menu');
    if (!header) return;
    let paddingPX = header.offsetHeight;
    if (isFlutter) paddingPX = 80;
    if (this.headerHeight != paddingPX) {
      // if change necessary
      if (this.hasInitiatedSubmenuObserver) {
        const headerSubmenu = document.getElementById('header-sub-menu');
        if (headerSubmenu) this.submenuHeight = headerSubmenu.offsetHeight;
        if (this.headerSubmenuVisible) {
          this._setBodyPadding(paddingPX - 50); // submenu is shifted up by 50 px
        } else {
          this._setBodyPadding(paddingPX - this.submenuHeight); // if submenu visibility = 0
        }
      } else {
        this._setBodyPadding(paddingPX);
      }
    }
  }

  private _setBodyPadding(paddingPX: number) {
    this.headerHeight = paddingPX;
    const container = document.getElementById(this.containerID);
    if (!container) return;
    container.style.paddingTop = paddingPX + 'px';
    this.containerOffsetChange$.next(paddingPX);
  }

  setupSubmenuMutationObserver() {
    if (this.hasInitiatedSubmenuObserver)
      this.unsubscribeSubmenuMutationObserver();
    this.headerSubmenuVisible = document
      .getElementById('header-sub-menu')
      .classList.contains('visible');
    const headerSubmenu = document.getElementById('header-sub-menu');
    this.headerSubmenuVisible =
      headerSubmenu && headerSubmenu.classList.contains('visible');
    if (!headerSubmenu) return;
    this.headerSubmenuMutationObserver.observe(headerSubmenu, {
      attributes: true,
    });
    this.hasInitiatedSubmenuObserver = true;
  }

  unsubscribeSubmenuMutationObserver() {
    if (this.hasInitiatedSubmenuObserver) {
      this.headerSubmenuMutationObserver.disconnect();
      this.hasInitiatedSubmenuObserver = false;
    }
  }

  private _handleHeaderSubmenuMutation(mutationsList: MutationRecord[]) {
    // console.log('_handleHeaderSubmenuMutation ', mutationsList);
    let isSubmenuVisible: boolean;
    for (let mutation of mutationsList) {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        const submenu = mutation.target as HTMLElement;
        isSubmenuVisible = submenu.classList.contains('visible');
      }
    }
    // ignore if visibility didn't change or if the header is 'in scrolling mode'
    if (
      isSubmenuVisible != this.headerSubmenuVisible &&
      document.getElementById('menu-header').classList.contains('full-header')
    ) {
      this.headerSubmenuVisible = isSubmenuVisible;
      setTimeout(() => {
        const header: HTMLElement = document.getElementById('menu');
        const headerSubmenu = document.getElementById('header-sub-menu');
        if (!header || !headerSubmenu) return;
        if (isSubmenuVisible) {
          this._setBodyPadding(header.offsetHeight - 50); // submenu is shifted up by 50 px
        } else {
          this._setBodyPadding(header.offsetHeight - this.submenuHeight); // if submenu visibility = 0
        }
      }, 500);
    }
  }

  onLogout() {
    this.setSuggested(null);
  }
}
