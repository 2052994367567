import { DocumentType } from "../../user/models/documents-type";
import { MacroDocumentType } from "../../user/models/macro-documents-type";

export interface FinalConfirmInfo {
  contractualDocument: ContractualDocumantConfirm[];
  mandatoryDocumentForContract: MandatoryDocForContract;
  signature: SignatureConfirm;
  termsAcceptance: boolean;
  documentExpired: string[];
  workerIdArca: number;
}

export interface SignatureConfirm {
  signature: SignatureState;
}

export interface ContractualDocumantConfirm {
  docTypeCod: DocumentType;
  docTypeDes: string;
  mandatory: boolean;
  missing: boolean;
}

export type MandatoryDocForContract = {
  [x in MacroDocumentType]: {[y in DocumentType]: string};
}

export enum SignatureState {
  FALSE = "FALSE",
  SEND = "SEND",
  TRUE = "TRUE",
}
