import { Component, Input } from '@angular/core';
import { TimeShiftSheet } from 'src/app/pages/private/pages/worker/pages/messages/interfaces/proposal-detail.model';
import { weekMap } from 'src/app/pages/private/pages/worker/pages/messages/interfaces/time-sheet-map';

@Component({
  selector: 'app-shifts-time-dialog',
  templateUrl: './shifts-time-dialog.component.html',
  styleUrls: ['./shifts-time-dialog.component.scss'],
})
export class ShiftsTimeDialogComponent {
  @Input() timeSheet: TimeShiftSheet;
  @Input() index: number;


  get mapWeek() {
    return weekMap;
  }
  constructor() {}
}
