import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-how-it-works-landing',
  templateUrl: './how-it-works-landing.component.html',
  styleUrls: ['./how-it-works-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksLandingComponent {
  @Input() userType: string = 'COMPANY';
}
