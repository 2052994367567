import { Component, Input } from '@angular/core';
import { TimeInput } from '../../../models/inputs/time-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-time-input',
  template: `
    <tui-input-time
      id="{{ 'input' + name }}"
      [readOnly]="control.readonly"
      [tuiTextfieldLabelOutside]="true"
      [formControl]="control"
      [items]="control.timePeriods"
    >
    </tui-input-time>
  `,
})
export class TimeInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: TimeInput;
}
