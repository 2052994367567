<div>
  <h6 class="description">
    {{
      "PRIVATE.COMPANY.USER.USER_PROFILE.INFO.DIALOG_SIGNATURE.TEXT_2" | translate
    }}
    <span class="phone-number">
      {{ data }}
    </span>
  </h6>
  <form [formGroup]="privacyForm">
    <tui-checkbox-labeled formControlName="generalTerms" class="mb-2">
      {{ "USER.SIGNATURE.GENERAL_TERMS.TEXT" | translate }}
      <a (click)="viewGeneralTerms()" class="privacy-link">{{
        "USER.SIGNATURE.GENERAL_TERMS.LINK" | translate
      }}</a>
      {{ "USER.SIGNATURE.GENERAL_TERMS.TEXT2" | translate }}
      <b>*</b>
    </tui-checkbox-labeled>
    <tui-checkbox-labeled formControlName="privacy" class="mb-2">
      {{ "USER.SIGNATURE.PRIVACY.TEXT" | translate }}
      <a (click)="viewPrivacy()" class="privacy-link">{{
        "USER.SIGNATURE.PRIVACY.LINK" | translate
      }}</a>
      <b> *</b>
    </tui-checkbox-labeled>
    <tui-checkbox-labeled formControlName="biometricTerms" class="mb-2">
      {{ "USER.SIGNATURE.BIOMETRIC_TERMS.TEXT_1" | translate }}
      <a (click)="viewPrivacy()" class="privacy-link">{{
        "USER.SIGNATURE.BIOMETRIC_TERMS.LINK" | translate
      }}</a>
      {{ "USER.SIGNATURE.BIOMETRIC_TERMS.TEXT_2" | translate }}
      <div>
        <small
          *ngIf="!privacyForm.get('biometricTerms').value"
          role="alert"
          aria-label="Validation error"
          class="color-status-danger"
          ><strong>
            {{ "USER.SIGNATURE.BIOMETRIC_TERMS.WARNING" | translate }}
          </strong>
        </small>
      </div>
    </tui-checkbox-labeled>

    <small
      *ngIf="
        privacyForm.get('generalTerms').touched &&
        privacyForm.get('generalTerms').errors?.required &&
        privacyForm.get('privacy').touched &&
        privacyForm.get('privacy').errors?.required
      "
      role="alert"
      aria-label="Validation error"
      class="color-status-danger"
      ><strong>
        {{ "VALIDATIONS.REQUIRED" | translate }}
      </strong>
    </small>
  </form>
  <div class="row mb-2 mt-4">
    <div class="col-6">
      <button (click)="cancel()" class="btn btn-green-outline">
        {{ "UTILS.CANCEL" | translate }}
      </button>
    </div>
    <div class="col-6">
      <button
        (click)="confirm()"
        class="btn btn-green ms-auto"
        [disabled]="!privacyForm.valid"
      >
        {{
          privacyForm.get("biometricTerms").value
            ? ("USER.SIGNATURE.BTN.CONFIRM" | translate)
            : ("USER.SIGNATURE.BTN.CONFIRM_NO_BIOMETRIC" | translate)
        }}
      </button>
    </div>
  </div>
</div>
