import { Enumerated } from 'src/app/core/auth/interfaces/company.model';
import { OfferResponse } from '../../../../documents/pages/offert/interfaces';

export interface Announcement {
  idArca: number;
  commercialOffer?: OfferResponse;
  description: string;
  numberCandidates: number;
  status: JobOfferStatus;
  title: string;
  uuid: string;
  skill: string;
  moderationComment?: string;
  moderationStatus?: ModerationStatus;
  taskCode?: string;
  taskDescription?: string;
  codiceSettore?: string; // not in 'getAnnouncementList' or 'getAnnouncementDetail' API
  location?: Location;
  hourlySalaryRange: JobOfferHourlySalaryRange;
  fullTime: number; //FULL_TIME(1), PART_TIME(0)
  sectorCode: string;
  notificationFrequency: string;
  noSuggested?: boolean;
}

export interface Location {
  regione: Enumerated;
  provincia: string;
  comune: string;
}

export interface CreateAnnouncementRequest {
  offerUuid?: string;
  title: string;
  description: string;
  skill?: string;
  taskCode?: string;
  codiceSettore?: string;
  location?: Location;
  fullTime: number; //FULL_TIME(1), PART_TIME(0)
  hourlySalaryRange: JobOfferHourlySalaryRange;
}

export interface EditAnnouncementRequest {
  jobOfferUuid: string;
  title: string;
  description: string;
  skill?: string;
}
export interface ChangeAnnouncementStatusParams {
  jobOfferUuid: string;
  status: JobOfferStatus;
}

export enum JobOfferStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  CLOSED = 'CLOSED',
}
export enum ModerationStatus {
  TO_MODERATE = 'TO_MODERATE',
  MODERATION_SUCCESS = 'MODERATION_SUCCESS',
  MODERATION_FAILED = 'MODERATION_FAILED',
}
export enum JobOfferHourlySalaryRange {
  SALARY_SIX = 'SALARY_SIX',
  SALARY_SEVEN = 'SALARY_SEVEN',
  SALARY_EIGHT = 'SALARY_EIGHT',
  SALARY_NINE = 'SALARY_NINE',
  SALARY_TEN = 'SALARY_TEN',
  SALARY_ELEVEN = 'SALARY_ELEVEN',
  SALARY_TWELVE = 'SALARY_TWELVE',
  SALARY_THIRTEEN = 'SALARY_THIRTEEN',
  SALARY_FOURTEEN = 'SALARY_FOURTEEN',
}
