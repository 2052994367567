<p>{{ "REGISTER.WORKER.PRIVACY.INTRO" | translate }}</p>
<form [formGroup]="privacyForm" style="text-align: left">
  <tui-checkbox-labeled formControlName="termsFlag" class="mb-2">
    {{ "REGISTER.WORKER.PRIVACY.TERMS.TEXT" | translate }}
    <a (click)="viewTerms()" class="privacy-link">{{
      "REGISTER.WORKER.PRIVACY.TERMS.LINK" | translate
    }}</a>
    {{ "REGISTER.WORKER.PRIVACY.TERMS.TEXT2" | translate }}
    <b>*</b>
  </tui-checkbox-labeled>
  <small
    *ngIf="
      privacyForm.get('termsFlag').touched &&
      privacyForm.get('termsFlag').errors?.required
    "
    role="alert"
    aria-label="Validation error"
    class="color-status-danger"
    ><strong>
      {{ "VALIDATIONS.REQUIRED" | translate }}
    </strong></small
  >

  <tui-checkbox-labeled formControlName="mediaFlag" class="mb-2">
    {{ "REGISTER.WORKER.PRIVACY.MEDIA.TEXT" | translate }}
    <a (click)="viewMedia()" class="privacy-link">{{
      "REGISTER.WORKER.PRIVACY.MEDIA.LINK" | translate
    }}</a>
    <b>*</b>
  </tui-checkbox-labeled>
  <small
    *ngIf="
      privacyForm.get('mediaFlag').touched &&
      privacyForm.get('mediaFlag').errors?.required
    "
    role="alert"
    aria-label="Validation error"
    class="color-status-danger"
    ><strong>
      {{ "VALIDATIONS.REQUIRED" | translate }}
    </strong></small
  >

  <tui-checkbox-labeled formControlName="privacyFlag" class="mb-2">
    {{ "REGISTER.WORKER.PRIVACY.INFO.TEXT" | translate }}
    <a (click)="viewInfo()" class="privacy-link">{{
      "REGISTER.WORKER.PRIVACY.INFO.LINK" | translate
    }}</a>
    <b>*</b>
  </tui-checkbox-labeled>
  <small
    *ngIf="
      privacyForm.get('privacyFlag').touched &&
      privacyForm.get('privacyFlag').errors?.required
    "
    role="alert"
    aria-label="Validation error"
    class="color-status-danger"
    ><strong>
      {{ "VALIDATIONS.REQUIRED" | translate }}
    </strong></small
  >
  <tui-checkbox-labeled formControlName="promoFlag" class="mb-2">
    {{ "REGISTER.WORKER.PRIVACY.PROMO.TEXT" | translate }}
  </tui-checkbox-labeled>
</form>
<div class="row mt-4">
  <div class="col-6">
    <button class="w-100 btn btn-green-outline" (click)="cancel()">
      {{ "UTILS.CANCEL" | translate }}
    </button>
  </div>
  <div class="col-6">
    <button
      [disabled]="!privacyForm.valid"
      (click)="confirm()"
      class="w-100 btn btn-green"
    >
      {{ "UTILS.CONFIRM" | translate }}
    </button>
  </div>
</div>
