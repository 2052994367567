import { Component, OnInit, Input } from '@angular/core';
import { UserType } from 'src/app/core/auth/interfaces/role.model';

@Component({
  selector: 'app-homepage-welcome',
  templateUrl: './homepage-welcome.component.html',
  styleUrls: ['./homepage-welcome.component.scss'],
})
export class HomepageWelcomeComponent implements OnInit {
  @Input() type!: UserType;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      const element = document.getElementById('fancy-word-container');
      if (!element) return;
      element.classList.add('fill');
    }, 1000);
  }
}
