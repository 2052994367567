import { Injectable } from '@angular/core';
import { ApiClientService as ApiClient} from 'src/app/api-client.service';

@Injectable()
export class ChatbotService {
  constructor(private apiClient: ApiClient) {}

  generateWebChatToken() {
    return this.apiClient.request('generateWebChatToken');
  }
}
