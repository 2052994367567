import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RoleType, role2route } from 'src/app/core/auth/interfaces/role.model';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { UserService } from 'src/app/core/auth/services/user.service';
import { RegisterService } from 'src/app/pages/auth/services/register.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @Output() closeUserPanel: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    public userService: UserService,
    private registerService: RegisterService,
    private authService: AuthService
  ) { }

  ngOnInit(): void { }

  get roleTypeEnum() {
    return RoleType;
  }

  onLogOut() {
    if (this.isImpersonating()) {
      this.deimpersonate();
    } else {
      this.registerService.clear(); //just in case
      this.authService.logout().subscribe(() => {
        this.router.navigate([''], { queryParams: { loggedin: false } });
      });
    }
  }

  close() {
    this.closeUserPanel.emit(true);
  }

  get userRole() {
    return this.userService.userRoleInfo.role;
  }
  outlet() {
    return role2route(this.userService.userRoleInfo.role);
  }

  isImpersonating() {
    return !!this.userService.getIdentity()?.impersonator;
  }
  async deimpersonate() {
    await this.authService.deimpersonateClient().toPromise();
    this.router.navigate(['private']);
  }


}
