import { Pipe, PipeTransform } from '@angular/core';
import { TimeShiftSheet } from '../interfaces/proposal-detail.model';

@Pipe({ name: 'getDays' })
export class RestDayByTimeSheetPipe implements PipeTransform {
  transform(
    days: TimeShiftSheet,
    mapWeek: Map<string, string>,
    restDay = false
  ): string[] {
    const daysToShow = [];
    mapWeek.forEach((value, key) => {
      if (restDay) {
        if (days[value] === 0) {
          daysToShow.push(key);
        }
      } else {
        if (days[value] === 1) {
          daysToShow.push(key);
        }
      }
    });
    return daysToShow;
  }
}
