import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { tap } from 'rxjs/operators';
import { RoleType } from 'src/app/core/auth/interfaces/role.model';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { UserAgent } from 'src/app/pages/auth/enum/user-agent.enum';
import { environment } from 'src/environments/environment';
import { OverlayService } from '../overlay/overlay.service';
import { UserService } from './../../../core/auth/services/user.service';

@Component({
  selector: 'app-footer-campaign',
  templateUrl: './footer-campaign.component.html',
  styleUrls: ['./footer-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterCampaignComponent {
  constructor(
    private overlayService: OverlayService,
    private userService: UserService
  ) {}

  @Input() isPrivate: boolean = false;
  UserType = UserType;
  UserAgent = UserAgent;
  RoleType = RoleType;
  userType: UserType = UserType.WORKER;
  userAgent: UserAgent = new RegExp(UserAgent.FLUTTER_WEB_VIEW, 'i').test(
    window.navigator.userAgent
  )
    ? UserAgent.FLUTTER_WEB_VIEW
    : null;

  user$ = this.userService.user$.pipe(
    tap((user: any) => {
      if (
        (user && 'impersonator' in user) ||
        (user && user?.roles && RoleType.DELEGATE in user?.roles)
      ) {
        this.userType = UserType.DELEGATE;
        this.userService.getIdentity();
      } else if (user && user?.userRole == RoleType.COMPANY) {
        this.userType = UserType.COMPANY;
      } else this.userType = UserType.WORKER;
    })
  );

  openLoginOverlay() {
    this.overlayService.generalLoginOverlayOpen();
  }

  clickSocial(socialName: string) {
    window.open(`${environment.social[socialName]}`, '_blank');
  }

  handlePrivacy(key: string) {
    window.open(`${environment.privacy[key]}`, '_blank');
  }
}
