import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiStepperModule } from '@taiga-ui/kit';
import { FormModule } from 'src/app/shared/form';
import { TableModule } from 'src/app/shared/table/table.module';
import { GetEditableDocumentStatusPipe } from './get-editable-document-status.pipe';
import { GetDownloadableDocumentStatusPipe } from './get-downloadable-document-status.pipe';

@NgModule({
  declarations: [
    GetEditableDocumentStatusPipe,
    GetDownloadableDocumentStatusPipe,
  ],
  providers: [
    GetEditableDocumentStatusPipe,
    GetDownloadableDocumentStatusPipe,
  ],
  exports: [
    GetEditableDocumentStatusPipe,
    GetDownloadableDocumentStatusPipe,
  ],
  imports: [
    CommonModule,
    FormModule,
    TableModule,
    TuiSvgModule,
    TuiStepperModule,
  ],
})
export class GetFieldWorkerDocumentPipeModule {}
