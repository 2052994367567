import { Component, Input } from '@angular/core';
import { FilterButtonsInput } from '../../../models/inputs/filter-buttons-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-filter-buttons-input',
  template: `
    <tui-filter
      [formControl]="control"
      [items]="control.options | async"
      size="m"
    ></tui-filter>
  `,
})
export class FilterButtonsInputComponent extends BaseInputComponent {
  @Input() control: FilterButtonsInput;
}
