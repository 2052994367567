import { Component, Input, OnInit } from '@angular/core';
import { Notification } from '../../notifications/interfaces/notification';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { UserType } from 'src/app/core/auth/interfaces/role.model';

@Component({
  selector: 'app-notifications-carousel-section',
  templateUrl: './notifications-carousel-section.component.html',
  styleUrls: ['./notifications-carousel-section.component.scss'],
})
export class NotificationsCarouselSectionComponent implements OnInit {
  @Input() userType: UserType;
  title = 'PRIVATE.COMPANY.NOTIFICATIONS.TITLE';

  notifications: Notification[];
  visibleItems: number = 1;
  itemIndex: number = 0;

  constructor(private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.notificationsService.getNotifications({page: 0, size: 10}).subscribe((result) => {
      // console.log('getNotifications', result);
      this.notifications = result.content;
    });
  }

  carouselMove(direction: 'previous' | 'next') {
    var lastAlowedIndex = this.notifications.length - this.visibleItems;
    if (direction === 'next') {
      if (this.itemIndex == lastAlowedIndex) {
        this.itemIndex = 0;
      } else {
        this.itemIndex = this.itemIndex + 1;
      }
    } else {
      if (this.itemIndex == 0) {
        this.itemIndex = lastAlowedIndex;
      } else {
        this.itemIndex = this.itemIndex - 1;
      }
    }
  }
}
