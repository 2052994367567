<div class="announcement-card-container">
  <div class="announcement-card" [ngClass]="{
    premium: isPremium,
    WORKER: userType && userType == UserType.WORKER,
    COMPANY: userType && userType == UserType.COMPANY
  }">
    <div class="img-container">
      <div *ngIf="isPremium" class="premium-tag label">
        <span>{{ "UTILS.PREMIUM" | translate }}</span>
      </div>
      <div class="img" *ngIf="job.profileImage">
        <img [src]="job.profileImage" />
      </div>
      <div class="img no-img-border" *ngIf="!job.profileImage">
        {{
        "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NO_PHOTO" | translate
        }}
      </div>
    </div>
    <div class="contents d-flex flex-column">
      <div class="show-if-not-S header d-flex flex-row justify-content-between">
        <div class="title">{{ job.title }}</div>
        <div class="date d-flex flex-column align-items-end justify-content-center">
          <span *ngIf="!isPremium">{{
            "SEARCH.ANNOUNCEMENT.CREATED" | translate
            }}</span>
          <span>{{ getDate() }}</span>
        </div>
      </div>
      <div class="show-if-S header d-flex flex-column">
        <div class="date d-flex flex-row justify-content-between justify-content-center">
          <span>{{ getDate()}}</span>
          <tui-svg *ngIf="favourite" [src]="'tuiIconHeartFilled'"></tui-svg>
        </div>
        <div class="title">{{ job.title }}</div>
      </div>
      <div class="d-flex flex-row justify-content-between align-items-start flex-grow-1">
        <div class="info d-flex flex-column justify-content-between">
          <div>
            <div class="place">{{ getCity() }}</div>
            <div class="contract"></div>
          </div>
          <div class="sector" *ngIf="job?.sector">{{ "MAPPINGS.sectors." + job.sector | translate }}</div>
        </div>
        <tui-svg class="show-if-not-S" *ngIf="favourite" [src]="'tuiIconHeartFilled'"></tui-svg>
      </div>
    </div>
  </div>
</div>