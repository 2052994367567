<table [class]="tableClass">
  <thead
    *ngIf="showHeader"
    adsp-table-thead
    [columns]="columns"
    [tableId]="tableId"
    [dataSource]="dataSource"
    [selected]="selected"
    [rowIdentity]="rowIdentity"
    (checkboxToggle)="checkboxToggleHeader($event)"
    [checkboxHeader]="!singleSelection"
  ></thead>

  <tbody
    adsp-table-tbody
    [tableId]="tableId"
    [emptyMessage]="emptyMessage"
    [columns]="columns"
    [dataSource]="dataSource"
    [selected]="selected"
    [rowIdentity]="rowIdentity"
    [rowDetail]="rowDetail"
    [displayCheck]="displayCheck"
    [rowDetailBorder]="rowDetailBorder"
    [rowId]="rowId"
    [ngClass]="isRowClickWired ? 'cursor-pointer' : 'cursor-default'"
    (rowClick)="rowClick.emit($event)"
    (checkboxToggle)="checkboxToggleRow($event)"
    (buttonToggleClickEvent)="buttonToggleClick($event)"
  ></tbody>
</table>

<tui-pagination
  *ngIf="showPager && (dataSource.rows$ | async)?.length && totalPages > 1"
  [index]="dataSource.tablePage.pageNumber"
  [length]="totalPages"
  (indexChange)="pagerChange($event)"
></tui-pagination>
