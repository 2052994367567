<div>
  <p style="font: normal normal medium 16px/19px Roboto">
    {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.TEXT_1" | translate }}
  </p>
  <p style="font: normal normal medium 16px/19px Roboto">
    {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.TEXT_2" | translate }}
  </p>
  <div style="width: max-content">
    <p style="font: normal normal normal 14px/16px Roboto">
      <tui-svg class="icon" src="tuiIconCheck"></tui-svg>
      {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.INAIL" | translate }}
    </p>
  </div>
  <div style="width: max-content">
    <p style="font: normal normal normal 14px/16px Roboto">
      <tui-svg class="icon" src="tuiIconCheck"></tui-svg>
      {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.CCNL" | translate }}
    </p>
  </div>

  <div class="my-2">
    <button (click)="toComplete()" class="btn btn-green">
      {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.BUTTON" | translate }}
    </button>
  </div>
</div>
