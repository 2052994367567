import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap } from 'rxjs/operators';
import { ProviderEnum } from 'src/app/core/auth/enum/provider.enum';
import { StatoEnum } from 'src/app/core/auth/enum/stato.enum';
import { WorkerStatus } from 'src/app/core/auth/interfaces/worker.model';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { LoginService } from 'src/app/pages/auth/components/login/login.service';
import { Form, TextInput, ValidationStatus } from 'src/app/shared/form';
import { CloseOverlayNextStep } from '../overlay-next-step.enum';
import { UserRoleInfo } from './../../../../core/auth/services/user.service';
import { UserAgent } from 'src/app/pages/auth/enum/user-agent.enum';

@UntilDestroy()
@Component({
  selector: 'app-general-login-overlay',
  templateUrl: './general-login-overlay.component.html',
  styleUrls: ['./general-login-overlay.component.scss'],
})
export class GeneralLoginOverlayComponent implements OnInit {
  loginForm: Form;
  hide: boolean = true;
  ProviderEnum = ProviderEnum;

  UserAgent = UserAgent;
  userAgent: UserAgent = new RegExp(UserAgent.FLUTTER_WEB_VIEW, 'i').test(
    window.navigator.userAgent
  )
    ? UserAgent.FLUTTER_WEB_VIEW
    : null;
    
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<CloseOverlayNextStep>,
    private i18n: TranslateService,
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    private dialog: TuiDialogService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this._initFormLogin();
  }

  _initFormLogin() {
    this.loginForm = new Form({
      header: {
        show: false,
      },
      controls: {
        email: new TextInput({
          type: 'email',
          required: true,
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.ERROR.CUSTOM(
              (control) => control.getError('email'),
              {
                text: this.i18n.instant('VALIDATIONS.EMAIL'),
              }
            ),
          ],
          label: 'LOGIN.FORM.EMAIL.LABEL',
          placeholder: 'LOGIN.FORM.EMAIL.PLACEHOLDER',
          validatorOrOpts: [Validators.email],
        }),
        password: new TextInput({
          type: 'password',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label: 'LOGIN.FORM.PASSWORD.LABEL',
          placeholder: 'LOGIN.FORM.PASSWORD.PLACEHOLDER',
        }),
      },
    });
  }

  passwordToogle() {
    this.hide = !this.hide;
  }

  forgotPassword() {
    this.spinner.show();
    this.authService
      .forgotPassword(this.loginForm.get('email').value)
      .pipe(
        finalize(() => this.spinner.hide()),
        untilDestroyed(this)
      )
      .subscribe(() =>
        this.dialog
          .open(this.i18n.instant('LOGIN.FORGOT_PASSWORD.DIALOG.TEXT'), {
            dismissible: true,
            size: 's',
            label: this.i18n.instant('LOGIN.FORGOT_PASSWORD.DIALOG.TITLE'),
          })
          .subscribe()
      );
  }

  onLogin() {
    this.loginService.login(this.loginForm.value);
    this.closeLoginOverlay(CloseOverlayNextStep.NONE);
  }

  closeLoginOverlay(nextStep: CloseOverlayNextStep) {
    this.context.completeWith(nextStep);
  }

  socialLogin(providerName: ProviderEnum) {
    switch (providerName) {
      case ProviderEnum.APPLE:
        this.authService
          .AppleAuth()
          .pipe(
            untilDestroyed(this),
            tap(
              ([userRoleInfo, workerStatus]: [
                UserRoleInfo,
                { status: WorkerStatus }
              ]) => {
                if (
                  userRoleInfo.stato ==
                  StatoEnum.REGISTRAZIONE_INCOMPLETA_SOCIAL
                )
                  this.context.completeWith(
                    CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_INCOMPLETE_REGISTRATION
                  );
                else if (workerStatus.status !== WorkerStatus.REGISTERED)
                  this.context.completeWith(
                    CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_PROFILE
                  );
                this.context.completeWith(CloseOverlayNextStep.NONE);
              }
            )
          )
          .subscribe();
        break;
      case ProviderEnum.FACEBOOK:
        this.authService
          .FbAuth()
          .pipe(
            untilDestroyed(this),
            tap(
              ([userRoleInfo, workerStatus]: [
                UserRoleInfo,
                { status: WorkerStatus }
              ]) => {
                if (
                  userRoleInfo.stato ==
                  StatoEnum.REGISTRAZIONE_INCOMPLETA_SOCIAL
                )
                  this.context.completeWith(
                    CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_INCOMPLETE_REGISTRATION
                  );
                else if (workerStatus.status !== WorkerStatus.REGISTERED)
                  this.context.completeWith(
                    CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_PROFILE
                  );
                this.context.completeWith(CloseOverlayNextStep.NONE);
              }
            )
          )
          .subscribe();
        break;
      case ProviderEnum.GOOGLE:
        this.authService
          .GoogleAuth()
          .pipe(
            untilDestroyed(this),
            tap(
              ([userRoleInfo, workerStatus]: [
                UserRoleInfo,
                { status: WorkerStatus }
              ]) => {
                if (
                  userRoleInfo.stato ==
                  StatoEnum.REGISTRAZIONE_INCOMPLETA_SOCIAL
                )
                  this.context.completeWith(
                    CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_INCOMPLETE_REGISTRATION
                  );
                else if (workerStatus.status !== WorkerStatus.REGISTERED)
                  this.context.completeWith(
                    CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_PROFILE
                  );
                this.context.completeWith(CloseOverlayNextStep.NONE);
              }
            )
          )
          .subscribe();
        break;
      default:
        break;
    }
  }

  onRegisterAsCompany() {
    this.closeLoginOverlay(
      CloseOverlayNextStep.REDIERCT_TO_COMPANY_REGISTRATION
    );
  }
  onRegisterAsCandidate() {
    this.closeLoginOverlay(
      CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_REGISTRATION
    );
  }
}
