<ng-container>
  <button
    class="resp btn btn-green-outline float-end"
    (click)="addPlace()">
    <tui-svg src="tuiIconPlus"></tui-svg>
    {{ "REGISTER.WORKER.COMPLETION.ADD_PLACE" | translate }}
  </button>   
  <form [formGroup]="placesForm" class="mt-5">
    <div formArrayName="items"
      *ngFor="let item of placesForm.get('items')['controls']; let i = index;">
      <div [formGroupName]="i" class="mb-5">
        <app-form [form]="item"></app-form>
        <button
          class="btn btn-yellow float-end"
          (click)="removePlace(i)">
          <tui-svg src="tuiIconRemoveLarge"></tui-svg>
          {{ "REGISTER.WORKER.COMPLETION.REMOVE_PLACE" | translate }}
        </button>       
      </div>
    </div>
  </form>  
</ng-container>