<div class="col-2">
  <div class="circle mt-2">
    <img *ngIf="notification.iconType === 'conversation'" src="/assets/icons/send_black.svg" alt="send">
    <tui-svg *ngIf="notification.iconType !== 'conversation'" class="icon" [src]="getIcon(notification.iconType)"> </tui-svg>
  </div>
</div>
<div class="col-9 px-2 pt-1 label">
  <div class="sub-title">
    {{ notification.sendDate | date: "dd/MM/yyyy" }}
  </div>
  <div class="title">
    {{ notification.notifyTitle }}
  </div>
  <div class="label mt-2" [innerHTML]="notification?.notifyText"></div>
</div>
<div class="col-1 d-flex align-items-center">
  <tui-svg src="tuiIconChevronRightLarge" class="icon"></tui-svg>
</div>
