import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('image') image: ElementRef<HTMLElement>;

  constructor() {}

  ngAfterViewInit(): void {
    if (!this.container) return;
    this.container.nativeElement.style.paddingRight =
      this.image.nativeElement.offsetWidth + 'px';
  }

  clickGoogleStore() {
    window.open(`${environment.store.google}`, '_blank');
  }
  clickAppleStore() {
    window.open(`${environment.store.apple}`, '_blank');
  }
}
