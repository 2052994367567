import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { environment } from 'src/environments/environment';

export interface SignatureTerms {
  acceptGeneralTerms: boolean;
  acceptPrivacy: boolean;
  acceptBiometricTerms: boolean;
}

@Component({
  selector: 'app-signature-confirm',
  templateUrl: './signature-confirm.component.html',
  styleUrls: ['./signature-confirm.component.scss'],
})
export class SignatureConfirmComponent {
  privacyForm = new FormGroup({
    generalTerms: new FormControl(false, Validators.requiredTrue),
    privacy: new FormControl(false, Validators.requiredTrue),
    biometricTerms: new FormControl(false),
  });
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<SignatureTerms>
  ) {}

  get data(): number {
    return this.context.data;
  }

  confirm() {
    this.context.completeWith({
      acceptGeneralTerms: true,
      acceptPrivacy: true,
      acceptBiometricTerms: this.privacyForm.get('biometricTerms').value,
    });
  }

  cancel() {
    this.context.completeWith({
      acceptGeneralTerms: false,
      acceptPrivacy: false,
      acceptBiometricTerms: false,
    });
  }

  viewGeneralTerms() {
    window.open(`${environment.privacy.signature.generalTerms}`, '_blank');
  }

  viewPrivacy() {
    window.open(`${environment.privacy.signature.privacy}`, '_blank');
  }
}
