export const META_INFO = {
  "/": {
    title: "InPlace - Dal contatto al contratto: agenzia per il lavoro digitale",
    description: "InPlace - Dal contatto al contratto: agenzia per il lavoro digitale"
  },
  "/home": {
    title: "Reception | InPlace - Dal contatto al contratto",
    description: "InPlace è la piattaforma digitale per cercare e offrire lavoro. Registrati come candidato e carica il CV o accedi come azienda."
  },
  "/candidato": {
    title: "Candidati | InPlace - Dal contatto al contratto",
    description: "Cerca offerte di lavoro, contatta le aziende e candidati inviando il tuo curriculum. Accedi ora a InPlace, l'agenzia per il lavoro 100% digitale."
  },
  "/azienda": {
    title: "Aziende | InPlace - Dal contatto al contratto",
    description: "InPlace è l'agenzia per il lavoro 100% digitale che accompagna le aziende dal primo contatto con i candidati al contratto di lavoro."
  },
  "/auth/register": {
    title: "Register | InPlace - Dal contatto al contratto",
    description: "Registrati su InPlace come candidato per trovare il tuo lavoro ideale o inserisci i dati aziendali per cercare il tuo prossimo candidato."
  },
  "/auth/login": {
    title: "Login | InPlace - Dal contatto al contratto",
    description: "Accedi ora a InPlace, l'agenzia 100% digitale per cercare e offrire lavoro. Effettua il login al profilo e scopri opportunità e annunci di lavoro."
  },
  "/who": {
    title: "Chi Siamo | InPlace - Dal contatto al contratto",
    description: "Nasce InPlace, l'agenzia per il lavoro 100% digitale per mettere in contatto aziende e potenziali candidati."
  },
  "/support": {
    title: "Assistenza | InPlace - Dal contatto al contratto",
    description: "Quali sono le principali tipologie di contratto di lavoro? Come registrarsi in piattaforma? InPlace è qui per aiutarti, leggi le FAQ."
  },
  "/private/azienda/who": {
    title: "Chi Siamo | InPlace - Dal contatto al contratto",
    description: "Nasce InPlace, l'agenzia per il lavoro 100% digitale per mettere in contatto aziende e potenziali candidati."
  },
  "/private/azienda/support": {
    title: "Assistenza | InPlace - Dal contatto al contratto",
    description: "Quali sono le principali tipologie di contratto di lavoro? Come registrarsi in piattaforma? InPlace è qui per aiutarti, leggi le FAQ."
  },
  "/private/candidato/who": {
    title: "Chi Siamo | InPlace - Dal contatto al contratto",
    description: "Nasce InPlace, l'agenzia per il lavoro 100% digitale per mettere in contatto aziende e potenziali candidati."
  },
  "/private/candidato/support": {
    title: "Assistenza | InPlace - Dal contatto al contratto",
    description: "Quali sono le principali tipologie di contratto di lavoro? Come registrarsi in piattaforma? InPlace è qui per aiutarti, leggi le FAQ."
  },
  "/private/azienda/cerca-candidati": {
    title: "Candidati in evidenza | InPlace - Dal contatto al contratto",
    description: "Trova dipendenti tra i candidati presenti su InPlace. Esamina profili in linea con i tuoi annunci di lavoro e connettiti con le figure più in linea."
  },
  "/private/candidato/cerca-annunci/": {
    title: "Annunci in evidenza| InPlace - Dal contatto al contratto",
    description: "Scopri i nostri annunci di lavoro in evidenza. Su InPlace, agenzia per il lavoro digitale, puoi trovare offerte consigliate per te e le tue esigenze."
  }


};