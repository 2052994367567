import { Component, Input } from '@angular/core';
import { DateInputTime } from '../../../models/inputs/date-input-time';
import { TuiTimeMode } from '@taiga-ui/cdk';

@Component({
  selector: 'app-date-time-input',
  template: `<tui-input-date-time
    [readOnly]="control.readonly"
    [tuiTextfieldLabelOutside]="true"
    [formControl]="control"
    [min]="control.min"
    [max]="control.max"
    [timeMode]="timeMode"
  >
    <!-- {{ control.placeholder | translate }} -->
  </tui-input-date-time>`,
})
export class DateInputTimeComponent {
  @Input('control') set _control (c: DateInputTime) {
    this.control = c;
    this.timeMode = c.timeMode as TuiTimeMode;
  }
  control: DateInputTime;
  timeMode: TuiTimeMode;
}
