<div class="candidate-card-container h-100">
  <div
    class="candidate-card h-100"
    [ngClass]="{
      premium: isPremium,
      WORKER: userType && userType == UserType.WORKER,
      COMPANY: userType && userType == UserType.COMPANY
    }"
  >
    <div class="contents d-flex flex-column">
      <div
        class="show-if-not-S header"
      >
        <div class="title">{{ worker?.name | titlecase }}</div>
        <div
          class="date d-flex flex-column align-items-end justify-content-center"
          *ngIf="worker?.lastSeen"
        >
          {{ "SEARCH.INFO.LAST_SEEN" | translate }}
          <span>
            {{ " " + (worker?.lastSeen | date : "dd MMMM yyyy" : "" : "it") }}
          </span>
        </div>
      </div>

      <!-- SMALL SCREENS -->
      <div class="show-if-S header">
        <!-- LAST SEEN -->
        <div
          class="date d-flex flex-row justify-content-between align-items-center"
        >
          <span *ngIf="worker?.lastSeen">
            {{ "SEARCH.INFO.LAST_SEEN" | translate }}
            {{ " " + (worker?.lastSeen | date : "dd MMMM yyyy" : "" : "it") }}
          </span>
        </div>
        <div class="title">{{ worker?.name | titlecase }}</div>
      </div>

      <!-- ADDRESS -->
      <div class="info d-flex flex-column justify-content-end">
        <div
          class="place"
          *ngIf="worker?.address?.municipality || worker?.address?.region"
        >
          <div class="label">{{ "SEARCH.INFO.ADDRESS" | translate }}</div>
          <div class="info fw-bold">
            {{
              worker?.address?.municipality
                ? ("MAPPINGS.municipalities." + worker.address.municipality
                  | translate
                  | titlecase)
                : null
            }}
            {{
              worker?.address?.region
                ? (worker?.address?.municipality ? " - " : "") +
                  ("MAPPINGS.regions." + worker.address.region | translate)
                : null
            }}
            {{
              worker?.address?.country && worker?.address?.country != "ITALIA"
                ? " , " +
                  ("MAPPINGS.countries." + worker.address.country | translate)
                : null
            }}
          </div>
        </div>

        <!-- MATCHED LOCATION -->
        <div
          class="place"
          *ngIf="
            worker?.matchedLocation?.municipality ||
            worker?.matchedLocation?.region ||
            (worker?.matchedLocation?.country &&
              worker?.matchedLocation?.country !== 'ITALIA')
          "
        >
          <div class="label">
            {{ "SEARCH.INFO.JOB_LOCATION_PREFERENCE" | translate }}
          </div>
          <div class="info fw-bold">
            {{
              worker?.matchedLocation?.municipality
                ? ("MAPPINGS.municipalities." +
                    worker?.matchedLocation?.municipality
                  | translate
                  | titlecase)
                : null
            }}
            {{
              worker?.matchedLocation?.region
                ? (worker?.matchedLocation?.municipality ? " - " : "") +
                  ("MAPPINGS.regions." + worker?.matchedLocation?.region
                    | translate
                    | titlecase)
                : null
            }}
            {{
              worker?.matchedLocation?.country &&
              worker?.matchedLocation?.country !== "ITALIA"
                ? (worker?.matchedLocation?.region ? " ," : "") +
                  ("MAPPINGS.regions." + worker?.matchedLocation?.region
                    | translate)
                : null
            }}
          </div>
        </div>

        <div class="sector" *ngIf="worker?.task?.des || worker?.sector">
          {{
           ( worker?.task && worker?.task?.des
              ? (worker.task.des | titlecase)
              : "Non specificato") +
                " - " +
                ("MAPPINGS.sectors." + worker?.sector | translate)
          }}
        </div>

        <div class="btn-container d-grid">
          <button type="button" class="btn btn-green mt-2">
            {{ "SEARCH.BUTTONS.SHOW" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noAddress>
  <span class="info no-address"
    >{{ "SEARCH.INFO.NO_DISTANCE" | translate }}
  </span>
</ng-template>
