import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { OverlayService } from '../overlay/overlay.service';
import { Router } from '@angular/router';
import { UserType } from 'src/app/core/interfaces/user-type.enum';

export enum CarouselElementType {
  WORKER_CARD = 'WORKER_CARD',
  JOB_CARD = 'JOB_CARD',
  SUGGESTED_WORKER_CARD = 'SUGGESTED_WORKER_CARD',
  SUGGESTED_JOB_CARD = 'SUGGESTED_JOB_CARD',
}

@Component({
  selector: 'app-carousel-with-items',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input() showPagination: boolean = false;
  @Input() elementsInRow: number = 1;
  @Input() elementsInColumn: number = 1;
  @Input() elementType!: CarouselElementType;
  @Input() isPrivate: boolean = false;
  @Input() favourite: boolean = false;
  @Input() userType: UserType = UserType.WORKER;
  @Input() titleTemplate?: TemplateRef<any>;
  @Input() subtitleTemplate?: TemplateRef<any>;
  @Input('elements') set _elements(elements: any[]) {
    this.elements = elements;
    if (this.elements.length < 2) this.elementsInRow = 1;
    if (window.innerWidth < 750) {
      this.elementsInColumn = 1;
      this.elementsInRow = 1;
    }
    if (
      this.elementType == CarouselElementType.SUGGESTED_WORKER_CARD ||
      this.elementType == CarouselElementType.SUGGESTED_JOB_CARD
    ) {
      if (this.elements.length < 3) this.elementsInRow = 2;
      if (window.innerWidth > 1200 && this.elementsInRow === 2)
        this.ngClasses.push('px-5');
      if (window.innerWidth < 1200) {
        this.elementsInRow = 3;
        this.elementsInColumn = 8;
        if (this.elements.length < 3) this.elementsInRow = 2;
        if (this.elements.length < 2) this.elementsInRow = 1;
      }
      if (window.innerWidth < 992) {
        this.elementsInRow = 2;
        this.elementsInColumn = 12;
        if (this.elements.length < 2) this.elementsInRow = 1;
      }
      if (window.innerWidth < 576) {
        this.elementsInRow = 1;
        this.elementsInColumn = 2;
      }
    }
    this._constructItems();
  }

  elements: any[] = [];
  items: { elements: any[] }[] = [];
  visibleItems: number = 1;
  itemIndex: number = 0;
  ElementType = CarouselElementType;
  ngClasses: string[] = [];

  constructor(private overlayService: OverlayService, private router: Router) {}

  onCardClick(element: any) {
    if (this.isPrivate) {
      if (
        this.elementType == CarouselElementType.JOB_CARD ||
        this.elementType == CarouselElementType.SUGGESTED_JOB_CARD
      ) {
        this.router.navigate([
          '/private/candidato/cerca-annunci/search-detail/' + element.id,
        ]);
      }
      if (
        this.elementType == CarouselElementType.WORKER_CARD ||
        this.elementType == CarouselElementType.SUGGESTED_WORKER_CARD
      ) {
        this.router.navigate([
          '/private/azienda/cerca-candidati/search-detail/' + element.id,
        ]);
      }
    } else {
      this.overlayService.generalLoginOverlayOpen();
    }
  }

  carouselMove(direction: 'previous' | 'next') {
    if (direction === 'next') {
      if (this.itemIndex == this.items.length - 1) {
        this.itemIndex = 0;
      } else {
        this.itemIndex = this.itemIndex + 1;
      }
    } else {
      if (this.itemIndex == 0) {
        this.itemIndex = this.items.length - 1;
      } else {
        this.itemIndex = this.itemIndex - 1;
      }
    }
  }

  private _constructItems() {
    var elementsPerItem: number = this.elementsInColumn * this.elementsInRow;
    this.items = [];
    var curentGroup: { elements: any[] } = {
      elements: [],
    };
    this.elements.forEach((element, index) => {
      if (curentGroup.elements.length < elementsPerItem) {
        curentGroup.elements.push(element);
      }
      if (
        curentGroup.elements.length == elementsPerItem ||
        index == this.elements.length - 1
      ) {
        this.items.push(curentGroup);
        curentGroup = { elements: [] };
      }
    });
  }
}
