<div
  class="complete-profile-section my-5"
  #component
  *ngIf="userState != userStateEnum.COMPLETO"
>
  <h3>{{ "HOME.COMPLETE_PROFILE.TITLE" | translate }}</h3>
  <div class="contents">
    <div class="left">
      <div
        class="text"
        [innerHTML]="
          userType == UserTypeEnum.WORKER
            ? ('HOME.COMPLETE_PROFILE.TEXT_WORKER' | translate)
            : ('HOME.COMPLETE_PROFILE.TEXT_COMPANY' | translate)
        "
      ></div>
      <button class="btn btn-green-reverse" (click)="goToCompleteProfile()">
        {{ "HOME.COMPLETE_PROFILE.BTN" | translate }}
      </button>
    </div>
    <div class="right-container">
      <div class="right" [ngClass]="{ middle: isViewMiddle }">
        <div class="point point_1">
          <span class="second">{{
            userType == UserTypeEnum.WORKER
              ? ("HOME.COMPLETE_PROFILE.WORKER_POINT_1" | translate | uppercase)
              : ("HOME.COMPLETE_PROFILE.COMPANY_POINT_1" | translate | uppercase)
          }}</span>
        </div>
        <div class="point point_2">
          <span class="second">{{
            userType == UserTypeEnum.WORKER
              ? ("HOME.COMPLETE_PROFILE.WORKER_POINT_2" | translate | uppercase)
              : ("HOME.COMPLETE_PROFILE.COMPANY_POINT_2" | translate | uppercase)
          }}</span>
        </div>
      </div>
      <div class="right" [ngClass]="{ middle: isViewMiddle }" *ngIf="userType == UserTypeEnum.WORKER">
        <div class="point point_2">
          <span class="second">{{
            ("HOME.COMPLETE_PROFILE.WORKER_POINT_3" | translate | uppercase)
          }}</span>
        </div>
        <div class="point point_1">
          <span class="second">{{
            ("HOME.COMPLETE_PROFILE.WORKER_POINT_4" | translate | uppercase)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
