import { NbAclOptions } from '@nebular/security';

export const acl: NbAclOptions = {
  accessControl: {
    COMPLETO: { view: '*', create: '*', edit: '*', delete: '*', },
    INCOMPLETO: { view: [], create: [], edit: ['*'], delete: [] },
    REGISTERED: { view: [], create: [], edit: [], delete: [] },
    DELETED: { view: [], create: [], edit: [], delete: [] },
    ELIGIBLE: { view: '*', create: '*', edit: '*', delete: '*' },
    CONTRACTUALIZABLE: { view: '*',  create: '*', edit: '*', delete: '*' },
  },
};
