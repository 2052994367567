import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Company } from 'src/app/core/auth/interfaces/company.model';
import { UserProfileService } from 'src/app/pages/private/pages/company/pages/user/services/user-profile.service';
import {
  FileInput,
  Form,
  NopInput,
  ValidationStatus,
} from 'src/app/shared/form';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-company-profile-welcome-section',
  templateUrl: './company-welcome-section.component.html',
  styleUrls: ['./company-welcome-section.component.scss'],
})
export class CompanyWelcomeSectionComponent implements OnInit {
  @ViewChild('modalAddPhoto') modalAddPhoto: TemplateRef<any>;
  @ViewChild('commingSoonTemplate') commingSoonTemplate: TemplateRef<any>;
  @ViewChild('photo') photo: ElementRef;
  @ViewChild('webcam') webcam: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  @Input() user: Company;
  @Input() isEdit: boolean = false;
  @Output() onSaveModifications: EventEmitter<any> = new EventEmitter();

  photoUrl: string = '';
  uuidEditPhoto: string = '';
  formDocument: Form;
  videoElement: HTMLVideoElement;
  stream: MediaStream;
  photoTaken: boolean = false;
  errorPhoto: boolean = false;
  photoToSave: HTMLImageElement;
  errorMsg = '';

  constructor(
    private loading: NgxSpinnerService,
    private router: Router,
    private service: UserProfileService,
    private i18n: TranslateService,
    private dialogService: TuiDialogService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getPhoto();
  }

  saveProfile() {
    this.onSaveModifications.emit(true);
  }

  onModifyProfile() {
    this.router.navigate(['/private/azienda/utente/edit-profile']);
  }

  getName(): string {
    return this.user.ragioneSociale ? this.user.ragioneSociale : this.user.nome;
  }

  getCreatedDate(): string {
    return new Date(this.user.created).toLocaleString('it', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  }
  getLocation(): string {
    if (!this.user.sedeLegale) {
      return "";
    }
    if (this.user.sedeLegale.stato === 'ITALIA') {
      const comune = this.i18n.instant('MAPPINGS.municipalities')[this.user.sedeLegale.comune];
      return `${comune} (${this.user.sedeLegale.provincia})`;
    } else {
      return `${this.user.sedeLegale.comuneEstero} (${this.user.sedeLegale.stato})`;
    }
  }

  getPhoto() {
    // this.service
    //   .getPhoto(this.user.documentRegistry?.documentFile?.userUuid)
    //   .pipe(
    //     finalize(() => this.loading.hide()),
    //     untilDestroyed(this)
    //   )
    //   .subscribe((resp: any) => {
    //     this.photoUrl = resp.url;
    //   });
    this.photoUrl = environment.companyImageURL;
  }

  onEditPhoto() {
    this.dialogService.open(this.commingSoonTemplate).subscribe();
    return;
    // this.loading.show();
    // var dialogTitle =
    //   'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.LOAD_PHOTO';
    // this.uuidEditPhoto = '';
    // this._initFormPhoto();
    // this.service
    //   .getPhoto(this.user.documentRegistry.documentFile.userUuid)
    //   .pipe(
    //     finalize(() => this.loading.hide()),
    //     untilDestroyed(this)
    //   )
    //   .subscribe((resp: any) => {
    //     if (resp.uuidDocument) {
    //       this.uuidEditPhoto = resp.uuidDocument;
    //     }
    //     this.dialogService
    //       .open(this.modalAddPhoto, {
    //         dismissible: false,
    //         size: 'l',
    //         label: this.i18n.instant(dialogTitle),
    //       })
    //       .subscribe({
    //         complete: () => {
    //           this.stream.getTracks().forEach((track) => {
    //             track.stop();
    //           });
    //         },
    //       });
    //     const constraints = {
    //       video: true,
    //       audio: false,
    //     };
    //     this.photoTaken = false;
    //     this.errorPhoto = false;
    //     this.errorMsg = '';
    //     navigator.mediaDevices
    //       .getUserMedia(constraints)
    //       .then((stream) => {
    //         this.videoElement = this.webcam.nativeElement;
    //         this.stream = stream;
    //         this.videoElement.srcObject = this.stream;
    //         this.videoElement.play();
    //       })
    //       .catch((err) => {
    //         this.errorPhoto = true;
    //         if (
    //           err.name === 'NotFoundError' ||
    //           err.name === 'DevicesNotFoundError'
    //         ) {
    //           // required track is missing
    //           console.log('Required track is missing');
    //           this.errorMsg = this.i18n.instant(
    //             'ERRORS.TAKE_PHOTO.DEVICE_NOT_FOUND.MESSAGE'
    //           );
    //         } else if (
    //           err.name === 'NotReadableError' ||
    //           err.name === 'TrackStartError'
    //         ) {
    //           // webcam or mic are already in use
    //           console.log('Webcam or mic are already in use');
    //           this.errorMsg = this.i18n.instant(
    //             'ERRORS.TAKE_PHOTO.ALREADY_IN_USE.MESSAGE'
    //           );
    //         } else if (
    //           err.name === 'OverconstrainedError' ||
    //           err.name === 'ConstraintNotSatisfiedError'
    //         ) {
    //           // constraints can not be satisfied by avb. devices
    //           console.log(
    //             'Constraints can not be satisfied by available devices'
    //           );
    //           this.errorMsg = this.i18n.instant(
    //             'ERRORS.TAKE_PHOTO.CONSTRAINTS.MESSAGE'
    //           );
    //         } else if (
    //           err.name === 'NotAllowedError' ||
    //           err.name === 'PermissionDeniedError'
    //         ) {
    //           // permission denied in browser
    //           console.log('Permission Denied.');
    //           this.errorMsg = this.i18n.instant(
    //             'ERRORS.TAKE_PHOTO.PERMISSION.MESSAGE'
    //           );
    //         } else if (err.name === 'TypeError' || err.name === 'TypeError') {
    //           // empty constraints object
    //           console.log('Both audio and video are FALSE');
    //           this.errorMsg = this.i18n.instant(
    //             'ERRORS.TAKE_PHOTO.CHANNELS_NOT_FOUND.MESSAGE'
    //           );
    //         } else {
    //           // other errors
    //           console.log('Sorry! Another error occurred.');
    //           this.errorMsg = this.i18n.instant(
    //             'ERRORS.TAKE_PHOTO.GENERIC.MESSAGE'
    //           );
    //         }
    //       });
    //   });
  }

  takePhoto() {
    this.loading.show();
    this.photoTaken = false;
    const height =
      this.webcam.nativeElement.videoHeight /
      (this.webcam.nativeElement.videoWidth / 320);
    const context = this.canvas.nativeElement.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    const data = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', data);
    this.canvas.nativeElement.width = 320;
    this.canvas.nativeElement.height = height;
    context.drawImage(this.webcam.nativeElement, 0, 0, 320, height);
    const dataToSave = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', dataToSave);
    this.photoToSave = dataToSave;
    this.photoTaken = true;
    this.loading.hide();
  }

  savePhoto(type, observer) {
    this.loading.show();
    const name = this._randomString(
      20,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    let params = {};
    if (type === 'web') {
      const photoImg = this._dataURLtoFile(this.photoToSave, name + '.png');
      params = {
        profileImg: photoImg,
      };
    } else {
      params = {
        profileImg: this.formDocument.get('file').value,
      };
    }
    this.service
      .savePhoto(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.getPhoto();
        observer.complete();
        this.toastr.success(
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TOAST_TEXT'
          ),
          this.i18n.instant(
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TOAST_TITLE'
          )
        );
      });
  }

  private _randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  private _dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  private _initFormPhoto() {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        nome: new NopInput({}),
        file: new FileInput({
          size: '12|12|12|12|12',
          buttonPlaceholder:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.PHOTO.LABEL',
          label:
            'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.PHOTO.PLACEHOLDER',
          placeholder: '',
          required: true,
          accept: 'image/png, image/jpeg, image/jpg',
          maxSize: 50 * 1000000,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          valueChange: (file: File) => {
            this.formDocument.get('nome').setValue(file.name);
          },
        }),
      },
    });
  }
}
