import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from 'src/app/core/utility.service';

@Pipe({ name: 'getEditableDocumentStatus' })
export class GetEditableDocumentStatusPipe implements PipeTransform {
  public constructor(private utilityService: UtilityService) {}
  transform(status: string): boolean {
    return this.utilityService.getEditableDocStatus(status);
  }
}
