<div class="user-profile-welcome-section my-5">
  <div class="profile-pic">
    <div class="img" *ngIf="photoUrl">
      <img class="card-light hover-img-radius" [src]="photoUrl" />
    </div>
    <div class="img" *ngIf="!photoUrl">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NO_PHOTO" | translate
      }}
    </div>
    <button class="btn btn-green mt-5" (click)="onEditPhoto()">
      {{ "PRIVATE.WORKER.USER_DETAIL.IMG_BTN" | translate }}
    </button>
  </div>
  <div class="profile-sum">
    <div class="profile-header">
      <div class="left">
        <h4>{{ "PRIVATE.WORKER.USER_DETAIL.WELCOME" | translate }}</h4>
        <h3>{{ getName() | titlecase }}</h3>
      </div>
      <div class="right">
        <tui-hosted-dropdown
          tuiDropdownAlign="bottom"
          [content]="videoCvDropdown">
          <button class="btn btn-green">
            {{ "PRIVATE.WORKER.USER_DETAIL.VIDEO_CV_BTN" | translate }}
            <tui-svg style="margin-left: 5px; font-size: 20px;" src="tuiIconVideo"></tui-svg>
          </button>
        </tui-hosted-dropdown>
        <button
          *ngIf="!isEdit"
          class="btn btn-green-outline"
          (click)="onModifyProfile()">
          <tui-svg src="tuiIconDraft"></tui-svg>
          {{ "PRIVATE.WORKER.USER_DETAIL.EDIT_PROFILE_BTN" | translate }}
        </button>
        <!-- <button
          *ngIf="isEdit"
          class="btn btn-green-outline"
          (click)="saveProfile()">
          <tui-svg src="tuiIconDraft"></tui-svg>
          {{ "PRIVATE.WORKER.USER_DETAIL.SAVE_PROFILE_BTN" | translate }}
        </button> -->
      </div>
    </div>
    <div class="mt-3">
      <span
        class="avaliability"
        [ngClass]="{ 'not-avaliable': user.employed }">{{
        "PRIVATE.WORKER.USER_DETAIL.AVALIABLE_FOR_WORK_" + !user.employed
        | uppercase
        | translate
        }}</span>
      <span class="work">{{ "MAPPINGS.sectors." + user.sector | translate }}</span>
    </div>
    <div class="inplace-info text-small">
      <div class="left">
        <div class="feedback">
          <span class="number">{{ user.ratings }}</span>
          <span class="text black">{{
            "PRIVATE.WORKER.USER_DETAIL.FEEDBACK" | translate
            }}</span>
          <span class="stars"><app-rating-stars
              [stars]="getStars(user.averageRating)"></app-rating-stars></span>
        </div>
        <div class="jobs">
          <span class="number">{{ user.contracts }}</span>
          <span class="text black">{{
            "PRIVATE.WORKER.USER_DETAIL.INPLACE_JOBS" | translate
            }}</span>
        </div>
      </div>
      <div class="right">
        <div class="date black">
          <span>{{
            "PRIVATE.WORKER.USER_DETAIL.CREATED_DATE" | translate
            }}</span><span><b>{{ getCreatedDate() }}</b></span>
        </div>
      </div>
    </div>
    <div class="user-info">
      <div class="location">
        <div class="icon">
          <img src="./assets/icons/location_yellow.svg" />
        </div>
        <div class="info">
          <div class="title text-small">
            {{ "PRIVATE.WORKER.USER_DETAIL.LOCATION" | translate }}
          </div>
          <div class="data">{{ getLocation() }}</div>
        </div>
      </div>
      <div class="education" *ngIf="latestEducation">
        <div class="icon">
          <img src="./assets/icons/education_yellow.svg" />
        </div>
        <div class="info">
          <div class="title text-small">
            {{ "PRIVATE.WORKER.USER_DETAIL.EDUCATION_TITLE" | translate }}
          </div>
          <div class="data">{{ latestEducation }}</div>
        </div>
      </div>
      <div class="language" *ngIf="languagesString">
        <div class="icon">
          <img src="./assets/icons/language_yellow.svg" />
        </div>
        <div class="info">
          <div class="title text-small">
            {{ "PRIVATE.WORKER.USER_DETAIL.LANGUAGES" | translate }}
          </div>
          <div class="data">{{ languagesString }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="user-profile-welcome-section-mobile my-5">
  <div class="profile-header">
    <div class="left">
      <h4>{{ "PRIVATE.WORKER.USER_DETAIL.WELCOME" | translate }}</h4>
      <h2>{{ getName() | titlecase }}</h2>
    </div>
    <div class="right">
      <tui-hosted-dropdown
        tuiDropdownAlign="bottom"
        [content]="videoCvDropdown">
        <button class="btn btn-green">
          <tui-svg src="tuiIconVideo"></tui-svg>
        </button>
      </tui-hosted-dropdown>
      <button class="btn btn-green-outline" (click)="onModifyProfile()">
        <tui-svg src="tuiIconDraft"></tui-svg>
      </button>
    </div>
  </div>
  <div class="profile-sub-header">
    <span class="work">{{ "MAPPINGS.sectors." + user.sector | translate }}</span>
    <div class="date black">
      <span>{{ "PRIVATE.WORKER.USER_DETAIL.CREATED_DATE" | translate }} <b>{{ getCreatedDate() }}</b></span>
    </div>
  </div>
  <div class="profile-pic">
    <div class="img" *ngIf="photoUrl">
      <img class="card-light hover-img-radius" [src]="photoUrl" />
    </div>
    <div class="img" *ngIf="!photoUrl">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NO_PHOTO" | translate
      }}
    </div>
    <button *ngIf="!isEdit" class="btn btn-green mt-3" (click)="onEditPhoto()">
      {{ "PRIVATE.WORKER.USER_DETAIL.IMG_BTN" | translate }}
    </button>
  </div>
  <div class="user-info">
    <div class="location">
      <div class="icon">
        <img src="./assets/icons/location_yellow.svg" />
      </div>
      <div class="info">
        <div class="title text-small">
          {{ "PRIVATE.WORKER.USER_DETAIL.LOCATION" | translate }}
        </div>
        <div class="data">{{ getLocation() }}</div>
      </div>
    </div>
    <div class="education" *ngIf="latestEducation">
      <div class="icon">
        <img src="./assets/icons/education_yellow.svg" />
      </div>
      <div class="info">
        <div class="title text-small">
          {{ "PRIVATE.WORKER.USER_DETAIL.EDUCATION_TITLE" | translate }}
        </div>
        <div class="data">{{ latestEducation }}</div>
      </div>
    </div>
    <div class="language" *ngIf="languagesString">
      <div class="icon">
        <img src="./assets/icons/language_yellow.svg" />
      </div>
      <div class="info">
        <div class="title text-small">
          {{ "PRIVATE.WORKER.USER_DETAIL.LANGUAGES" | translate }}
        </div>
        <div class="data">{{ languagesString }}</div>
      </div>
    </div>
  </div>
  <span
    class="avaliability mt-2"
    [ngClass]="{ 'not-avaliable': user.employed }">{{
    "PRIVATE.WORKER.USER_DETAIL.AVALIABLE_FOR_WORK_" + !user.employed
    | uppercase
    | translate
    }}</span>
  <div class="jobs">
    <span class="number">{{ user.contracts }}</span>
    <span class="text black">{{
      "PRIVATE.WORKER.USER_DETAIL.INPLACE_JOBS" | translate
      }}</span>
  </div>
  <div class="feedback">
    <span class="number">{{ user.ratings }}</span>
    <span class="text black">{{
      "PRIVATE.WORKER.USER_DETAIL.FEEDBACK" | translate
      }}</span>
    <span class="stars"><app-rating-stars
        [stars]="getStars(user.averageRating)"></app-rating-stars></span>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template let-observer #modalAddPhoto>
  <app-form [form]="formDocument"></app-form>
  <div class="mb-2 justify-content-center pb-mobile" *ngIf="uuidEditPhoto">
    {{
    "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_3" | translate
    }}
  </div>
  <div class="d-flex justify-content-center pt-3">
    <button
      (click)="savePhoto('form', observer)"
      [disabled]="!formDocument.valid"
      class="text-center btn btn-green">
      <tui-svg src="tuiIconPlus"></tui-svg>
      {{ "UTILS.EDIT" | translate }}
    </button>
  </div>
  <div class="justify-content-center pt-3" *ngIf="!errorPhoto">
    <div class="d-flex flex-wrap align-items-stretch justify-content-center gap-2">
      <div class="mb-2 camera col-12 col-md-6">
        <div>
          {{
          "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_1"
          | translate
          }}
        </div>
        <div>
          <video id="video" class="video" controls #webcam></video>
        </div>
      </div>
      <div class="mb-2 output col-12 col-md-6">
        <div *ngIf="photoTaken">
          {{
          "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_2"
          | translate
          }}
        </div>
        <div [ngStyle]="{ height: !photoTaken ? '0' : 'auto' }">
          <canvas id="canvas" #canvas> </canvas>
          <img id="photo" #photo />
        </div>
      </div>
    </div>
    <div class="mb-2 col-12 justify-content-center" *ngIf="uuidEditPhoto">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_3"
      | translate
      }}
    </div>
    <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
      <button class="btn btn-primary btn-lg mb-2" (click)="takePhoto()">
        {{ "UTILS.TAKE_PHOTO" | translate }}
      </button>
      <button
        class="btn btn-primary btn-lg mb-2"
        *ngIf="photoTaken"
        (click)="savePhoto('web', observer)">
        {{ "UTILS.SAVE_PHOTO" | translate }}
      </button>
    </div>
  </div>
  <div class="text-danger justify-content-center pt-3" *ngIf="errorPhoto">
    <div>
      {{ "ERRORS.TAKE_PHOTO.DEVICE_NOT_FOUND.TITLE" | translate }}
    </div>
    <div>{{ errorMsg }}</div>
  </div>
</ng-template>

<ng-template #videoCvDropdown>
  <div
    class="video-cv-dropdown m-2"
    *ngIf="workerDocuments | async as workerDocuments">
    <div class="buttons d-flex">
      <button
        (click)="onShowCVVideo()"
        class="me-2 btn btn-green-outline"
        [disabled]="!hasVideoCV">
        <tui-svg src="tuiIconEyeOpen"></tui-svg>
      </button>
      <button
        (click)="onShowCVVideoModal()"
        class="me-2 btn btn-green-outline"
        [disabled]="!hasVideoCV">
        <tui-svg src="tuiIconDraft"></tui-svg>
      </button>
      <button
        (click)="onShowRecordCVVideoModal()"
        class="btn btn-green-outline"
        [disabled]="!hasVideoCV">
        <tui-svg src="tuiIconVideo"></tui-svg>
      </button>
    </div>
    <p *ngIf="hasVideoCV">{{ "PRIVATE.WORKER.USER_DETAIL.PERSONAL_DOCUMENT.STATUS.DOC_UPLOAD_SUCCESS_FINAL" | translate }}  </p>
  </div>
</ng-template>

<ng-template #showVideoTemplate let-data="data" let-observer>
  <div class="my-2" *ngIf="!data">
    <div>{{ "PRIVATE.WORKER.USER_DETAIL.NO_VIDEO_CV" | translate }}</div>
    <div class="buttons d-flex justify-content-center mt-2"><button
      (click)="onShowCVVideoModal(); observer.complete()"
      class="me-2 btn btn-green-outline"
      [disabled]="!hasVideoCV">
      <tui-svg src="tuiIconDraft"></tui-svg>
    </button>
    <button
      (click)="onShowRecordCVVideoModal(); observer.complete()"
      class="btn btn-green-outline"
      [disabled]="!hasVideoCV">
      <tui-svg src="tuiIconVideo"></tui-svg>
    </button></div>
  </div>
  <div class="mb-2" *ngIf="data">
    <video width="320" height="240" controls>
      <source [src]="data.url" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
  <div *ngIf="data && data.disabled">
    <div class="mb-1 red-text">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.ALREADY_KO"
      | translate
      }}
    </div>
    <div class="mb-1 red-text fw-bold">
      {{ data.disabledMessage }}
    </div>
  </div>
</ng-template>

<ng-template let-observer #modalAddDocument>
  <app-form [form]="formDocument"></app-form>
  <div class="d-flex justify-content-center pt-3 pb-mobile">
    <button
      (click)="
        formDocument.value.range === macroDocType.VIDEOCV
          ? uploadVideoCV(observer)
          : addDocument(observer)
      "
      [disabled]="!formDocument.valid"
      class="text-center btn btn-green">
      <tui-svg src="tuiIconPlus"></tui-svg>
      {{ "UTILS.EDIT" | translate }}
    </button>
  </div>
</ng-template>

<ng-template let-observer #modalRecord>
  <div class="justify-content-center pt-3 pb-mobile" *ngIf="!errorRecord">
    <div class="mb-1" *ngIf="!videoRecord">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_VIDEO_TEXT_1"
      | translate
      }}
    </div>
    <div class="mb-2" [ngStyle]="{ display: videoRecord ? 'none' : 'block' }">
      <video class="video" #video autoplay [muted]="'muted'"></video>
    </div>
    <div class="mb-1" *ngIf="videoRecord">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_VIDEO_TEXT_2"
      | translate
      }}
    </div>
    <div class="mb-2" [ngStyle]="{ display: !videoRecord ? 'none' : 'block' }">
      <video class="video" controls #recordedVideo></video>
    </div>
    <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
      <button
        class="btn btn-primary btn-lg me-2"
        *ngIf="!isRecording && !videoRecord"
        (click)="startRecording()">
        {{ "UTILS.START_RECORD" | translate }}
      </button>
      <button
        class="btn btn-warning btn-lg"
        *ngIf="isRecording && !videoRecord"
        (click)="stopRecording()">
        {{ "UTILS.STOP_RECORD" | translate }}
      </button>
      <button
        class="btn btn-primary btn-lg me-2 mb-2"
        *ngIf="!isRecording && videoRecord"
        (click)="resetRecord()">
        {{ "UTILS.RESET_RECORD" | translate }}
      </button>
      <button
        class="btn btn-primary btn-lg mb-2"
        *ngIf="!isRecording && videoRecord"
        (click)="saveRecording(observer)">
        {{ "UTILS.SAVE_RECORD" | translate }}
      </button>
    </div>
  </div>
  <div class="text-danger justify-content-center pt-3 pb-mobile" *ngIf="errorRecord">
    <div>
      {{ "ERRORS.RECORD_CV.DEVICE_NOT_FOUND.TITLE" | translate }}
    </div>
    <div>{{ errorMsg }}</div>
  </div>
</ng-template>
