<div *ngIf="searchForm">
  <div class="search-bar px-0 ngm">
    <!-- <app-toggle [isWorker]="userType == UserType.WORKER"></app-toggle> -->
    <div class="row">
      <!-- WHAT CONTROL -->
      <div class="col-12 col-lg-5 mb-2 mb-lg-0 ngm">
        <app-input-label
          [field]="searchForm.get('what')"
          [name]="searchForm.get('what').label"
          [filter]="false"
        ></app-input-label>
        <app-textbox-input
          [control]="searchForm.get('what') | castTextInput"
          [name]="searchForm.get('what').label"
          (click)="hidden = false"
        ></app-textbox-input>
      </div>

      <!-- WHERE CONTROL -->
      <div
        class="d-sm-inline col-12 col-md-5 col-lg-3 mb-2 mb-md-0 ngm where-input"
        [ngClass]="{ 'd-none': hidden }"
        [style.display]="hidden ? 'none' : 'inline'"
      >
        <app-input-label
          [field]="searchForm.get('where')"
          [name]="searchForm.get('where').label"
          [filter]="false"
        ></app-input-label>

        <app-select-virtual-input [control]="whereControl | castSelectInput">
        </app-select-virtual-input>

      </div>

      <!-- DISTANCE CONTROL -->
      <div
        class="d-sm-inline col-12 col-sm-6 col-md-4 col-lg-2 ngm distance-input"
        [ngClass]="{ 'd-none': hidden }"
        [style.display]="hidden ? 'none' : 'inline'"
      >
        <app-input-label
          [field]="searchForm.get('distance')"
          [name]="searchForm.get('distance').label"
          [filter]="false"
        ></app-input-label>
        <app-select-input
          [control]="searchForm.get('distance') | castSelectInput"
        ></app-select-input>
      </div>
      <!-- SEARCH BTN -->
      <div
        class="col-12 col-sm-3 col-lg-2 mt-3 mt-sm-auto d-grid d-sm-flex flex-sm-row mx-0 ms-sm-auto ngm"
      >
        <button
          class="btn btn-green search-btn ngm"
          type="submit"
          (click)="search()"
          [disabled]="
            !(
              whatControl.value ||
              (whereControl.value && distanceControl.value)
            )
          "
        >
          {{
            "LANDING_PAGE.COMPANY.SEARCH.BUTTONS.SEARCH" | translate | uppercase
          }}
          <tui-svg class="ms-auto ms-sm-1" src="tuiIconSearch"></tui-svg>
        </button>
      </div>
    </div>
  </div>
</div>
