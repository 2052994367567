import { catchError, map, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TuiDialogService } from '@taiga-ui/core';
import {
  ConfirmBeforeDeleteResponseModel,
  ConfirmBeforeDeleteTemplateComponent,
} from './confirm-before-delete-template/confirm-before-delete-template.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

export interface GeolocationResultModel {
  city: string;
  continent: string;
  continentCode: string;
  countryCode: string;
  countryName: string;
  latitude: number;
  locality: string;
  localityInfo: any;
  localityLanguageRequested: string;
  longitude: number;
  lookupSource: string;
  plusCode: string;
  postcode: string;
  principalSubdivision: string;
  principalSubdivisionCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class SharedInRootService {
  constructor(
    private dialogService: TuiDialogService,
    private _http: HttpClient
  ) {}

  /** returns true if 'yes', false if 'cancel'
   * and undefined if dialog closed with 'X' */
  confirmBeforeDelete(): Promise<boolean> {
    return this.dialogService
      .open(new PolymorpheusComponent(ConfirmBeforeDeleteTemplateComponent), {
        dismissible: false,
        size: 's',
        data: {},
      })
      .pipe(
        map((outcome: ConfirmBeforeDeleteResponseModel) => {
          return outcome?.didConfirm;
        })
      )
      .toPromise();
  }

  roundNumber(valueToRound: number, step: number): number {
    return Math.round(valueToRound / step) * step;
  }

  getLocationByCoordinates(
    latitude: string | number,
    longitude: string | number
  ): Observable<GeolocationResultModel> {
    var url =
      'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=' +
      latitude +
      '&longitude=' +
      longitude +
      '&localityLanguage=it';
    return this._http.request('GET', url).pipe(
      timeout(30000),
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
      map((data: GeolocationResultModel) => {
        return data;
      })
    );
  }
}
