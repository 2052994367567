<h5 class="fw-bold">
  {{
    "PRIVATE.COMPANY.OFFERT_SIMULATION.TIME_SHEET_LIST.TITLE_SHIFTS" | translate
  }}
  {{ index }}
</h5>
<div>
  <div class="row">
    <div class="col-6">
      <p class="text-color-dark mb-0">
        {{
          "PRIVATE.COMPANY.OFFERT_SIMULATION.TIME_SHEET_LIST.START" | translate
        }}
      </p>
      <p class="text-color-dark fw-bold">
        {{ timeSheet.from1 }}
      </p>
    </div>
    <div class="col-6">
      <p class="text-color-dark mb-0">
        {{
          "PRIVATE.COMPANY.OFFERT_SIMULATION.TIME_SHEET_LIST.END" | translate
        }}
      </p>

      <p class="text-color-dark fw-bold">
        {{ timeSheet.to1 }}
      </p>
    </div>
  </div>
</div>
<div class="pt-2" *ngIf="timeSheet.from2 && timeSheet.to2">
  <div class="row">
    <div class="col-6">
      <p class="text-color-dark mb-0">
        {{
          "PRIVATE.COMPANY.OFFERT_SIMULATION.TIME_SHEET_LIST.START" | translate
        }}
      </p>
      <p class="text-color-dark fw-bold">
        {{ timeSheet.from2 }}
      </p>
    </div>
    <div class="col-6">
      <p class="text-color-dark mb-0">
        {{
          "PRIVATE.COMPANY.OFFERT_SIMULATION.TIME_SHEET_LIST.END" | translate
        }}
      </p>

      <p class="text-color-dark fw-bold">
        {{ timeSheet.to2 }}
      </p>
    </div>
  </div>
</div>
<div class="pb-2 border-bottom-grey mb-2">
  <div class="row">
    <div class="col-12">
      <span>
        {{
          "PRIVATE.COMPANY.OFFERT_SIMULATION.TIME_SHEET_LIST.SHIFTS_WORKING"
            | translate
        }}
      </span>
      <ng-container
        *ngFor="let day of timeSheet | getDays: mapWeek:false; let last = last"
      >
        <span class="fw-bold"> {{ day }} </span>
        <span *ngIf="!last" class="fw-bold">-</span>
      </ng-container>
    </div>
  </div>
</div>
