<div class="how-it-works text-center">
  <div class="row text-container">
    <div class="col-12 my-4 ngm">
      <h4 class="title">
        {{ "LANDING_PAGE." + userType + ".HOW.TITLE" | translate | uppercase }}
      </h4>
    </div>
  </div>

  <div class="d-sm-none d-flex">
    <swiper #swiper [slidesPerView]="2" [spaceBetween]="16" [scrollbar]="scrollbarOpt">
      <ng-template
        swiperSlide
        *ngFor="let step of [].constructor(4); index as i"
      >
        <div class="step-container" [id]="'step-container-' + (i + 1)">
          <!-- STEP NUMBER -->
          <div class="step-number-container">
            <span class="step-number" [id]="'step-number-' + (i + 1)">
              {{ i + 1 }}
            </span>
          </div>

          <!-- STEP-TEXT -->
          <div class="step-text-container">
            <div
              class="title"
              [innerHTML]="
                'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TITLE'
                  | translate
                  | uppercase
              "
            ></div>
            <div
              class="text mt-2 ngm"
              [innerHTML]="
                'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TEXT'
                  | translate
              "
            ></div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="d-none d-xl-none d-sm-flex">
    <swiper [slidesPerView]="3" [spaceBetween]="24" [scrollbar]="true">
      <ng-template
        swiperSlide
        *ngFor="let step of [].constructor(4); index as i"
      >
        <div class="step-container" [id]="'step-container-' + (i + 1)">
          <!-- STEP NUMBER -->
          <div class="step-number-container">
            <span class="step-number" [id]="'step-number-' + (i + 1)">
              {{ i + 1 }}
            </span>
          </div>

          <!-- STEP-TEXT -->
          <div class="step-text-container">
            <div
              class="title"
              [innerHTML]="
                'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TITLE'
                  | translate
                  | uppercase
              "
            ></div>
            <div
              class="text mt-2 ngm"
              [innerHTML]="
                'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TEXT'
                  | translate
              "
            ></div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="d-none d-xl-flex d-xxl-none">
    <ng-container *ngFor="let step of [].constructor(4); index as i">
      <div class="step-container" [id]="'step-container-' + (i + 1)">
        <!-- STEP NUMBER -->
        <div class="step-number-container">
          <span class="step-number" [id]="'step-number-' + (i + 1)">
            {{ i + 1 }}
          </span>
        </div>

        <!-- STEP-TEXT -->
        <div class="step-text-container">
          <div
            class="title"
            [innerHTML]="
              'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TITLE'
                | translate
                | uppercase
            "
          ></div>
          <div
            class="text mt-2 ngm"
            [innerHTML]="
              'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TEXT'
                | translate
            "
          ></div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="d-none d-xxl-flex">
    <div class="mx-auto d-flex flex-row">
      <ng-container *ngFor="let step of [].constructor(4); index as i">
        <div class="step-container" [id]="'step-container-' + (i + 1)">
          <!-- STEP NUMBER -->
          <div class="step-number-container">
            <span class="step-number" [id]="'step-number-' + (i + 1)">
              {{ i + 1 }}
            </span>
          </div>

          <!-- STEP-TEXT -->
          <div class="step-text-container">
            <div
              class="title"
              [innerHTML]="
                'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TITLE'
                  | translate
                  | uppercase
              "
            ></div>
            <div
              class="text mt-2 ngm"
              [innerHTML]="
                'LANDING_PAGE.' + userType + '.HOW.STEP_' + (i + 1) + '.TEXT'
                  | translate
              "
            ></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
