import { NbRoleProvider } from '@nebular/security';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserRoleInfo, UserService } from './services/user.service';
import { RoleType } from './interfaces/role.model';

@Injectable()
export class NbSimpleRoleProvider extends NbRoleProvider {
  constructor(private userService: UserService) {
    super();
  }

  getRole(): Observable<string> {
    return this.userService.getUserRoleInfo().pipe(
      switchMap((user: UserRoleInfo) => {
        if (user.role === RoleType.WORKER){
          return this.userService.getUserWorkerStatus().pipe(
          map(({ status }) => {
              return status;
            })
          )
        }else { 
          return this.userService.getUserCompanyStatus().pipe(
            map(({ status }) => {
              return status;
            })
          )
        }        
      })
    );
  }
}
