<div class="col-12 box-info">
  <div class="row border-bottom fs-6">
    <h5 class="fw-bold">
      {{ "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.SUB_TITLE" | translate }}
    </h5>
  </div>
  <ng-container *ngFor="let timeSheet of timeSheets">
    <div class="row border-bottom">
      <div class="col row border-end">
        <div class="col-6 pt-4">
          <p class="text-color-dark mb-1">
            {{
              "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.HOUR_IN" | translate
            }}
          </p>
          <p class="text-color-dark fw-bold">
            {{ timeSheet.from1 }}
          </p>
        </div>
        <div class="col-6 pt-4">
          <p class="text-color-dark mb-0">
            {{
              "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.HOUR_OUT" | translate
            }}
          </p>

          <p class="text-color-dark fw-bold">
            {{ timeSheet.to1 }}
          </p>
        </div>
      </div>
      <div class="col row">
        <div class="col-6 pt-4 ps-4">
          <p class="text-color-dark mb-0">
            {{
              "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.HOUR_IN" | translate
            }}
          </p>

          <p class="text-color-dark fw-bold">
            {{ timeSheet.from2 }}
          </p>
        </div>
        <div class="col-6 pt-4 ps-4">
          <p class="text-color-dark mb-0">
            {{
              "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.HOUR_OUT" | translate
            }}
          </p>

          <p class="text-color-dark fw-bold">
            {{ timeSheet.to2 }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 py-3 border-bottom-grey mb-4">
      <div class="row">
        <div class="col-12">
          <span>
            {{
              "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.DAYS_WORKING"
                | translate
            }}
          </span>
          <ng-container
            *ngFor="
              let day of timeSheet | getDays: mapWeek:false;
              let last = last
            "
          >
            <span class="fw-bold"> {{ day }} </span>
            <span *ngIf="!last" class="fw-bold">-</span>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-12 pt-2">
    <span>
      {{ "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.REST_DAYS" | translate }}
    </span>
    <ng-container
      *ngFor="let restDay of timeSheets | getRestDays: mapWeek; let last = last"
    >
      <span class="fw-bold"> {{ restDay }} </span>
      <span *ngIf="!last" class="fw-bold">-</span>
    </ng-container>
  </div>
  <div class="col-12 my-4">
    <span>
      {{ "PRIVATE.WORKER.MESSAGES.DETAIL.DIALOG_TIME.INFO" | translate }}
    </span>
  </div>
</div>
