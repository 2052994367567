import { Component, Input, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { Application } from 'src/app/pages/private/pages/worker/pages/announcements/interfaces/application';
import { Announcement } from 'src/app/shared/interfaces/search-announcements-list';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
})
export class JobCardComponent implements OnInit {
  @Input() isPremium: boolean = false;
  @Input() userType?: UserType;
  @Input() job!: Announcement | Application;
  @Input() favourite: boolean = false;

  get UserType() {
    return UserType;
  }

  constructor() { }

  ngOnInit(): void {
    // console.log('JobCardComponent', this.job);
    if (!this.job.profileImage) this.job.profileImage = environment.companyImageURL;
  }

  getDate(): string {
    // if (!date) return '---';
    if (this.job.created) {
      return new Date(this.job.created).toLocaleString('it', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }
    if ((this.job as Announcement).date) {
      return new Date((this.job as Announcement).date).toLocaleString('it', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }
    return "---";
  }

  getCity(): string {
    if ((this.job as Announcement).city) return (this.job as Announcement).city;
    if ((this.job as Application).city) return (this.job as Application).city;
  }
}
