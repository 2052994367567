import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stared-card',
  templateUrl: './stared-card.component.html',
  styleUrls: ['./stared-card.component.scss'],
})
export class StaredCardComponent implements OnInit {
  @Input() light: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
