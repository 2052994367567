<div class="how-it-works text-center">
  <div class="text-container">
    <h4 class="title">
      {{ "WHO.HOW.TITLE" | translate | uppercase }}
    </h4>
  </div>

  <div class="d-none d-xl-inline-flex how-it-works-steps">
    <ng-container *ngFor="let step of [].constructor(3); index as i">
      <div class="step-container" [id]="'step-container-' + (i + 1)">
        <!-- STEP NUMBER -->
        <div class="step-number-container">
          <span class="step-number" [id]="'step-number-' + (i + 1)">
            {{ i + 1 }}
          </span>
        </div>

        <!-- STEP-TEXT -->
        <div class="step-text-container">
          <!-- TITLE -->
          <div
            class="title"
            [innerHTML]="
              'WHO.HOW.STEP_' + (i + 1) + '.TITLE' | translate | uppercase
            "
          ></div>
          <!-- SUB-TITLE -->
          <div
            class="sub-title"
            [innerHTML]="
              'WHO.HOW.STEP_' + (i + 1) + '.SUB_TITLE' | translate | uppercase
            "
          ></div>
          <!-- TEXT -->
          <div
            class="text"
            [innerHTML]="'WHO.HOW.STEP_' + (i + 1) + '.TEXT_1' | translate"
          ></div>
          <div
            class="text"
            [innerHTML]="'WHO.HOW.STEP_' + (i + 1) + '.TEXT_2' | translate"
          ></div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="d-none d-md-inline-flex d-xl-none how-it-works-steps">
    <swiper [slidesPerView]="2" [spaceBetween]="16">
      <ng-template
        swiperSlide
        *ngFor="let step of [].constructor(3); index as i"
      >
        <div class="step-container" [id]="'step-container-' + (i + 1)">
          <!-- STEP NUMBER -->
          <div class="step-number-container">
            <span class="step-number" [id]="'step-number-' + (i + 1)">
              {{ i + 1 }}
            </span>
          </div>

          <!-- STEP-TEXT -->
          <div class="step-text-container">
            <!-- TITLE -->
            <div
              class="title"
              [innerHTML]="
                'WHO.HOW.STEP_' + (i + 1) + '.TITLE' | translate | uppercase
              "
            ></div>
            <!-- SUB-TITLE -->
            <div
              class="sub-title"
              [innerHTML]="
                'WHO.HOW.STEP_' + (i + 1) + '.SUB_TITLE' | translate | uppercase
              "
            ></div>
            <!-- TEXT -->
            <div
              class="text"
              [innerHTML]="'WHO.HOW.STEP_' + (i + 1) + '.TEXT_1' | translate"
            ></div>
            <div
              class="text"
              [innerHTML]="'WHO.HOW.STEP_' + (i + 1) + '.TEXT_2' | translate"
            ></div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="d-inline-flex d-md-none how-it-works-steps">
    <swiper [slidesPerView]="2" [spaceBetween]="-16">
      <ng-template
        swiperSlide
        *ngFor="let step of [].constructor(3); index as i"
      >
        <div class="step-container" [id]="'step-container-' + (i + 1)">
          <!-- STEP NUMBER -->
          <div class="step-number-container">
            <span class="step-number" [id]="'step-number-' + (i + 1)">
              {{ i + 1 }}
            </span>
          </div>

          <!-- STEP-TEXT -->
          <div class="step-text-container">
            <!-- TITLE -->
            <div
              class="title"
              [innerHTML]="
                'WHO.HOW.STEP_' + (i + 1) + '.TITLE' | translate | uppercase
              "
            ></div>
            <!-- SUB-TITLE -->
            <div
              class="sub-title"
              [innerHTML]="
                'WHO.HOW.STEP_' + (i + 1) + '.SUB_TITLE' | translate | uppercase
              "
            ></div>
            <!-- TEXT -->
            <div
              class="text"
              [innerHTML]="'WHO.HOW.STEP_' + (i + 1) + '.TEXT_1' | translate"
            ></div>
            <div
              class="text"
              [innerHTML]="'WHO.HOW.STEP_' + (i + 1) + '.TEXT_2' | translate"
            ></div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
