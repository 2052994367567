<form [formGroup]="form" (submit)="save.emit(form.value)">
  <app-form-inputs
    [filter]="form.filter"
    [controls]="form.controls | castBaseInputArray"
    [form]="form"
  ></app-form-inputs>

  <button
    *ngIf="showFooter()"
    class="btn btn-green float-right"
    (click)="save.emit(form.value)"
    [disabled]="!form.valid"
  >
    <div btnContent>{{ form?.footer.submit.text | translate }}</div>
  </button>

  <button
    *ngIf="showFooter() && showClearButton()"
    class="btn btn-green-outline float-right mr-4"
    (click)="resetForm()"
    [disabled]="!form.valid"
  >
    <span>{{ form?.footer.clear.text | translate }}</span>
  </button>

  <input #htmlInputSubmit type="submit" style="display: none" />
</form>
