import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-complete-worker-dialog',
  templateUrl: './complete-worker-dialog.component.html',
  styleUrls: ['./complete-worker-dialog.component.scss'],
})
export class CompleteWorkerDialogComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext
  ) {}

  ngOnInit(): void {}

  toComplete() {
    // add queryParam to routing back from complete-profile #MULTIPOSTING4
    const announcementId = localStorage.getItem('announcementId');
    const queryParams = announcementId ? { announcementId } : {};
    this.router.navigate(
      ['private', 'candidato', 'utente', 'user-profile'],
      { queryParams }
    );
    this.context.completeWith();
  }
}
