<ng-container *ngIf="suggested$ | async">
  <div
    class="profile-menu my-4"
    [ngClass]="{ sticky: isSticky }"
    [style]="isSticky ? 'top:' + stickyTopOffset + 'px;' : ''"
  >
    <div
      class="menu-item"
      [ngClass]="getItemClass(item)"
      *ngFor="let item of links"
      (click)="navigate(item)"
    >
      {{ item.title | translate }}
    </div>
  </div>
</ng-container>
