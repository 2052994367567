import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class RedirectToPrivateGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      map((authenticated: boolean) => {
        if (authenticated) {
          this.authService.getToken().subscribe((token) => {
            if (token.getPayload().roles.includes('ROLE_WORKER')) {
              this.router.navigateByUrl('private/candidato' + state.url);
            } else if (token.getPayload().roles.includes('ROLE_DELEGATE')) {
              this.router.navigateByUrl('/private/delegato/home');
            } else {
              this.router.navigateByUrl('private/azienda' + state.url);
            }
            return false;
          });
        }
        return true;
      }),
      take(1)
    );
  }
}
