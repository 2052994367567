<div class="welcome-section py-5" [ngClass]="type">
  <div class="mb-5">
    <div class="line">
      <h1>{{ "HOME.WELCOME.TITLE_1" | translate }}</h1>
    </div>
    <div class="line">
      <h1 class="mx-3">
        {{ "HOME.WELCOME.TITLE_2_" + (type | uppercase) | translate }}
      </h1>
      <div id="fancy-word-container" class="fancy-word-container">
        <div class="fancy-word-bg"></div>
        <h1 class="fancy-word">
          {{ "HOME.WELCOME.TITLE_3_" + (type | uppercase) | translate }}
        </h1>
      </div>
    </div>
  </div>
  <h5 class="green" style="white-space: pre-line">
    {{ "HOME.WELCOME.SUBTITLE_" + (type | uppercase) | translate }}
  </h5>
</div>

<!--
  Trying to animate the fancy word
https://blog.logrocket.com/creative-text-styling-with-the-css-mix-blend-mode-property/
https://codepen.io/robinrendle/pen/wKqmbW
https://www.w3.org/Style/Examples/007/text-shadow.en.html
https://jsfiddle.net/75Umu/3/
-->
