<div
  class="notifications-carousel-container my-5"
  [id]="'notifications-carousel-' + title"
  *ngIf="notifications && notifications.length > 0"
>
  <h6 *ngIf="title">{{ title | translate | uppercase }}</h6>
  <div
    tuiCarouselButtons
    class="wrapper d-flex flex-nowrap align-items-center justify-content-stretch"
  >
    <button
      *ngIf="notifications && notifications.length > visibleItems"
      tuiIconButton
      icon="tuiIconChevronLeftLarge"
      title="Previous"
      type="button"
      class="btn btn-green-outline"
      (click)="carouselMove('previous')"
    ></button>
    <tui-carousel
      #carousel
      class="carousel"
      [ngClass]="
        notifications && notifications.length <= visibleItems
          ? 'full-width'
          : ''
      "
      [draggable]="true"
      [itemsCount]="visibleItems"
      [(index)]="itemIndex"
    >
      <ng-container *ngFor="let item of notifications; let i = index">
        <app-notification-carousel-card
          *tuiItem
          draggable="false"
          class="item"
          [notification]="item"
          [userType]="userType"
          [class.item_active]="i === i + 1"
        ></app-notification-carousel-card>
      </ng-container>
    </tui-carousel>
    <button
      *ngIf="notifications && notifications.length > visibleItems"
      #tuiIconButtonNext
      tuiIconButton
      type="button"
      icon="tuiIconChevronRightLarge"
      title="Next"
      class="btn btn-green-outline"
      (click)="carouselMove('next')"
    ></button>
  </div>
</div>
