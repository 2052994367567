import { Pipe, PipeTransform } from '@angular/core';
import { ProgressPhazesState } from 'src/app/pages/private/pages/company/pages/documents/pages/offert/interfaces';

const mapColorOffertState = new Map()
  .set(ProgressPhazesState.TO_CREATE, '#f79041')
  .set(ProgressPhazesState.CREATED, '#f7b141')
  .set(ProgressPhazesState.COMPLETED, '#00AB8E')
  .set(ProgressPhazesState.ACCEPTED, '#10B14B')
  .set(ProgressPhazesState.FAILED, '#D40D0C');

@Pipe({ name: 'colorProgress' })
export class ColorProgressPipe implements PipeTransform {
  transform(offertPhase: string): string {
    return mapColorOffertState.get(offertPhase);
  }
}
