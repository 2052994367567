import { Component, Input } from '@angular/core';
import { TimeShiftSheet } from 'src/app/pages/private/pages/worker/pages/messages/interfaces/proposal-detail.model';
import { weekMap } from 'src/app/pages/private/pages/worker/pages/messages/interfaces/time-sheet-map';

@Component({
  selector: 'app-standard-time-dialog',
  templateUrl: './standard-time-dialog.component.html',
  styleUrls: ['./standard-time-dialog.component.scss'],
})
export class StandardTimeDialogComponent {
  @Input() timeSheets: TimeShiftSheet[];

  get mapWeek() {
    return weekMap;
  }
  constructor() {}
}
