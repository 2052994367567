import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  TuiContextWithImplicit,
  TuiIdentityMatcher,
  TuiStringHandler,
  tuiPure,
} from '@taiga-ui/cdk';
import { TuiComboBoxComponent } from '@taiga-ui/kit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectInput, SelectOption } from '../../../models/inputs/select-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-select-virtual-input',
  templateUrl: './select-virtual-input.component.html',
  styleUrls: ['./select-virtual-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectVirtualInputComponent extends BaseInputComponent {
  @Input('control') set _control(c: SelectInput) {
    this.control = c;
    this.identityMatcher = c.identityMatcher as TuiIdentityMatcher<any>;
  }
  @Input() name: string;
  initialItems: Observable<SelectOption<string, string>[]>;
  control: SelectInput;
  identityMatcher: TuiIdentityMatcher<any>;
  @ViewChild(TuiComboBoxComponent)
  comboBoxRef: TuiComboBoxComponent<any>;

  @tuiPure
  stringify(
    items: ReadonlyArray<SelectOption>,
    control: SelectInput
  ): TuiStringHandler<TuiContextWithImplicit<string | SelectOption>> {
    const map = new Map(
      items.map(({ cod, des }) => [cod, des] as [string, string])
    );
    if (control.bindValue) {
      return ({ $implicit }: TuiContextWithImplicit<string>) =>
        map.get($implicit) || '';
    }
    if (control.bindLabel) {
      return ({ $implicit }: TuiContextWithImplicit<SelectOption>) =>
        $implicit.des || '';
    }
    return ({ $implicit }: TuiContextWithImplicit<SelectOption>) => {
      return map.get($implicit.cod) || '';
    };
  }

  @tuiPure
  stringifyValue(
    items: ReadonlyArray<SelectOption>,
    control: SelectInput
  ): TuiStringHandler<string> {
    if (items) {
      const map = new Map(
        items.map(({ cod, des }) => [cod, des] as [string, string])
      );
      if (control.bindValue) {
        return ($implicit: string) => map.get($implicit) || '';
      }

      return ($implicit: any) => $implicit.des || '';
    }
    return () => '';
  }

  @tuiPure
  stringifyLabel(items: ReadonlyArray<SelectOption>, control: SelectInput) {
    if (items) {
      const map = new Map(
        items.map(({ cod, des }) => [cod, des] as [string, string])
      );

      if (control.bindValue) {
        return ($implicit) => {
          return map.get($implicit) || '';
        };
      }
    }

    return ($implicit) => $implicit.des || '';
  }

  @tuiPure
  getValue(
    item: Readonly<SelectOption>,
    control: SelectInput
  ): string | SelectOption {
    if (control.bindValue) {
      return item.cod;
    }
    return item;
  }

  getByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value === searchValue) return key;
    }
  }

  async onSearchChange($event) {
    if (!this.initialItems) {
      this.initialItems = this.control.options;
    }
    this.control.options = this.initialItems.pipe(
      map((options) => {
        if ($event && !this.control.value) {
          return options.filter((option) =>
            option.des.toUpperCase().includes($event.toUpperCase())
          );
        } else {
          return options;
        }
      })
    );
  }

  readonly matcher = (control: SelectInput) => {
    if (!control.value) {
      if (control.bindValue) {
        return ($implicit: { cod: string; des: string }, search: string) => {
          return new RegExp(`^${search}`, 'i').test($implicit.des);
        };
      }
      return ($implicit: { cod: string; des: string }, search: string) => {
        return new RegExp(`^${search}`, 'i').test($implicit.des);
      };
    } else {
      return ($implicit: { cod: string; des: string }, search: string) =>
        new RegExp(`^${search}`, 'i').test($implicit.des);
    }
  };
}
