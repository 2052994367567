import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PixelWorkerLandingService {
  constructor() {}

  load() {
    // Pixel Refine B2C
    (function (n) {
      function r() {
        var n = t.createElement('iframe');
        return ((n.height = '0'),
        (n.width = '0'),
        (n.style.display = 'none'),
        (n.style.visibility = 'hidden'),
        n) as any;
      }
      function u(n) {
        t.body.appendChild(n);
      }
      var t: any = document,
        i = 'https://vf.r3f.technology/vf/sync/tags/';

      if (typeof fetch == 'function')
        fetch(i + 'js/' + n)
          .then(function (n) {
            return n.json();
          })
          .then(function (n) {
            var t = r();
            t.srcdoc = n.Body;
            u(t);
          });
      else {
        var t = r();
        (t as any).src = i + n;
        u(t);
      }
    })(2002);
  }
}
