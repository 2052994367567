<div class="row gap-2">
  <ng-container *ngFor="let field of $any(controls) | keyvalue: this.keyvaluePipeCompareFn">
    <div
      *ngIf="!field.value.hidden"
      class="align-self-top"
      [ngClass]="{
        'align-self-center':
          field.value.inputType === 'checkbox' && (field | castCheckboxInput).centered === true
      }"
      [class]="
        field.value.size?.toString() ||
        (field.value.inputType !== 'nop' ? 'col-12 p-0' : 'p-0')
      "
    >
      <app-form-input
        [field]="field.value"
        [name]="field.key"
        [form]="form"
        [filter]="filter"
      ></app-form-input>
    </div>
  </ng-container>
</div>
