import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../auth/services/user.service';
import { role2loginRoute } from '../auth/interfaces/role.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private i18n: TranslateService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private loading: NgxSpinnerService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error.keyErrorMessage == 'WHITENET_EXCEPTION') {
          this.toastrService.error(
            this.i18n.instant('ERRORS.GENERAL_ERROR.WHITENET.MESSAGE', { wnErrorDescription: error.error?.response.errorDescription }),
            this.i18n.instant('ERRORS.GENERAL_ERROR.TITLE'),
            {
              enableHtml: true,
            }
          );
          return throwError(error);
        }
        if (
          error.status === 400 &&
          error.error?.keyErrorMessage &&
          error.error.keyErrorMessage == 'ACTIVE_CONTRACTS'
        ) {
          this.toastrService.error(
            this.i18n.instant(
              'PRIVATE.COMPANY.USER.USER_PROFILE.INFO.TOAST_DELETE_ERROR.MESSAGE'
            ),
            this.i18n.instant(
              'PRIVATE.COMPANY.USER.USER_PROFILE.INFO.TOAST_DELETE_ERROR.TITLE'
            ),
            {
              tapToDismiss: true,
              closeButton: true,
              disableTimeOut: true,
              enableHtml: true,
            }
          );
          return throwError(error);
        }
        if (
          error.status === 400 &&
          error.error?.keyErrorMessage &&
          error.error.keyErrorMessage == 'MISSING_CCNL'
        ) {
          this.toastrService.error(
            error.error.response.errorDescription,
            'ERRORE',
            {
              tapToDismiss: true,
              closeButton: true,
              timeOut: 10000,
              // disableTimeOut: true,
              enableHtml: true,
            }
          );
          return throwError(error);
        }
        if (error.status === 200 && error.url) {
          window.location.href = error.url;
          return throwError([]);
        }
        if (error.status === 401) {
          this.toastrService.error('Credenziali non valide', 'Errore');
          this.authService.logout().subscribe(() => {
            if (!this.router.url.includes('auth/login')) {
              this.router.navigate(['auth', 'login'], {
                queryParams: {
                  userType: role2loginRoute(
                    this.userService.userRoleInfo?.role
                  ),
                },
              });
            }
          });
          return throwError(error);
        }
        if (error.status === 403) {
          this.toastrService.error(
            'Non hai i permessi per effettuare questa azione',
            'Errore'
          );
          return throwError(error);
        }
        if (request.url.includes('refresh-token')) {
          this.loading.hide();
          this.authService.logout().subscribe(() =>
            this.router.navigate(['auth', 'login'], {
              queryParams: {
                userType: role2loginRoute(this.userService.userRoleInfo?.role),
              },
            })
          );
          return throwError(error);
        }
        if (
          error.error?.keyErrorMessage &&
          !SILENCED_ERRORS.includes(error.error?.keyErrorMessage)
        ) {
          this.toastrService.error(
            this.i18n.instant(`ERRORS.${error.error.keyErrorMessage}.MESSAGE`),
            this.i18n.instant(`ERRORS.${error.error.keyErrorMessage}.TITLE`)
          );
          return throwError(error);
        }
        if (
          error.error?.keyErrorMessage &&
          SILENCED_ERRORS.includes(error.error?.keyErrorMessage)
        ) {
          return throwError(error);
        }
        if (Array.isArray(error.error)) {
          const ges = error.error
            .filter((e) => e.type !== 'FIELD_ERROR')
            .map((e) => [e.reason, `Errore`]);
          const fes = error.error
            .filter((e) => e.type === 'FIELD_ERROR')
            .map((e) => [e.reason, `Campo non valido`]);
          ges
            .concat(fes)
            .forEach(([reason, title]) =>
              this.toastrService.error(reason, title)
            );
          return throwError(error);
        }
        this.toastrService.error(
          this.i18n.instant('ERRORS.GENERAL_ERROR.MESSAGE'),
          this.i18n.instant('ERRORS.GENERAL_ERROR.TITLE')
        );
        return throwError(error);
      })
    );
  }
}

const SILENCED_ERRORS = [
  'ACTIVE_CONTRACTS',
  'USER_NOT_REGISTERED',
  'MISSING_CV',
  'MISSING_PROFILE_PHOTO',
  'MISSING_DELEGATE_EXCEPTION',
];
