import { Injectable } from '@angular/core';
import { ApiClientService as ApiClient} from 'src/app/api-client.service';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/auth/services/user.service';
import { NotificationsList } from '../interfaces/notifications-list';
import { Notification } from 'src/app/shared/notifications/interfaces/notification';
import { PaggingRequestParams } from 'src/app/core/interfaces/pagging-request-params';
import { NotificationState } from '../enum/notification-state.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  oldNotifications: Notification[] = [];
  newNotifications: Notification[] = [];

  constructor(private apiClient: ApiClient, private userService: UserService) {}

  getNotifications(params: PaggingRequestParams, status?: NotificationState) {
    return this.apiClient.request<NotificationsList>('getNotifications', null, status ? { ...params, status } : params);
  }
  getNotificationsNumber() {
    return this.apiClient.request('getNotificationsNumber').pipe(
      map((value: number) => {
        this.userService.setNotificationsNumber(value);
        return value;
      })
    );
  }

  updateNotificationStatus(uuid: string) {
    return this.apiClient.request(
      'updateNotificationStatus',
      {},
      {
        uuid,
      }
    );
  }
}
