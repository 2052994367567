import { EventEmitter, Pipe, PipeTransform, TemplateRef } from '@angular/core';
import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { merge, Observable, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BootstrapSize } from '../bootstrap-size';
import { InternalStatusMessage, ValidationStatus } from '../validations';
import { InputStatus } from '../validations/input-status';

@Pipe({ name: 'castFormControl' })
export class CastFormControlPipe implements PipeTransform {
  transform<S>(value: S): FormControl {
      return value as unknown as FormControl;
  }
}

@Pipe({ name: 'castBaseInput' })
export class CastBaseInputPipe implements PipeTransform {
  transform<S>(value: S): BaseInput {
      return value as unknown as BaseInput;
  }
}

declare type NbComponentStatus =
  | 'basic'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'control';

export type ValidatorOrOptions =
  | ValidatorFn
  | ValidatorFn[]
  | AbstractControlOptions
  | null;

export interface BaseInputConstructor<T = string> {
  value?: T;
  label?: string;
  size?: string | BootstrapSize;
  placeholder?: string;
  index?: number;
  clearable?: boolean;
  hidden?: boolean;
  required?: boolean;
  readonly?: boolean;
  pattern?: string | RegExp;
  valueChange?: (value: T) => void;
  validationStatus?: ValidationStatus[];
  validatorOrOpts?: ValidatorOrOptions;
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null;
  formGroupClass?: string[];
  tooltipActive?: boolean;
  tooltipLabel?: string;
  infoDialogActive?: boolean;
  infoDialogTemplate?: TemplateRef<any>;
}

export const TOUCHED = 'TOUCHED';
export const UNTOUCHED = 'UNTOUCHED';
export const DIRTY = 'DIRTY';
export const PRISTINE = 'PRISTINE';

export abstract class BaseInput<T = string> extends FormControl {
  readonly inputType: string;
  readonly value: T;

  readonly size?: string | BootstrapSize;
  readonly label?: string;
  readonly placeholder?: string;
  readonly index?: number;
  readonly clearable?: boolean;
  _hidden?: boolean;
  _required?: boolean;
  _readonly?: boolean = false;
  readonly pattern?: string | RegExp;
  tooltipActive?: boolean = false;
  readonly tooltipLabel?: string;
  infoDialogActive?: boolean = false;
  infoDialogTemplate?: TemplateRef<any>;

  /**
   * The input status of the control. There are four possible
   * status values:
   *
   * * **DIRTY**: This control has been modified.
   * * **PRISTINE**: This control has not been modified yet.
   * * **TOUCHED**: This control has been touched.
   * * **UNTOUCHED**: This control has not been touched yet..
   *
   * These status values are mutually exclusive, so a control cannot be
   * both dirty AND pristine or touched AND untuched.
   */
  readonly inputStatus: string;
  readonly inputStatusChanges: Observable<any>;

  readonly valueChange?: (value: T) => void;

  readonly validationStatus?: ValidationStatus[];

  readonly formGroupClass?: string[];
  readonly colorStatus: NbComponentStatus;
  readonly statusMessage: InternalStatusMessage;

  private _subscriptions: Subscription[] = [];

  constructor(options: BaseInputConstructor<T>) {
    super(options.value, options.validatorOrOpts, options.asyncValidator);

    this.label = options.label;
    this.formGroupClass = options.formGroupClass || [];

    this._initSize(options.size);
    this._initInputStatusObservable();
    this._initStatusChanges();

    if (options.placeholder) {
      this.placeholder = options.placeholder;
    }

    if (options.index) {
      this.index = options.index;
    }

    if (options.clearable) {
      this.clearable = options.clearable;
    }

    if (options.hidden) {
        this.hidden = options.hidden;
    }

    if (options.required) {
      this.required = options.required;
      const validator = [];
      if (this.validator) {
        validator.push(this.validator);
      }
      validator.push(Validators.required);
      this.setValidators(validator);
    }

    if (options.readonly) {
      this.readonly = options.readonly;
      this.disable();
    }

    if (options.pattern) {
      this.pattern = options.pattern;
      const validator = [];
      if (this.validator) {
        validator.push(this.validator);
      }
      validator.push(Validators.pattern(options.pattern));
      this.setValidators(validator);
    }

    if (options.valueChange) {
      this.valueChange = options.valueChange;
      this._initValueChanges();
    }

    if (options.validationStatus) {
      this.validationStatus = options.validationStatus;
    }

    this.colorStatus = this._getColorStatus();
    this.statusMessage = this._getStatusMessage();

    if (options.tooltipLabel) {
      this.tooltipLabel = options.tooltipLabel;
    }

    if (options.tooltipActive) {
      this.tooltipActive = options.tooltipActive;
    }

    if (options.infoDialogActive) {
      this.infoDialogActive = options.infoDialogActive;
    }

    if (options.infoDialogTemplate) {
      this.infoDialogTemplate = options.infoDialogTemplate;
    }
  }

  /** @internal */
  private _initValueChanges(): void {
    // inizializza evento value changes
    this._subscriptions.push(this.valueChanges.subscribe(this.valueChange));
  }

  /** @internal */
  private _initStatusChanges(): void {
    this._subscriptions.push(
      merge(this.inputStatusChanges, this.statusChanges, this.valueChanges)
        .pipe(
          debounceTime(10),
          tap(() => {
            (
              this as {
                colorStatus: any;
              }
            ).colorStatus = this._getColorStatus();
            (
              this as {
                statusMessage: any;
              }
            ).statusMessage = this._getStatusMessage();
          })
        )
        .subscribe()
    );
  }

  /** @internal */
  private _initSize(size: string | BootstrapSize): void {
    if (typeof size === 'string') {
      (this as { size: any }).size = BootstrapSize.formPipe(size);
    } else if (size instanceof BootstrapSize) {
      (this as { size: any }).size = size;
    } else {
      (this as { size: any }).size = new BootstrapSize();
    }
  }

  /** @internal */
  private _initInputStatusObservable(): void {
    (
      this as {
        inputStatusChanges: Observable<any>;
      }
    ).inputStatusChanges = new EventEmitter();
  }

  /** @internal */
  private _getColorStatus(): NbComponentStatus {
    if (this.validationStatus && Array.isArray(this.validationStatus)) {
      for (const vs of this.validationStatus) {
        if ((vs as InputStatus).checkFn(this)) {
          return (vs as InputStatus).status;
        }
      }
    }

    if (
      this.invalid &&
      (!this.validationStatus || !Array.isArray(this.validationStatus))
    ) {
      return 'danger';
    }

    return 'basic';
  }

  /** @internal */
  private _getStatusMessage(): InternalStatusMessage {
    if (this.validationStatus && Array.isArray(this.validationStatus)) {
      for (const vs of this.validationStatus) {
        if ((vs as InputStatus).checkFn(this)) {
          return (vs as InputStatus).message;
        }
      }
    }

    return null;
  }

  isRequired(): boolean {
    let required = false;

    if (this.required === true) {
      required = true;
    }

    if (this.validator) {
      const validationResult = this.validator(new FormControl());
      required =
        validationResult !== null && validationResult.required === true;
    }

    return required;
  }

  setRequired(required: boolean) {
    if(this.required != required) {
      this.required = required;
      if (required) {
        const validator = [];
        if (this.validator) {
          validator.push(this.validator);
        }
        validator.push(Validators.required);
        this.setValidators(validator);
        this.updateValueAndValidity()
      } else {
        var validator = [];
        if (this.validator) {
          validator.push(this.validator);
        }
        validator = validator.filter(v => v != Validators.required);
        this.setValidators(validator);
        this.updateValueAndValidity();
      }
    }
  }

  unsubscribeSubscriptions(): void {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setSize(size: string | BootstrapSize): void {
    this._initSize(size);
  }

  setIndex(index: number): void {
    (this as { index: number }).index = index;
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    super.setValue(value, options);
    (this as { value: any }).value = value;
  }

  patchValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    super.patchValue(value, options);
  }

  markAsTouched(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    super.markAsTouched(opts);

    (this as { inputStatus: string }).inputStatus = TOUCHED;

    if (opts.emitEvent !== false) {
      (this.inputStatusChanges as EventEmitter<any>).emit(this.inputStatus);
    }
  }

  markAsUntouched(
    opts: { onlySelf?: boolean; emitEvent?: boolean } = {}
  ): void {
    super.markAsUntouched(opts);

    (this as { inputStatus: string }).inputStatus = UNTOUCHED;

    if (opts.emitEvent !== false) {
      (this.inputStatusChanges as EventEmitter<any>).emit(this.inputStatus);
    }
  }

  markAsDirty(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    super.markAsDirty(opts);

    (this as { inputStatus: string }).inputStatus = DIRTY;

    if (opts.emitEvent !== false) {
      (this.inputStatusChanges as EventEmitter<any>).emit(this.inputStatus);
    }
  }

  markAsPristine(opts: { onlySelf?: boolean; emitEvent?: boolean } = {}): void {
    super.markAsPristine(opts);

    (this as { inputStatus: string }).inputStatus = PRISTINE;

    if (opts.emitEvent !== false) {
      (this.inputStatusChanges as EventEmitter<any>).emit(this.inputStatus);
    }
  }

  set hidden(hidden: boolean) {
    this._hidden = hidden;
  }
  get hidden() {
    return this._hidden;
  }

  set readonly(readonly: boolean) {
    if (readonly) {
      this.disable();
    } else {
      this.enable();
    }
    this._readonly = readonly;
  }

  get readonly() {
    return this._readonly;
  }

  set required(required: boolean) {
    this._required = required;
  }
  get required() {
    return this._required;
  }
}
