import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';
import { TuiDecimalT } from '@taiga-ui/core';

@Pipe({ name: 'castTextInput' })
export class CastTextInputPipe implements PipeTransform {
  transform<S>(value: S): TextInput {
      return value as unknown as TextInput;
  }
}

export type TextInputType =
  | 'text'
  | 'url'
  | 'email'
  | 'tel'
  | 'password'
  | 'number';

export interface TextInputConstructor<T = string>
  extends BaseInputConstructor<T> {
  type: TextInputType;
  numberFormat?: boolean;
  min?: number;
  max?: number;
  showDecimal?: TuiDecimalT; // if numberFormat
  decimalPrecision?: number; // if numberFormat
  prefix?: string; // if numberForma
  postfix?: string; // if numberFormat
}

export class TextInput<T = string> extends BaseInput<T> {
  inputType = 'textbox';
  type: TextInputType;
  numberFormat?: boolean = false;
  min?: number;
  max?: number;
  showDecimal?: TuiDecimalT;
  decimalPrecision?: number;
  prefix?: string;
  postfix?: string;

  constructor(options: TextInputConstructor<T>) {
    super(options);
    this.type = options.type;
    if (this.type === 'number') {
      if (options.numberFormat) {
        this.numberFormat = options.numberFormat;
        if(!options.max) {
          this.max = 100000000000000000;
        }
        if(!options.showDecimal) {
          this.showDecimal = 'not-zero';
        }
        if(!options.decimalPrecision) {
          this.decimalPrecision = 2;
        }
        if(!options.prefix) {
          this.prefix = '';
        }
        if(!options.postfix) {
          this.postfix = '';
        }
      }
      if (options.min || options.min === 0) {
        this.min = options.min;
      }
      if (options.max || options.max === 0) {
        this.max = options.max;
      }
      if(options.showDecimal) {
        this.showDecimal = options.showDecimal;
      }
      if(options.decimalPrecision) {
        this.decimalPrecision = options.decimalPrecision;
      }
      if(options.prefix) {
        this.prefix = options.prefix;
      }
      if(options.postfix) {
        this.postfix = options.postfix;
      }
    }
  }
}
