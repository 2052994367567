import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'br',
})
export class BrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(html: string) {
    if(!!html){
      const brText = html.replace(/\n/g, '<br>');
      return brText;
    }
    return html;
  }
}
