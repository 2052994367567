<div
  class="stared-card mx-5 my-4 text-center"
  [ngClass]="light ? 'card-light' : 'card'"
>
  <div class="star">
    <img src="./assets/icons/star.svg" />
  </div>
  <div class="stared-card-body">
    <ng-content></ng-content>
  </div>
</div>
