<div class="user-profile-welcome-section my-5">
  <div class="profile-pic">
    <div class="img" *ngIf="photoUrl">
      <img class="card-light hover-img-radius" [src]="photoUrl" />
    </div>
    <div class="img" *ngIf="!photoUrl">
      {{"PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NO_PHOTO" | translate }}
    </div>
    <!-- <button class="btn btn-green mt-5" (click)="onEditPhoto()">
      {{ "PRIVATE.COMPANY.USER.USER_PROFILE.IMG_BTN" | translate }}
    </button> -->
  </div>
  <div class="profile-sum">
    <div class="profile-header">
      <div class="left">
        <h4>{{ "PRIVATE.COMPANY.USER.USER_PROFILE.WELCOME" | translate }}</h4>
        <h3>{{ getName() | titlecase }}</h3>
      </div>
      <div class="right">
        <button *ngIf="!isEdit" class="btn btn-green-outline" (click)="onModifyProfile()">
          <tui-svg src="tuiIconDraft"></tui-svg>
          {{ "PRIVATE.COMPANY.USER.USER_PROFILE.EDIT_PROFILE_BTN" | translate }}
        </button>
      </div>
    </div>
    <div class="mt-3">
      <span class="work" *ngIf="user.settore">{{ "MAPPINGS.sectors." + user.settore | translate }}</span>
    </div>
    <div class="inplace-info text-small">
      <div class="left">
        <div class="announcements">
          <span class="number">{{ user.jobOfferCount }}</span>
          <span class="text black">{{"PRIVATE.COMPANY.USER.USER_PROFILE.ANNOUNCEMENTS" | translate}}</span>
        </div>
        <div class="jobs">
          <span class="number">{{ user.contractCount }}</span>
          <span class="text black">{{"PRIVATE.COMPANY.USER.USER_PROFILE.INPLACE_JOBS" | translate}}</span>
        </div>
      </div>
      <div class="right">
        <div class="date black">
          <span>{{"PRIVATE.COMPANY.USER.USER_PROFILE.CREATED_DATE" | translate}} <b>{{ getCreatedDate() }}</b></span>
        </div>
      </div>
    </div>
    <div class="user-info">
      <div class="location">
        <div class="icon">
          <img src="./assets/icons/location_blue.svg" />
        </div>
        <div class="info">
          <div class="title text-small">
            {{ "PRIVATE.COMPANY.USER.USER_PROFILE.LOCATION" | translate }}
          </div>
          <div class="data">{{ getLocation() }}</div>
        </div>
      </div>
      <div class="client-type">
        <div class="icon">
          <img src="./assets/icons/profile_tag_blue.svg" />
        </div>
        <div class="info">
          <div class="title text-small">
            {{ "PRIVATE.COMPANY.USER.USER_PROFILE.CLIENT_TYPE" | translate }}
          </div>
          <div class="data">
            {{
            "PRIVATE.COMPANY.USER.USER_PROFILE.CLIENT_TYPE_" + user.clientType
            | translate
            }}
          </div>
        </div>
      </div>
      <div class="partita-iva">
        <div class="icon">
          <img src="./assets/icons/pIVA_blue.svg" />
        </div>
        <div class="info">
          <div class="title text-small">
            {{ "PRIVATE.COMPANY.USER.USER_PROFILE.P_IVA" | translate }}
          </div>
          <div class="data">{{ user.pIva }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="user-profile-welcome-section-mobile my-5">
  <div class="profile-header">
    <div class="left">
      <h4>{{ "PRIVATE.COMPANY.USER.USER_PROFILE.WELCOME" | translate }}</h4>
      <h2>{{ getName() | titlecase }}</h2>
    </div>
    <div class="right">
      <button class="btn btn-green-outline" (click)="onModifyProfile()">
        <tui-svg src="tuiIconDraft"></tui-svg>
      </button>
    </div>
  </div>
  <div class="profile-sub-header">
    <span class="work" *ngIf="user.settore">{{ "MAPPINGS.sectors." + user.settore | translate }}</span>
    <div class="date black">
      <span>{{"PRIVATE.COMPANY.USER.USER_PROFILE.CREATED_DATE" | translate}} <b>{{ getCreatedDate() }}</b></span>
    </div>
  </div>
  <div class="profile-pic">
    <div class="img" *ngIf="photoUrl">
      <img class="card-light hover-img-radius" [src]="photoUrl" />
    </div>
    <div class="img" *ngIf="!photoUrl">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NO_PHOTO" | translate
      }}
    </div>
<!--    <button *ngIf="!isEdit" class="btn btn-green mt-3" (click)="onEditPhoto()">-->
<!--      {{ "PRIVATE.COMPANY.USER.USER_PROFILE.IMG_BTN" | translate }}-->
<!--    </button>-->
  </div>
  <div class="user-info">
    <div class="location">
      <div class="icon">
        <img src="./assets/icons/location_blue.svg" />
      </div>
      <div class="info">
        <div class="title text-small">
          {{ "PRIVATE.COMPANY.USER.USER_PROFILE.LOCATION" | translate }}
        </div>
        <div class="data">{{ getLocation() }}</div>
      </div>
    </div>
    <div class="client-type">
      <div class="icon">
        <img src="./assets/icons/profile_tag_blue.svg" />
      </div>
      <div class="info">
        <div class="title text-small">
          {{ "PRIVATE.COMPANY.USER.USER_PROFILE.CLIENT_TYPE" | translate }}
        </div>
        <div class="data">
          {{
          "PRIVATE.COMPANY.USER.USER_PROFILE.CLIENT_TYPE_" + user.clientType
          | translate
          }}
        </div>
      </div>
    </div>
    <div class="partita-iva">
      <div class="icon">
        <img src="./assets/icons/pIVA_blue.svg" />
      </div>
      <div class="info">
        <div class="title text-small">
          {{ "PRIVATE.COMPANY.USER.USER_PROFILE.P_IVA" | translate }}
        </div>
        <div class="data">{{ user.pIva }}</div>
      </div>
    </div>
  </div>
  <div class="jobs">
    <span class="number">{{ user.contractCount }}</span>
    <span class="text black">{{
      "PRIVATE.COMPANY.USER.USER_PROFILE.INPLACE_JOBS" | translate
      }}</span>
  </div>
  <div class="announcements">
    <span class="number">{{ user.jobOfferCount }}</span>
    <span class="text black">{{
      "PRIVATE.COMPANY.USER.USER_PROFILE.ANNOUNCEMENTS" | translate
      }}</span>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template let-observer #modalAddPhoto>
  <app-form [form]="formDocument"></app-form>
  <div class="mb-2 justify-content-center" *ngIf="uuidEditPhoto">
    {{
    "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_3" | translate
    }}
  </div>
  <div class="d-flex justify-content-center pt-3">
    <button (click)="savePhoto('form', observer)" [disabled]="!formDocument.valid" class="text-center btn btn-green">
      <tui-svg src="tuiIconPlus"></tui-svg>
      {{ "UTILS.EDIT" | translate }}
    </button>
  </div>
  <div *ngIf="!errorPhoto">
    <div class="d-flex flex-wrap align-items-stretch justify-content-center gap-2">
      <div class="mb-2 camera col-12 col-md-6">
        <div>
          {{
          "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_1"
          | translate
          }}
        </div>
        <div>
          <video id="video" class="video" controls #webcam></video>
        </div>
      </div>
      <div class="mb-2 output col-12 col-md-6">
        <div>
          {{
          "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_2"
          | translate
          }}
        </div>
        <div [ngStyle]="{ height: !photoTaken ? '0' : 'auto' }">
          <canvas id="canvas" #canvas> </canvas>
          <img id="photo" #photo />
        </div>
      </div>
    </div>
    <div class="mb-2 col-12 justify-content-center" *ngIf="uuidEditPhoto">
      {{
      "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_3"
      | translate
      }}
    </div>
    <div class="mb-2 justify-content-center">
      <button class="btn btn-primary btn-lg me-2" (click)="takePhoto()">
        {{ "UTILS.TAKE_PHOTO" | translate }}
      </button>
      <button class="btn btn-primary btn-lg me-2" *ngIf="photoTaken" (click)="savePhoto('web', observer)">
        {{ "UTILS.SAVE_PHOTO" | translate }}
      </button>
    </div>
  </div>
  <div class="text-danger justify-content-center pt-3" *ngIf="errorPhoto">
    <div>
      {{ "ERRORS.TAKE_PHOTO.DEVICE_NOT_FOUND.TITLE" | translate }}
    </div>
    <div>{{ errorMsg }}</div>
  </div>
</ng-template>

<ng-template #commingSoonTemplate>
  <div class="mt-2">Questa funzionalità sarà disponibile a breve.</div>
</ng-template>
