import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { RoleType } from 'src/app/core/auth/interfaces/role.model';
import { UserService } from 'src/app/core/auth/services/user.service';

@Component({
  selector: 'app-complete-profile-dialog',
  templateUrl: './complete-profile-dialog.component.html',
  styleUrls: ['./complete-profile-dialog.component.scss'],
})
export class CompleteProfileDialogComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext
  ) {}

  ngOnInit(): void {}

  toComplete() {
    if(this.userService.userRoleInfo.role === RoleType.WORKER){
      this.router.navigate(['private', 'candidato', 'utente', 'user-profile'],)
        .then(() => {
          this.context.completeWith();
        });
    } else {
      this.router.navigate(['private', 'azienda', 'utente', 'user-profile'])
        .then(() => {
          this.context.completeWith();
        });
    }
  }
}
