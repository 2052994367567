import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { SliderInput } from '../../../models/inputs/slider-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-slider-input',
  styleUrls: ['./slider-input.component.less', './slider-input.component.scss'],
  template: `
    <div #input>
      <input
        tuiSlider
        type="range"
        size="m"
        class="custom-slider"
        [ngClass]="{ 'full-opacity': control.readonly }"
        id="{{ 'input' + name }}"
        [readOnly]="control.readonly"
        [formControl]="control"
        [max]="control.max"
        [min]="control.min"
      />
      <div *ngIf="!control.showCurrentValue" class="ticks-labels">
        <span *ngFor="let label of control.labels">{{ label }}</span>
      </div>
      <span *ngIf="control.showCurrentValue" class="curent-value" #curent_value
        >{{ control.value }}
        {{
          control.currentValueLabelEnd
            ? (control.currentValueLabelEnd | translate)
            : ''
        }}</span
      >
    </div>
  `,
})
export class SliderInputComponent
  extends BaseInputComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('input') input: ElementRef<HTMLElement>;
  @ViewChild('curent_value') curent_value: ElementRef<HTMLElement>;
  @Input() name: string;
  @Input() control: SliderInput;
  fullWidth: number;

  ngOnInit(): void {
    if (this.control.showCurrentValue) {
      this.control.valueChanges.subscribe((value) => {
        this._adjustCurentValueTag(value);
      });
    }
  }
  ngAfterViewInit(): void {
    if (this.control.showCurrentValue) {
      this._adjustCurentValueTag(this.control.value);
    }
  }

  private _adjustCurentValueTag(curentValue: number) {
    let inputRect = this.input.nativeElement.getBoundingClientRect()
    this.fullWidth = inputRect.width;
    let elementRect = this.curent_value.nativeElement.getBoundingClientRect()
    var tagWidth = elementRect.width;
    var tagHalf = tagWidth / 2;
    var slider = (curentValue * this.fullWidth) / this.control.max;
    var margin = slider - tagHalf;
    if (margin < 0) margin = 0;
    if (margin >= this.fullWidth - tagWidth - 10) margin = this.fullWidth - tagWidth - 10; // added a slight offset to prevent clipping
    this.curent_value.nativeElement.style.marginLeft = margin + 'px';
  }
}
