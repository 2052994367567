import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { StatoEnum } from 'src/app/core/auth/enum/stato.enum';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { UserService } from 'src/app/core/auth/services/user.service';

@Component({
  selector: 'app-homepage-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
})
export class CompleteProfileComponent implements OnInit {
  @ViewChild('component', { static: false })
  private component: ElementRef<HTMLDivElement>;
  isViewMiddle: boolean = false;
  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.component && this.userState != StatoEnum.COMPLETO) {
      const middleOffset = (window.innerHeight * 15) / 100; // 15% of view height
      const rect = this.component.nativeElement.getBoundingClientRect();
      const topIsMiddle = rect.top >= 0 + middleOffset;
      const bottomIsMiddle = rect.bottom <= window.innerHeight - middleOffset;
      this.isViewMiddle = topIsMiddle && bottomIsMiddle;
    }
  }

  @Input() userType: UserType;
  get UserTypeEnum() {
    return UserType;
  }

  userState: StatoEnum;
  get userStateEnum() {
    return StatoEnum;
  }

  constructor(userService: UserService, private router: Router) {
    userService
      .getUserRoleInfo()
      .subscribe((info) => (this.userState = info.stato as StatoEnum));
  }

  ngOnInit(): void { }

  goToCompleteProfile() {
    if (this.userType == this.UserTypeEnum.WORKER) {
      // add queryParam to routing back from complete-profile #MULTIPOSTING4
      const announcementId = localStorage.getItem('announcementId');
      const queryParams = announcementId ? { announcementId } : {};
      this.router.navigate(['private/candidato/utente/user-profile'], {
        queryParams,
      });
    } else {
      this.router.navigate(['private/azienda/utente/user-profile']);
    }
  }
}
