import { Component, Inject } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

export interface ConfirmBeforeDeleteResponseModel {
  didConfirm: boolean;
}

@Component({
  template: `
    <h5 class="mt-2">{{ 'UTILS.CONFIRM_BEFORE_DELETE' | translate }}</h5>
    <div>
      <button class="btn btn-green" (click)="click(true)">
        {{ 'UTILS.YES' | translate }}
      </button>
      <button class="btn btn-green-outline mx-2" (click)="click(false)">
        {{ 'UTILS.CANCEL' | translate }}
      </button>
    </div>
  `,
})
export class ConfirmBeforeDeleteTemplateComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    readonly context: TuiDialogContext<ConfirmBeforeDeleteResponseModel, null>
  ) {}

  click(e) {
    this.context.completeWith({ didConfirm: e });
  }
}
