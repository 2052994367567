import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TuiExpandModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiPaginationModule } from '@taiga-ui/kit';
import { FormModule } from '../form';
import { TableComponent } from './components/table/table.component';
import { TableTbodyComponent } from './components/tbody/table-tbody.component';
import { TableTheadComponent } from './components/thead/table-thead.component';
import { DeepObjectPipe } from './pipes/deep-object.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TuiExpandModule,
    TuiPaginationModule,
    TuiSvgModule,
    FormModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    TableComponent,
    TableTheadComponent,
    TableTbodyComponent,
    DeepObjectPipe,
  ],
  exports: [TableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableModule {}
