import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableColumn } from 'src/app/shared/table/models/column.model';
import { formatDate } from '@angular/common';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { InterviewListItem, InterviewStatus } from 'src/app/shared/interfaces/interview.model';
import { InterviewsService } from 'src/app/shared/services/interviews.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ServerPageableDataSource } from 'src/app/shared/table/models/server-pageable-data-source';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-interview-list',
  templateUrl: './interviews-list.component.html'
})
export class InterviewsListComponent implements AfterViewInit {
  @ViewChild('candidateTemplate') candidateTemplate: TemplateRef<any>;
  @ViewChild('taskTemplate') taskTemplate: TemplateRef<any>;
  @ViewChild('linkTemplate') linkTemplate: TemplateRef<any>;
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  columns: TableColumn[] = []; 
  @Input() dataSource: ServerPageableDataSource<InterviewListItem[]>;

  @Input() pageSize: number = 10;
  currentPage:number = 0;
  @Output() onChangePage:  EventEmitter<any> = new EventEmitter(); 

  renewalSuccess = false;
  extensionSuccess = false;

  @Input() userType: UserType;  
  get userTypeEnum() {
    return UserType;
  }
  get InterviewStatus() {
    return InterviewStatus;
  }

  constructor(
    private route: ActivatedRoute,
    private i18n: TranslateService,
    private loading: NgxSpinnerService,
    private interviewsService: InterviewsService,
    private toastrService: ToastrService,
    private router: Router) {
      
  }

  ngAfterViewInit(): void {
    var columns = [
      {
        name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.LAST_EDIT'),
        prop: 'updated',
        pipe: {
          transform: (value: any) => {
            var d = new Date(0);
            d.setUTCSeconds(value);
            return formatDate(d, 'dd/MM/yyyy HH:mm', 'it-IT')
          }
        },
        cellClass: 'fs-6',
      },      
      {
        name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.TASK'),
        prop: 'task',
        cellClass: 'fs-6',
        cellTemplate: this.taskTemplate,
      },      
      {
        name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.INTERVIEW_DATE'),
        prop: 'at',
        pipe: {
          transform: (value: any) => {
            var d = new Date(0);
            d.setUTCSeconds(value);
            return formatDate(d, 'dd/MM/yyyy HH:mm', 'it-IT')
          }
        },
        cellClass: 'fs-6',
      },
      {
        name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.TYPE'),
        prop: 'type',
        pipe: {
          transform: (value: string) =>
            this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TYPE.'+value),
        },
        cellClass: 'fs-6',
      },
      {
        name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.STATUS'),
        prop: 'status',
        pipe: {
          transform: (value: string) =>
            this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.STATUS.'+value),
        },
        cellClass: 'fs-6',
      },
      {
        prop: '',
        sortable: false,
        buttonToggleIcon: true,
        cellClass: 'fs-6',
        useConditionalBtnTemplateFunction: true,
        conditionalBtnTemplateFunction: (row: any) => {
          return row.status !== InterviewStatus.EXPIRED && 
                 row.status !== InterviewStatus.CANCELLED && 
                 row.status !== InterviewStatus.REJECTED && 
                 row.status !== InterviewStatus.COMPLETED
        }
      },
    ];

    if(this.userType === UserType.COMPANY){
      columns.splice(1, 0, 
        {
          name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.CANDIDATE'),
          prop: 'candidate',
          cellClass: 'fs-6',
          cellTemplate: this.candidateTemplate,
        }
      )
    }
    this.columns = columns;
  }

  pagerChange($event){
    this.currentPage = $event;
    this.onChangePage.emit($event)
  }

  cancelInterview(row: InterviewListItem) {
    this.loading.show();
    this.interviewsService.cancelInterviewWorker(row.id, this.userType)
      .pipe(
        catchError((err) => {
          this.loading.hide();
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.loading.hide();
        this.dataSource.refresh();
        this.toastrService.success(
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CANCEL_MESSAGE.TITLE'),
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CANCEL_MESSAGE.MESSAGE')
        );
      });   
  }

  confirmInterview(row: any) {
    this.loading.show();
    this.interviewsService.confirmInterviewWorker(row.id)
      .pipe(
        catchError((err) => {
          this.loading.hide();
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.loading.hide();
        this.dataSource.refresh();
        this.toastrService.success(
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CONFIRM_MESSAGE.TITLE'),
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CONFIRM_MESSAGE.MESSAGE')
        );
      }); 
  }
  
  rejectInterview(row: any) {
    this.loading.show();
    this.interviewsService.rejectInterviewWorker(row.id)
      .pipe(
        catchError((err) => {
          this.loading.hide();
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.loading.hide();
        this.dataSource.refresh();
        this.toastrService.success(
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.REJECT_MESSAGE.TITLE'),
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.REJECT_MESSAGE.MESSAGE')
        );
      });
  }

  goToCandidate(id: string) {
    this.router.navigate(['private','azienda','cerca-candidati','search-detail',id,]);
  }

  goToLink(link: string) {
    window.location.href = link
    // window.open(link)
    // this.router.navigate(['private','azienda','cerca-candidati','search-detail',id,]);
  }

  viewPrivacy() {
    window.open(`${environment.privacy.interview}`, '_blank');
  }

}
