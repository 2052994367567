import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppConfig } from './app/app.config';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

let confFileName = 'config';
if (environment['name']) {
  confFileName = `config.${environment['name']}`;
}

fetch(`./assets/config/${confFileName}.json`)
  .then((response) => response.json())
  .then((data) => AppConfig.extend(data))
  .then((data) => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule(data))
      .catch((err) => console.error(err));
  });
