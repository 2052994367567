import {
  Component,
  Input,
  TemplateRef,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TextInput } from '../../../models';

export interface ButtonModel {
  cssClasses: string;
  template: TemplateRef<any>;
  disabledIfInputInvalid?: boolean;
}

@Component({
  selector: 'app-textbox-input-with-btn',
  template: `
    <div class="input" #inputContainer>
      <tui-input
        *ngIf="!control.numberFormat"
        id="{{ 'btn-input' + name }}"
        [readOnly]="control.readonly"
        [tuiTextfieldLabelOutside]="true"
        [formControl]="control"
        [tuiTextfieldType]="control.type == 'number' ? 'text' : control.type"
      >
        {{ control.placeholder | translate }}
      </tui-input>
      <tui-input-number
        *ngIf="control.numberFormat"
        id="{{ 'btn-input' + name }}"
        [readOnly]="control.readonly"
        [tuiTextfieldLabelOutside]="true"
        [formControl]="control"
        [min]="control?.min ? control.min : null"
        [max]="control?.max"
        [decimal]="control.showDecimal"
        [precision]="control.decimalPrecision"
        [prefix]="control.prefix"
        [postfix]="control.postfix"
      >
        {{ control.placeholder | translate }}
      </tui-input-number>
      <button
        *ngIf="_btn"
        [ngClass]="_btn.cssClasses"
        [disabled]="_btn.disabledIfInputInvalid && !control.valid"
        (click)="onBtnClick()"
      >
        <ng-container *ngTemplateOutlet="_btn.template"></ng-container>
      </button>
    </div>
  `,
  styleUrls: ['./textbox-input-with-btn.component.scss'],
})
export class TextboxInputWithBtnComponent
  extends BaseInputComponent
  implements AfterViewInit
{
  @Input() name: string;
  @Input() control: TextInput;
  @Input() set btn(button: ButtonModel) {
    this._btn = button;
  }
  @Output() btnClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('inputContainer') inputContainer: ElementRef<HTMLElement>;
  _btn: ButtonModel;

  ngAfterViewInit(): void {
    const input = document.getElementById('btn-input' + this.name);
    input.addEventListener('focusin', this._handleFocused.bind(this));
    input.addEventListener('focusout', this._handleUnfocused.bind(this));
    this.control.valueChanges.subscribe((o) => {
      if (this.control.valid) {
        this._handleValid();
      } else {
        this._handleInvalid();
      }
    });
    this.control.inputStatusChanges.subscribe((o) => {
      if (this.control.valid) {
        this._handleValid();
      } else {
        this._handleInvalid();
      }
    });
  }

  onBtnClick() {
    this.btnClicked.emit(true);
  }

  private _handleFocused() {
    const focused = 'focused';
    if (!this.inputContainer.nativeElement.classList.contains(focused))
      this.inputContainer.nativeElement.classList.add(focused);
  }
  private _handleUnfocused() {
    const focused = 'focused';
    if (this.inputContainer.nativeElement.classList.contains(focused))
      this.inputContainer.nativeElement.classList.remove(focused);
  }
  private _handleInvalid() {
    const invalid = 'invalid';
    if (!this.inputContainer.nativeElement.classList.contains(invalid))
      this.inputContainer.nativeElement.classList.add(invalid);
  }
  private _handleValid() {
    const invalid = 'invalid';
    if (this.inputContainer.nativeElement.classList.contains(invalid))
      this.inputContainer.nativeElement.classList.remove(invalid);
  }
}
