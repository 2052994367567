<div class="card h-100">
  <div class="card-body text-color-dark">
    <h5 class="fw-bold">{{ tutorialGroup?.title }}</h5>
  </div>
  <div class="pb-3 h-100">
    <ul class="list-group">
      <li
        (click)="goToDetail(tutorial)"
        *ngFor="let tutorial of tutorialGroup.tutorialList"
        class="list-group-item cursor-pointer fw-bolder mx-3 py-2 primary-color border-bottom"
      >
        {{ tutorial?.title }}
      </li>
    </ul>
  </div>
</div>
