import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { UserAgent } from 'src/app/pages/auth/enum/user-agent.enum';
import { HeaderService } from 'src/app/shared/components/header/header.service';
import { UserService } from './../../../../core/auth/services/user.service';

@Component({
  selector: 'app-private-default-layout',
  template: `<ng-content select="app-header-campaign"></ng-content>
    <div
      id="private-body"
      style="transition: padding-top 500ms linear 500ms;"
      [ngStyle]="{
        'margin-top': userAgent != UserAgent.FLUTTER_WEB_VIEW ? '' : '2rem'
      }"
    >
      <ng-content select="router-outlet"></ng-content>
    </div>`,
})
export class PrivateDefaultLayoutComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  UserAgent = UserAgent;
  userAgent: UserAgent;
  sub: Subscription;

  constructor(
    private headerService: HeaderService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userAgent = new RegExp(UserAgent.FLUTTER_WEB_VIEW, 'i').test(
      window.navigator.userAgent
    )
      ? UserAgent.FLUTTER_WEB_VIEW
      : null;

    this.sub = combineLatest([
      this.userService.identity$.pipe(map((identity) => identity?.userUuid)),
      this.route.queryParamMap.pipe(
        filter((params: ParamMap) => params.has('deviceToken')),
        map((params: ParamMap) => params.get('deviceToken'))
      ),
    ])
      .pipe(
        switchMap(([userUuid, deviceToken]) => {
          return this.authService.updateDeviceToken(userUuid, deviceToken);
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.headerService.init('private-body', true);
    }, 501);
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}
