import { Enumerated } from 'src/app/core/auth/interfaces/company.model';
import {
  EducationLevel,
  LanguageLevel,
} from '../../pages/private/pages/worker/pages/user/interfaces/worker-curriculum';

export interface SearchFiltersBase {
  sector: Enumerated[];
  orderBy: FilterOrderBy[];
}

export interface SearchWorkersFiltersResponse extends SearchFiltersBase {
  // BE NAME = ClientFilterResponse
  educationLevel: EducationLevel[];
  language: Enumerated[];
  languageLevel: LanguageLevel[];
  previousExperience: PreviousExperiences[];
  status: CandidateStatus[];
}

export interface SearchAnnouncementsFiltersResponse extends SearchFiltersBase {
  // BE NAME = WorkerFilterResponse
  region: string[];
  distanceMin: number;
  distanceMax: number;
  partener: any; // REWIEW: not on BE, but used on FE!
  publicOnly:boolean;
}

export interface SearchAnnouncementsFilters
  extends SearchAnnouncementsFiltersResponse {
  what: string;
  where: string;
  distance: number;
}

export interface SearchWorkersFilters extends SearchWorkersFiltersResponse {
  what: string;
  where: string;
  // pageNumber: number;
  distance?: number;
}

export enum PreviousExperiences { // BE HIRED.java
  HA_LAVORATO_IN_FILIALE_VIRTUALE = 'HA_LAVORATO_IN_FILIALE_VIRTUALE',
  NON_HA_MAI_LAVORATO_IN_FILIALE_VIRTUALE = 'NON_HA_MAI_LAVORATO_IN_FILIALE_VIRTUALE',
}

export enum FilterOrderBy {
  COMPATIBILITA = 'COMPATIBILITA',
  DATA = 'DATA',
  DISTANZA = 'DISTANZA',
  AGE = 'AGE', // only for candidate search
}

export enum CandidateStatus {
  OCCUPATO = 'OCCUPATO',
  DISOCCUPATO = 'DISOCCUPATO',
}
