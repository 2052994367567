import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RoleType } from 'src/app/core/auth/interfaces/role.model';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { environment } from 'src/environments/environment';
import { OverlayService } from '../overlay/overlay.service';
import { UserService } from './../../../core/auth/services/user.service';
import { UserAgent } from './../../../pages/auth/enum/user-agent.enum';

@Component({
  selector: 'app-header-campaign',
  templateUrl: './header-campaign.component.html',
  styleUrls: ['./header-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCampaignComponent {
  constructor(
    private overlayService: OverlayService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService, 
  ) {}

  @Input() isPrivate: boolean = false;

  stickyTopOffset: number = 100;


  @HostListener('body:scroll', ['$event'])
  scroll(e) {
    const headerHeight = document.getElementById("menu").clientHeight
    let scroll = e;
    if(scroll.target.scrollTop<headerHeight) 
      this.stickyTopOffset = headerHeight - scroll.target.scrollTop
    else
    this.stickyTopOffset = 0
  }

  openUserPanel: boolean = false;
  UserType = UserType;
  RoleType = RoleType;
  UserAgent = UserAgent;
  impersonating$ = of(this.userService.getIdentity());
  userType: UserType = UserType.WORKER;
  userAgent: UserAgent = new RegExp(UserAgent.FLUTTER_WEB_VIEW, 'i').test(
    window.navigator.userAgent
  )
    ? UserAgent.FLUTTER_WEB_VIEW
    : null;

  user$ = this.userService.user$.pipe(
    tap((user: any) => {
      if (
        (user && 'impersonator' in user) ||
        (user && user?.roles && RoleType.DELEGATE in user?.roles)
      ) {
        this.userType = UserType.DELEGATE;
        this.userService.getIdentity();
      } else if (user && user.userRole == RoleType.COMPANY) {
        this.userType = UserType.COMPANY;
      } else this.userType = UserType.WORKER;
    })
  );

  notificationsNumber$ = this.userService.notificationsNumber$.pipe(
    switchMap((notifications) =>
      this.userService.proposalsNumber$.pipe(
        map((proposals) => proposals + notifications)
      )
    )
  );

  onLogin() {
    this.overlayService.generalLoginOverlayOpen();
  }

  onHeartClick() {
    this.userType == UserType.WORKER
      ? this.router.navigate(['private/candidato/annunci'])
      : this.router.navigate(['private/azienda/annunci/candidati-salvati']);
  }

  deImpersonate() {
    this.authService
      .deimpersonateClient()
      .pipe(
        tap(() => this.userType == UserType.DELEGATE),
        tap(() => this.router.navigate(['private']))
      )
      .subscribe();
  }

  clickSocial(socialName: string) {
    window.open(`${environment.social[socialName]}`, '_blank');
  }
}
