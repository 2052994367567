<footer
  *ngIf="userAgent != UserAgent.FLUTTER_WEB_VIEW"
>
  <!-- BIGGER SCREENS -->
  <div class="container-fluid d-none d-sm-flex">
    <div class="mx-2 mx-lg-5 ngm">
      <div class="row">
        <div class="col my-4 inplace-logo ngm">
          <img src="./assets/images/logo_inplace_white.svg" alt="InPlace" />
        </div>
      </div>

      <div class="row footer-links">
        <ng-container *ngIf="!isPrivate; else privateLinks">
          <!-- PER I CANDIDATI -->
          <ul class="col-lg-3 col-6">
            <span class="title">
              {{ "FOOTER.LINKS.WORKER.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2">
              <a class="nav-link" [routerLink]="['/candidato']">
                {{ "FOOTER.LINKS.WORKER.LINK_1" | translate }}
              </a>
              <a class="nav-link" (click)="openLoginOverlay()">
                {{ "FOOTER.LINKS.WORKER.LINK_2" | translate }}
              </a>
            </li>
          </ul>
          <!-- PER LE AZIENDE -->
          <ul class="col-lg-3 col-6">
            <span class="title">
              {{ "FOOTER.LINKS.COMPANY.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2">
              <a class="nav-link" [routerLink]="['/azienda']">
                {{ "FOOTER.LINKS.COMPANY.LINK_1" | translate }}
              </a>
              <a class="nav-link" (click)="openLoginOverlay()">
                {{ "FOOTER.LINKS.COMPANY.LINK_2" | translate }}
              </a>
            </li>
          </ul>
          <!-- CERCHI SUPPORTO? -->
          <ul class="col-lg-3 col-6 mt-4 mt-lg-0 ngm">
            <span class="title">
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a class="nav-link" [routerLink]="['/support']">
                {{ "FOOTER.LINKS.SUPPORT.LINK_1" | translate }}
              </a>

              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }} </span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </li>
          </ul>
          <!-- CHI SIAMO -->
          <ul class="col-lg-3 col-6 mt-4 mt-lg-0 ngm">
            <span class="title">
              {{ "FOOTER.LINKS.ABOUT_US.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2">
              <a class="nav-link" [routerLink]="['/who']">
                {{ "FOOTER.LINKS.ABOUT_US.LINK_1" | translate }}
              </a>
            </li>
          </ul>
          <!-- SOCIAL -->
          <div class="col-12 col-lg-3 mt-4 ngm">
            <ng-container [ngTemplateOutlet]="socialLinks"></ng-container>
          </div>
        </ng-container>
        <!-- TERMS AND CONDITIONS -->
        <div class="row mt-5 mb-4 ngm">
          <div class="col-12 col-md-auto mt-2">
            <div class="col-12 col-md-auto">
              {{ "FOOTER.SITE.TEXT" | translate }}
            </div>
            <div class="col-12 col-md-auto cursor-pointer mt-2">
              <a (click)="handlePrivacy('term')">
                {{ "FOOTER.SITE.TERM" | translate }}
              </a>
              |
              <a (click)="handlePrivacy('policy')">
                {{ "FOOTER.SITE.PRIVACY" | translate }}
              </a>
              |
              <a (click)="handlePrivacy('cookie')"
                >{{ "FOOTER.SITE.COOKIE" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SMALL SCREENS -->
  <div class="container-fluid d-flex d-sm-none footer-small">
    <div class="mx-2 ngm">
      <div class="my-4 ngm">
        <img src="./assets/images/logo_inplace_white.svg" alt="InPlace" />
      </div>

      <div class="row footer-links">
        <ng-container *ngIf="!isPrivate; else smallPrivateLinks">
          <!-- ACCORDION -->
          <div class="accordion accordion-flush" id="accordionFooter">
            <!-- PER I CANDIDATI -->
            <div class="accordion-item">
              <span class="accordion-header title" id="accordion-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-collapseOne"
                  aria-expanded="false"
                  aria-controls="accordion-collapseOne"
                >
                  {{ "FOOTER.LINKS.WORKER.TITLE" | translate }}
                </button>
              </span>
              <div
                id="accordion-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="accordion-headingOne"
                data-bs-parent="#accordionFooter"
              >
                <div class="accordion-body">
                  <a
                    class="nav-link"
                    [routerLink]="['/candidato']"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-collapseOne"
                  >
                    {{ "FOOTER.LINKS.WORKER.LINK_1" | translate }}
                  </a>
                  <a
                    class="nav-link"
                    (click)="openLoginOverlay()"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-collapseOne"
                  >
                    {{ "FOOTER.LINKS.WORKER.LINK_2" | translate }}
                  </a>
                </div>
              </div>
            </div>

            <!-- PER LE AZIENDE -->
            <div class="accordion-item">
              <span class="accordion-header title" id="accordion-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-collapseTwo"
                  aria-expanded="false"
                  aria-controls="accordion-collapseTwo"
                >
                  {{ "FOOTER.LINKS.COMPANY.TITLE" | translate }}
                </button>
              </span>
              <div
                id="accordion-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="accordion-headingTwo"
                data-bs-parent="#accordionFooter"
              >
                <div class="accordion-body">
                  <a
                    class="nav-link"
                    [routerLink]="['/azienda']"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-collapseTwo"
                  >
                    {{ "FOOTER.LINKS.COMPANY.LINK_1" | translate }}
                  </a>
                  <a
                    class="nav-link"
                    (click)="openLoginOverlay()"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-collapseTwo"
                  >
                    {{ "FOOTER.LINKS.COMPANY.LINK_2" | translate }}
                  </a>
                </div>
              </div>
            </div>

            <!-- CERCHI SUPPORTO? -->
            <div class="accordion-item">
              <span class="accordion-header title" id="accordion-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-collapseThree"
                  aria-expanded="false"
                  aria-controls="accordion-collapseThree"
                >
                  {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
                </button>
              </span>
              <div
                id="accordion-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="accordion-headingThree"
                data-bs-parent="#accordionFooter"
              >
                <div class="accordion-body">
                  <a
                    class="nav-link"
                    [routerLink]="['/support']"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-collapseThree"
                  >
                    {{ "FOOTER.LINKS.SUPPORT.LINK_1" | translate }}
                  </a>

                  <div class="nav-link my-2 ngm">
                    <span
                      >{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }}
                    </span>
                    <p
                      class="mb-0 ngm"
                      [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                    ></p>
                  </div>

                  <a class="nav-link mail" href="mailto:support@inplace.it">
                    {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
                  </a>
                </div>
              </div>
            </div>

            <!-- CHI SIAMO -->
            <div class="accordion-item">
              <span class="accordion-header title" id="accordion-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-collapseFour"
                  aria-expanded="false"
                  aria-controls="accordion-collapseFour"
                >
                  {{ "FOOTER.LINKS.ABOUT_US.TITLE" | translate }}
                </button>
              </span>
              <div
                id="accordion-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="accordion-headingFour"
                data-bs-parent="#accordionFooter"
              >
                <div class="accordion-body">
                  <a class="nav-link" [routerLink]="['/who']">
                    {{ "FOOTER.LINKS.ABOUT_US.LINK_1" | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container [ngTemplateOutlet]="smallSocialLinks"></ng-container>
        <!-- TERMS AND CONDITIONS -->
        <div class="my-5 ngm terms">
          <div class="mt-2 ngm">
            <div class="info">
              {{ "FOOTER.SITE.TEXT" | translate }}
            </div>
            <div class="cursor-pointer mt-2 ngm">
              <a (click)="handlePrivacy('term')">
                {{ "FOOTER.SITE.TERM" | translate }}
              </a>
              |
              <a (click)="handlePrivacy('policy')">
                {{ "FOOTER.SITE.PRIVACY" | translate }}
              </a>
              |
              <a (click)="handlePrivacy('cookie')"
                >{{ "FOOTER.SITE.COOKIE" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PRIVATE LINKS -->
  <ng-template #privateLinks>
    <ng-container *ngIf="user$ | async">
      <ng-container [ngSwitch]="userType">
        <!-- COMPANY LINKS -->
        <ng-container *ngSwitchCase="UserType.COMPANY">
          <!-- PER LE AZIENDE -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.COMPANY.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a class="nav-link" [routerLink]="['/private/azienda/home']">
                {{ "FOOTER.LINKS.COMPANY.LINK_1" | translate }}
              </a>
              <a
                class="nav-link"
                [routerLink]="[
                  '/private/azienda/annunci/gestione-annunci/create'
                ]"
              >
                {{ "FOOTER.LINKS.COMPANY.LINK_2" | translate }}
              </a>
            </li>
          </ul>

          <!-- CERCHI SUPPORTO? -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a
                class="nav-link"
                [routerLink]="['/private/azienda/support/faq/azienda']"
              >
                {{ "FOOTER.LINKS.SUPPORT.LINK_1" | translate }}
              </a>

              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }} </span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </li>
          </ul>

          <!-- CHI SIAMO -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.ABOUT_US.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a class="nav-link" [routerLink]="['/private/azienda/who']">
                {{ "FOOTER.LINKS.ABOUT_US.LINK_1" | translate }}
              </a>
            </li>
          </ul>

          <div class="col-12 col-lg-3 mt-4 mt-lg-0 ngm">
            <ng-container [ngTemplateOutlet]="socialLinks"></ng-container>
          </div>
        </ng-container>

        <!-- WORKER LINKS -->
        <ng-container *ngSwitchCase="UserType.WORKER">
          <!-- PER I CANDIDATI -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.WORKER.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a class="nav-link" [routerLink]="['/private/candidato/home']">
                {{ "FOOTER.LINKS.WORKER.LINK_1" | translate }}
              </a>
              <a
                class="nav-link"
                [routerLink]="['/private/candidato/utente/user-profile']"
              >
                {{ "FOOTER.LINKS.WORKER.LINK_2" | translate }}
              </a>
            </li>
          </ul>
          <!-- CERCHI SUPPORTO? -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a
                class="nav-link"
                [routerLink]="['/private/candidato/support/faq/candidato']"
              >
                {{ "FOOTER.LINKS.SUPPORT.LINK_1" | translate }}
              </a>

              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }}</span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </li>
          </ul>
          <!-- CHI SIAMO -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.ABOUT_US.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <a class="nav-link" [routerLink]="['/private/candidato/who']">
                {{ "FOOTER.LINKS.ABOUT_US.LINK_1" | translate }}
              </a>
            </li>
          </ul>
          <div class="col-12 col-lg-3 mt-4 mt-lg-0 ngm">
            <ng-container [ngTemplateOutlet]="socialLinks"></ng-container>
          </div>
        </ng-container>

        <!-- DELEGATE LINKS -->
        <ng-container *ngSwitchCase="UserType.DELEGATE">
          <!-- CERCHI SUPPORTO? -->
          <ul class="col-4 col-lg-3">
            <span class="title">
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </span>
            <li class="nav-item mt-2 ngm">
              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }} </span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </li>
          </ul>

          <div class="col-12 col-lg-3 mt-4 mt-lg-0 ngm">
            <ng-container [ngTemplateOutlet]="socialLinks"></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <!-- SMALL PRIVATE LINKS -->
  <ng-template #smallPrivateLinks>
    <div
      class="accordion accordion-flush"
      id="accordionFooter"
      [ngSwitch]="userType"
    >
      <!-- COMPANY LINKS -->
      <ng-container *ngSwitchCase="UserType.COMPANY">
        <!-- PER LE AZIENDE -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseOne"
              aria-expanded="false"
              aria-controls="accordion-collapseOne"
            >
              {{ "FOOTER.LINKS.COMPANY.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingOne"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <a
                class="nav-link"
                [routerLink]="['/private/azienda/home']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseOne"
              >
                {{ "FOOTER.LINKS.COMPANY.LINK_1" | translate }}
              </a>
              <a
                class="nav-link"
                [routerLink]="[
                  '/private/azienda/annunci/gestione-annunci/create'
                ]"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseOne"
              >
                {{ "FOOTER.LINKS.COMPANY.LINK_2" | translate }}
              </a>
            </div>
          </div>
        </div>

        <!-- CERCHI SUPPORTO? -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseTwo"
              aria-expanded="false"
              aria-controls="accordion-collapseTwo"
            >
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingTwo"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <a
                class="nav-link"
                [routerLink]="['/private/azienda/support/faq/azienda']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseTwo"
              >
                {{ "FOOTER.LINKS.SUPPORT.LINK_1" | translate }}
              </a>

              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }} </span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </div>
          </div>
        </div>

        <!-- CHI SIAMO -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseThree"
              aria-expanded="false"
              aria-controls="accordion-collapseThree"
            >
              {{ "FOOTER.LINKS.ABOUT_US.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingThree"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <a
                class="nav-link"
                [routerLink]="['/private/azienda/who']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseThree"
              >
                {{ "FOOTER.LINKS.ABOUT_US.LINK_1" | translate }}
              </a>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- WORKER LINKS -->
      <ng-container *ngSwitchCase="UserType.WORKER">
        <!-- PER I CANDIDATI -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseOne"
              aria-expanded="false"
              aria-controls="accordion-collapseOne"
            >
              {{ "FOOTER.LINKS.WORKER.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingOne"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <a
                class="nav-link"
                [routerLink]="['/private/candidato/home']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseOne"
              >
                {{ "FOOTER.LINKS.WORKER.LINK_1" | translate }}
              </a>
              <a
                class="nav-link"
                [routerLink]="['/private/candidato/utente/user-profile']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseOne"
              >
                {{ "FOOTER.LINKS.WORKER.LINK_2" | translate }}
              </a>
            </div>
          </div>
        </div>

        <!-- CERCHI SUPPORTO? -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseTwo"
              aria-expanded="false"
              aria-controls="accordion-collapseTwo"
            >
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingTwo"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <a
                class="nav-link"
                [routerLink]="['/private/candidato/support/faq/candidato']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseTwo"
              >
                {{ "FOOTER.LINKS.SUPPORT.LINK_1" | translate }}
              </a>

              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }} </span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </div>
          </div>
        </div>

        <!-- CHI SIAMO -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseThree"
              aria-expanded="false"
              aria-controls="accordion-collapseThree"
            >
              {{ "FOOTER.LINKS.ABOUT_US.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingThree"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <a
                class="nav-link"
                [routerLink]="['/private/candidato/who']"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-collapseThree"
              >
                {{ "FOOTER.LINKS.ABOUT_US.LINK_1" | translate }}
              </a>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- DELEGATE LINKS -->
      <ng-container *ngSwitchCase="UserType.DELEGATE">
        <!-- CERCHI SUPPORTO? -->
        <div class="accordion-item">
          <span class="accordion-header title" id="accordion-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-collapseTwo"
              aria-expanded="false"
              aria-controls="accordion-collapseTwo"
            >
              {{ "FOOTER.LINKS.SUPPORT.TITLE" | translate }}
            </button>
          </span>
          <div
            id="accordion-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="accordion-headingTwo"
            data-bs-parent="#accordionFooter"
          >
            <div class="accordion-body">
              <div class="nav-link my-2 ngm">
                <span>{{ "FOOTER.LINKS.SUPPORT.SUB_TITLE" | translate }} </span>
                <p
                  class="mb-0 ngm"
                  [innerHTML]="'FOOTER.LINKS.SUPPORT.TEXT' | translate"
                ></p>
              </div>

              <a class="nav-link mail" href="mailto:support@inplace.it">
                {{ "FOOTER.LINKS.SUPPORT.LINK_2" | translate }}
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <!-- SOCIAL -->
  <ng-template #socialLinks>
    <ul class="social">
      <span class="title">
        {{ "FOOTER.LINKS.SOCIAL.TITLE" | translate }}
      </span>
      <div class="d-flex gap-2 mt-2 ngm">
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('linkedin')">
            <img src="./assets/images/icon_linkedin_white.svg" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('facebook')">
            <img src="./assets/images/icon_facebook_white.svg" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('instagram')">
            <img src="./assets/images/icon_instagram_white.svg" />
          </a>
        </li>
      </div>
    </ul>
  </ng-template>

  <!--SMALL SOCIAL -->
  <ng-template #smallSocialLinks>
    <ul class="social mt-4 ngm">
      <span class="title">
        {{ "FOOTER.LINKS.SOCIAL.TITLE" | translate }}
      </span>
      <div class="d-flex gap-3 mt-3">
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('linkedin')">
            <img src="./assets/images/icon_linkedin_white.svg" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('facebook')">
            <img src="./assets/images/icon_facebook_white.svg" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('instagram')">
            <img src="./assets/images/icon_instagram_white.svg" />
          </a>
        </li>
      </div>
    </ul>
  </ng-template>
</footer>
