import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogService } from '@taiga-ui/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Worker } from 'src/app/core/auth/interfaces/worker.model';
import { Curriculum } from 'src/app/pages/private/pages/worker/pages/user/interfaces/worker-curriculum';
import { WorkerDocumentsInfo } from 'src/app/pages/private/pages/worker/pages/user/interfaces/worker-documents.model';
import { MacroDocumentType } from 'src/app/pages/private/pages/worker/pages/user/models/macro-documents-type';
import { DocumentType } from 'src/app/pages/private/pages/worker/pages/user/models/documents-type';
import { DocumentStatus } from 'src/app/pages/private/pages/worker/pages/user/pages/user-worker-profile/models/documents-status';
import { UserWorkerProfileService } from 'src/app/pages/private/pages/worker/pages/user/services/user-worker-profile.service';
import {
  FileInput,
  Form,
  NopInput,
  ValidationStatus,
} from 'src/app/shared/form';
import { WorkerDoc } from 'src/app/pages/private/pages/worker/pages/user/interfaces/worker-doc.model';
import { SharedInRootService } from 'src/app/shared/services/root.service';
declare var MediaRecorder: any;

@UntilDestroy()
@Component({
  selector: 'app-user-profile-welcome-section',
  templateUrl: './user-welcome-section.component.html',
  styleUrls: ['./user-welcome-section.component.scss'],
})
export class UserWelcomeSectionComponent implements OnInit {
  @ViewChild('modalAddPhoto') modalAddPhoto: TemplateRef<any>;
  @ViewChild('photo') photo: ElementRef;
  @ViewChild('webcam') webcam: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('modalAddDocument') modalAddDocument: TemplateRef<any>;
  @ViewChild('showVideoTemplate') showVideoTemplate: TemplateRef<any>;
  @ViewChild('modalRecord') modalRecord: TemplateRef<any>;
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('video') videoElementRef: ElementRef;

  @Input() user: Worker;
  @Input() isEdit: boolean = false;
  @Output() onSaveModifications: EventEmitter<any> = new EventEmitter();

  photoUrl: string = '';
  uuidEditPhoto: string = '';
  formDocument: Form;
  videoElement: HTMLVideoElement;
  stream: MediaStream;
  photoTaken: boolean = false;
  errorPhoto: boolean = false;
  photoToSave: HTMLImageElement;
  errorMsg = '';
  languagesString: string = '';
  latestEducation: string = '';
  workerDocuments: BehaviorSubject<WorkerDocumentsInfo> = new BehaviorSubject(
    null
  );
  videoRecord: boolean = false;
  errorRecord: boolean = false;
  recordVideoElement: HTMLVideoElement;
  recordedBlobs: Blob[];
  mediaRecorder: any;
  myTimeout: any;
  isRecording: boolean = false;
  downloadUrl: string;

  hasVideoCV = false
  hasCV = false

  get macroDocType() {
    return MacroDocumentType;
  }
  get docType() {
    return DocumentType;
  }
  get documentStatus() {
    return DocumentStatus;
  }

  constructor(
    private loading: NgxSpinnerService,
    private router: Router,
    private service: UserWorkerProfileService,
    private sharedService: SharedInRootService,
    private i18n: TranslateService,
    private dialogService: TuiDialogService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.refreshCV();

    this.service
      .getWorkerCurriculum()
      .subscribe((curriculum: Curriculum) => {
        this._setLanguages(curriculum);
        this._setLatestEducation(curriculum);
      });
    this.getPhoto();
  }

  saveProfile() {
    this.onSaveModifications.emit(true);
  }

  onModifyProfile() {
    this.router.navigate(['/private/candidato/utente/edit-personal-data']);
  }

  getCreatedDate(): string {
    return new Date(this.user.created).toLocaleString('it', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  }
  
  getLocation(): string {
    const address = this.user.indirizzi.find((a) => a.tipologiaIndirizzo == 'RESIDENZA');
    if (!address) {
      return "";
    }
    if (address.stato === 'ITALIA') {
    const comune = this.i18n.instant('MAPPINGS.municipalities')[address.comune]
      return `${comune} (${address.provincia})`;
    } else {
      return `${address.comuneEstero} (${address.stato})`;
    }
  }

  getName(): string {
    return this.user.nome /*+ ' ' + this.user.cognome.charAt(0) + '.'*/;
  }

  getStars(stars: number): number {
    // we get sters from 0-10 => convert to 0-5 (with .5)
    return this.sharedService.roundNumber(stars, 0.5);
  }

  getPhoto() {
    this.service
      .getPhoto()
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe((resp: any) => {
        this.photoUrl = resp.url;
      });
  }

  onEditPhoto() {
    this.loading.show();
    var dialogTitle =
      'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.LOAD_PHOTO';
    this.uuidEditPhoto = '';
    this._initFormPhoto();
    this.service
      .getPhoto()
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe((resp: any) => {
        if (resp.uuidDocument) {
          this.uuidEditPhoto = resp.uuidDocument;
        }
        this.dialogService
          .open(this.modalAddPhoto, {
            dismissible: false,
            size: 'l',
            label: this.i18n.instant(dialogTitle),
          })
          .subscribe({
            complete: () => {
              this.stream.getTracks().forEach((track) => {
                track.stop();
              });
            },
          });
        const constraints = {
          video: true,
          audio: false,
        };
        this.photoTaken = false;
        this.errorPhoto = false;
        this.errorMsg = '';
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.videoElement = this.webcam.nativeElement;
            this.stream = stream;
            this.videoElement.srcObject = this.stream;
            this.videoElement.play();
          })
          .catch((err) => {
            this.errorPhoto = true;
            if (
              err.name === 'NotFoundError' ||
              err.name === 'DevicesNotFoundError'
            ) {
              // required track is missing
              console.log('Required track is missing');
              this.errorMsg = this.i18n.instant(
                'ERRORS.TAKE_PHOTO.DEVICE_NOT_FOUND.MESSAGE'
              );
            } else if (
              err.name === 'NotReadableError' ||
              err.name === 'TrackStartError'
            ) {
              // webcam or mic are already in use
              console.log('Webcam or mic are already in use');
              this.errorMsg = this.i18n.instant(
                'ERRORS.TAKE_PHOTO.ALREADY_IN_USE.MESSAGE'
              );
            } else if (
              err.name === 'OverconstrainedError' ||
              err.name === 'ConstraintNotSatisfiedError'
            ) {
              // constraints can not be satisfied by avb. devices
              console.log(
                'Constraints can not be satisfied by available devices'
              );
              this.errorMsg = this.i18n.instant(
                'ERRORS.TAKE_PHOTO.CONSTRAINTS.MESSAGE'
              );
            } else if (
              err.name === 'NotAllowedError' ||
              err.name === 'PermissionDeniedError'
            ) {
              // permission denied in browser
              console.log('Permission Denied.');
              this.errorMsg = this.i18n.instant(
                'ERRORS.TAKE_PHOTO.PERMISSION.MESSAGE'
              );
            } else if (err.name === 'TypeError' || err.name === 'TypeError') {
              // empty constraints object
              console.log('Both audio and video are FALSE');
              this.errorMsg = this.i18n.instant(
                'ERRORS.TAKE_PHOTO.CHANNELS_NOT_FOUND.MESSAGE'
              );
            } else {
              // other errors
              console.log('Sorry! Another error occurred.');
              this.errorMsg = this.i18n.instant(
                'ERRORS.TAKE_PHOTO.GENERIC.MESSAGE'
              );
            }
          });
      });
  }

  takePhoto() {
    this.loading.show();
    this.photoTaken = false;
    const height =
      this.webcam.nativeElement.videoHeight /
      (this.webcam.nativeElement.videoWidth / 320);
    const context = this.canvas.nativeElement.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    const data = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', data);
    this.canvas.nativeElement.width = 320;
    this.canvas.nativeElement.height = height;
    context.drawImage(this.webcam.nativeElement, 0, 0, 320, height);
    const dataToSave = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', dataToSave);
    this.photoToSave = dataToSave;
    this.photoTaken = true;
    this.loading.hide();
  }

  savePhoto(type, observer) {
    this.loading.show();
    const name = this._randomString(
      20,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    let params = {};
    if (type === 'web') {
      const photoImg = this._dataURLtoFile(this.photoToSave, name + '.png');
      params = {
        profileImg: photoImg,
      };
    } else {
      params = {
        profileImg: this.formDocument.get('file').value,
      };
    }
    this.service
      .savePhoto(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.getPhoto();
        observer.complete();
        this.toastr.success(
          this.i18n.instant('PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TOAST_TEXT'),
          this.i18n.instant('PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TOAST_TITLE')
        );
      });
  }

  refreshCV() {
    this.loading.show();
    Promise.all([
      this.service.getWorkerHasCurriculum().toPromise(),
      this.service.getWorkerHasVideoCurriculum().toPromise()
    ]).then(value =>{
      console.log(value)
      this.hasCV = value[0]; 
      this.hasVideoCV = value[1];  
    })
  }

  onShowCVVideo() {
    this.loading.show();
    if(this.hasVideoCV) {
      this.service
      .getVideoCV()
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe((resp) => {
        this.dialogService
          .open(this.showVideoTemplate, {
            dismissible: true,
            size: 'l',
            label: this.i18n.instant(
              'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE_CV_VIDEO'
            ),
            data: resp,
          })
          .subscribe();
        }
      );
    } else {
      this.loading.hide();
      this.dialogService
        .open(this.showVideoTemplate, {
          dismissible: true,
          size: 'l',
        })
        .subscribe();
    }
  }

  onShowCVVideoModal() {
      this._initFormCVImage(DocumentType.VIDEOCV);
      this.openAddDialog();
  }

  openAddDialog() {
    var dialogTitle = 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE_CV_VIDEO';
    this.dialogService
      .open(this.modalAddDocument, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(dialogTitle),
      })
      .subscribe();
  }

  onShowRecordCVVideoModal() {
    var dialogTitle = 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.TITLE_CV_VIDEO';
    this.dialogService
      .open(this.modalRecord, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(dialogTitle),
      })
      .subscribe({
        complete: () => {
          this.stream.getTracks().forEach((track) => {
            track.stop();
          });
        },
      });
    const constraints = {
      audio: true,
      video: true,
    };
    this.videoRecord = false;
    this.errorRecord = false;
    this.errorMsg = '';
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.videoElement = this.videoElementRef.nativeElement;
        this.recordVideoElement = this.recordVideoElementRef.nativeElement;
        this.stream = stream;
        this.videoElement.srcObject = this.stream;
      })
      .catch((err) => {
        this.errorRecord = true;
        if (
          err.name === 'NotFoundError' ||
          err.name === 'DevicesNotFoundError'
        ) {
          // required track is missing
          console.log('Required track is missing');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.DEVICE_NOT_FOUND.MESSAGE'
          );
        } else if (
          err.name === 'NotReadableError' ||
          err.name === 'TrackStartError'
        ) {
          // webcam or mic are already in use
          console.log('Webcam or mic are already in use');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.ALREADY_IN_USE.MESSAGE'
          );
        } else if (
          err.name === 'OverconstrainedError' ||
          err.name === 'ConstraintNotSatisfiedError'
        ) {
          // constraints can not be satisfied by avb. devices
          console.log('Constraints can not be satisfied by available devices');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.CONSTRAINTS.MESSAGE'
          );
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError'
        ) {
          // permission denied in browser
          console.log('Permission Denied.');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.PERMISSION.MESSAGE'
          );
        } else if (err.name === 'TypeError' || err.name === 'TypeError') {
          // empty constraints object
          console.log('Both audio and video are FALSE');
          this.errorMsg = this.i18n.instant(
            'ERRORS.RECORD_CV.CHANNELS_NOT_FOUND.MESSAGE'
          );
        } else {
          // other errors
          console.log('Sorry! Another error occurred.');
          this.errorMsg = this.i18n.instant('ERRORS.RECORD_CV.GENERIC.MESSAGE');
        }
      });
  }

  uploadVideoCV(observer) {
    this.loading.show();
    var params = {
      videoCv: this.formDocument.value.file as File
    }
    this.service
      .uploadVideoCV(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this._initWorkerDocuments();
      });
  }

  addDocument(observer) {
    this.loading.show();
    this.service
      .addWorkerDocument(this.formDocument.value)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this._initWorkerDocuments();
      });
  }

  startRecording() {
    this.recordedBlobs = [];
    for (const mimeType of [
      'video/mp4',
      'video/webm',
      'video/webm; codecs="vp8"',
      'video/webm; codecs="vp8, vorbis"',
      'video/webm; codecs="vp8, opus"',
      'video/webm; codecs="vp9"',
      'video/webm; codecs="vp9, vorbis"',
      'video/webm; codecs="vp9, opus"',
      'video/webm; codecs="av1"',
      'video/webm; codecs="av1, opus"',
    ]) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        try {
          this.mediaRecorder = new MediaRecorder(this.stream, { mimeType });
          this.mediaRecorder.start(); // collect 100ms of data
          this.isRecording = !this.isRecording;
          this._onDataAvailableEvent();
          this._onStopRecordingEvent();
          this.myTimeout = setTimeout(() => {
            if (!this.videoRecord) {
              this.stopRecording();
            }
          }, 30000); // TODO impostare secondi
          return;
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  stopRecording(): void {
    this.mediaRecorder.stop();
    this.isRecording = !this.isRecording;
    this.videoRecord = true;
  }

  resetRecord() {
    this.videoRecord = false;
  }

  saveRecording(observer) {
    this.loading.show();
    const name = this._randomString(
      20,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    const params = {
      videoCv: new File([this.recordedBlobs[0]], name, {
        type: 'video/x-matroska',
      })
    };
    this.stream.getTracks().forEach((track) => {
      track.stop();
    });
    this.service
      .uploadVideoCV(params)
      .pipe(
        finalize(() => this.loading.hide()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        observer.complete();
        this._initWorkerDocuments();
      });
  }

  private _onStopRecordingEvent() {
    try {
      clearTimeout(this.myTimeout);
      this.mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: 'video/mp4',
        });
        this.downloadUrl = window.URL.createObjectURL(videoBuffer); // you can download with <a> tag
        this.recordVideoElement.src = this.downloadUrl;
      };
    } catch (error) {
      console.log(error);
    }
  }

  private _onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  private _initWorkerDocuments() {
    this.service
      .getWorkerPersonalDocuments()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.workerDocuments.next(value);
      });
  }

  private _initFormCVImage(docType: DocumentType, document?: WorkerDoc) {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        nome: new NopInput({}),
        file: new FileInput({
          size: '12|12|12|12|12',
          buttonPlaceholder: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.FILE.LABEL',
          label: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.FORM.FILE.LABEL',
          placeholder: '',
          required: true,
          accept: docType === DocumentType.VIDEOCV ? 'video/mp4, video/quicktime' : '',
          maxSize: docType === DocumentType.VIDEOCV ? 50 * 1000000 : 5 * 1000000,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          valueChange: (file: File) => {
            this.formDocument.get('nome').setValue(file.name);
          },
        }),
        tipoDocumento: new NopInput({ value: docType }),
        range: new NopInput({
          value:
            docType === DocumentType.VIDEOCV
              ? MacroDocumentType.VIDEOCV
              : MacroDocumentType.DOC_CV,
        }),
        uuidDocumentRegistry: new NopInput({
          value: document?.documentRegistryUuid,
        }),
      },
    });
  }

  private _setLanguages(curriculum: Curriculum) {
    const allLanguages = [curriculum.nativeLanguage]
      .concat(Object.keys(curriculum.otherLanguages))
      .map(l => this.i18n.instant("MAPPINGS.languages")[l]);
    this.languagesString = allLanguages.join(", ") ;
  }

  private _setLatestEducation(curriculum: Curriculum) {
    const latest = curriculum.education
      .filter(e => !!e.anno)
      .sort((a,b) => +b.anno - +a.anno)
      .shift();

    this.latestEducation = latest ? latest.title : '';
  }

  private _initFormPhoto() {
    this.formDocument = new Form({
      header: { show: false },
      controls: {
        nome: new NopInput({}),
        file: new FileInput({
          size: '12|12|12|12|12',
          buttonPlaceholder: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.PHOTO.LABEL',
          label: 'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_RICONOSCIMENTO.FORM.PHOTO.PLACEHOLDER',
          placeholder: '',
          required: true,
          accept: 'image/png, image/jpeg, image/jpg',
          maxSize: 50 * 1000000,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          valueChange: (file: File) => {
            this.formDocument.get('nome').setValue(file.name);
          },
        }),
      },
    });
  }

  private _randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  private _dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
