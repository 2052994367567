import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TuiLinkModule, TuiModeModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiProgressModule } from '@taiga-ui/kit';
import {
  MessagesComponent,
  NotificationsComponent,
  PrivateDefaultLayoutComponent,
  UserComponent,
} from './layouts/private';
import { LoadingService } from './layouts/private/loading.services';
import { MiniNotificationComponent } from './layouts/private/menu-actions/notifications/components/mini-notification/mini-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsAndMessagesComponent } from './layouts/private/menu-actions/notifications-and-messages/notifications-and-messages.component';

const COMPONENTS = [
  PrivateDefaultLayoutComponent,
  MessagesComponent,
  UserComponent,
  NotificationsComponent,
  MiniNotificationComponent,
  NotificationsAndMessagesComponent,
];

const TAIGA_MODULES = [
  TuiLinkModule,
  TuiModeModule,
  TuiProgressModule,
  TuiSvgModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...TAIGA_MODULES,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [...COMPONENTS],
  exports: [CommonModule, ...COMPONENTS],
})
export class ThemeModule {
  constructor() {}

  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [LoadingService],
    };
  }
}
