import { Pipe, PipeTransform } from '@angular/core';
import { ProgressPhazesState } from 'src/app/pages/private/pages/company/pages/documents/pages/offert/interfaces';

const mapPercentageContractState = new Map()
  .set(ProgressPhazesState.TO_CREATE, '25')
  .set(ProgressPhazesState.CREATED, '50')
  .set(ProgressPhazesState.COMPLETED, '70')
  .set(ProgressPhazesState.ACCEPTED, '100')
  .set(ProgressPhazesState.FAILED, '100');

@Pipe({ name: 'percentageProgress' })
export class PercentageProgressPipe implements PipeTransform {
  transform(contractPhase: string): string {
    return mapPercentageContractState.get(contractPhase);
  }
}
