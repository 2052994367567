import { Component, Input, OnInit } from '@angular/core';
import { ICON_TYPE } from 'src/app/shared/notifications/enum/icon-type';
import { Notification } from 'src/app/shared/notifications/interfaces/notification';
@Component({
  selector: 'app-mini-notification',
  templateUrl: './mini-notification.component.html',
  styleUrls: ['./mini-notification.component.scss'],
})
export class MiniNotificationComponent implements OnInit {
  @Input() notification: Notification;
  iconTypeMap = ICON_TYPE;
  constructor() { }

  ngOnInit(): void {
   }

  getIcon(iconType: string): string {
    if (iconType in this.iconTypeMap) {
      return `${this.iconTypeMap[iconType].icon}`;
    }
    return 'tuiIconAttention';
  }
}
