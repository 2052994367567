<div class="work-card">
  <div class="card card-body">
    <div class="row text-color-dark">
      <div class="col-9 col-sm-9">
        <div class="row">
          <div class="col-12 col-sm-9">
            <h5 class="fw-bold">{{ title }}</h5>
          </div>
          <div class="col-12 col-sm-3 text-end">
            <span
              class="progress"
              [ngClass]="{
                active: status === workStateEnum.ACTIVE,
                'to-start': status === workStateEnum.TO_START,
                terminate: status === workStateEnum.TERMINATE
              }"
            >
              <span *ngIf="status === workStateEnum.ACTIVE">{{
                "AT_WORK.STATUS.ACTIVE" | translate
              }}</span>
              <span *ngIf="status === workStateEnum.TO_START">{{
                "AT_WORK.STATUS.TO_START" | translate
              }}</span>
              <span *ngIf="status === workStateEnum.TERMINATE">{{
                "AT_WORK.STATUS.TERMINATE" | translate
              }}</span>
            </span>
          </div>
          <div class="col-12 col-sm-4">
            <div class="label">
              {{
                (userRole === roleTypeEnum.COMPANY
                  ? "AT_WORK.LABELS.CANDIDATE_ID"
                  : "AT_WORK.LABELS.OFFER_ID"
                ) | translate
              }}
            </div>
            <div class="text">{{ id }}</div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="label">{{ "AT_WORK.LABELS.TASK" | translate }}</div>
            <div class="text">{{ task }}</div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="label">{{ "AT_WORK.LABELS.PLACE" | translate }}</div>
            <div class="text">{{ place }}</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <div class="fw-bold text-center">
          <ng-container *ngIf="deadline == 0">{{
            "AT_WORK.LABELS.EXPIRED_TODAY" | translate
          }}</ng-container>
          <ng-container *ngIf="deadline > 0">
            {{ deadline }} {{ "AT_WORK.LABELS.DEADLINE" | translate }}
          </ng-container>
          <ng-container *ngIf="deadline < 0">
            {{ "AT_WORK.LABELS.EXPIRED" | translate : { days: -deadline } }}
          </ng-container>
        </div>
        <div class="row">
          <div class="col-12 col-sm-3 text-end">
            <tui-svg class="p-icon" src="tuiIconCalendarLarge"></tui-svg>
          </div>
          <div class="col-12 col-sm-9">
            <div class="label">{{ "AT_WORK.LABELS.PERIOD" | translate }}</div>
            <div class="text">{{ period }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 footer">
    <div class="row mx-0 align-items-center py-2 justify-content-md-center">
      <div class="col-12 col-sm-3 text-center fs-6">
        <a
          tuiLink
          icon="tuiIconDownload"
          iconAlign="left"
          class="fw-bold text-dark"
          (click)="pdfClick(work)"
        >
          {{ btn1Label }}
        </a>
      </div>
      <div class="col-12 col-sm-3 text-center fs-6">
        <a
          tuiLink
          icon="tuiIconEyeOpen"
          iconAlign="left"
          class="fw-bold text-dark"
          (click)="middleClick(work)"
        >
          {{ btn2Label }}
        </a>
      </div>
      <div
        class="col-12 col-sm-3 text-center"
        *ngIf="status === workStateEnum.TERMINATE && btn4Label"
      >
        <button
          (click)="ratingClick(work)"
          class="fw-bold fs-6 btn btn-green-outline"
        >
          <tui-svg src="tuiIconStarFilled"></tui-svg>
          {{ btn4Label }}
        </button>
      </div>
      <div class="col-12 col-sm-3 text-center">
        <button
          (click)="timeSheetClick(work)"
          class="fw-bold fs-6 btn btn-green"
        >
          <tui-svg src="tuiIconCalendar"></tui-svg>
          {{ btn3Label }}
        </button>
      </div>
    </div>
  </div>
</div>
