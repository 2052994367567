import {
  NbAuthJWTToken,
  NbAuthOptions,
  NbPasswordAuthStrategy,
} from '@nebular/auth';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';

export const authenticationOptions: (
  appConfig: typeof AppConfig
) => NbAuthOptions = (appConfig: typeof AppConfig) => ({
  strategies: [
    NbPasswordAuthStrategy.setup({
      name: 'email-password',
      // baseEndpoint: appConfig.backend.baseUrl,
      baseEndpoint: `${environment.baseUrl}/api/identity`,
      login: {
        endpoint: '/api/login',
        method: 'POST',
        defaultErrors: ['LOGIN.ERRORS.GENERIC'],
      },
      refreshToken: {
        endpoint: '/api/refresh-token',
        method: 'POST',
        requireValidToken: true,
      },
      token: {
        class: NbAuthJWTToken,
        key: 'token',
      },
    }),
  ],
  forms: {
    login: {
      redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
      strategy: 'auth-username-password', // strategy id key.
      showMessages: {
        success: false,
        error: true,
      },
    },
    logout: {
      redirectDelay: 0,
      strategy: 'auth-username-password',
    },
    resetPassword: {
      redirectDelay: 500,
      strategy: 'auth-username-password',
      showMessages: {
        success: true,
        error: true,
      },
    },
    refreshToken: {
      redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
      strategy: 'oath2', // strategy id key.
      rememberMe: true, // whether to show or not the `rememberMe` checkbox
      showMessages: {
        // show/not show success/error messages
        success: true,
        error: true,
      },
    },
    validation: {
      username: {
        required: true,
      },
      password: {
        required: true,
      },
    },
  },
});
