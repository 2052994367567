import { Component, Inject, OnInit } from '@angular/core';
import { GeneralLoginOverlayComponent } from '../general-login-overlay/general-login-overlay.component';
import { TranslateService } from '@ngx-translate/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { LoginService } from 'src/app/pages/auth/components/login/login.service';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import { CloseOverlayNextStep } from '../overlay-next-step.enum';

@Component({
  selector: 'app-candidate-login-overlay',
  templateUrl: './candidate-login-overlay.component.html',
  styleUrls: ['./candidate-login-overlay.component.scss'],
})
export class CandidateLoginOverlayComponent
  extends GeneralLoginOverlayComponent
  implements OnInit
{
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) context: TuiDialogContext<CloseOverlayNextStep>,
    i18n: TranslateService,
    spinner: NgxSpinnerService,
    authService: AuthService,
    dialog: TuiDialogService,
    loginService: LoginService
  ) {
    super(context, i18n, spinner, authService, dialog, loginService);
  }

  ngOnInit(): void {
    super._initFormLogin();
  }

  onRegister() {
    this.closeLoginOverlay(CloseOverlayNextStep.REDIRECT_TO_CANDIDATE_REGISTRATION);
  }
}
