import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';

@Pipe({ name: 'castNopInput' })
export class CastNopInputPipe implements PipeTransform {
  transform<S>(value: S): NopInput {
      return value as unknown as NopInput;
  }
}

export interface NopConstructor<T, E>
  extends Omit<BaseInputConstructor<T>, 'label'> {}

export class NopInput<T = string, E = string> extends BaseInput<T> {
  inputType = 'nop';

  constructor(options: NopConstructor<T, E>) {
    super(options);
  }
}
