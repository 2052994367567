import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@UntilDestroy()
@Component({
  selector: 'app-general-register-overlay',
  templateUrl: './general-register-overlay.component.html',
  styleUrls: ['./general-register-overlay.component.scss'],
})
export class GeneralRegisterOverlayComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any>,
    private router: Router
  ) {}

  onRegister() {
    this.context.completeWith(
      this.router.navigateByUrl('/auth/register').then()
    );
  }
}
