import { Component, Input } from '@angular/core';
import { FileInput } from '../../..';

@Component({
  selector: 'app-file-input',
  template: `
    <tui-input-file
      [formControl]="control"
      [maxFileSize]="control.maxSize"
      [accept]="control.accept"
    ></tui-input-file>
  `,
})
export class FileInputComponent {
  @Input() control: FileInput;
  @Input() name: string;
}
