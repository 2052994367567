import { Component, Input } from '@angular/core';
import { TagInput } from '../../../models/inputs/tag-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-tag-input',
  template: `
    <tui-input-tag
      [readOnly]="control.readonly"
      [tuiTextfieldLabelOutside]="true"
      [formControl]="control"
      [tuiTextfieldCleaner]="true"
      [tagValidator]="control.tagValidator"
    >
      {{ control.placeholder | translate }}
    </tui-input-tag>
  `,
})
export class TagInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: TagInput;
}
