<div class="search-section py-5" [ngClass]="type" *ngIf="filter">
  <h3>{{ "HOME.SEARCH.TITLE_" + (type | uppercase) | translate }}</h3>
  <div class="search-form px-5 py-4">
    <div class="row px-4">
      <div class="form-group col-12">
        <app-input-label
          [field]="filterForm.get('what')"
          [name]="filterForm.get('what').label"
          [filter]="false"></app-input-label>
        <app-textbox-input
          [control]="filterForm.get('what') | castTextInput"
          [name]="filterForm.get('what').label"></app-textbox-input>
      </div>
    </div>

    <div class="row where-distance-container px-4">
      <div class="form-group col-12 col-md-6 col-lg-7">
        <app-input-label
          [field]="filterForm.get('where')"
          [name]="filterForm.get('where').label"
          [filter]="false"></app-input-label>

        <ng-container
          *ngIf="type == UserType.COMPANY; else searchAnnouncements">
          <app-select-virtual-input
            [control]="filterForm.get('where') | castSelectInput">
          </app-select-virtual-input>
        </ng-container>

        <ng-template #searchAnnouncements>
          <app-textbox-input
            [control]="filterForm.get('where') | castTextInput"
            [name]="filterForm.get('where').label"></app-textbox-input>
        </ng-template>
      </div>
      <div class="col-12 col-md-6 col-lg-5 distance mb-3">
        <div class="distance-label me-4">
          <app-input-label
            class="contrast-color"
            [field]="filterForm.get('distance')"
            [name]="filterForm.get('distance').label"
            [filter]="false"></app-input-label>
        </div>
        <app-slider-input
          style="margin-top: 20px"
          [control]="filterForm.get('distance') | castSliderInput"></app-slider-input>
      </div>
    </div>

    <div class="row px-4 my-3"
      *ngIf="approvedOffers | async">
      <p class="fiscal-code-text">
        {{"HOME.SEARCH.FISCAL_CODE.TEXT" | translate}}
        <a class="fiscal-code-link"
          [routerLink]="['/private', 'azienda', 'cerca-candidati', 'codice-fiscale']">
          {{"HOME.SEARCH.FISCAL_CODE.LINK" | translate}}
        </a>
      </p>
    </div>

    <button
      class="btn btn-blob"
      (click)="search()"
      [disabled]="
        !(
          this.filterForm.get('what').value ||
          (this.filterForm.get('where').value &&
            this.filterForm.get('distance').value)
        )
      ">
      <tui-svg src="tuiIconArrowRightLarge"></tui-svg>
    </button>
  </div>
</div>