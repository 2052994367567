import { SignatureState } from "src/app/pages/private/pages/worker/pages/messages/interfaces/final-confirm-info";
import { RequiredDocs } from "src/app/pages/private/pages/worker/pages/user/interfaces/required-docs";
import { Education, JobExperience, Language } from "src/app/pages/private/pages/worker/pages/user/interfaces/worker-curriculum";
import { SelectOption } from "src/app/shared/form";
import { ProviderEnum } from "../enum/provider.enum";
import { Enumerated, GenderTypeEnum, Instant, LocalDate } from "./company.model";

export interface Address {
  tipologiaIndirizzo: string;
  uuid: string;
  cap: string;
  stato: string;
  region: string;
  provincia: string;
  comune: string;
  comuneEstero: string;
  provinciaEstera: string;
  indirizzo: string;
}

export interface JobLocationPreference{
  region: string;
  district: string;
  municipality: string;
  distance: number;
}

export interface Worker extends WorkerCounters {
  anniEsperienza: number;
  ccnl: SelectOption<Enumerated,string>;
  codiceComuneDiNascita: string;
  codiceFiscale: string;
  codiceNazionalita: Enumerated;
  codiceNazioneDiNascita: Enumerated;
  codiceProvinciaDiNascita: string;
  codiceRegioneDiNascita: Enumerated;
  cognome: string;
  dataNascita: LocalDate;
  descComuneEsteroDiNascita: string;
  domicilioIsResidenza: boolean;
  email: string;
  euCitizen: boolean | null;
  genere: GenderTypeEnum;
  indirizzi: Address[];
  mansione: SelectOption<Enumerated,string>;
  nome: string;
  numeroTelefono: string;
  promoFlag: boolean;
  profilationFlag: boolean;
  sector: Enumerated;
  status: WorkerStatus;
  statusEvents: WorkerStatusEvent[];
  uuid: string;
  created: Instant;
  employed: boolean;
  jobLocationPreferences: JobLocationPreference[];
  completion: {
    percentage: number;
    personalData: boolean;
    personalDocuments: boolean;
    contractualDocuments: boolean;
    signature: SignatureState;
  }
}

export enum WorkerStatus {
  REGISTERED = 'REGISTERED',
  ELIGIBLE = 'ELIGIBLE',
  CONTRACTUALIZABLE = 'CONTRACTUALIZABLE',
  DELETED = 'DELETED'
}
export enum WorkerStatusEvent {
  SET_PROFILE_DATA = 'SET_PROFILE_DATA',
  SET_PERSONAL_DATA = 'SET_PERSONAL_DATA',
  SET_PERSONAL_DOCUMENTS = 'SET_PERSONAL_DOCUMENTS',
  SET_CONTRACTUAL_DOCUMENTS = 'SET_CONTRACTUAL_DOCUMENTS',
  REQ_SIGNATURE = 'REQ_SIGNATURE',
  SET_SIGNATURE = 'SET_SIGNATURE',
  FAIL_SIGNATURE = 'FAIL_SIGNATURE'
}

export interface WorkerCounters {
  advertisement: number;
  application: number;
  completitionPercentage: number;
  contracts: number;
  disponibility: number;
  documentCertified: boolean;
  signature: SignatureState;
  ratings: number;
  averageRating: number;
  activateDigitalSignature: boolean;
}

export interface WorkerBaseRequest {
  email: string;
  termsFlag: boolean;
  privacyFlag: boolean;
  mediaFlag: boolean;
  promoFlag: boolean;
  password?: string;
  profilationFlag? : boolean;
  provider?: ProviderEnum;
}

export interface RegisterWorkerRequest extends WorkerBaseRequest {
  nome: string;
  cognome: string;
  codiceFiscale: string;
  nazionalita: Enumerated;
  genere: GenderTypeEnum;
  cellulare: string;
  dataDiNascita: LocalDate;
  statoDiNascita: Enumerated;
  regioneDiNascita?: Enumerated;
  provinciaDiNascita?: string;
  comuneDiNascita?: string;
  descComuneEsteroDiNascita?: string;
  statoResidenza: Enumerated;
  regioneResidenza?: Enumerated;
  provinciaResidenza?: string;
  comuneResidenza?: string;
  descComuneEsteroDiResidenza?: string;
  descProvinciaEsteroDiResidenza?: string;
  indirizzoResidenza: string;
  capResidenza: string;
  domicilioIsResidenza: boolean;
  statoDomicilio?: Enumerated;
  regioneDomicilio?: Enumerated;
  provinciaDomicilio?: string;
  comuneDomicilio?: string;
  descComuneEsteroDiDomicilio?: string;
  descProvinciaEsteroDiDomicilio?: string;
  indirizzoDomicilio?: string;
  capDomicilio: string;
  mansioneInteresse: string;
  anniEsperienza?: number;
  impiegoVeloce: boolean;
  codiceSettore: Enumerated;
  preloadDocContract: boolean;
}

export interface RegisterWorkerResponse {
  jwtToken: string;
  userUuid: string;
  typeDocument: RequiredDocs;
  expires: number;
  refreshToken: string;
}

export interface RegisterWorkerProfileRequest  {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dob: LocalDate;
  sector: string;
  mansioneInteresse: string;
  anniEsperienza: number;
  jobLocationPreferences: JobLocationPreference[]
}

export interface AddressRequest {
  stato: string;
  regione: string;
  provincia: string;
  comune: string;
  indirizzo: string;
  cap: string;
  comuneEstero: string;
  provinciaEstera: string;
}

export interface editWorkerPersonalDataRequest {
  codiceFiscale: string;
  statoDiNascita: Enumerated;
  regioneDiNascita?: Enumerated;
  provinciaDiNascita?: string;
  comuneDiNascita?: string;
  descComuneEsteroDiNascita?: string;
  nazionalita: Enumerated;
  cellulare: string;
  genere: GenderTypeEnum;
  domicilioIsResidenza: boolean;
  impiegoVeloce: boolean;
  promoFlag: boolean;
  residenza: AddressRequest;
  domicilio: AddressRequest;
}

export interface editWorkerCurriculumRequest {
  nativeLanguage: Enumerated;
  otherLanguages: Language;
  education: Education[];
  jobExperience: JobExperience[];
  sector: string;
  mansioneInteresse: string;
  anniEsperienza: string;
  jobLocationPreferences: JobLocationPreference[];
  employed: string;
}

export interface ProfileValidation {
  userUuid: string;
}
