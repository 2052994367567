<div
  [ngSwitch]="field.inputType"
  [class.form-group]="
    field.inputType !== 'divider' &&
    field.inputType !== 'checkbox' &&
    field.inputType !== 'nop'
  "
  [class.mt-3]="field.inputType === 'dialog-file'"
  [class]="field.formGroupClass.join(' ')"
>
  <app-input-label
    *ngIf="
      field.inputType !== 'divider' &&
      field.inputType !== 'noop' &&
      field.inputType !== 'button'
    "
    [field]="field"
    [name]="name"
    [filter]="filter"
  ></app-input-label>

  <div *ngSwitchCase="'nop'">
    <app-nop-input></app-nop-input>
  </div>

  <!-- Input type: text -->
  <app-textbox-input
    *ngSwitchCase="'textbox'"
    [control]="field | castTextInput"
    [name]="name"
  ></app-textbox-input>

  <app-file-input
    *ngSwitchCase="'file'"
    [control]="field | castFileInput"
    [name]="name"
  ></app-file-input>

  <!-- Input type: textarea -->
  <app-texarea-input
    *ngSwitchCase="'textarea'"
    [control]="field"
    [name]="name"
  ></app-texarea-input>

  <!-- Input type: select -->
  <app-select-input
    *ngSwitchCase="'select'"
    [control]="field | castSelectInput"
  ></app-select-input>

  <!-- Input type: autocomplete -->
  <app-autocomplete-input
    *ngSwitchCase="'autocomplete'"
    [control]="field | castAutocompleteInput"
  ></app-autocomplete-input>

  <!-- Input type: checkbox -->
  <app-checkbox-input
    *ngSwitchCase="'checkbox'"
    [control]="field | castCheckboxInput"
    [name]="name"
  ></app-checkbox-input>

  <!-- Input type: radios -->
  <app-radios-input
    *ngSwitchCase="'radios'"
    [control]="field | castRadiosInput"
  ></app-radios-input>

  <!-- Input type: tag -->
  <app-tag-input
    *ngSwitchCase="'tag'"
    [control]="field | castTagInput"
    [name]="name"
  ></app-tag-input>

  <!-- Input type: time -->
  <app-time-input
    *ngSwitchCase="'time'"
    [control]="field | castTimeInput"
    [name]="name"
  ></app-time-input>

  <!-- Input type: slider -->
  <app-slider-input
    *ngSwitchCase="'slider'"
    [control]="field | castSliderInput"
    [name]="name"
  ></app-slider-input>

  <app-filter-buttons-input
    *ngSwitchCase="'filter-buttons'"
    [control]="field | castFilterButtonsInput"
  ></app-filter-buttons-input>

  <!-- Input type: date time -->
  <app-date-time-input
    *ngSwitchCase="'date-time'"
    [control]="field | castDateInputTime"
  ></app-date-time-input>

  <!-- Input type: date -->
  <app-date-input
    *ngSwitchCase="
      field.inputType === 'date' || field.inputType === 'date-range'
        ? field.inputType
        : ''
    "
    [control]="field | castDateInput"
  ></app-date-input>

  <!-- Input type: toggle -->
  <app-toggle-input
    *ngSwitchCase="'toggle'"
    [control]="field | castToggleInput"
    [name]="name">
  </app-toggle-input>

  <!-- Show info detail -->
  <app-validation-input-message
    *ngIf="field.inputType !== 'divider' && field.inputType !== 'noop'"
    [statusMessage]="field.statusMessage"
  ></app-validation-input-message>
</div>
