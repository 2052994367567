import { Observable } from 'rxjs';
import { BaseInput, BaseInputConstructor } from './base-input';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'castSelectInput' })
export class CastSelectInputPipe implements PipeTransform {
  transform<S>(value: S): SelectInput {
    return value as unknown as SelectInput;
  }
}

export interface SelectOption<T = string, E = string> {
  cod?: T;
  des?: E;
}

export interface SelectInputConstructor<T, E> extends BaseInputConstructor<T> {
  options: Observable<SelectOption<T, E>[]>;
  bindValue?: boolean;
  identityMatcher?: (t, e) => {};
  bindLabel?: string;
  multiple?: boolean;
  virtualScroll?: boolean;
  optionsSeparator?: boolean;
  editable?: boolean;
}

export class SelectInput<T = string, E = string> extends BaseInput<T> {
  inputType = 'select';
  options: Observable<SelectOption<T, E>[]>;
  bindValue = true;
  bindLabel: string;
  identityMatcher: (t, e) => {} = (item1, item2) => item1 === item2;
  multiple: boolean;
  virtualScroll: boolean;
  optionsSeparator: boolean;
  editable: boolean;

  constructor(options: SelectInputConstructor<T, E>) {
    super(options);
    this.options = options.options;
    if (typeof options.bindValue === 'boolean') {
      this.bindValue = options.bindValue;
    }
    if (options.identityMatcher) {
      this.identityMatcher = options.identityMatcher;
    }
    if (options.bindLabel) {
      this.bindLabel = options.bindLabel;
    }
    if (options.multiple) {
      this.multiple = options.multiple;
    }
    if (options.virtualScroll) {
      this.virtualScroll = options.virtualScroll;
    }
    if (options.optionsSeparator) {
      this.optionsSeparator = options.optionsSeparator;
    }
    if (options.editable) {
      this.editable = options.editable;
    }
  }

  setOptions(options: Observable<SelectOption<T, E>[]>): void {
    this.options = options;
  }
}
