<div class="carousel-container my-5">
  <ng-container
    *ngIf="titleTemplate"
    [ngTemplateOutlet]="titleTemplate"
  ></ng-container>
  <ng-container
    *ngIf="subtitleTemplate"
    [ngTemplateOutlet]="subtitleTemplate"
  ></ng-container>
  <div
    tuiCarouselButtons
    class="wrapper d-flex flex-nowrap align-items-center justify-content-stretch ps-3 ps-md-0"
  >
    <button
      *ngIf="items.length > visibleItems"
      tuiIconButton
      icon="tuiIconChevronLeftLarge"
      title="Previous"
      type="button"
      class="btn btn-green-outline"
      (click)="carouselMove('previous')"
    ></button>
    <tui-carousel
      #carousel
      class="carousel"
      [ngClass]="items.length <= visibleItems ? 'full-width' : ''"
      [draggable]="true"
      [itemsCount]="visibleItems"
      [(index)]="itemIndex"
    >
      <ng-container *ngFor="let item of items; let i = index">
        <div
          *tuiItem
          draggable="false"
          class="item"
          [ngStyle]="{
            'grid-template-columns': 'repeat(' + elementsInRow + ', 1fr)'
          }"
          [class.item_active]="i === i + 1"
        >
          <ng-container [ngSwitch]="elementType">
            <ng-container
              *ngSwitchCase="ElementType.WORKER_CARD"
              [ngTemplateOutlet]="workerCardTemplate"
              [ngTemplateOutletContext]="{ elements: item.elements }"
            ></ng-container>

            <ng-container
              *ngSwitchCase="ElementType.JOB_CARD"
              [ngTemplateOutlet]="jobCardTemplate"
              [ngTemplateOutletContext]="{ elements: item.elements }"
            ></ng-container>

            <ng-container
              *ngSwitchCase="ElementType.SUGGESTED_WORKER_CARD"
              [ngTemplateOutlet]="suggestedWorkerCardTemplate"
              [ngTemplateOutletContext]="{ elements: item.elements }"
            >
            </ng-container>

            <ng-container
              *ngSwitchCase="ElementType.SUGGESTED_JOB_CARD"
              [ngTemplateOutlet]="suggestedJobCardTemplate"
              [ngTemplateOutletContext]="{ elements: item.elements }"
            >
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </tui-carousel>
    <button
      *ngIf="items.length > visibleItems"
      #tuiIconButtonNext
      tuiIconButton
      type="button"
      icon="tuiIconChevronRightLarge"
      title="Next"
      class="btn btn-green-outline"
      (click)="carouselMove('next')"
    ></button>
  </div>
  <tui-pagination
    *ngIf="showPagination && items.length > visibleItems"
    #pagination
    size="m"
    class="pagination mt-3"
    [length]="items.length"
    [(index)]="itemIndex"
  ></tui-pagination>
</div>

<ng-template #workerCardTemplate let-elements="elements">
  <app-worker-card
    *ngFor="let worker of elements"
    class="element cursor-pointer"
    [worker]="worker"
    [userType]="userType"
    [favourite]="favourite"
    (click)="onCardClick(worker)"
  ></app-worker-card>
</ng-template>

<ng-template #jobCardTemplate let-elements="elements">
  <app-job-card
    *ngFor="let job of elements"
    class="element cursor-pointer"
    [job]="job"
    [userType]="userType"
    [favourite]="favourite"
    (click)="onCardClick(job)"
  >
  </app-job-card>
</ng-template>

<ng-template #suggestedWorkerCardTemplate let-elements="elements">
  <app-suggested-worker-card
    *ngFor="let worker of elements"
    class="element cursor-pointer"
    [ngClass]="ngClasses"
    [worker]="worker"
    [userType]="userType"
    [favourite]="favourite"
    (click)="onCardClick(worker)"
  ></app-suggested-worker-card>
</ng-template>

<ng-template #suggestedJobCardTemplate let-elements="elements">
  <app-suggested-job-card
    *ngFor="let job of elements"
    class="element cursor-pointer"
    [ngClass]="ngClasses"
    [job]="job"
    [userType]="userType"
    [favourite]="favourite"
    (click)="onCardClick(job)"
  ></app-suggested-job-card>
</ng-template>
