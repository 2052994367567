import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { HeaderService } from 'src/app/shared/components/header/header.service';
import { SaveFavoriteResponse } from '../../../../../../../../../shared/interfaces/company-search-list';
import { SuggestedWorker } from '../model/suggested-worker.model';

export interface SuggestedWorkerRes {
  suggestedWorkers: SuggestedWorker[];
  uuid: string;
  noSuggested: boolean;
}

@Injectable({ providedIn: 'root' })
export class CandidatesService {
  constructor(
    private apiClient: ApiClient,
    private headerService: HeaderService
  ) {}

  private suggestedLatestSub = new BehaviorSubject<SuggestedWorker[]>([]);
  suggestedLatest$ = this.suggestedLatestSub.asObservable();

  private suggestedByIdSub = new BehaviorSubject<SuggestedWorkerRes>(null);
  suggestedById$ = this.suggestedByIdSub.asObservable();

  getFavorite() {
    return this.apiClient.request('getFavorite');
  }

  saveFavorite(id: number): Observable<SaveFavoriteResponse> {
    return this.apiClient.request('saveFavorite', null, { id });
  }

  getSuggestedWorkersByLatestJobOffer(
    size?: number
  ): Observable<SuggestedWorker[]> {
    return this.apiClient
      .request<SuggestedWorker[]>(
        'getSuggestedWorkersByLatestJobOffer',
        null,
        size && { size }
      )
      .pipe(tap((res) => this.setSuggestedLatest(res)));
  }

  getSuggestedWorkersByJobOffer(
    uuid: string,
    size?: number
  ): Observable<SuggestedWorkerRes> {
    return this.apiClient
      .request<SuggestedWorker[]>(
        'getSuggestedWorkersByJobOffer',
        null,
        { size },
        { uuid }
      )
      .pipe(
        map((res) => {
          if (res.length) {
            return {
              suggestedWorkers: [...res],
              uuid,
              noSuggested: false,
            };
          }
          return {
            suggestedWorkers: [...res],
            uuid,
            noSuggested: true,
          };
        }),
        tap((res) => this.setSuggestedById(res))
      );
  }

  setSuggestedLatest(suggested: SuggestedWorker[]) {
    this.suggestedLatestSub.next(suggested);
  }

  setSuggestedById(suggestedRes: SuggestedWorkerRes) {
    this.suggestedByIdSub.next(suggestedRes);
  }

  onLogout() {
    this.setSuggestedById(null);
    this.setSuggestedLatest(null);
  }
}
