

<app-table
  [dataSource]="dataSource | castSimpleDataSource"
  [columns]="columns"
  [rowDetail]="rowDetailTemplate"
  [showPager]="false"
> </app-table>
<tui-pagination
  *ngIf="!!dataSource && dataSource.tablePage.totalPages > 1"
  [index]="currentPage"
  [length]="dataSource.tablePage.totalPages"
  (indexChange)="pagerChange($event)"
></tui-pagination>
<div class="mt-2">
  <a (click)="viewPrivacy()" class="privacy-link">{{ "PRIVATE.COMMON.INTERVIEWS.PRIVACY" | translate }}</a>
</div>

<ng-template #candidateTemplate let-row="row">
  <a
    tuiLink
    class="fw-bold text-dark cursor-pointer"
    (click)="goToCandidate(row.candidateId)"
    >{{ row.candidateId + " - " + row.candidate }}</a>
</ng-template>

<ng-template #taskTemplate let-row="row">
  <span *ngIf="userType === userTypeEnum.WORKER">{{row.clientId + " - "}}</span>
  <span>{{row.task}}</span>
</ng-template>

<ng-template #linkTemplate let-row="row">
  <a
    *ngIf="!!row.link"
    tuiLink
    icon="tuiIconDownload"
    iconAlign="left"
    class="fw-bold text-dark cursor-pointer"
    (click)="goToLink(row.link)"
    >{{ "PRIVATE.COMMON.INTERVIEWS.TABLE.GO_TO_LINK" | translate }}</a
  >
</ng-template>


<ng-template let-row="row" #rowDetailTemplate>
  <div
    class="row mx-0 align-items-center py-2 justify-content-md-center"
    style="min-height: 48px; background-color: #dce7ea"
  >
    <div class="col col-lg-2" *ngIf="row.status === InterviewStatus.CREATED && userType === userTypeEnum.WORKER">
      <button
        class="fw-bold fs-6 btn btn-green"
        (click)="confirmInterview(row)"
      >
        {{ "PRIVATE.COMMON.INTERVIEWS.BUTTONS.CONFIRM" | translate }}
      </button>
    </div>
    <div class="col col-lg-2" *ngIf="row.status === InterviewStatus.CREATED && userType === userTypeEnum.WORKER" >
      <button
        class="fw-bold fs-6 btn btn-green-outline"
        (click)="rejectInterview(row)"
      >
        {{ "PRIVATE.COMMON.INTERVIEWS.BUTTONS.REJECT" | translate }}
      </button>
    </div>
    <div class="col col-lg-2" *ngIf="(userType !== userTypeEnum.WORKER && row.status === InterviewStatus.CREATED) ||
                                     row.status === InterviewStatus.CONFIRMED">
      <button
        class="fw-bold fs-6 btn btn-green-outline"
        (click)="cancelInterview(row)"
      >
        {{ "PRIVATE.COMMON.INTERVIEWS.BUTTONS.CANCEL" | translate }}
      </button>

    </div>
    <div class="col col-lg-2" *ngIf="row.type == 'VIDEO' && row.status === InterviewStatus.CONFIRMED else interviewLocation">
         <button
          class="fw-bold fs-6 btn btn-green"
          [disabled]="!row.link"
          (click)="goToLink(row.link)">
         {{ "PRIVATE.COMMON.INTERVIEWS.BUTTONS.GO_TO_LINK" | translate }}
       </button>
    </div>
    <ng-template #interviewLocation>
      <div class="col col-lg-4 fw-bold fs-6" *ngIf="row.type == 'IN_PERSON' && row.location">
        <div>
          {{ "PRIVATE.COMMON.INTERVIEWS.LOCATION.ADDRESS" | translate }} {{row.location}}
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
