<div class="row justify-content-center mb-4">
  <div class="col-10 col-md-8 text-center">
    <div class="card border-0 bg-grey">
      <div class="card-body row justify-content-center">
        <div class="circle">
          <tui-svg class="icon margin-icon" src="tuiIconCheckLarge"></tui-svg>
        </div>
        <h4 class="mt-2">
          {{ upperTitle | translate }}
        </h4>
        <h6 class="fw-bold">
          {{ upperSubtitle | translate }}
        </h6>
        <div class="col-10">
          <ng-container *ngTemplateOutlet="upperBodyContent"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-10 col-md-8">
    <div class="card card-bottom pb-2">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-10">
            <h5>
              {{ lowerTitle | translate }}
            </h5>
            <ng-container *ngTemplateOutlet="lowerBodyContent"></ng-container>
          </div>
        </div>
        <ng-container [ngSwitch]="buttons?.length">
          <div *ngSwitchCase="1" class="pt-2 text-center px-4">
            <ng-container *ngTemplateOutlet="buttons[0]"></ng-container>
          </div>
          <div
            *ngSwitchCase="2"
            class="d-flex pt-2 justify-content-between row px-4 gap-2"
          >
            <ng-container *ngTemplateOutlet="buttons[0]"></ng-container>
            <ng-container *ngTemplateOutlet="buttons[1]"></ng-container>
          </div
        ></ng-container>
      </div>
    </div>
  </div>
</div>
