import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-notification',
  templateUrl: './top-notification.component.html',
  styleUrls: ['./top-notification.component.scss'],
})
export class TopNotificationComponent implements OnInit {
  @Input() icon: string;
  @Input() text1: string;
  @Input() text2: string;
  @Input() text3: string;
  @Input() number: number;
  constructor() {}

  ngOnInit(): void {}
}
