import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';

@Pipe({ name: 'castSliderInput' })
export class CastSliderInputPipe implements PipeTransform {
  transform<S>(value: S): SliderInput {
      return value as unknown as SliderInput;
  }
}

export interface SliderInputConstructor<T = string>
  extends BaseInputConstructor<T> {
  min: number;
  max: number;
  segments?: number;
  labels?: string[];
  showCurrentValue?: boolean;
  currentValueLabelEnd?: string;
}

export class SliderInput<T = number> extends BaseInput<T> {
  inputType = 'slider';
  min: number;
  max: number;
  segments?: number;
  labels?: string[];
  showCurrentValue: boolean = false;
  currentValueLabelEnd?: string;

  constructor(options: SliderInputConstructor<T>) {
    super(options);
    this.min = options.min;
    this.max = options.max;
    if (options.segments) {
      this.segments = options.segments;
    }
    if (options.labels) {
      this.labels = options.labels;
    }
    if (options.showCurrentValue) {
      this.showCurrentValue = options.showCurrentValue;
    }
    if (options.currentValueLabelEnd) {
      this.currentValueLabelEnd = options.currentValueLabelEnd;
    }
  }
}
