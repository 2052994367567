import { Observable } from 'rxjs';
import { BaseInput, BaseInputConstructor } from './base-input';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'castRadiosInput' })
export class CastRadiosInputPipe implements PipeTransform {
  transform<S>(value: S): RadiosInput {
      return value as unknown as RadiosInput;
  }
}

export interface RadioOption<T = string, E = string> {
  cod?: T;
  des?: E;
}
export interface RadiosInputConstructor<T, E>
  extends Omit<BaseInputConstructor<T>, 'placeholder' | 'pattern'> {
  options: Observable<RadioOption<T, E>[]>;
  inline?: boolean;
}

export class RadiosInput<T = string, E = string> extends BaseInput<T> {
  inputType = 'radios';
  options: Observable<RadioOption<T, E>[]>;
  inline?: boolean = true;

  constructor(options: RadiosInputConstructor<T, E>) {
    super(options);
    this.options = options.options;

    if (options.inline || options.inline === false) {
      this.inline = options.inline;
    }
  }
}
