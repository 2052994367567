import { Component, Input } from '@angular/core';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { SuggestedWorker } from 'src/app/pages/private/pages/company/pages/my-announcements/pages/candidates/model/suggested-worker.model';

@Component({
  selector: 'app-suggested-worker-card',
  templateUrl: './suggested-worker-card.component.html',
  styleUrls: ['./suggested-worker-card.component.scss'],
})
export class SuggestedWorkerCardComponent {
  @Input() isPremium: boolean = false;
  @Input() userType?: UserType;
  @Input() worker!: SuggestedWorker;
  @Input() favourite: boolean = false;
  UserType = UserType;
}
