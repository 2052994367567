<div>
  <p style="font: normal normal medium 16px/19px Roboto">
    {{ "PRIVATE.UTILITY.STATUS_EVENT_DIALOG.TEXT_1" | translate }}
  </p>
  <p style="font: normal normal medium 16px/19px Roboto">
    {{ "PRIVATE.UTILITY.STATUS_EVENT_DIALOG.TEXT_2" | translate }}
  </p>
  
  <div class="my-2 button-container">
    <button (click)="toComplete()" class="btn btn-green">
      {{ "PRIVATE.UTILITY.STATUS_EVENT_DIALOG.BUTTON" | translate }}
    </button>
  </div>
</div>
