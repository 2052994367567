import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { RoleType } from '../interfaces/role.model';

@Injectable()
export class NotAuthenticationGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router, private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      map((authenticated: boolean) => {
        if (authenticated) {
          this.router.navigate(['private']);  
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
