<div class="login-overlay d-flex flex-column">
  <div class="row mt-3 justify-content-center">
    <div class="login">
      <div class="row mb-3">
        <h2>{{ "LOGIN.LOGIN" | translate }}</h2>
        <h5 style="white-space: pre-line">
          {{ "LOGIN.LOGIN_TEXT" | translate }}
        </h5>
        <div class="form-responsive single-col">
          <div class="form-group">
            <app-input-label
              [field]="loginForm.get('email')"
              [name]="loginForm.get('email').label"
              [filter]="false"
            ></app-input-label>
            <app-textbox-input
              [control]="loginForm.get('email') | castTextInput"
              [name]="loginForm.get('email').label"
            ></app-textbox-input>
            <app-validation-input-message
              [statusMessage]="loginForm.get('email').statusMessage"
            ></app-validation-input-message>
          </div>
          <div class="form-group">
            <app-input-label
              [field]="loginForm.get('password')"
              [name]="loginForm.get('password').label"
              [filter]="false"
            ></app-input-label>
            <tui-input
              [tuiTextfieldType]="hide ? 'password' : 'text'"
              [formControl]="loginForm.get('password')"
              [tuiTextfieldLabelOutside]="true"
            >
              {{ loginForm.get("password").placeholder | translate }}
            </tui-input>
            <app-validation-input-message
              [statusMessage]="loginForm.get('password').statusMessage"
            ></app-validation-input-message>
            <div class="row m-0">
              <div
                class="col-6 d-block mt-2 text-primary fw-bolder cursor-pointer"
                *ngIf="loginForm.get('email')?.value"
                (click)="forgotPassword()"
              >
                {{ "LOGIN.FORGOT_PASSWORD.LABEL" | translate }}
              </div>
              <div class="col-6" *ngIf="!loginForm.get('email')?.value">
                &nbsp;
              </div>
              <div
                class="col-6 d-block mt-2 text-primary fw-bolder cursor-pointer text-end"
                *ngIf="loginForm.get('password')?.value && hide"
                (click)="passwordToogle()"
              >
                {{ "LOGIN.SHOW_PASSWORD" | translate }}
              </div>
              <div
                class="col-6 d-block mt-2 text-primary fw-bolder cursor-pointer text-end"
                *ngIf="loginForm.get('password')?.value && !hide"
                (click)="passwordToogle()"
              >
                {{ "LOGIN.HIDE_PASSWORD" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          [disabled]="!loginForm.valid"
          (click)="onLogin()"
          class="btn btn-green text-uppercase"
        >
          {{ "UTILS.LOGIN" | translate }}
        </button>
      </div>
    </div>
    <div class="register">
      <div class="d-flex flex-column justify-content-center">
        <h4>{{ "LOGIN.NOT_REGISTRED_YET" | translate }}</h4>
        <h6>{{ "LOGIN.REGISTER_NOW" | translate }}</h6>
        <div class="d-flex justify-content-center">
          <button
            (click)="onRegister()"
            class="btn btn-green-outline text-uppercase mt-4"
          >
            {{ "LOGIN.REGISTER_WORKER" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
