import { Injectable } from '@angular/core';
import { SearchAnnouncementsFilters } from '../interfaces/search-filters';

@Injectable({
  providedIn: 'root',
})
export class SearchAnnouncementsFilterService {
  private filters: SearchAnnouncementsFilters;

  constructor() {}

  setFilterList(filters: SearchAnnouncementsFilters) {
    this.filters = filters;
  }

  getFilterList(): SearchAnnouncementsFilters {
    return this.filters;
  }
}
