import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';

@Pipe({ name: 'castTextareaInput' })
export class CastTextareaInputPipe implements PipeTransform {
  transform<S>(value: S): TextareaInput {
      return value as unknown as TextareaInput;
  }
}

export interface TextareaConstructor extends BaseInputConstructor {
  rows?: number;
}

export class TextareaInput extends BaseInput {
  inputType = 'textarea';
  rows?: number;

  constructor(options: TextareaConstructor) {
    super(options);
    this.rows = options.rows || 5;
  }
}
