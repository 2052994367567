import { Component, Input } from '@angular/core';
import { TutorialListItem } from '../../support/pages/tutorial/interfaces/tutorial.model';

@Component({
  selector: 'app-tutorial-card',
  templateUrl: './tutorial-card.component.html',
  styleUrls: ['./tutorial-card.component.scss'],
})
export class TutorialCardComponent {
  constructor() {}

  @Input() tutorialGroup: any;
  @Input() tutorialClickEvent: Function;

  goToDetail(tutorial: TutorialListItem) {
    this.tutorialClickEvent(tutorial);
  }
}
