<div class="login-overlay d-flex flex-column">
  <div class="row mt-3 justify-content-center">
    <div class="register">
      <div class="d-flex flex-column justify-content-center mt-4">
        <h2 class="mb-0 pb-0">{{ "REGISTER.OVERLAY.TITLE" | translate }}</h2>

        <h5 style="white-space: pre-line">
          {{ "REGISTER.OVERLAY.SUB_TITLE" | translate }}
        </h5>

        <div class="d-flex justify-content-center mt-4">
          <button class="btn btn-green-outline" (click)="onRegister()">
            {{ "LOGIN.REGISTER_NOW" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
