export enum TypeTimeWorkEnum {
  STANDARD = 1,
  TURNI = 0,
}

export const typeTimeWorkMap = new Map()
  .set(TypeTimeWorkEnum.STANDARD, {
    cod: TypeTimeWorkEnum.STANDARD,
    des: 'STANDARD',
  })
  .set(TypeTimeWorkEnum.TURNI, {
    cod: TypeTimeWorkEnum.TURNI,
    des: 'TURNI',
  });

export const weekMap = new Map<string, string>()
  .set('Lun', 'monday')
  .set('Mar', 'tuesday')
  .set('Mer', 'wednesday')
  .set('Gio', 'thursday')
  .set('Ven', 'friday')
  .set('Sab', 'saturday')
  .set('Dom', 'sunday');

export enum FullTimeWorkEnum {
  FULL_TIME = 1,
  PART_TIME = 0,
}

export const fullTimeWorkMap = new Map()
  .set(FullTimeWorkEnum.FULL_TIME, {
    cod: FullTimeWorkEnum.FULL_TIME,
    des: 'Full Time',
  })
  .set(FullTimeWorkEnum.PART_TIME, {
    cod: FullTimeWorkEnum.PART_TIME,
    des: 'Part Time',
  });
