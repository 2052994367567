export enum DocumentType {
  PATENTE = "PATENTE",
  PASSAPORTO = "PASSAPORTO",
  CARTA_IDENTITA = "CARTA_IDENTITA",
  CODICE_FISCALE = "CODICE_FISCALE",
  //PERMESSO_DI_SOGGIORNO = 'PERMESSO_DI_SOGGIORNO',
  //RICEVUTA_PRESENTAZIONE_DI_RINNOVO = 'RICEVUTA_PRESENTAZIONE_DI_RINNOVO',
  //DICHIARAZIONE_IDONEITA_ALLOGGI = 'DICHIARAZIONE_IDONEITA_ALLOGGIO',
  
  CARICHI_PENDENTI = 'CARICHI_PENDENTI',
  HACCP = 'HACCP',
  BREVETTO_BAGNINO = 'BREVETTO_BAGNINO',
  CV = 'CV',
  VIDEOCV = 'VIDEOCV',
  DETRAZIONE_IMPOSTA = "DETRAZIONE_IMPOSTA",
  STATO_FAMIGLIA_RESIDENZA = "STATO_FAMIGLIA_RESIDENZA",
  COMUNICAZIONE_COORDINATE_BANCARIE = "COMUNICAZIONE_COORDINATE_BANCARIE",
  MODULO_ADESIONE_SINDACATO = "MODULO_ADESIONE_SINDACATO",
  AUTOCERTIFICAZIONE_DISOCCUPAZIONE ="AUTOCERTIFICAZIONE_DISOCCUPAZIONE",
  MODULO_TFR = "MODULO_TFR",
  PHOTO = "PHOTO",
  HCCP = "HCCP",
  DICHIARAZIONE_INPS = "DICHIARAZIONE_INPS" // is commented on BE, but used on FE...
}
