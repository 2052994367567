import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from 'src/app/core/utility.service';

@Pipe({ name: 'getDownloadableDocumentStatus' })
export class GetDownloadableDocumentStatusPipe implements PipeTransform {
  public constructor(private utilityService: UtilityService) {}
  transform(status: string): boolean {
    return this.utilityService.getDownloadableDocStatus(status);
  }
}
