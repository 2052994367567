import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { of } from 'rxjs';
import { UtilityService } from 'src/app/core/utility.service';
import { BaseInput, Form, SelectInput, TextInput, ValidationStatus } from 'src/app/shared/form';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserService } from 'src/app/core/auth/services/user.service';

import { TranslateService } from '@ngx-translate/core';
import { Address, AddressRequest } from 'src/app/core/auth/interfaces/worker.model';

@UntilDestroy()
@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit {
  @Input() address: Address
  addressForm: Form;

  constructor(
    private utilityService: UtilityService,
    private i18n: TranslateService,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this._initAddressForm(this.address);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.address.previousValue != changes.address.currentValue){
      this._initAddressForm(this.address);
    }
  }

  private _initAddressForm(address: Address): void {
    this.addressForm = new Form({
      header: {show: false, },
      filter: false,
      controls: this._getResidenceFormFields(address),
    });
    if (address && address?.stato !== 'ITALIA') {
      this.addressForm.addControlAfter('provinciaEstera', this._getForeignProvince(address),'stato');
      this.addressForm.addControlAfter('comuneEstero', this._getForeignCity(address), 'provinciaEstera');
      if (this.addressForm.get('comune')) {
        this.addressForm.removeControl('comune');
      }
      if (this.addressForm.get('provincia')) {
        this.addressForm.removeControl('provincia');
      }
      if (this.addressForm.get('regione')) {
        this.addressForm.removeControl('regione');
      }
    }
  }

  private _getResidenceFormFields(address: Address): {
    [key: string]: BaseInput<any>;
  } {
    return {
      stato: new SelectInput({
        required: true,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        size: '12|12|12|4|4',
        label: 'FIELDS.STATE.LABEL',
        placeholder: 'UTILS.SELECT',
        value: address ? address.stato : null,
        options: of(this.utilityService.getStates()),
        valueChange: (value: any) => {
          if (value) {
            if (value === 'ITALIA') {
              this.addressForm.addControlAfter('regione', this._getRegionField(address, false), 'stato');
              (this.addressForm.get('regione') as SelectInput).setOptions(of(this.utilityService.getRegions()));
              this.addressForm.get('regione').readonly = false;

              this.addressForm.addControlAfter('provincia', this._getProvinceField(address),'regione');
              this.addressForm.get('provincia').readonly = true;
              this.addressForm.addControlAfter('comune', this._getCityField(address), 'provincia');
              this.addressForm.get('comune').readonly = true;

              if (this.addressForm.get('provinciaEstera')) {
                this.addressForm.removeControl('provinciaEstera');
              }
              if (this.addressForm.get('comuneEstero')) {
                this.addressForm.removeControl('comuneEstero');
              }
            } else {
              this.addressForm.addControlAfter('provinciaEstera', this._getForeignProvince(address),'stato');
              this.addressForm.addControlAfter('comuneEstero', this._getForeignCity(address), 'provinciaEstera');
              if (this.addressForm.get('comune')) {
                this.addressForm.removeControl('comune');
              }
              if (this.addressForm.get('provincia')) {
                this.addressForm.removeControl('provincia');
              }
              if (this.addressForm.get('regione')) {
                this.addressForm.removeControl('regione');
              }
            }
          }
        },
      }),
      regione: this._getRegionField(address, address && address.stato ? false : true,),
      provincia: this._getProvinceField(address, address && address.region ? false : true,),
      comune: this._getCityField(address, address && address.provincia ? false : true,),
      indirizzo: new TextInput({
        type: 'text',
        required: true,
        value: address ? address.indirizzo : null,
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        size: '12|12|12|4|4',
        label: 'FIELDS.ADDRESS.LABEL',
        placeholder: 'FIELDS.ADDRESS.PLACEHOLDER',
      }),
      cap: new TextInput({
        type: 'text',
        value: address ? address.cap : null,
        size: '12|12|12|2|2',
        label: 'FIELDS.CAP.LABEL',
        placeholder:
          'FIELDS.CAP.PLACEHOLDER',
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.ERROR.CUSTOM((control) => control.getError('pattern'), {text: this.i18n.instant('VALIDATIONS.CAP')}),
        ],
        pattern: '^[0-9]{5}',
      }),
    };
  }

  private _getRegionField(address: Address, readonly = false): SelectInput {
    return new SelectInput({
      required: true,
      readonly: readonly,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      size: '12|12|12|4|4',
      label: 'FIELDS.REGION.LABEL',
      placeholder: 'UTILS.SELECT',
      value: address ? address.region : null,
      options: address ? of(this.utilityService.getRegions()) : of([]),
      valueChange: (value: any) => {
        let provinciaField = this.addressForm.get('provincia');
        if (!provinciaField)
          return
        if (value) {
          (provinciaField as SelectInput).setOptions(of(this.utilityService.getProvinces(value)));
          provinciaField.readonly = false;
        } else {
          provinciaField.readonly = true;
        }
      },
    })
  }

  private _getProvinceField(address: Address, readonly = false): SelectInput {
    return new SelectInput({
      required: true,
      readonly: readonly,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      size: '12|12|12|4|4',
      label: 'FIELDS.PROVINCE.LABEL',
      placeholder: 'UTILS.SELECT',
      options: address ? of(this.utilityService.getProvinces(address.region)) : of([]),
      value: address ? address.provincia : null,
      valueChange: (value: any) => {
        let comuneField = this.addressForm.get('comune');
        if (!comuneField)
          return
        if (value) {
          (comuneField as SelectInput).setOptions(of(this.utilityService.getCities(value)));
          comuneField.readonly = false;
        } else  {
          comuneField.readonly = true;
        }
      },
    })
  }

  private _getCityField(address: Address, readonly = false) : SelectInput{
    return new SelectInput({
      required: true,
      readonly:readonly,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      size: '12|12|12|4|4',
      label: 'FIELDS.CITY.LABEL',
      placeholder: 'UTILS.SELECT',
      value: address ? address.comune : null,
      options: address ? of(this.utilityService.getCities(address.provincia)) : of([]),
    })
  }

  private _getForeignProvince(address: Address) : TextInput{
    return new TextInput({
      type: 'text',
      size: '12|12|12|4|4',
      required: false,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      label: 'FIELDS.FOREIGN_PROVINCE.LABEL',
      placeholder: 'FIELDS.FOREIGN_PROVINCE.PLACEHOLDER',
      value: address?.provinciaEstera,
    })
  }

  private _getForeignCity(address: Address) : TextInput{
    return new TextInput({
      type: 'text',
      size: '12|12|12|4|4',
      required: false,
      validationStatus: [ValidationStatus.ERROR.REQUIRED],
      label: 'FIELDS.FOREIGN_CITY.LABEL',
      placeholder: 'FIELDS.FOREIGN_CITY.PLACEHOLDER',
      value: address?.comuneEstero,
    })
  }

  mapIntoAddressRequest(): AddressRequest{
    let values = this.addressForm.value;
    return {
      stato: values.stato,
      regione: values.regione,
      provincia: values.provincia,
      comune: values.comune,
      indirizzo: values.indirizzo,
      cap: values.cap,
      comuneEstero: values.comuneEstero,
      provinciaEstera: values.provinciaEstera
    }
  }
}
