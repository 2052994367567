import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { SaveFavoriteResponse } from '../../../../../../../shared/interfaces/company-search-list';
import { Application } from '../interfaces/application';
import { SuggestedJobOffer } from './../interfaces/suggested-job-offer.model';

@Injectable({ providedIn: 'root' })
export class AnnouncementsService {
  private suggestedAnnouncementsSub$ = new BehaviorSubject<SuggestedJobOffer[]>(
    []
  );
  suggestedAnnouncements$ = this.suggestedAnnouncementsSub$.asObservable();
  constructor(private apiClient: ApiClient) {}

  getFavorite() {
    return this.apiClient.request('getFavoriteAnnouncements');
  }

  saveFavoriteAnnouncement(id: number): Observable<SaveFavoriteResponse> {
    return this.apiClient.request('saveWorkerFavorite', null, { id });
  }

  getSentApps(): Observable<Application[]> {
    return this.apiClient.request('getSentApplications');
  }

  getSuggestedAnnouncements(size?: number) {
    return this.apiClient.request<SuggestedJobOffer[]>(
      'getSuggestedJobOffers',
      null,
      size && { size }
    );
  }

  setSuggestedAnnouncements(announcements: SuggestedJobOffer[]) {
    this.suggestedAnnouncementsSub$.next(announcements);
  }
}
