import { Component, Input } from '@angular/core';
import { Announcement } from 'src/app/shared/interfaces/search-announcements-list';

@Component({
  selector: 'app-announcement-search-card',
  templateUrl: './announcement-search-card.component.html',
  styleUrls: ['./announcement-search-card.component.scss'],
})
export class AnnouncementSearchCardComponent {
  constructor() {}

  @Input() announcement: Announcement;
  @Input() status?: string;
  @Input() titleClickEvent: Function;
  @Input() btnClick: Function;

  goToDetail(id: number) {
    this.titleClickEvent(id);
  }

  apply(id: number) {
    this.btnClick(id);
  }
}
