<div class="card card-other" [ngClass]="{'h-100': fullHeight}">
  <div class="card-body">
    <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
      <h5 class="fw-bold">
        {{ getLabel("TITLE") | translate }}
      </h5>
    </div>
    <div class="col py-4">
      <p class="mb-0" *ngIf="disabled">
        {{ getLabel("DISABLED_TEXT") | translate }}
      </p>
      <p class="mb-0" *ngIf="!disabled">
        {{ getLabel("TEXT") | translate }}
      </p>
    </div>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-end">
      <button class="btn btn-green" [disabled]="disabled" (click)="onButtonClick.emit()">
        <tui-svg src="tuiIconDraft"></tui-svg>
        {{ getLabel("BUTTON") | translate }}
      </button>
    </div>
  </div>
</div>