
export interface Interview {
  candidate?: string;
}

export interface InterviewRequest{
  candidateArcaId: number;
  type: 'VIDEO' | 'IN_PERSON',
  at: string,
  taskCode: string;
  location: string;
}

export interface InterviewListItem{
  id: number;
  candidate?: string;
  candidateId?: string;
  clientId?: string;
  taskDescription?: string;
  status?: InterviewStatus;
  lastEditDate?: Date;
  interviewDateTime?: Date;
  link?: string;
  location?: string;
}

export enum InterviewStatus {
  CREATED = "CREATED",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
  COMPLETED = "COMPLETED"
}
