import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { META_INFO } from '../../config/meta.config';

// Used to automatically add meta tags by reading ../../config/meta.config
// It adds title, description, og:title, og:description and og:url automatically
// Will be selected the composed url nearer to actual route

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  
  constructor(private title: Title, private meta: Meta, private router: Router) {}

  updateMeta(route: string){    
    var routeFound = this.searchForRoute(route);
    if(routeFound){
      const {title, description} = META_INFO[routeFound];
      this.updateTitle(title);
      this.updateDescription(description);
      this.updateOgTag("url", window.location.href);
    }
  }

  //Select the route by searching for every url block, it takes the last composed url found in meta config
  searchForRoute(route: string){
    let splittedRoute = route.split("/");
    let routeFound = null;
    if (splittedRoute.length > 2){
      splittedRoute.forEach((routeElement, index, array) => {
        let composedRoute = array.slice(0,index+1).join("/")
        if (composedRoute === "")
          composedRoute = "/";
        if (Object.prototype.hasOwnProperty.call(META_INFO, composedRoute)){         
          routeFound = composedRoute;
        }
      });
    }
    return routeFound;
  }

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
      this.updateOgTag("title", title)
    }
  }

  updateDescription(description: string) {
    if (description) {
      this.meta.updateTag({ name: 'description', property:'og:description', content: description });
    }
  }

  updateOgTag(name: string, value: string) {
    if (value) {
      this.meta.updateTag({ name: name, property: "og:"+name, content: value });
    }
  }
}