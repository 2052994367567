import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { ScrollbarOptions, SwiperComponent } from 'swiper/types';

@Component({
  selector: 'app-how-it-works-landing-b',
  templateUrl: './how-it-works-landing-b.component.html',
  styleUrls: ['./how-it-works-landing-b.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksLandingBComponent {
  @Input() userType: string = 'COMPANY';

  constructor(){
    
  }
  
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
/*
  slideNext(){
    this.swiper.slideNext(100);
  }
  slidePrev(){
    this.swiper.swiperRef.slidePrev(100);
  }
*/

  scrollbarOpt: ScrollbarOptions = {
    /**
el?: CSSSelector | HTMLElement | null;
     */
    
  

    hide: false,
  
    /**
     * Set to `true` to enable make scrollbar draggable that allows you to control slider position
     *
     * @default false
     */
    draggable: true,
  
    /**
     * Set to `true` to snap slider position to slides when you release scrollbar
     *
     * @default false
     */
    snapOnRelease: true,
  
    /**
     * Size of scrollbar draggable element in px
     *    dragSize?: 'auto' | number;
     * @default 'auto'
     */

  
    /**
     * Scrollbar element additional CSS class when it is disabled
     *    lockClass?: string;
     * @default 'swiper-scrollbar-lock'
     */

  
    /**
     * Scrollbar draggable element CSS class
     *    dragClass?: string;
     * @default 'swiper-scrollbar-drag'
     */

  }
}
