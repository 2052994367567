import { Component, Input } from '@angular/core';
import { RadiosInput } from '../../../models/inputs/radios-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-radios-input',
  template: `
    <div *ngIf="!control.inline">
      <tui-radio-labeled
        *ngFor="let option of control.options | async"
        [formControl]="control"
        class="tui-space_bottom-3"
        [item]="option.cod"
      >
        {{ option.des | translate }}
      </tui-radio-labeled>
    </div>
    <div *ngIf="control.inline" tuiGroup class="group">
      <tui-radio-labeled
        *ngFor="let option of control.options | async"
        [formControl]="control"
        class="tui-space_bottom-3"
        [item]="option.cod"
      >
        {{ option.des | translate }}
      </tui-radio-labeled>
    </div>
  `,
})
export class RadiosInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() label = '';
  @Input() control?: RadiosInput;
}
