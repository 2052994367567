export enum MacroDocumentType {
  DOCUMENTI_IDENTITA = 'DOCUMENTI_IDENTITA',
  DOCUMENTI_A_FINI_FISCALI = 'DOCUMENTI_A_FINI_FISCALI',
  DOC_CV = 'DOC_CV',
  PERMESSO_DI_SOGGIORNO = 'PERMESSO_DI_SOGGIORNO',
  ATTESTATI = 'ATTESTATI',
  VIDEOCV = 'VIDEOCV',
  DOC_CONTRATTUALI_GENERICI = "DOC_CONTRATTUALI_GENERICI",
  DOCUMENTI_CONTRATTUALI_SINGOLO_CONTRATTO = "DOCUMENTI_CONTRATTUALI_SINGOLO_CONTRATTO",
  PROFILE = "PROFILE",
}
