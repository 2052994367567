<div class="search-container">
  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()" *ngIf="filterForm">
    <div *ngIf="!publicOnly">
      <div class="upper-filter-container">
        <div class="container">
          <div class="row my-4 mx-3 justify-content-center align-items-center">
            <div class="col-12 col-lg-5 what-col">
              <app-textbox-input
                [control]="filterForm.get('what') | castTextInput"
                [name]="'what'"
              ></app-textbox-input>
            </div>
            <div class="col-12 col-lg-5 where-col">
              <app-textbox-input
                [control]="filterForm.get('where') | castTextInput"
                [name]="'where'"
              ></app-textbox-input>
            </div>
            <div class="col-12 col-lg-2 mb-1">
              <button
                type="submit"
                class="btn btn-green-outline"
                [disabled]="!filterForm.valid"
              >
                <tui-svg src="tuiIconSearch"></tui-svg>
                {{ "PRIVATE.COMPANY.RECENT_SEARCH.FORM_TOP.BUTTON" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="lower-filter-container">
        <span (tuiActiveZoneChange)="onActiveZone($event)" *ngIf="!partenerUuid">
          <button
            type="button"
            iconRight="tuiIconChevronDown"
            [tuiDropdownContent]="dropdownSectorContent"
            [tuiDropdown]="openSector"
            (tuiObscured)="onObscured($event)"
            (click)="onClick('dropdownSectorContent')"
          >
            {{ "SEARCH.FILTER.SECTOR" | translate
            }}<tui-svg class="icon" src="tuiIconChevronDown"></tui-svg>
          </button>
          <ng-template #dropdownSectorContent>
            <div class="dropdown p-2">
              <app-input-label
                [field]="filterForm.get('sector')"
                [name]="'sector'"
                [filter]="false"
              ></app-input-label>
              <app-select-input
                [control]="filterForm.get('sector') | castSelectInput"
              ></app-select-input>
            </div>
          </ng-template>
        </span>
        <span (tuiActiveZoneChange)="onActiveZone($event)">
          <button
            type="button"
            iconRight="tuiIconChevronDown"
            [tuiDropdownContent]="dropdownExpirienceContent"
            [tuiDropdown]="openExpirience"
            (tuiObscured)="onObscured($event)"
            (click)="onClick('dropdownExpirienceContent')"
          >
            {{ "SEARCH.FILTER.REGION" | translate }}
            <tui-svg class="icon" src="tuiIconChevronDown"></tui-svg>
          </button>
          <ng-template #dropdownExpirienceContent>
            <div class="dropdown p-2">
              <app-input-label
                [field]="filterForm.get('region')"
                [name]="'region'"
                [filter]="false"
              ></app-input-label>
              <app-select-input
                [control]="filterForm.get('region') | castSelectInput"
              ></app-select-input>
            </div>
          </ng-template>
        </span>
        <span (tuiActiveZoneChange)="onActiveZone($event)" *ngIf="!partenerUuid">
          <button
            type="button"
            iconRight="tuiIconChevronDown"
            [tuiDropdownContent]="dropdownAvailabilityContent"
            [tuiDropdown]="openAvailability"
            (tuiObscured)="onObscured($event)"
            (click)="onClick('dropdownAvailabilityContent')"
          >
            {{ "SEARCH.FILTER.DISTANCE" | translate
            }}<tui-svg class="icon" src="tuiIconChevronDown"></tui-svg>
          </button>
          <ng-template #dropdownAvailabilityContent>
            <div class="dropdown p-2">
              <app-input-label
                [field]="filterForm.get('distance')"
                [name]="'distance'"
                [filter]="false"
              ></app-input-label>
              ({{ filterForm.get("distance").value }}
              {{ "UTILS.MISURA_DISTANZA" | translate }})
              <app-slider-input
                [control]="filterForm.get('distance') | castSliderInput"
              ></app-slider-input>
            </div>
          </ng-template>
        </span>
      </div>
      <div class="active-filter-container" *ngIf="activeFilter?.length > 0">
        <div class="container row m-auto">
          <div class="col-12 col-lg-9 mb-2">
            <button
              *ngFor="let filter of activeFilter"
              (click)="clearFilter(filter)"
            >
              {{ filter?.label | translate }}:
              <span>{{ filter?.value }}</span>
              <tui-svg class="icon" src="tuiIconCloseLarge"></tui-svg>
            </button>
          </div>
          <div class="col-12 col-lg-3 mb-2 text-center">
            <button class="button clear" (click)="clearAllFilters()">
              {{ "SEARCH.FILTER.CLEAR" | translate
              }}<tui-svg class="icon" src="tuiIconCloseLarge"></tui-svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="search-body-container">
      <div class="container row m-auto">
        <div
          class="col-12 mb-2 left"
          [ngClass]="isPublic ? 'col-lg-12' : 'col-lg-9'"
        >
          <div class="order-container row justify-content-end">
            <div class="col-12 col-sm-8 left m-auto" *ngIf="!publicOnly">
              <span class="records_found"
                >{{ totalElements }}
                {{ "SEARCH.ANNOUNCEMTS_RECORDS_FOUND" | translate }}</span
              >
            </div>
            <div class="col-12 col-sm-4 left" *ngIf="!publicOnly">
              <app-select-input
                *ngIf="!partenerUuid"
                [control]="filterForm.get('orderBy') | castSelectInput"
              ></app-select-input>
            </div>
          </div>
          <div *ngIf="annuncementList">
            <app-announcement-search-card
              *ngFor="let announcement of annuncementList"
              [announcement]="announcement"
              [status]="user ? (user | castWorkerUser).status : null"
              [titleClickEvent]="goToDetail"
              [btnClick]="apply"
            ></app-announcement-search-card>
          </div>
          <div
            class="card card-body"
            *ngIf="!annuncementList || annuncementList.length === 0"
          >
            {{ "SEARCH.WORKER.NO_RESULT" | translate }}
          </div>

          <div class="pager" *ngIf="!isPublic; else publicSearch">
            <tui-pagination
              *ngIf="totalElements > size"
              [index]="pageNumber"
              [length]="getTotalPages()"
              (indexChange)="pagerChange($event)"
            ></tui-pagination>
          </div>

          <ng-template #publicSearch>
            <div
              class="pager"
              (click)="registerOverlay()"
              style="cursor: pointer"
            >
              <tui-pagination
                style="pointer-events: none"
                *ngIf="totalElements > size"
                [index]="pageNumber"
                [length]="getTotalPages()"
              >
              </tui-pagination>
            </div>
          </ng-template>
        </div>
        <div class="col-12 col-lg-3 mb-2 right">
          <div class="card card-body my-2" *ngIf="partenerUuid">
            <div class="carousel-title">
              {{ "SEARCH.WORKER.REGISTRA.RED_BOX.PARTENER_TITLE" | translate }}
            </div>
            <div class="text-center">
              <img
                alt=""
                [src]="currentPartener?.linkImg"
                class="partener-logo"
              />
            </div>
            <div class="text text-center px-2 mt-2">
              <p>
                {{ currentPartener?.brandName }}
              </p>
            </div>
            <button
              (click)="resetPartenerSearch()"
              class="btn btn-outline-primary partenr-button"
            >
              {{ "SEARCH.WORKER.REGISTRA.RED_BOX.PARTENER_BUTTON" | translate }}
            </button>
          </div>

          <!--div class="card card-body mb-2" *ngIf="!partenerUuid">
            <div class="carousel-title">
              {{
                "SEARCH.WORKER.REGISTRA.RED_BOX.PARTENER_CAROUSEL_TITLE"
                  | translate
              }}
            </div>
            <div tuiCarouselButtons class="wrapper">
              <button
                tuiIconButton
                icon="tuiIconChevronLeftLarge"
                title="Previous"
                type="button"
                class="carousel-btn"
                (click)="carousel.prev()"
              ></button>
              <tui-carousel
                #carousel
                [draggable]="true"
                [itemsCount]="1"
                [(index)]="index"
                [duration]="5000"
                class="carousel-container"
              >
                <ng-container *ngFor="let part of parteners; let i = index">
                  <img
                    *tuiItem
                    alt=""
                    draggable="false"
                    [src]="part.linkImg"
                    class="partener-logo"
                    [class.item_active]="i === index + 1"
                    (click)="searchPartenes(part.uuid)"
                  />
                </ng-container>
              </tui-carousel>
              <button
                tuiIconButton
                type="button"
                icon="tuiIconChevronRightLarge"
                class="carousel-btn"
                title="Next"
                (click)="carousel.next()"
              ></button>
            </div>
          </div-->

          <!-- OLD SUGGESTED LIST -->
          <!-- <div
            *ngIf="
              !isPublic &&
              user &&
              (user | castWorkerUser)?.status === WorkerStatus.ELIGIBLE
            "
            class="card card-body suggested-anns"
          >
            <div class="title" style="font-size: 18px">
              {{ "SEARCH.WORKER.JOB_SUGGESTIONS.TITLE" | translate }}
            </div>
            <div
              class="body"
              *ngIf="suggestedSearchList?.length > 0; else emptyList"
            >
              <div *ngFor="let item of suggestedSearchList">
                <button class="button" (click)="goToSuggestedDetail(item.id)">
                  {{ item.title }}
                  <tui-svg class="icon" src="tuiIconArrowRight"></tui-svg>
                </button>
              </div>
            </div>
          </div> -->

          <!-- <ng-template #emptyList>
            <div class="body row">
              <div class="col-auto px-0">
                <tui-svg
                  class="icon"
                  style="float: left"
                  src="tuiIconSearch"
                ></tui-svg>
              </div>
              <div class="col">
                {{ "SEARCH.WORKER.JOB_SUGGESTIONS.NO_RESULT" | translate }}
              </div>
            </div>
          </ng-template> -->

          <div
            class="card card-body red-body"
            *ngIf="
              !isPublic &&
              user &&
              (user | castWorkerUser).status !== WorkerStatus.ELIGIBLE &&
              (user | castWorkerUser).status !== WorkerStatus.CONTRACTUALIZABLE
            "
          >
            <div class="title">
              {{ "SEARCH.WORKER.COMPLETA_PROFILO.RED_BOX.TITLE" | translate }}
            </div>
            <div class="text px-2">
              <p>
                {{ "SEARCH.WORKER.COMPLETA_PROFILO.RED_BOX.TEXT" | translate }}
              </p>
              <p>
                {{
                  "SEARCH.WORKER.COMPLETA_PROFILO.RED_BOX.TEXT_2" | translate
                }}
              </p>
              <p style="text-align: left; float: left">
                <tui-svg class="icon" src="tuiIconCheck"></tui-svg>
                {{ "SEARCH.WORKER.COMPLETA_PROFILO.RED_BOX.REQ" | translate }}
              </p>
              <p style="text-align: left; float: left">
                <tui-svg class="icon" src="tuiIconCheck"></tui-svg>
                {{ "SEARCH.WORKER.COMPLETA_PROFILO.RED_BOX.REQ_2" | translate }}
              </p>
            </div>
            <button (click)="toComplete()" class="btn btn-outline-primary">
              {{ "SEARCH.COMPLETE_PROFILE.RED_BOX.BUTTON" | translate }}
            </button>
          </div>

          <!-- NEW SUGGESTED LIST -->
          <div *ngIf="suggestedList?.length" class="my-2">
            <h6>{{ "PRIVATE.WORKER.ANNOUNCEMENTS.SUGGESTED" | translate }}</h6>
            <div
              class="card card-body suggested-card d-flex flex-column justify-content-center align-items-center mt-3"
              *ngFor="let announcement of suggestedList | slice : 0 : 6"
              (click)="goToDetail(announcement.id)"
            >
              <div class="title">{{ announcement?.title | titlecase }}</div>
              <div class="sector">
                <div class="label">
                  {{ ("SEARCH.INFO.SECTOR" | translate) + ": " }}
                  <span class="info">
                    {{
                      "MAPPINGS.sectors." +
                        (announcement?.sector ? announcement.sector : "ALTRO")
                        | translate
                    }}
                  </span>
                </div>
              </div>
            </div>
            <button
              class="btn btn-green btn-suggested mx-auto"
              *ngIf="suggestedList?.length > 6"
              [routerLink]="['/private/candidato/annunci/suggeriti']"
            >
              {{
                "PRIVATE.WORKER.ANNOUNCEMENTS.BTN_TO_SUGGESTED" | translate
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
