import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';

@Pipe({ name: 'castTagInput' })
export class CastTagInputPipe implements PipeTransform {
  transform<S>(value: S): TagInput {
      return value as unknown as TagInput;
  }
}

export interface TagInputConstructor<T = string>
  extends BaseInputConstructor<T> {
  tagValidator?: (tag) => boolean;
}

export class TagInput<T = string> extends BaseInput<T> {
  inputType = 'tag';
  tagValidator: (tag) => boolean = () => true;

  constructor(options: TagInputConstructor<T>) {
    super(options);
    if (options.tagValidator) {
      this.tagValidator = options.tagValidator;
    }
  }
}
