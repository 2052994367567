import { Component, Input, OnInit } from '@angular/core';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { Candidate } from 'src/app/shared/interfaces/company-search-list';

@Component({
  selector: 'app-worker-card',
  templateUrl: './worker-card.component.html',
  styleUrls: ['./worker-card.component.scss'],
})
export class WorkerCardComponent implements OnInit {
  @Input() isPremium: boolean = false;
  @Input() userType?: UserType;
  @Input() worker!: Candidate;
  @Input() favourite: boolean = false;

  get UserType() {
    return UserType;
  }

  constructor() { }

  ngOnInit(): void {
    // console.log('WorkerCardComponent', this.worker);
  }

  getDate(date: string | number): string {
    if (!date) return '---';
    return new Date(date).toLocaleString('it', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  }

  getName(): string {
    return (
      this.worker.firstName + ' ' + this.worker.familyName?.charAt(0) + '.'
    );
  }

}
