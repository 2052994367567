import { Component, Input } from '@angular/core';
import { RoleType } from 'src/app/core/auth/interfaces/role.model';
import { WorkState } from 'src/app/pages/private/pages/worker/pages/documents/pages/work/enum/work-state';

@Component({
  selector: 'app-work-card',
  templateUrl: './work-card.component.html',
  styleUrls: ['./work-card.component.scss'],
})
export class WorkCardComponent {
  constructor() { }

  @Input() title: string;
  @Input() status: string;
  @Input() id: string|number;
  @Input() task: string;
  @Input() place: string;
  @Input() deadline: number;
  @Input() period: string;
  @Input() btn1Label: string;
  @Input() btn1ClickEvent: Function;
  @Input() btn2Label: string;
  @Input() btn2ClickEvent: Function;
  @Input() btn3Label: string;
  @Input() btn3ClickEvent: Function;
  @Input() btn4Label: string;
  @Input() btn4ClickEvent: Function;
  @Input() work;
  @Input() userRole: RoleType;

  get workStateEnum() {
    return WorkState;
  }

  get roleTypeEnum() {
    return RoleType;
  }

  pdfClick(work) {
    this.btn1ClickEvent(work);
  }

  middleClick(work) {
    this.btn2ClickEvent(work);
  }

  timeSheetClick(work) {
    this.btn3ClickEvent(work);
  }

  ratingClick(work) {
    this.btn4ClickEvent(work);
  }
}
