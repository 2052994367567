import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { of } from 'rxjs';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { UtilityService } from 'src/app/core/utility.service';
import {
  Form,
  SelectInput,
  SelectOption,
  TextInput,
} from 'src/app/shared/form';
import { SearchAnnouncementsFiltersResponse } from 'src/app/shared/interfaces/search-filters';
import { SearchAnnouncementsFilterService } from 'src/app/shared/services/search-announcements-filter.service';
import { SearchCandidatesFilterService } from 'src/app/shared/services/search-candidates-filter.service';

@Component({
  selector: 'app-search-campaign',
  templateUrl: './search-campaign.component.html',
  styleUrls: ['./search-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchCampaignComponent {
  constructor(
    private utilityService: UtilityService,
    private router: Router,
    private searchCandidatesFilterService: SearchCandidatesFilterService,
    private searchAnnouncementsFilterService: SearchAnnouncementsFilterService
  ) {}

  @Input() userType: UserType;
  @Input() isPublic: boolean = true;
  hidden: boolean = true;
  UserType = UserType;
  searchForm: Form;
  filter: SearchAnnouncementsFiltersResponse;
  cities = []
  
  distances: SelectOption<number, string>[] = [
    { cod: 5, des: '5 km' },
    { cod: 25, des: '25 km' },
    { cod: 50, des: '50 km' },
    { cod: 100, des: '100 km' },
  ];

  ngOnInit() {
    this.cities = this.utilityService.getCitiesList();
    this._setForm();
    this.searchForm.get('distance').disable();
  }

  _setForm() {
    this.searchForm = new Form({
      header: {
        show: false,
      },
      controls: {
        what: new TextInput({
          type: 'text',
          size: '12|12|12|6|6',
          label:
            this.userType == UserType.COMPANY
              ? 'LANDING_PAGE.COMPANY.SEARCH.KEYWORD.LABEL'
              : 'LANDING_PAGE.WORKER.SEARCH.KEYWORD.LABEL',
          placeholder: 'LANDING_PAGE.COMPANY.SEARCH.KEYWORD.PLACEHOLDER',
          pattern: /.*\S.*/,
        }),
        where: new SelectInput<string, string>({
          label: 'LANDING_PAGE.COMPANY.SEARCH.WHERE.LABEL',
          placeholder: 'LANDING_PAGE.COMPANY.SEARCH.WHERE.PLACEHOLDER',
          options: of(this.cities),
          virtualScroll: true,
          bindValue: false,
          valueChange: (value: any) => {
            if (value.length || value?.cod) {
              this.distanceControl.enable();
              this.distanceControl.setValue(25, { emitEvent: false });
              this.whereControl.setValue(value, { emitEvent: false });
            } else {
              this.distanceControl.disable();
            }
          },
        }),
        distance: new SelectInput<number, string>({
          label: 'LANDING_PAGE.COMPANY.SEARCH.DISTANCE.LABEL',
          placeholder: 'LANDING_PAGE.COMPANY.SEARCH.DISTANCE.PLACEHOLDER',
          options: of(this.distances),
          bindValue: false,
          valueChange: (value: any) => {
            if (value?.cod) {
              this.distanceControl.setValue(value, { emitEvent: false });
            }
          },
        }),
      },
    });
  }

  search() {
    this.searchForm.markAllAsTouched();
    if (this.userType == UserType.COMPANY) {
      if (
        !(
          this.whatControl.value ||
          (this.whereControl.value && this.distanceControl.value)
        )
      )
        return;
    }
    if (this.isPublic) {
      var url =
        this.userType == UserType.WORKER
          ? '/candidato/cerca-annunci'
          : '/azienda/cerca-candidati';
    } else {
      var url =
        this.userType == UserType.WORKER
          ? 'private/candidato/cerca-annunci/search'
          : 'private/azienda/cerca-candidati/search';
    }
    var params: Params = {};
    if (this.whatControl.value?.length > 0) {
      params['what'] = this.whatControl.value;
    }
    if (this.whereControl.value?.length > 0 || this.whereControl.value) {
      params['where'] = this.whereControl.value;
    }
    if (this.distanceControl.value) {
      params['distance'] = (this.distanceControl.value as SelectOption)?.cod;
    }
    if (this.userType == UserType.COMPANY) {
      this.searchCandidatesFilterService.setFilterList({
        ...this.searchForm.value,
        distance: (this.distanceControl.value as SelectOption)?.cod,
      });
    } else if (this.userType == UserType.WORKER) {
      this.searchAnnouncementsFilterService.setFilterList({
        ...this.searchForm.value,
        distance: (this.distanceControl.value as SelectOption)?.cod,
      });
    }
    this.router.navigate([url]);
  }

  get whatControl() {
    return this.searchForm.get('what');
  }

  get whereControl() {
    return this.searchForm.get('where');
  }

  get distanceControl() {
    return this.searchForm.get('distance');
  }
}
