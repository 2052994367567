<div
  class="container-notifications"
  [ngClass]="{
    worker: userType == UserTypeEnum.WORKER,
    company: userType == UserTypeEnum.COMPANY
  }"
>
  <div class="">
    <div class="nav nav-tabs mb-2" id="myTab" role="tablist">
      <a
        class="nav-item nav-link pt-1 active"
        id="notif-tab"
        data-bs-toggle="tab"
        data-bs-target="#notif"
        type="button"
        role="tab"
        aria-controls="notif"
        aria-selected="true"
      >
        {{ "PRIVATE.COMPANY.NOTIFICATIONS.TITLE" | translate | uppercase }}
        <span *ngIf="notificationsNumber" class="number">{{
          notificationsNumber
        }}</span>
      </a>
      <a
        class="nav-item nav-link pt-1"
        id="messages-tab"
        data-bs-toggle="tab"
        data-bs-target="#messages"
        type="button"
        role="tab"
        aria-controls="messages"
        aria-selected="false"
      >
        {{ "PRIVATE.COMPANY.MESSAGES.TITLE" | translate | uppercase }}
        <span *ngIf="messagesNumber" class="number">{{ messagesNumber }}</span>
      </a>
    </div>

    <div class="tab-content">
      <div
        class="tab-pane active px-2"
        id="notif"
        role="tabpanel"
        aria-labelledby="notif-tab"
      >
        <ng-container *ngFor="let notif of newNotifications">
          <div
            class="border-bottom p-2 cursor-pointer"
            (click)="updateNotifStatus(notif.uuid)"
            [routerLink]="getPath(notif.iconType)"
          >
            <app-mini-notification
              class="row"
              [notification]="notif"
            ></app-mini-notification></div
        ></ng-container>
        <div class="row p-2 mt-2 justify-content-center fs-6">
          <button (click)="goToNotifications()" class="btn btn-green-outline">
            {{ "PRIVATE.COMPANY.NOTIFICATIONS.BUTTON" | translate }}
          </button>
        </div>
      </div>
      <div
        class="tab-pane px-2"
        id="messages"
        role="tabpanel"
        aria-labelledby="messages-tab"
      >
        <!-- <ng-container *ngFor="let not of oldNotifications">
          <div
            class="border-bottom p-2 cursor-pointer"
            [routerLink]="getPath(not.iconType)"
          >
            <app-mini-notification
              class="row"
              [notification]="not"
            ></app-mini-notification></div
        ></ng-container> -->
        <div class="row p-2 mt-2 justify-content-center fs-6">
          <button (click)="goToMessages()" class="btn btn-green-outline">
            {{ "PRIVATE.COMPANY.NOTIFICATIONS.BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
