export enum DocumentStatus {
  TO_PROCESS = 'TO_PROCESS',
  PROCESSING = 'PROCESSING',
  CERTIFYING = 'CERTIFYING',
  CERTIFIED = 'CERTIFIED',
  DOC_UPLOAD_FAILED = 'DOC_UPLOAD_FAILED',
  CERT_FAILED = 'CERT_FAILED',
  DOC_UPLOAD_SUCCESS = 'DOC_UPLOAD_SUCCESS',
  DOC_UPLOAD_SUCCESS_FINAL = 'DOC_UPLOAD_SUCCESS_FINAL',
  DOC_REVOKED = 'DOC_REVOKED',
}

export enum DocContrattualeStatoInserimentoType {
  INSERITO = "INSERITO",
  NON_INSERITO =" NON_INSERITO",
  IN_CARICAMENTO = "IN_CARICAMENTO",
  CARICAMENTO_FALLITO = "CARICAMENTO_FALLITO",
}
