export enum RoleType {
  COMPANY = 'ROLE_CLIENT',
  WORKER = 'ROLE_WORKER',
  DELEGATE = 'ROLE_DELEGATE',
}
export enum UserType {
  COMPANY = 'azienda',
  WORKER = 'candidato',
  DELEGATE = 'delegato',
}

const r2u = {
  [RoleType.COMPANY]: UserType.COMPANY,
  [RoleType.WORKER]: UserType.WORKER,
  [RoleType.DELEGATE]: UserType.DELEGATE,
}
const u2r = {
  [UserType.COMPANY]: RoleType.COMPANY,
  [UserType.WORKER]: RoleType.WORKER,
  [UserType.DELEGATE]: RoleType.DELEGATE,
}

const r2r = {
  [RoleType.COMPANY]: UserType.COMPANY,
  [RoleType.DELEGATE]: UserType.COMPANY,
  [RoleType.WORKER]: UserType.WORKER,
}

export function role2route(roleType: RoleType) {
  return r2u[roleType];
}

export function role2loginRoute(roleType: RoleType) {
  if (!roleType) {
    return r2r[RoleType.COMPANY];
  }
  return r2r[roleType];

}

export function user2role(userType: UserType) {
  return u2r[userType];
}
