import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeIt from '@angular/common/locales/it';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { AppConfig } from './app.config';
import { registerLocaleData } from '@angular/common';
import { ThemeModule } from './theme/theme.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { SharedModule } from './shared/shared.module';
// Firebase services + environment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { UrlService } from './shared/url/url.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PixelModule } from 'ngx-pixel';
import { PrivacyComponent } from './pages/privacy/privacy.component';

registerLocaleData(localeIt);

const TAIGA_MODULES = [TuiRootModule, TuiDialogModule];
const FIREBASE_MODULES = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAuthModule,
  AngularFirestoreModule,
  AngularFireStorageModule,
  AngularFireDatabaseModule,
];

export function httpLoaderFactory(http: HttpClient) {
  const staticL10n = new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
  const dynamicL10n = new TranslateHttpLoader(http, `${environment.baseUrl}/api/l10n/`, '/');
  return {
      getTranslation(lang: string): Observable<any> {
        const o1 = staticL10n.getTranslation(lang);
        const o2 = dynamicL10n.getTranslation(lang).pipe(catchError(() => of({})));
        return combineLatest([o1, o2]).pipe(map(([o3, o4]) => Object.assign({}, o3, o4)));
      }
  };
}

export const AppModule = (appConfig: typeof AppConfig) => {
  @NgModule({
    declarations: [AppComponent, PrivacyComponent],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      NgxSpinnerModule,
      ...TAIGA_MODULES,
      ...FIREBASE_MODULES,
      ToastrModule.forRoot({
        timeOut: 3000,
        closeButton: true,
      }),
      ThemeModule.forRoot(),
      CoreModule.forRoot(appConfig),
      AppRoutingModule,
      NgbModule,
      SharedModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (httpLoaderFactory),
            deps: [HttpClient]
        },
      }),
      NgxGoogleAnalyticsModule.forRoot(environment.googleTrackingCode, null, "https://googletagmanager.com/gtm.js?id=" + environment.googleTrackingCode),
      NgxGoogleAnalyticsRouterModule,
      PixelModule.forRoot({ enabled: environment.pixelTrackingEnabled, pixelId: environment.pixelTrackingCode })
    ],
    bootstrap: [AppComponent],
    providers: [UrlService],
  })
  class Module {}
  return Module;
};
