<ng-container *ngIf="dataSource.rows$ | async as rows">
  <ng-container *ngIf="rows.length; else noItems">
    <ng-container *ngFor="let row of rows; let index = index">
      <tr [id]="rowId ? row?.uuid : ''">
        <!-- TODO: richiamare un metodo sul template non è performante spostare la logica in una direttiva o su un pipe -->
        <td
          *ngFor="let column of columns"
          [class]="getClass(row, column)"
          [style.width.%]="column.width"
          (click)="!column.rowDetailIcon && !column.useConditionalBtnTemplateFunction && onRowClick(row)"
        >
          <app-checkbox-input
            *ngIf="column.checkboxable"
            name="rowCheckbox-{{ tableId }}-{{ index }}"
            [checked]="checked(row)"
            [disabled]="disabledCheck(row)"
            (click)="$event.stopPropagation()"
            (change)="
              $event.stopPropagation();
              checkboxToggle.emit({ row: row, event: $event })
            "
          ></app-checkbox-input>

          <!-- TODO: richiamare un metodo sul template non è performante spostare la logica in una direttiva o su un pipe -->
          <span>
            <ng-container
              *ngIf="column.cellTemplate; else valueContent"
              [ngTemplateOutlet]="column.cellTemplate"
              [ngTemplateOutletContext]="{
                row: row,
                index: index
              }"
            ></ng-container>

            <ng-template #valueContent>{{ getValue(row, column) }}</ng-template>
          </span>
          <a
            *ngIf="column.rowDetailIcon"
            [attr.data-bs-toggle]="column.rowDetailIcon ? 'collapse' : null"
            [attr.href]="
              column.rowDetailIcon ? getAccordionDataTarget(index) : null
            "
            [attr.aria-expanded]="column.rowDetailIcon ? false : null"
            class="button-detail-toggle float-right" [ngClass]="{'btn btn-green white-color': column.buttonToggleIcon}"
            (click)="buttonToggleClick(row, $event.target)"
          >
            {{ column.buttonToggleName ? (column.buttonToggleName | translate) : '' }}
            <tui-svg *ngIf="column.buttonToggleIcon" src="tuiIconChevronRightLarge"></tui-svg>
          </a>
          <span *ngIf="column.useConditionalBtnTemplateFunction">
            <a
            *ngIf="column.conditionalBtnTemplateFunction(row)"
            [attr.data-bs-toggle]="column.conditionalBtnTemplateFunction(row) ? 'collapse' : null"
            [attr.href]="
              column.conditionalBtnTemplateFunction(row) ? getAccordionDataTarget(index) : null
            "
            [attr.aria-expanded]="column.conditionalBtnTemplateFunction(row) ? false : null"
            class="button-detail-toggle float-right" [ngClass]="{'btn btn-green white-color': column.buttonToggleIcon}"
            (click)="buttonToggleClick(row, $event.target)"
          >
            {{ column.buttonToggleName ? (column.buttonToggleName | translate) : '' }}
            <tui-svg *ngIf="column.buttonToggleIcon" src="tuiIconChevronRightLarge"></tui-svg>
          </a>
          </span>
        </td>
      </tr>

      <tr *ngIf="rowDetail">
        <td
          [attr.colspan]="columns.length + 2"
          class="hiddenRow hide-bottom-border"
        >
          <div
            [id]="getAccordionId(index)"
            class="collapse"
            [class.collapse-show-border]="rowDetailBorder"
            [attr.data-id]="index"
          >
            <ng-container
              *ngTemplateOutlet="
                rowDetail;
                context: {
                  $implicit: 1,
                  row: row
                }
              "
            ></ng-container>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-container>

  <ng-template #noItems>
    <tr>
      <td class="text-center" [attr.colspan]="columns?.length">
        <span class="font-weight-bold">{{ emptyMessage | translate }} </span>
      </td>
    </tr>
  </ng-template>
</ng-container>
