<div class="document-section-desktop">
  <table class="table table-responsive">
    <thead>
      <tr>
        <th scope="col">
          {{
            "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.TIPO_DOCUMENTO"
              | translate
          }}
        </th>
        <ng-container
          *ngIf="
            macroDocType !== MacroDocumentType.DOC_CV &&
            macroDocType !== MacroDocumentType.VIDEOCV
          "
        >
          <th scope="col" *ngIf="workerDocument?.type !== 'CODICE_FISCALE'">
            {{
              "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NUMERO_DOCUMENTO"
                | translate
            }}
          </th>
          <th scope="col" *ngIf="workerDocument?.type !== 'CODICE_FISCALE'">
            {{
              "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SCADENZA"
                | translate
            }}
          </th>
          <td *ngIf="macroDocType === MacroDocumentType.PERMESSO_DI_SOGGIORNO" >
            {{
                ("PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.RICHIESTA_RINNOVO" ) | translate
            }}
            </td>
        </ng-container>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-{{
          workerDocument?.status || documentStatus.TO_PROCESS
        }}"
      >
        <td
          class="type-document"
          *ngIf="macroDocType === MacroDocumentType.DOC_CV"
        >
          {{
            "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TEXT" | translate
          }}
        </td>
        <td
          class="type-document"
          *ngIf="macroDocType === MacroDocumentType.VIDEOCV"
        >
          {{
            "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_VIDEO"
              | translate
          }}
        </td>
        <td *ngIf="macroDocType == MacroDocumentType.PERMESSO_DI_SOGGIORNO">
          {{ "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PERMESSO_DI_SOGGIORNO" | translate }}
        </td>

        <ng-container
          *ngIf="
            macroDocType !== MacroDocumentType.DOC_CV &&
            macroDocType !== MacroDocumentType.VIDEOCV
          "
        >
          <td
            class="type-document"
            *ngIf="macroDocType !== MacroDocumentType.PERMESSO_DI_SOGGIORNO"
          >
            {{
              (workerDocument?.type ? ("PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS." + workerDocument?.type | translate) : "---")
            }}
          </td>
          <td>
            {{
                workerDocument?.type !== 'CODICE_FISCALE' ? (workerDocument?.number || "---") : null
            }}
          </td>
          <td>
            {{
                workerDocument?.type !== 'CODICE_FISCALE' ? ((workerDocument?.expirationDate | date : "dd/MM/yyyy") || "---") : null
            }}
          </td>
          <td *ngIf="macroDocType === MacroDocumentType.PERMESSO_DI_SOGGIORNO" >
          {{
              ("COMMON."+(workerDocument?.renewal ? 'SI' : 'NO' ) | translate)  || "---"
          }}
          </td>
        </ng-container>
        <td class="d-flex justify-content-end me-3">
          <button
            (click)="refresh()"
            [disabled]="disabled"
            class="me-2 btn btn-icon btn-green-outline"
          >
            <tui-svg src="tuiIconRefreshLarge"></tui-svg>
          </button>
          <ng-container *ngIf="macroDocType !== MacroDocumentType.VIDEOCV">
            <button
              (click)="downloadDocument(macroDocType, docType)"
              class="me-2 btn btn-icon btn-green-outline"
              [disabled]="
                disabled ||
                (!( workerDocument?.status | getDownloadableDocumentStatus ) && ( macroDocType === MacroDocumentType.DOC_CV && !hasCV))
              "
            >
              <tui-svg src="tuiIconDownload"></tui-svg>
            </button>

            <button
              class="btn btn-icon btn-green-outline"
              [disabled]="
                disabled ||
                !(
                    workerDocument?.status | getEditableDocumentStatus
                )
              "
              (click)="onShowDocsModal(macroDocType, docType)"
            >
              <tui-svg src="tuiIconDraft"></tui-svg>
            </button>
          </ng-container>
          <ng-container *ngIf="macroDocType === MacroDocumentType.VIDEOCV">
            <button
              (click)="onShowCVVideo()"
              class="me-2 btn btn-icon btn-green-outline"
              [disabled]="
                disabled ||
                !(
                    workerDocument?.status | getEditableDocumentStatus
                )
              "
            >
              <tui-svg src="tuiIconEyeOpen"></tui-svg>
            </button>
            <button
              (click)="onShowDocsModal(macroDocType, docType)"
              class="me-2 btn btn-icon btn-green-outline"
              [disabled]="
                disabled ||
                !(
                    workerDocument?.status | getEditableDocumentStatus
                )
              "
            >
              <tui-svg src="tuiIconDraft"></tui-svg>
            </button>
            <button
              (click)="onShowRecordCVVideoModal()"
              class="btn btn-icon btn-green-outline"
              [disabled]="
                disabled ||
                !(

                    workerDocument?.status | getEditableDocumentStatus
                )
              "
            >
              <tui-svg src="tuiIconVideo"></tui-svg>
            </button>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <p *ngIf="(macroDocType === MacroDocumentType.DOC_CV && !hasCV) || (macroDocType === MacroDocumentType.VIDEOCV  && !hasVideoCV)"
  class="{{documentStatus.TO_PROCESS}}"
>
  {{
    "PRIVATE.WORKER.USER_DETAIL.PERSONAL_DOCUMENT.STATUS.DOC_NOT_LOADED" | translate
  }}
</p>


  <p
    *ngIf="
      workerDocument?.status !== null
    "
    class="{{
      workerDocument?.status || documentStatus.TO_PROCESS
    }}"
  >
    {{
      "PRIVATE.WORKER.USER_DETAIL.PERSONAL_DOCUMENT.STATUS." +
        workerDocument?.status | translate
    }}
  </p>
</div>

<div class="document-section-mobile mt-3">
  <div
    class="border-{{
      workerDocument?.status || documentStatus.TO_PROCESS
    }} p-3"
  >
    <div
      class="type-document"
      *ngIf="macroDocType === MacroDocumentType.DOC_CV"
    >
      {{ "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_TEXT" | translate }}
    </div>
    <div
      class="type-document"
      *ngIf="macroDocType === MacroDocumentType.VIDEOCV"
    >
      {{
        "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_VIDEO" | translate
      }}
    </div>
    <ng-container
      *ngIf="
        macroDocType !== MacroDocumentType.DOC_CV &&
        macroDocType !== MacroDocumentType.VIDEOCV
      "
    >
      <div class="type-document d-flex flex-row flex-wrap">
        {{
          (workerDocument?.type ? ("PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS." + workerDocument?.type | translate) : "---")
        }}
      </div>
      <div>
        <b>{{
          "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.NUMERO_DOCUMENTO"
            | translate
        }}</b>
        {{
          workerDocument?.number || "---"
        }}
      </div>
      <div>
        <b>{{
          "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SCADENZA" | translate
        }}</b>
        {{
          (workerDocument?.expirationDate | date : "dd/MM/yyyy") || "---"
        }}
      </div>
    </ng-container>
    <div class="d-flex justify-content-end">
      <button
        (click)="refresh()"
        [disabled]="disabled"
        class="me-2 btn btn-icon btn-green-outline"
      >
        <tui-svg src="tuiIconRefreshLarge"></tui-svg>
      </button>
      <ng-container *ngIf="macroDocType !== MacroDocumentType.VIDEOCV">
        <button
          (click)="downloadDocument(macroDocType, docType)"
          class="me-2 btn btn-icon btn-green-outline"
          [disabled]="
            disabled
             || !( workerDocument?.status | getDownloadableDocumentStatus )
          "
        >
          <tui-svg src="tuiIconDownload"></tui-svg>
        </button>
        <button
          (click)="onShowDocsModal(macroDocType, docType)"
          class="btn btn-icon btn-green-outline"
          [disabled]="
            disabled ||
            !(
                workerDocument?.status | getEditableDocumentStatus
            )
          "
        >
          <tui-svg src="tuiIconDraft"></tui-svg>
        </button>
      </ng-container>
      <ng-container *ngIf="macroDocType === MacroDocumentType.VIDEOCV">
        <button
          (click)="onShowCVVideo()"
          class="me-2 btn btn-icon btn-green-outline"
          [disabled]="
            disabled ||
            !(
                workerDocument?.status | getEditableDocumentStatus
            )
          "
        >
          <tui-svg src="tuiIconEyeOpen"></tui-svg>
        </button>
        <button
          (click)="onShowDocsModal(macroDocType, docType)"
          class="me-2 btn btn-icon btn-green-outline"
          [disabled]="
            disabled ||
            !(
                workerDocument?.status | getEditableDocumentStatus
            )
          "
        >
          <tui-svg src="tuiIconDraft"></tui-svg>
        </button>
        <button
          (click)="onShowRecordCVVideoModal()"
          class="btn btn-icon btn-green-outline"
          [disabled]="
            disabled ||
            !(
                workerDocument?.status | getEditableDocumentStatus
            )
          "
        >
          <tui-svg src="tuiIconVideo"></tui-svg>
        </button>
      </ng-container>
    </div>
  </div>
  <p
    *ngIf="
      workerDocument?.status !== null
    "
    class="{{
      workerDocument?.status || documentStatus.TO_PROCESS
    }}"
  >
    {{
      ("PRIVATE.WORKER.USER_DETAIL.PERSONAL_DOCUMENT.STATUS." +
        workerDocument?.status) | translate
    }}
  </p>
</div>

<ng-template let-observer #modalAddDocument>
  <app-form [form]="formDocument"></app-form>
  <ng-container *ngIf="showFormRenewDocument">
    <h2 class="t-heading">{{'PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.SOLO_EXTRACOMUNITARI.FORM.TITLE_RICHIESTA_RINNOVO' | translate}}</h2>
    <app-form *ngIf="formRenewDocument" [form]="formRenewDocument"></app-form>
  </ng-container>
  
  <!-- TAKE PIC -->
  <div class="take-picture">
    <button
      class="btn btn-green mb-3 mx-auto"
      (click)="onEditPhoto()"
      *ngIf="
        formDocument.get('fileRetroActive') &&
        !formDocument.get('fileRetroActive').value
      "
    >
      {{ "UTILS.TAKE_PHOTO" | translate }}
    </button>
    <div
      class="d-flex gap-3 justify-content-center mb-3"
      *ngIf="
        formDocument.get('fileRetroActive') &&
        formDocument.get('fileRetroActive').value
      "
    >
      <button class="btn btn-green" (click)="onEditPhoto(false)">
        {{ "UTILS.TAKE_PHOTO" | translate }} Fronte
      </button>
      <button class="btn btn-green" (click)="onEditPhoto(true)">
        {{ "UTILS.TAKE_PHOTO" | translate }} Retro
      </button>
    </div>
  </div>
  <!-- END TAKE PIC -->
  <div class="d-flex justify-content-center pt-3 pb-mobile">
    <button
      (click)="
        formDocument.value.tipoDocumento == DocumentType.VIDEOCV
          ? uploadVideoCV(observer)
          : formDocument.value.tipoDocumento == DocumentType.CV
          ? uploadCV(observer)
          : addDocument(observer)
      "
      [disabled]="disabled || !formDocument.valid || (showFormRenewDocument && !formRenewDocument.valid)"
      class="text-center btn btn-green"
    >
      <tui-svg src="tuiIconPlus"></tui-svg>
      {{
        (formDocument.value.tipoDocumento == DocumentType.CV &&
        formDocument.value?.alreadyExists
          ? "UTILS.EDIT"
          : "UTILS.UPLOAD"
        ) | translate
      }}
    </button>
  </div>
</ng-template>

<ng-template let-observer #modalRecord>
  <div class="justify-content-center pt-3" *ngIf="!errorRecord">
    <div class="mb-1" *ngIf="!videoRecord">
      {{
        "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_VIDEO_TEXT_1"
          | translate
      }}
    </div>
    <div class="mb-2" [ngStyle]="{ display: videoRecord ? 'none' : 'block' }">
      <video class="video" #video autoplay [muted]="'muted'"></video>
    </div>
    <div class="mb-1" *ngIf="videoRecord">
      {{
        "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.CV_VIDEO_TEXT_2"
          | translate
      }}
    </div>
    <div class="mb-2" [ngStyle]="{ display: !videoRecord ? 'none' : 'block' }">
      <video class="video" controls #recordedVideo></video>
    </div>
    <div
      class="mb-2 d-flex flex-column align-items-center justify-content-center"
    >
      <button
        class="btn btn-primary btn-icon btn-lg me-2"
        *ngIf="!isRecording && !videoRecord"
        (click)="startRecording()"
      >
        {{ "UTILS.START_RECORD" | translate }}
      </button>
      <button
        class="btn btn-warning btn-icon btn-lg"
        *ngIf="isRecording && !videoRecord"
        (click)="stopRecording()"
      >
        {{ "UTILS.STOP_RECORD" | translate }}
      </button>
      <button
        class="btn btn-primary btn-icon btn-lg me-2 mb-2"
        *ngIf="!isRecording && videoRecord"
        (click)="resetRecord()"
      >
        {{ "UTILS.RESET_RECORD" | translate }}
      </button>
      <button
        class="btn btn-primary btn-icon btn-lg mb-2"
        *ngIf="!isRecording && videoRecord"
        (click)="saveRecording(observer)"
      >
        {{ "UTILS.SAVE_RECORD" | translate }}
      </button>
    </div>
  </div>
  <div
    class="text-danger justify-content-center pt-3 pb-mobile"
    *ngIf="errorRecord"
  >
    <div>
      {{ "ERRORS.RECORD_CV.DEVICE_NOT_FOUND.TITLE" | translate }}
    </div>
    <div>{{ errorMsg }}</div>
  </div>
</ng-template>

<ng-template let-data="data" let-observer #showVideoTemplate>
  <div class="my-2" *ngIf="!data">
    <div>{{ "PRIVATE.WORKER.USER_DETAIL.NO_VIDEO_CV" | translate }}</div>
    <div class="buttons d-flex justify-content-center mt-2">
      <button
        (click)="onShowDocsModal(macroDocType, docType); observer.complete()"
        class="me-2 btn btn-green-outline"
        [disabled]="
          disabled ||
          !(
            workerDocument?.status | getEditableDocumentStatus
          )
        "
      >
        <tui-svg src="tuiIconDraft"></tui-svg>
      </button>
      <button
        (click)="onShowRecordCVVideoModal(); observer.complete()"
        class="btn btn-green-outline"
        [disabled]="
          disabled ||
          !(
            workerDocument?.status | getEditableDocumentStatus
          )
        "
      >
        <tui-svg src="tuiIconVideo"></tui-svg>
      </button>
    </div>
  </div>
  <div class="mb-2" *ngIf="data">
    <video width="320" height="240" controls>
      <source [src]="data.url" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
  <div *ngIf="data && data.disabled">
    <div class="mb-1 red-text">
      {{
        "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.DOCUMENTI_CV.ALREADY_KO"
          | translate
      }}
    </div>
    <div class="mb-1 red-text fw-bold">
      {{ data.disabledMessage }}
    </div>
  </div>
</ng-template>

<ng-template let-observer #modalAddPhoto>
  <div class="justify-content-center pt-3" *ngIf="!errorPhoto">
    <div
      class="d-flex flex-wrap align-items-stretch justify-content-center gap-2"
    >
      <div class="mb-2 camera col-12 col-md-6">
        <div>
          {{
            "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_1"
              | translate
          }}
        </div>
        <div>
          <video id="video" class="video" controls #webcam></video>
        </div>
      </div>
      <div class="mb-2 output col-12 col-md-6">
        <div *ngIf="photoTaken">
          {{
            "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_2"
              | translate
          }}
        </div>
        <div [ngStyle]="{ height: !photoTaken ? '0' : 'auto' }">
          <canvas id="canvas" #canvas> </canvas>
          <img id="photo" #photo />
        </div>
      </div>
    </div>
    <div class="mb-2 col-12 justify-content-center" *ngIf="uuidEditPhoto">
      {{
        "PRIVATE.WORKER.COMPLETE_PROFILE.TABS.DOCUMENTS.PHOTO_TEXT_3"
          | translate
      }}
    </div>
    <div
      class="mb-2 d-flex flex-column align-items-center justify-content-center"
    >
      <button
        class="btn btn-green mb-2"
        (click)="takePhoto()"
        [disabled]="takePhotoDisabled"
      >
        {{ "UTILS.TAKE_PHOTO" | translate }}
      </button>
      <button
        class="btn btn-green btn-lg mb-2"
        *ngIf="photoTaken"
        (click)="savePhoto('web', observer)"
      >
        {{ "UTILS.SAVE_PHOTO" | translate }}
      </button>
    </div>
  </div>
  <div class="text-danger justify-content-center pt-3" *ngIf="errorPhoto">
    <div>
      {{ "ERRORS.TAKE_PHOTO.DEVICE_NOT_FOUND.TITLE" | translate }}
    </div>
    <div>{{ errorMsg }}</div>
  </div>
</ng-template>