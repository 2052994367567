import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';
import { Validators } from '@angular/forms';

@Pipe({ name: 'castCheckboxInput' })
export class CastCheckboxInputPipe implements PipeTransform {
  transform<S>(value: S): CheckboxInput {
      return value as unknown as CheckboxInput;
  }
}

export interface CheckboxInputConstructor
  extends Omit<
    BaseInputConstructor<boolean>,
    'placeholder' | 'clearable' | 'pattern'
  > {
  centered?: boolean;
  boldText?: boolean;
}

export class CheckboxInput extends BaseInput<boolean> {
  inputType = 'checkbox';
  centered = false;
  boldText = false;
  constructor(options: CheckboxInputConstructor) {
    super(options);

    if (!options.value) {
      this.setValue(false);
    }

    if (options.required) {
      this.setValidators(Validators.requiredTrue);
    }
    if (options.centered) {
      this.centered = options.centered;
    }
    if (options.boldText) {
      this.boldText = options.boldText;
    }
  }
}
