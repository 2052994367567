<div class="announcement-card-container">
  <div
    class="announcement-card"
    [ngClass]="{
      premium: isPremium,
      WORKER: userType && userType == UserType.WORKER,
      COMPANY: userType && userType == UserType.COMPANY
    }"
  >
    <div class="contents d-flex flex-column justify-content-between">
      <div
        class="show-if-not-S header"
      >
        <div class="title">{{ job?.title | titlecase }}</div>
        <div
          class="date d-flex flex-column align-items-end justify-content-center"
        >
          {{ "SEARCH.ANNOUNCEMENT.CREATED" | translate }}
          <span>
            {{ " " + (job?.created | date : "dd MMMM yyyy" : "" : "it") }}
          </span>
        </div>
      </div>
      <div class="show-if-S header">
        <div
          class="date d-flex flex-row justify-content-between justify-content-center"
        >
          <span>
            {{ "SEARCH.ANNOUNCEMENT.CREATED" | translate }}
            {{ " " + (job?.created | date : "dd MMMM yyyy" : "" : "it") }}
          </span>
        </div>
        <div class="title">{{ job?.title }}</div>
      </div>

      <div class="info d-flex flex-column justify-content-between">
        <div>
          <div
            class="place mb-2"
            *ngIf="job?.location?.municipality || job?.location?.region"
          >
            <div class="info fw-bold">
              {{
                job?.location?.municipality
                  ? ("MAPPINGS.municipalities." + job.location.municipality
                    | translate
                    | titlecase)
                  : null
              }}
              {{
                job?.location?.region
                  ? (job?.location?.municipality ? " - " : "") +
                    ("MAPPINGS.regions." + job.location.region | translate)
                  : null
              }}
              {{
                job?.location?.country && job?.location?.country != "ITALIA"
                  ? " , " +
                    ("MAPPINGS.countries." + job.location.country | translate)
                  : null
              }}
            </div>
          </div>
        </div>
        <div class="sector" *ngIf="job?.sector">
          {{ "MAPPINGS.sectors." + job.sector | translate }}
        </div>

        <div class="btn-container d-grid">
          <button type="button" class="btn btn-green">
            {{ "SEARCH.BUTTONS.SEE_DETAIL" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
