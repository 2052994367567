import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { RoleType } from 'src/app/core/auth/interfaces/role.model';
import { finalize } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RegisterService } from '../../services/register.service';
import { UserService } from 'src/app/core/auth/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private router: Router,
    private registerService: RegisterService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private userService: UserService,
    private gaService: GoogleAnalyticsService
  ) { }

  login(userData: { email: string; password: string }) { // can be merged with auth.service.ts?
    this.spinner.show();
    this.authService
      .login(userData)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(({ result, user }) => {
        // this.isLoading = false;
        if (result.isSuccess()) {
          const userUuid = result.getResponse().body.userUuid;
          if (user.role === RoleType.WORKER) {
            this.userService
              .getUserWorkerStatus()
              .subscribe((status) => {
                this.authService.redirectToApp(user, userUuid, status);
              },(error) => {
                this.authService.redirectToApp(user, userUuid); // FIXME: does not redirect to app but show errore and stay in current page
              });
          } else if (user.role === RoleType.COMPANY) {
            this.userService
            .getUserCompanyStatus()
            .subscribe((status) => {
              this.authService.redirectToApp(user, userUuid, status);
            },(error) => {
              this.authService.redirectToApp(user, userUuid); // FIXME: does not redirect to app but show errore and stay in current page
            });
            this.authService.redirectToApp(user, result);
          } else {
            this.authService.redirectToApp(user, userUuid);
          }

          if (user.role === RoleType.WORKER || user.role === RoleType.COMPANY) {
            // Google analytics
            this.gaService.gtag("event", "login", {
              'user_type': user.role === RoleType.WORKER ? 'worker' : 'company', // it must be either <company> or <worker>
              'user_id': result.getResponse().body.userUuid,
              'method': 'email', // it must be <facebook> or <google> or <email> or <linkedin> or <apple>
            });
          }

        }
      });
  }

}
