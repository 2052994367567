import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TuiDay } from '@taiga-ui/cdk';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import {
  Worker,
  WorkerCounters,
  editWorkerPersonalDataRequest,
} from 'src/app/core/auth/interfaces/worker.model';
import { UserService } from 'src/app/core/auth/services/user.service';
import { RegisterService } from 'src/app/pages/auth/services/register.service';
import { environment } from '../../../../../../../../environments/environment';
import {
  AddWorkerDocumentRequest,
  DocumentsResponse,
  UploadCvModel,
  UploadVideoCvModel,
  VideoUrlResponse,
} from '../../documents/model/documents.model';
import { RequiredDocs } from '../interfaces/required-docs';
import { Curriculum } from '../interfaces/worker-curriculum';
import { WorkerDoc } from '../interfaces/worker-doc.model';
import { DocumentInformation, WorkerDocumentsInfo } from '../interfaces/worker-documents.model';
import { DocumentType } from '../models/documents-type';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UserWorkerProfileService {

  constructor(
    private apiClient: ApiClient,
    private httpClient: HttpClient,
    private userService: UserService,
    private registerService: RegisterService
  ) {}

  getWorkerPersonalDocuments(): Observable<WorkerDocumentsInfo> {
    let headers = new HttpHeaders();

    // Added temp token logic for post registration completion
    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;
    if (authAppToken) {
      headers = headers.set('jwt-auth', `${authAppToken.value}`);
    }
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }

    return this.httpClient.get<WorkerDocumentsInfo>(
      this.apiClient.getUrlByApiName('getWorkerDocuments'),
      {
        headers,
      }
    );
  }


  addWorkerDocument(formValues: AddWorkerDocumentRequest) {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }

    const formData = new FormData();
    for (const name in formValues) {
      if (formValues[name]) {
        const value = formValues[name];
        if (value instanceof TuiDay) {
          formData.append(name, value.toJSON());
        } else {
          formData.append(name, value);
        }
      }
    }

    return this.httpClient.put(
      this.apiClient.getUrlByApiName('uploadWorkerDocument'),
      formData,
      { responseType: 'text' as 'json' }
    );

  }

  addNonEUWorkerDocument(formValues: any) {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }

    const formData = new FormData();
    for (const name in formValues) {
      if (formValues[name]) {
        const value = formValues[name];
        if (value instanceof File) {
          formData.append(name, value);
        } else if (value instanceof Object) {
          const blobOverrides = new Blob([JSON.stringify(value)], {type: 'application/json',});
          formData.append(name, blobOverrides);
        } else if (value instanceof TuiDay) {
          formData.append(name, value.toJSON());
        } else {
          formData.append(name, value);
        }
      }
    }

    return this.httpClient.put(
      this.apiClient.getUrlByApiName('addNonEUDocument'),
      formData,

    );
  }

  getWorkerHasVideoCurriculum(): Observable<boolean> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get<boolean>(
      this.apiClient.getUrlByApiName('getWorkerHasVideoCurriculum'),
      {
        headers,
      }
    );
  }

  getWorkerHasCurriculum(): Observable<boolean> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get<boolean>(
      this.apiClient.getUrlByApiName('getWorkerHasCurriculum'),
      {
        headers,
      }
    );
  }

  getWorkerCurriculum(): Observable<Curriculum> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get<Curriculum>(
      this.apiClient.getUrlByApiName('getWorkerCurriculum'),
      {
        headers,
      }
    );
  }

  upsertWorkerCurriculum(data: Curriculum): Observable<any> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.put(
      this.apiClient.getUrlByApiName('upsertWorkerCurriculum'),
      data,
      { headers }
    );
  }

  downloadNonUeDocument(tipo: 'PERMESSO' | 'RINNOVO'): Observable<any> {
    let headers = new HttpHeaders();

    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;

    if (authAppToken) {
      headers = headers.set('Authorization', `Bearer ${authAppToken.value}`);
    }

    const apiRef = tipo == 'PERMESSO' ? 'downloadWorkerPermesso' : 'downloadWorkerRinnovo'
    const api = this.apiClient.getUrlByApiName(apiRef, {
      headers,
    });

    return this.httpClient
      .get(api, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          const filename = contentDisposition.split(';')[1].split('"')[1];
          const blob = new Blob([response.body], { type: response.body.type });
          saveAs(blob, filename);
        })
      );
  }

  downloadDocument(uuid: string): Observable<any> {
    let headers = new HttpHeaders();

    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;

    if (authAppToken) {
      headers = headers.set('Authorization', `Bearer ${authAppToken.value}`);
    }
    const api = this.apiClient.getUrlByApiName('downloadWorkerDocument', {
      uuid,
      headers,
    });

    return this.httpClient
      .get(api, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          const filename = contentDisposition.split(';')[1].split('"')[1];
          const blob = new Blob([response.body], { type: response.body.type });
          saveAs(blob, filename);
        })
      );
  }

  downloadCv(): Observable<any> {
    let headers = new HttpHeaders();

    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;

    if (authAppToken) {
      headers = headers.set('Authorization', `Bearer ${authAppToken.value}`);
    }
    const api = this.apiClient.getUrlByApiName('downloadCv', {
      headers,
    });

    return this.httpClient
      .get(api, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          const filename = contentDisposition.split(';')[1].split('"')[1];
          const blob = new Blob([response.body], { type: response.body.type });
          saveAs(blob, filename);
        })
      );
  }

  getWorkerProfile(): Observable<Worker> {
    let headers = new HttpHeaders();

    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;

    if (authAppToken) {
      headers = headers.set('Authorization', `Bearer ${authAppToken.value}`);
    }
    return this.httpClient.get<Worker>(
      this.apiClient.getUrlByApiName('getWorkerData'),
      {
        headers,
      }
    );
  }

  postWorkerPersonalData(
    formValues: editWorkerPersonalDataRequest
  ): Observable<any> {
    return this.apiClient.request('editWorkerPersonalData', formValues);
  }

  addWorkerSignature(
    biometricIdentificationTerms: boolean = false
  ): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams().set(
      'biometricIdentificationTerms',
      JSON.stringify(biometricIdentificationTerms)
    );
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get(
      this.apiClient.getUrlByApiName('requestSignature'),
      {
        params: params,
        responseType: "text"
      }
    );
  }

  getWorkerNonUeDocumentDetail(): Observable<DocumentInformation> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get<DocumentInformation>(
      this.apiClient.getUrlByApiName('getWorkerNonUeDocumentDetail'),
      { headers }
    );
  }

  getWorkerNonUeDocument(): Observable<DocumentInformation> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get<DocumentInformation>(
      this.apiClient.getUrlByApiName('getWorkerNonUeDocument'),
      { headers }
    );
  }

  getWorkerDocumentDetail(documentType: DocumentType): Observable<WorkerDoc> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    return this.httpClient.get<WorkerDoc>(
      this.apiClient.getUrlByApiName('getWorkerDocumentDetail', {
        documentType,
      }),
      { headers }
    );
  }

  updateProfileInfo(): Observable<Worker> {
    return this.apiClient.request<Worker>('getWorkerData').pipe(
      switchMap((worker) =>
        this.apiClient
          .request<WorkerCounters>('getWorkerProfileCounters')
          .pipe(map((addInfo) => ({ ...addInfo, ...worker })))
      ),
      tap((worker) => this.userService.setUser(worker))
    );
  }

  getVideoCV(): Observable<VideoUrlResponse> {
    return this.apiClient.request('getVideoCV',null, null);
  }

  uploadVideoCV(formValues: UploadVideoCvModel): Observable<void> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    const formData = new FormData();

    for (const name in formValues) {
      if (name) {
        var value = formValues[name];
        if (value == 'null') {
          value = null;
        }
        if (value instanceof TuiDay) {
          formData.append(name, value.toJSON());
        } else {
          formData.append(name, value);
        }
      }
    }

    return this.httpClient.put<void>(
      this.apiClient.getUrlByApiName('uploadVideoCV'),
      formData,
      {
        headers,
      }
    );
  }

  uploadCV(formValues: UploadCvModel): Observable<void> {
    let headers = new HttpHeaders();

    // Added temp token logic for post registration completion
    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    const authAppToken = authAppTokenString
      ? JSON.parse(authAppTokenString)
      : authAppTokenString;
    if (authAppToken) {
      headers = headers.set('jwt-auth', `${authAppToken.value}`);
    }
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    const formData = new FormData();

    for (const name in formValues) {
      if (name) {
        var value = formValues[name];
        if (value == 'null') {
          value = null;
        }
        formData.append(name, value);
      }
    }

    return this.httpClient.put<void>(
      this.apiClient.getUrlByApiName('uploadCV'),
      formData,
      {
        headers,
      }
    );
  }

  getPhoto() {
    return this.apiClient.request('getPhoto');
  }

  savePhoto(formValues): Observable<void> {
    let headers = new HttpHeaders();
    if (this.registerService.getJwtToken()) {
      headers = headers.set('jwt-auth', this.registerService.getJwtToken());
    }
    const formData = new FormData();
    for (const name in formValues) {
      if (name) {
        const value = formValues[name];
        if (value instanceof TuiDay) {
          formData.append(name, value.toJSON());
        } else {
          formData.append(name, value);
        }
      }
    }
    return this.httpClient.put<void>(
      this.apiClient.getUrlByApiName('savePhoto'),
      formData,
      {
        headers,
      }
    );
  }
}
