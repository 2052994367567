import { Pipe, PipeTransform } from '@angular/core';
import { TimeShiftSheet } from 'src/app/pages/private/pages/worker/pages/messages/interfaces/proposal-detail.model';

@Pipe({ name: 'getRestDays' })
export class MultipleRestDayByTimeSheetPipe implements PipeTransform {
  transform(
    daysList: TimeShiftSheet[],
    mapWeek: Map<string, string>
  ): string[] {
    const daysToShow = [];
    mapWeek.forEach((value, key) => {
      if (daysList.every((days) => days[value] === 0)) {
        daysToShow.push(key);
      }
    });
    return daysToShow;
  }
}
