import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { HeaderItemModel, HeaderService } from '../header.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent implements OnInit {
  links: HeaderItemModel[];
  currentURL: string;
  isSticky: boolean = false;
  stickyTopOffset: number = 0;
  typeOfUser: string;
  suggested$ = this.userType == UserType.COMPANY ? this.headerService.getPrivateCompanyTabs() : this.headerService.getPrivateWorkerTabs();
  @Input() set userType(userType: UserType) {
    if (userType === UserType.WORKER) {
      this.typeOfUser = UserType.WORKER;
      this.suggested$ = this.headerService.getPrivateWorkerTabs();
    }
    if (userType == UserType.COMPANY) {
      this.typeOfUser = UserType.COMPANY;
      this.suggested$ = this.headerService.getPrivateCompanyTabs();
    }
    if (userType == UserType.DELEGATE) {
      this.suggested$ = null;
      this.typeOfUser = UserType.DELEGATE;
      this.links = [];
    }
  }
  @Input() set sticky(isSticky: boolean) {
    this.isSticky = isSticky;
    if (this.isSticky) {
      this.headerService
        .containerOffsetChangeObservable()
        .subscribe((offset: number) => {
          this.stickyTopOffset = offset;
        });
      this.headerService.triggerContainerOffsetChangeObservable();
    }
  }

  constructor(private headerService: HeaderService, private router: Router) { }

  ngOnInit(): void {
    this.suggested$.subscribe((items) => {
      this.links = items;
      this._matchLinks(this.router.url);
    });
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        this._matchLinks((e as NavigationEnd).urlAfterRedirects);
      });
  }

  navigate(item: HeaderItemModel) {
    if (item.disabled) return;
    if (item.fragment) {
      this.router.navigate([item.navigationUrl], {
        state: { type: this.typeOfUser },
        fragment: item.fragment,
      });
    } else {
      this.router.navigate([item.navigationUrl], {
        state: { type: this.typeOfUser },
      });
    }
  }

  getItemClass(item: HeaderItemModel): string {
    var cssClass = '';
    if (!item.disabled) {
      cssClass = 'cursor-pointer';
    } else {
      cssClass = 'disabled';
    }
    if (item.cssClass) cssClass = cssClass + ' ' + item.cssClass;
    return cssClass;
  }

  private _matchLinks(urlToMatch: string) {
    this.currentURL = urlToMatch;
    this._setActiveSubmenuItem();
  }

  private _setActiveSubmenuItem() {
    this.links.forEach((item) => {
      const active: string = ' active ';
      if (!item.cssClass) item.cssClass = '';
      if (item.cssClass?.includes(active)) {
        item.cssClass = item.cssClass.replace(active, '');
      }
      if (this.currentURL.startsWith(item.navigationUrl)) {
        item.cssClass = item.cssClass + active;
      }
    });
  }
}