<div *ngIf="type == 'VIDEO' else inPersonInterview">
  <button class="btn btn-green-outline" (click)="onShowModalRequestInverview()" *ngIf="!iconButton">
    {{ "SEARCH.BUTTONS.INTERVIEW_VIDEO" | translate }}
  </button>
  <button class="btn btn-green m-auto header-button" (click)="onShowModalRequestInverview()" *ngIf="iconButton">
    <tui-svg src="tuiIconVideoLarge"></tui-svg>
  </button>
</div>

<ng-template #inPersonInterview>
  <button class="btn btn-green-outline" (click)="onShowModalRequestInverview()">
    {{ "SEARCH.BUTTONS.INTERVIEW_PERSONAL" | translate }}
  </button>
</ng-template>

<ng-template let-observer #modalRequestInverview>
  <app-form [form]="interviewForm"></app-form>
  <div class="d-flex justify-content-center pt-3 pb-mobile">
    <button
      (click)="onSendInterviewRequest(); observer.complete()"
      [disabled]="!interviewForm.valid"
      class="text-center btn btn-green"
    >
      <!-- <tui-svg src="tuiIconPlus"></tui-svg> -->
      {{ "ACTIONS.SEND" | translate }}
    </button>
  </div>
</ng-template>
