import { Injectable } from '@angular/core';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfferResponse } from '../../../../documents/pages/offert/interfaces';
import { AnnouncementState } from '../enum/announcement-state';
import {
  Announcement,
  ChangeAnnouncementStatusParams,
  CreateAnnouncementRequest,
  EditAnnouncementRequest,
  JobOfferStatus,
} from '../interfaces/announcement.model';
import { SaveFavoriteResponse } from '../../../../../../../../../shared/interfaces/company-search-list';
import { SearchJobApplicationsResponse } from 'src/app/shared/interfaces/search-job-applications.model';
import { FilterOrderBy } from 'src/app/shared/interfaces/search-filters';

@Injectable({ providedIn: 'root' })
export class AnnouncementsService {
  private onlineAnnouncementsSub$ = new BehaviorSubject<Announcement[]>(null);
  onlineAnnouncements$ = this.onlineAnnouncementsSub$.asObservable();

  constructor(private apiClient: ApiClient) {}

  getAnnouncementsList(filter: AnnouncementState): Observable<Announcement[]> {
    const params: any = {};
    switch (filter) {
      case AnnouncementState.ONLINE:
        params.status = JobOfferStatus.ONLINE;
        break;
      case AnnouncementState.OFFLINE:
        params.status = JobOfferStatus.OFFLINE;
        break;
      case AnnouncementState.CLOSED:
        params.status = JobOfferStatus.CLOSED;
        break;
    }
    return this.apiClient.request('getAnnouncementList', null, params);
  }

  acceptedOffers(): Observable<OfferResponse[]> {
    return this.apiClient.request('acceptedOffers');
  }

  createAnnouncement(formValues: CreateAnnouncementRequest) {
    return this.apiClient.request('createAnnouncement', formValues);
  }

  changeAnnouncementStatus(
    params: ChangeAnnouncementStatusParams
  ): Observable<Announcement> {
    return this.apiClient.request('changeAnnouncementStatus', null, params);
  }

  editAnnouncementStatus(
    formValues: EditAnnouncementRequest
  ): Observable<Announcement> {
    return this.apiClient.request('editAnnouncement', formValues);
  }

  getApplicatons(jobOfferArcaId) {
    return this.apiClient.request('getApplicatons', null, null, {
      jobOfferArcaId,
    });
  }

  getApplications(
    jobOfferUuid: string
  ): Observable<SearchJobApplicationsResponse> {
    const apiParams = {
      orderBy: FilterOrderBy.COMPATIBILITA,
    };
    return this.apiClient.request('getJobApplications', null, apiParams, {
      jobOfferUuid,
    });
  }

  saveFavoriteCandidate(id: number): Observable<SaveFavoriteResponse> {
    return this.apiClient.request('saveFavorite', null, { id });
  }

  announcementDetail(jobOfferUuid: string): Observable<Announcement> {
    return this.apiClient.request('getAnnouncementDetail', null, {
      jobOfferUuid,
    });
  }

  updateAnnouncementNotificationFrequency(
    jobOfferUuid: string,
    frequency: string
  ): Observable<Announcement> {
    return this.apiClient.request(
      'updateAnnouncementNotificationFrequency',
      null,
      {
        jobOfferUuid,
        frequency,
      }
    );
  }

  setOnlineAnnouncements(announcements: Announcement[]) {
    this.onlineAnnouncementsSub$.next(announcements);
  }

  onLogout() {
    this.setOnlineAnnouncements(null);
  }
}
