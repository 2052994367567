<div class="container">
  <div class="row">
    <div class="col p-3 bg-grey mt-4 border">
      <tui-svg class="icon me-2" src="{{ icon }}"></tui-svg>
      {{ text1 | translate }}
      <span class="fw-bold">{{ number }} {{ text2 | translate }}</span>
      {{ text3 | translate }}
    </div>
  </div>
</div>
