<div class="carousel-notification-card card">
  <div class="header">
    <div class="date black">{{ getDate() }}</div>
    <div class="label">{{ ('NOTIFICATIONS.ICON_TYPE.'+notification.iconType | uppercase) | translate }}</div>
  </div>
  <div class="content">
    <div class="text">
      <h4>{{ notification.notifyTitle }}</h4>
      <h5>{{ notification.notifyText }}</h5>
    </div>
    <!-- <div class="button">
      <button class="btn btn-green-outline" (click)="getPath(notification.iconType)">
        {{ "UTILS.SEE" | translate }}
        <tui-svg src="tuiIconArrowRightLarge"></tui-svg>
      </button>
    </div> -->
  </div>
</div>
