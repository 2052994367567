import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput } from './base-input';

@Pipe({ name: 'castToggleInput' })
export class CastToggleInputPipe implements PipeTransform {
  transform<S>(value: S): ToggleInput {
      return value as unknown as ToggleInput;
  }
}

export class ToggleInput<T = string> extends BaseInput<T> {
  inputType = 'toggle';
  label: string;
  inline?: boolean = true;
}
