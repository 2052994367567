import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-complete-client-dialog',
  templateUrl: './complete-client-dialog.component.html',
  styleUrls: ['./complete-client-dialog.component.scss'],
})
export class CompleteClientDialogComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext
  ) {}

  ngOnInit(): void {}

  toComplete() {
    this.context.completeWith();
    this.router.navigate(['private', 'azienda', 'utente', 'edit-profile']);
  }
}
