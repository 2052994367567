<div
  class="row px-3 py-2"
  style="background-color: #dfd5eb"
  *ngIf="(impersonating$ | async)?.impersonator"
>
  <div class="col">Stai impersonando un altro account</div>
  <div class="col text-end">
    <a
      style="text-decoration: underline; cursor: pointer"
      (click)="deImpersonate()"
    >
      Torna al tuo account
    </a>
  </div>
</div>

<header
  id="menu"
  *ngIf="userType"
  [ngClass]="userType == UserType.WORKER ? 'worker' : 'company'"
>
  <nav
    class="navbar navbar-expand-xl navbar-light px-4 px-lg-4 px-xxl-5 pt-4 pb-3 pt-lg-5 pb-lg-4 ngm"
    *ngIf="userAgent != UserAgent.FLUTTER_WEB_VIEW; else floatingPrivateMenu"
  >
    <div class="container-fluid d-none d-md-flex px-0">
      <a
        class="navbar-brand px-0 me-3 me-lg-4 me-xl-5 ngm"
        [routerLink]="['/home']"
      >
        <img
          src="./assets/images/logo_header.svg"
          height="50px"
          alt="InPlace"
        />
      </a>

      <!-- GUEST BTNS MD-SCREENS -->
      <div
        *ngIf="!isPrivate"
        class="d-md-flex d-none d-xl-none btn-guest-container me-4 ms-auto"
      >
        <ng-container [ngTemplateOutlet]="guestBtns"></ng-container>
      </div>

      <!-- HAMBURGER BTN -->
      <button
        class="navbar-toggler px-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        *ngIf="userType != UserType.DELEGATE"
      >
        <img
          class="toggle-icon"
          src="/assets/icons/landing-pages/hamburger_icon.svg"
          alt="Toggle icon"
        />
      </button>

      <!-- PUBLIC TABS COLLAPSE -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ng-container [ngTemplateOutlet]="publicTabsCollapse"></ng-container>
      </div>
    </div>

    <!-- OFFCANVAS BTN TOGGLE -->
    <div class="d-flex d-md-none container-fluid">
      <a class="ms-0 ms-lg-2 ms-xl-5 navbar-brand ngm" [routerLink]="['/home']">
        <img
          src="./assets/images/logo_header.svg"
          height="50px"
          alt="InPlace"
        />
      </a>
      <button
        class="navbar-toggler px-0"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasTabs"
        aria-controls="offcanvasTabs"
        *ngIf="userType != UserType.DELEGATE"
      >
        <img
          class="toggle-icon"
          src="/assets/icons/landing-pages/hamburger_icon.svg"
          alt="Toggle icon"
        />
      </button>

      <!-- PUBLIC TABS OFFCANVAS -->
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasTabs"
        aria-labelledby="offcanvasTabsLabel"
      >
        <ng-container [ngTemplateOutlet]="publicTabsOffcanvas"></ng-container>
      </div>
    </div>
  </nav>

  <!-- FLOATING PRIVATE MENU -->
  <div
    *ngIf="isPrivate || userAgent == UserAgent.FLUTTER_WEB_VIEW"
    class="d-xl-none"
  >
    <ng-container [ngTemplateOutlet]="floatingPrivateMenu"></ng-container>
  </div>

  <!-- PUBLIC TABS COLLAPSE-->
  <ng-template #publicTabsCollapse>
    <ng-container
      *ngIf="!isPrivate || !(user$ | async); else privateTabsCollapse"
    >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" id="publicTabsCollapse">
        <li class="nav-item">
          <a
            class="nav-link ps-0"
            [routerLink]="['/azienda']"
            [routerLinkActive]="['active']"
          >
            <span>{{ "HEADER.TABS.COMPANY" | translate | uppercase }}</span>
            <div class="underline"></div>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/candidato']"
            [routerLinkActive]="['active']"
          >
            <span>{{ "HEADER.TABS.WORKERS" | translate | uppercase }}</span>
            <div class="underline"></div>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/who']"
            [routerLinkActive]="['active']"
          >
            <span>
              {{ "HEADER.TABS.ABOUT_US" | translate | uppercase }}
              <div class="underline"></div>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/support']"
            [routerLinkActive]="['active']"
          >
            <span>{{ "HEADER.TABS.SUPPORT" | translate | uppercase }}</span>
            <div class="underline"></div>
          </a>
        </li>
      </ul>
    </ng-container>

    <div
      *ngIf="!isPrivate"
      class="d-xl-flex d-none btn-guest-container ms-auto ngm"
    >
      <ng-container [ngTemplateOutlet]="guestBtns"></ng-container>
    </div>

    <div class="d-none d-xl-inline" *ngIf="isPrivate">
      <ng-container [ngTemplateOutlet]="privateMenu"></ng-container>
    </div>
  </ng-template>

  <!-- PUBLIC TABS OFFCANVAS-->
  <ng-template #publicTabsOffcanvas>
    <button
      class="navbar-toggler ms-auto mt-4 me-3 ngm"
      type="button"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      <img
        class="toggle-icon"
        src="/assets/icons/landing-pages/offcanvas_close_btn.svg"
        alt="Close menu"
      />
    </button>
    <div class="mx-auto my-4 ngm">
      <ng-container *ngIf="!isPrivate; else privateTabsOffcanvas">
        <ul class="navbar-nav" data-bs-dismiss="offcanvas">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/azienda']"
              [routerLinkActive]="['active']"
            >
              <span>{{ "HEADER.TABS.COMPANY" | translate | uppercase }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/candidato']"
              [routerLinkActive]="['active']"
            >
              <span>{{ "HEADER.TABS.WORKERS" | translate | uppercase }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/who']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.ABOUT_US" | translate | uppercase }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/support']"
              [routerLinkActive]="['active']"
            >
              <span>{{ "HEADER.TABS.SUPPORT" | translate | uppercase }}</span>
            </a>
          </li>
        </ul>
      </ng-container>
    </div>

    <!-- GUEST BTNS -->
    <div
      *ngIf="!isPrivate"
      class="d-flex flex-column gap-2 btn-guest-container mx-auto ngm"
    >
      <button
        class="btn btn-green"
        data-bs-dismiss="offcanvas"
        type="button"
        (click)="onLogin()"
      >
        {{ "HEADER.BUTTONS.SIGN_IN" | translate | uppercase }}
      </button>
      <a
        class="btn btn-green-outline mt-3 ngm"
        data-bs-dismiss="offcanvas"
        [routerLink]="['/auth', 'register', 'general']"
      >
        {{ "HEADER.BUTTONS.SIGN_UP" | translate | uppercase }}
      </a>
    </div>

    <div class="d-none d-xl-inline" *ngIf="isPrivate">
      <ng-container [ngTemplateOutlet]="privateMenu"></ng-container>
    </div>

    <div class="mx-auto mt-auto mb-5 ngm">
      <a [routerLink]="['/home']" data-bs-dismiss="offcanvas">
        <img
          src="./assets/images/logo_header.svg"
          height="40px"
          alt="InPlace"
        />
      </a>

      <ng-container [ngTemplateOutlet]="socialLinks"></ng-container>
    </div>
  </ng-template>

  <!-- GUEST BTNS -->
  <ng-template #guestBtns>
    <button class="btn btn-green" type="button" (click)="onLogin()">
      {{ "HEADER.BUTTONS.SIGN_IN" | translate | uppercase }}
    </button>
    <a
      class="btn btn-green-outline"
      [routerLink]="['/auth', 'register', 'general']"
    >
      {{ "HEADER.BUTTONS.SIGN_UP" | translate | uppercase }}
    </a>
  </ng-template>

  <!--PRIVATE TABS COLLAPSE -->
  <ng-template #privateTabsCollapse>
    <ng-container [ngSwitch]="userType">
      <!-- COMPANY TABS -->
      <ng-container *ngSwitchCase="UserType.COMPANY">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" id="privateTabsCollapse">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/azienda/home']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HOME.NAV_LABELS.CANDIDATES" | translate | uppercase }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="[
                '/private/azienda/annunci/gestione-annunci/create'
              ]"
              [routerLinkActive]="['active']"
            >
              <span>
                {{
                  "HOME.NAV_LABELS.CREATE_ANNOUNCEMENT" | translate | uppercase
                }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="[
               '/private/azienda/documenti/offert/create'
              ]"
              [routerLinkActive]="['active']"
            >
              <span>
                {{
                  "HOME.NAV_LABELS.CREATE_OFFER" | translate | uppercase
                }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/azienda/who']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.ABOUT_US" | translate | uppercase }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/azienda/support/faq/azienda']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.SUPPORT" | translate | uppercase }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
        </ul>
      </ng-container>
      <!-- WORKER TABS -->
      <ng-container *ngSwitchCase="UserType.WORKER">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" id="privateTabsCollapse">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/candidato/home']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HOME.NAV_LABELS.WORK" | translate | uppercase }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/candidato/who']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.ABOUT_US" | translate | uppercase }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/candidato/support/faq/candidato']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.SUPPORT" | translate | uppercase }}
              </span>
              <div class="underline"></div>
            </a>
          </li>
        </ul>
      </ng-container>
      <!-- DELEGATE TABS -->
      <ng-container *ngSwitchCase="UserType.DELEGATE"></ng-container>
    </ng-container>
  </ng-template>

  <!--PRIVATE TABS OFFCANVAS -->
  <ng-template #privateTabsOffcanvas>
    <ng-container [ngSwitch]="userType">
      <!-- COMPANY TABS -->
      <ng-container *ngSwitchCase="UserType.COMPANY">
        <ul
          class="navbar-nav mx-auto mb-2 mb-lg-0"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasTabs"
        >
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/azienda/home']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HOME.NAV_LABELS.CANDIDATES" | translate | uppercase }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="[
                '/private/azienda/annunci/gestione-annunci/create'
              ]"
              [routerLinkActive]="['active']"
            >
              <span>
                {{
                  "HOME.NAV_LABELS.CREATE_ANNOUNCEMENT" | translate | uppercase
                }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="[
               '/private/azienda/documenti/offert/create'
              ]"
              [routerLinkActive]="['active']"
            >
              <span>
                {{
                  "HOME.NAV_LABELS.CREATE_OFFER" | translate | uppercase
                }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/azienda/who']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.ABOUT_US" | translate | uppercase }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/azienda/support/faq/azienda']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.SUPPORT" | translate | uppercase }}
              </span>
            </a>
          </li>
        </ul>
      </ng-container>
      <!-- WORKER TABS -->
      <ng-container *ngSwitchCase="UserType.WORKER">
        <ul
          class="navbar-nav mx-auto mb-2 mb-lg-0"
          data-bs-dismiss="offcanvas"
          data-bs-target="#offcanvasTabs"
        >
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/candidato/home']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HOME.NAV_LABELS.WORK" | translate | uppercase }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/candidato/who']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.ABOUT_US" | translate | uppercase }}
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/private/candidato/support/faq/candidato']"
              [routerLinkActive]="['active']"
            >
              <span>
                {{ "HEADER.TABS.SUPPORT" | translate | uppercase }}
              </span>
            </a>
          </li>
        </ul>
      </ng-container>
      <!-- DELEGATE TABS -->
      <ng-container *ngSwitchCase="UserType.DELEGATE"></ng-container>
    </ng-container>
  </ng-template>

  <!-- USER SECTION -->
  <ng-template #privateMenu>
    <div class="user-section" *ngIf="user$ | async as user">
      <!-- NOTIFICATIONS -->
      <tui-hosted-dropdown
        *ngIf="userType != UserType.DELEGATE"
        tuiDropdownAlign="left"
        [content]="notificationsPanel"
      >
        <tui-badged-content
          colorTop="var(--color)"
          size="s"
          *ngIf="notificationsNumber$ | async; else noNotifications"
          [contentTop]="''"
        >
          <button
            tuiIconButton
            size="m"
            appearance="icon-header"
            type="button"
            icon="tuiIconBellLarge"
            class="bigger-btn bell"
          ></button>
        </tui-badged-content>
        <ng-template #noNotifications>
          <button
            tuiIconButton
            size="m"
            appearance="icon-header"
            type="button"
            icon="tuiIconBellLarge"
            class="bigger-btn bell"
          ></button
        ></ng-template>
      </tui-hosted-dropdown>

      <!-- HEART -->
      <button
        *ngIf="userType != UserType.DELEGATE"
        id="header-heart-btn"
        tuiIconButton
        size="m"
        appearance="icon-header"
        type="button"
        icon="tuiIconHeartFilledLarge"
        class="bigger-btn heart"
        (click)="onHeartClick()"
      ></button>

      <!-- USER MENU -->
      <tui-hosted-dropdown
        tuiDropdownAlign="left"
        [content]="userPanel"
        (openChange)="openUserPanel = $event"
      >
        <div
          class="user-div cursor-pointer"
          [ngClass]="{ open: openUserPanel }"
        >
          <span>
            {{ "MENU.GREETING" | translate | uppercase }},
            {{
              user?.ragioneSociale || user?.nome || user?.name || user?.username
                | uppercase
            }}
          </span>
          <tui-svg src="tuiIconArrowRight"></tui-svg>
        </div>
      </tui-hosted-dropdown>
    </div>
  </ng-template>

  <!-- USER PANEL -->
  <ng-template #userPanel>
    <app-user (closeUserPanel)="openUserPanel = false"></app-user>
  </ng-template>

  <!-- NOTIFICATIONS PANEL -->
  <ng-template #notificationsPanel>
    <app-notifications-and-messages></app-notifications-and-messages>
  </ng-template>

  <!-- FLOATING USER MENU -->
  <ng-template #floatingPrivateMenu>
    <div class="floating-private-menu mx-5 px-0"  [style]="userAgent != UserAgent.FLUTTER_WEB_VIEW ? 'top:' + stickyTopOffset + 'px;' : '95px' " >
      <ng-container [ngTemplateOutlet]="privateMenu"></ng-container>
    </div>
  </ng-template>

  <!-- SOCIAL -->
  <ng-template #socialLinks>
    <ul class="social">
      <div class="d-flex mt-3 ngm">
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('linkedin')">
            <img src="/assets/icons/landing-pages/linkedin_black.svg" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('facebook')">
            <img src="/assets/icons/landing-pages/facebook_black.svg" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="clickSocial('instagram')">
            <img src="/assets/icons/landing-pages/instagram_circle_black.svg" />
          </a>
        </li>
      </div>
    </ul>
  </ng-template>
</header>
