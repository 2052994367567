import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimalComa' })
export class DecimalComaPipe implements PipeTransform {
  transform(decimal: any): string {
    if (decimal) {
      decimal = decimal.toString();

      if (decimal.includes('.')) {
        const roundedNumber = (Math.round(+decimal * 100) / 100).toString();
        const decimalComa = roundedNumber.replace('.', ',');
        if (!decimalComa.includes(',')) {
          return `${decimalComa},00`;
        } else {
          return `${decimalComa.split(',')[0]},${
            decimalComa.split(',')[1].length === 1
              ? `${decimalComa.split(',')[1]}0`
              : decimalComa.split(',')[1]
          }`;
        }
      }
      return `${decimal},00`;
    }
    return decimal;
  }
}
