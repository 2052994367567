import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClientService} from 'src/app/api-client.service';
import { Observable } from 'rxjs';
import { InterviewListItem, InterviewRequest } from '../interfaces/interview.model';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { TuiDay } from '@taiga-ui/cdk';

@Injectable()
export class InterviewsService {
  constructor(
    private apiClient: ApiClientService,
    private httpClient: HttpClient) {}

  requestInterview(request: InterviewRequest): Observable<boolean>{
    return this.apiClient.request('requestInterviewVideo', request);
  }

  getInterviewListCompany(data): Observable<InterviewListItem[]> {
    return this.apiClient.request('getInterviewsCompanyListFiltered', data);
  }

  getInterviewListWorker(data): Observable<InterviewListItem[]> {
    return this.apiClient.request('getInterviewsWorkerListFiltered', data);
  }

  confirmInterviewWorker(interviewId:number): Observable<boolean> {
    return this.apiClient.request('confirmInterviewWorker', null, null, {interviewId});
  }

  rejectInterviewWorker(interviewId:number): Observable<boolean> {
    return this.apiClient.request('rejectInterviewWorker', null, null, {interviewId});
  }

  cancelInterviewWorker(interviewId:number, userType: UserType): Observable<boolean> {
    if(userType === UserType.WORKER) {
      return this.apiClient.request('cancelInterviewWorker', null, null, {interviewId});
    } else {
      return this.apiClient.request('cancelInterviewCompany', null, null, {interviewId});
    }
  }

  requestInterviewTimes(request: TuiDay): Observable<any>{
    const month = (request.month + 1).toString().length < 2 ? "0"+(request.month + 1) : (request.month + 1)
    let req = request.day + "." + month + "." + request.year;
    return this.apiClient.request('getInterviewFreeTimeSlots', null, {'daydate': req});
  }
}
