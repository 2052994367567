import { Pipe, PipeTransform } from '@angular/core';
import { BaseInput, BaseInputConstructor } from './base-input';

@Pipe({ name: 'castDivider' })
export class CastDividerPipe implements PipeTransform {
  transform<S>(value: S): Divider {
      return value as unknown as Divider;
  }
}

export interface DividerConstructor<T, E>
  extends Omit<BaseInputConstructor<T>, 'label'> {
  marginTop?: number;
  marginBottom?: number;
}

export class Divider<T = string, E = string> extends BaseInput<T> {
  inputType = 'divider';

  marginTop: number;
  marginBottom: number;

  constructor(options: DividerConstructor<T, E> = {}) {
    super(options);

    this.marginTop = options.marginTop === undefined ? 2 : options.marginTop;
    this.marginBottom =
      options.marginBottom === undefined ? 2 : options.marginBottom;
  }
}
