import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiClientService as ApiClient} from 'src/app/api-client.service';
import { UserRoleInfo } from 'src/app/core/auth/services/user.service';
import { RequiredDocs } from '../../private/pages/worker/pages/user/interfaces/required-docs';
import { Form } from 'src/app/shared/form';
import { FormGroup } from '@angular/forms';
import { ProviderEnum } from 'src/app/core/auth/enum/provider.enum';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private _jwtToken: string;
  private _refreshToken: string;
  private _provider: ProviderEnum;
  private _userUuid: string;
  private _incompleto: boolean;
  private _requiredDocs: RequiredDocs;
  private _email: string;
  private _announcementId: string;

  private _registerAccountFormValues: any; //form.value
  private _registerPersonalDataFormValues: any; //form.value
  private _registerResidenceFormValues: any; //form.value
  private _registerDomicileFormValues: any; //form.value
  private _registerAvailabilityFormValues: any; //form.value
  private _registerAvailabilityForm2Values: any; //form.value
  private _privacyFormValues: any; //form.value

  constructor(private apiClient: ApiClient) {}

  mailValidation(activationToken: string): Observable<UserRoleInfo> {
    return this.apiClient.request('mailValidation', { activationToken });
  }

  setJwtToken(jwtToken: string) {
    this._jwtToken = jwtToken;
    this._onRegistrationDataChanged();
  }
  getJwtToken(): string {
    if(!this._jwtToken) this._getDataFromStorage();
    return this._jwtToken;
  }

  setRefreshToken(refreshToken: string) {
    this._refreshToken = refreshToken;
    this._onRegistrationDataChanged();
  }
  getRefreshToken(): string {
    if(!this._refreshToken) this._getDataFromStorage();
    return this._refreshToken;
  }

  setProvider(provider: ProviderEnum) {
    this._provider = provider;
    this._onRegistrationDataChanged();
  }
  getProvider(): ProviderEnum {
    if(!this._provider) this._getDataFromStorage();
    return this._provider;
  }

  setUserUuid(userUuid: string) {
    this._userUuid = userUuid;
    this._onRegistrationDataChanged();
  }
  getUserUuid(): string {
    if(!this._userUuid) this._getDataFromStorage();
    return this._userUuid;
  }

  setIncompleto(incompleto: boolean) {
    this._incompleto = incompleto;
    this._onRegistrationDataChanged();
  }
  getIncompleto(): boolean {
    if(!this._incompleto) this._getDataFromStorage();
    return this._incompleto;
  }

  setRequiredDocs(requiredDocs: RequiredDocs) {
    this._requiredDocs = requiredDocs;
    this._onRegistrationDataChanged();
  }
  getRequiredDocs(): RequiredDocs {
    if(!this._requiredDocs) this._getDataFromStorage();
    return this._requiredDocs;
  }

  setEmail(email: string) {
    this._email = email;
    this._onRegistrationDataChanged();
  }
  getEmail(): string {
    if(!this._email) this._getDataFromStorage();
    return this._email;
  }

  setAnnouncementId(announcementId: string) {
    this._announcementId = announcementId;
    this._onRegistrationDataChanged();
  }
  getAnnouncementId(): string {
    if(!this._announcementId) this._getDataFromStorage();
    return this._announcementId;
  }


  setRegisterAccountFormValues(form: Form) {
    this._registerAccountFormValues = form.value;
    this._onRegistrationDataChanged();
  }
  getRegisterAccountFormValues(): Form {
    if(!this._registerAccountFormValues) this._getDataFromStorage();
    return this._registerAccountFormValues;
  }

  setRegisterPersonalDataFormValues(form: Form) {
    this._registerPersonalDataFormValues = form.value;
    this._onRegistrationDataChanged();
  }
  getRegisterPersonalDataFormValues(): Form {
    if(!this._registerPersonalDataFormValues) this._getDataFromStorage();
    return this._registerPersonalDataFormValues;
  }

  setRegisterResidenceFormValues(form: Form) {
    this._registerResidenceFormValues = form.value;
    this._onRegistrationDataChanged();
  }
  getRegisterResidenceFormValues(): Form {
    if(!this._registerResidenceFormValues) this._getDataFromStorage();
    return this._registerResidenceFormValues;
  }

  setRegisterDomicileFormValues(form: Form) {
    this._registerDomicileFormValues = form.value;
    this._onRegistrationDataChanged();
  }
  getRegisterDomicileFormValues(): Form {
    if(!this._registerDomicileFormValues) this._getDataFromStorage();
    return this._registerDomicileFormValues;
  }

  setRegisterAvailabilityFormValues(form: Form) {
    this._registerAvailabilityFormValues = form.value;
    this._onRegistrationDataChanged();
  }
  getRegisterAvailabilityFormValues(): Form {
    if(!this._registerAvailabilityFormValues) this._getDataFromStorage();
    return this._registerAvailabilityFormValues;
  }

  setRegisterAvailabilityForm2Values(form: Form) {
    this._registerAvailabilityForm2Values = form.value;
    this._onRegistrationDataChanged();
  }
  getRegisterAvailabilityForm2Values(): Form {
    if(!this._registerAvailabilityForm2Values) this._getDataFromStorage();
    return this._registerAvailabilityForm2Values;
  }

  setPrivacyFormValues(form: FormGroup) {
    this._privacyFormValues = form.value;
    this._onRegistrationDataChanged();
  }
  getPrivacyFormValues(): FormGroup {
    if(!this._privacyFormValues) this._getDataFromStorage();
    return this._privacyFormValues;
  }

  private _onRegistrationDataChanged() {
    var data = {
      jwtToken: this._jwtToken,
      refreshToken: this._refreshToken,
      userUuid: this._userUuid,
      provider: this._provider,
      incompleto: this._incompleto,
      requiredDocs: this._requiredDocs,
      email: this._email,
      announcementId: this._announcementId,
      registerAccountForm: this._registerAccountFormValues,
      registerPersonalDataForm: this._registerPersonalDataFormValues,
      registerResidenceForm: this._registerResidenceFormValues,
      registerDomicileForm: this._registerDomicileFormValues,
      registerAvailabilityForm: this._registerAvailabilityFormValues,
      registerAvailabilityForm2: this._registerAvailabilityForm2Values,
      privacyForm: this._privacyFormValues
    }
    localStorage.setItem('register_info_storage', JSON.stringify(data));
  }
  private _getDataFromStorage() {
    var dataFromString = JSON.parse(localStorage.getItem('register_info_storage'));
    if(dataFromString) {
      this._jwtToken = dataFromString.jwtToken;
      this._refreshToken = dataFromString.refreshToken;
      this._userUuid = dataFromString.userUuid;
      this._provider = dataFromString.provider;
      this._incompleto = dataFromString.incompleto;
      this._requiredDocs = dataFromString.requiredDocs;
      this._email = dataFromString.email;
      this._announcementId = dataFromString.announcementId;
      this._registerAccountFormValues = dataFromString.registerAccountForm;
      this._registerPersonalDataFormValues = dataFromString.registerPersonalDataForm;
      this._registerResidenceFormValues = dataFromString.registerResidenceForm;
      this._registerDomicileFormValues = dataFromString.registerDomicileForm;
      this._registerAvailabilityFormValues = dataFromString.registerAvailabilityForm;
      this._registerAvailabilityForm2Values = dataFromString.registerAvailabilityForm2;
      this._privacyFormValues = dataFromString.privacyForm;
    }
  }
  clear() {
    this._jwtToken = '';
    this._refreshToken = '';
    this._userUuid = '';
    this._provider = null;
    this._incompleto = false;
    this._requiredDocs = null;
    this._email = '';
    this._announcementId = '';
    this._registerAccountFormValues = null;
    this._registerPersonalDataFormValues = null;
    this._registerResidenceFormValues = null;
    this._registerDomicileFormValues = null;
    this._registerAvailabilityFormValues = null;
    this._registerAvailabilityForm2Values = null;
    this._privacyFormValues = null;
    this._onRegistrationDataChanged();
  }

}
