export const referralQueryParams = [
    {
        queryParamKey: 'utm_source',
        sessionStorageKey: 'campaign_type',
    },
    {
        queryParamKey: 'utm_medium',
        sessionStorageKey: 'origin_domain',
    },
    {
        queryParamKey: 'utm_campaign',
        sessionStorageKey: 'campaign_id',
    },
    {
        queryParamKey: 'utm_term',
        sessionStorageKey: 'campaign_name',
    },
    {
        queryParamKey: 'utm_partner',
        sessionStorageKey: 'partner_id',
    },
];

export const referralSessionStorageKeys = [...(referralQueryParams.map(param => param.sessionStorageKey))];

export interface ReferralData {
    campaign_type?: string;
    origin_domain?: string;
    campaign_id?: string;
    campaign_name?: string;
    partner_id?: string;
    is_login?: ActionType;
}

export enum ActionType {
    LOGIN = 'LOGIN',
    REGISTRATION = 'REGISTRAZIONE',
}