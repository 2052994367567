import { Pipe, PipeTransform } from '@angular/core';

/**
 * Fix date time from yyyy-MM-dd HH:mm to UTC date
 * (browser compatibility)
 */
@Pipe({ name: 'fixDateTime' })
export class FixDateTimePipe implements PipeTransform {
  transform(dateTime: string): string {
    if (dateTime && dateTime.includes(' ')) {
      const fixedDateTime =
        dateTime.split(' ')[0] + 'T' + dateTime.split(' ')[1] + '00.000Z';
      return fixedDateTime;
    }
    return dateTime;
  }
}
