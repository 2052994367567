import { Component, Input } from '@angular/core';
import { UserType } from 'src/app/core/auth/interfaces/role.model';
import { SuggestedJobOffer } from 'src/app/pages/private/pages/worker/pages/announcements/interfaces/suggested-job-offer.model';

@Component({
  selector: 'app-suggested-job-card',
  templateUrl: './suggested-job-card.component.html',
  styleUrls: ['./suggested-job-card.component.scss'],
})
export class SuggestedJobCardComponent {
  @Input() isPremium: boolean = false;
  @Input() userType?: UserType;
  @Input() job!: SuggestedJobOffer;
  @Input() favourite: boolean = false;
  UserType = UserType;
}
