import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-list-item',
  templateUrl: './message-list-item.component.html',
  styleUrls: ['./message-list-item.component.scss'],
})
export class MessageListItemComponent implements OnInit {
  @Input() title: string;
  @Input() text1: string;
  @Input() text2: string;
  @Input() proposalUuid: string;
  @Input() proporalNew: boolean;
  @Input() messageListItemClick: Function;
  constructor() {}

  ngOnInit(): void {}
}
