import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface arrayStar {
  name: string;
  alt: string;
}

@Component({
  selector: 'app-rating-stars',
  template: `
    <div class="rating-stars">
      <span class="star" *ngFor="let star of starsArray$ | async">
        <img [src]="'/assets/icons/' + star.name" [alt]="star.alt" [width]="15"/>
      </span>
    </div>
  `,
  styles: ['.rating-stars{display:inline-block} img{margin: 3px}'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RatingStarsComponent implements OnChanges {
  @Input() stars: number = 0;
  @Input() maxStars: number = 5;
  @Input() isBlue: boolean = false;

  starsArray$: BehaviorSubject<arrayStar[]> = new BehaviorSubject<arrayStar[]>(
    []
  );

  ngOnChanges(changes: SimpleChanges): void {
    this._setStars(changes.stars.currentValue);
  }

  private _setStars(stars: number) {
    this.starsArray$.next([]);
    var newArray = [];
    this._createRange(this.maxStars).forEach((star) => {
      newArray.push(this._determineIcon(star, stars));
    });
    this.starsArray$.next(newArray);
  }

  private _createRange(number: number) {
    return new Array(number).fill(0).map((n, index) => index + 1);
  }

  private _determineIcon(starNumber: number, starsInput: number): arrayStar {
    if (starNumber <= Math.floor(starsInput)) {
      return this.isBlue ? { name: 'Star_full_blue.svg', alt: 'full star' } : { name: 'full_star.svg', alt: 'full star' };
    } else if (starNumber > Math.ceil(starsInput)) {
      return { name: 'empty_star.svg', alt: 'empty star' };
    } else {
      return this.isBlue ? { name: 'Star_half_blue.svg', alt: 'half star' } : { name: 'Star_half.svg', alt: 'half star' };
    }
  }
}
