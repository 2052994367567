import { Component, Input } from '@angular/core';
import { DateInput } from '../../../models/inputs/date-input';
import { TuiDay } from '@taiga-ui/cdk';

@Component({
  selector: 'app-date-input',
  template: `<tui-input-date
    [readOnly]="control.readonly"
    [tuiTextfieldLabelOutside]="true"
    [formControl]="control"
    [min]="min"
    [max]="max"
  >
    <!-- {{ control.placeholder | translate }} -->
  </tui-input-date>`,
})
export class DateInputComponent {
  @Input('control') set _control (c: DateInput) {
    this.control = c;
    this.min = this.control.min as TuiDay;
    this.max = this.control.max as TuiDay;
  }
  @Input() name: string;
  control: DateInput;
  min: TuiDay;
  max: TuiDay;
}
